/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Button, Spin, Table, Dropdown, Space, Menu, Empty } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faTrash,
	faPenToSquare,
	faBan,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateDataBlock,
	updateOpenDrawerDataBlock,
	updateShowDataBlockTable,
	updateOpenFormDataBlock,
	updateOpenFormDataBlockAction,
	updateOpenModalDeleteDataBlock,
} from "../../../redux/tools/toolsSlice";
import { useLazyGetDataBlockTypesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// COMPONENTS
import DataBlocksHeader from "./DataBlocksHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

const { Column } = Table;

function DataBlocksTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	// eslint-disable-next-line no-unused-vars
	const [selectedItem, setSelectedItem] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [dataBlocksCatalog, setDataBlocksCatalog] = useState<any>([]);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	const [
		triggerGetDataBlocks,
		{
			data: dataDataBlocks,
			isLoading: isLoadingDataBlocks,
			isFetching: isFetchingDataBlocks,
		},
	] = useLazyGetDataBlockTypesQuery();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { dataBlockRefreshTable, showDataBlockTable } = useSelector(
		(state: any) => state.tool
	);

	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon
							className='generalStyles__error generalStyles__mrFix'
							icon={faBan}
						/>
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const EDIT = validatePermission("data-blocks-catalog", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalEdit();
							}}
							onKeyDown={() => {
								handleOpenModalEdit();
							}}
						>
							<span>
								<FontAwesomeIcon
									className='generalStyles__info generalStyles__mrFix'
									icon={faPenToSquare}
								/>
								{t("organizationManagement.edit")}
							</span>
						</div>
					),
					key: "2",
			  }
			: null;

		const DELETE = validatePermission("data-blocks-catalog", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalDelete();
							}}
							onKeyDown={() => {
								handleOpenModalDelete();
							}}
						>
							<span>
								<FontAwesomeIcon
									className='generalStyles__info generalStyles__mrFix'
									icon={faTrash}
								/>
								{t("organizationManagement.delete")}
							</span>
						</div>
					),
					key: "3",
			  }
			: null;

		// RETURN OBJECT WITH VALID ACTIONS
		if (EDIT || DELETE) {
			return [EDIT, DELETE];
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickShowForm = () => {
		setIsLoading(true);
		dispatch(updateShowDataBlockTable(true));
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const handleOpenModalEdit = () => {
		dispatch(updateDataBlock(selectedItem));
		dispatch(updateOpenFormDataBlock(true));
		dispatch(updateOpenFormDataBlockAction("update"));
	};

	const handleOpenModalDelete = () => {
		dispatch(updateDataBlock(selectedItem));
		dispatch(updateOpenModalDeleteDataBlock(true));
	};

	const onCellClick = (record: any) => {
		if (record) {
			dispatch(updateDataBlock(record));
			dispatch(updateOpenDrawerDataBlock(true));
		}
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetDataBlocks({
			token,
		});
	};

	const refreshTable = async () => {
		getInitialData();
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
	}, [showDataBlockTable]);

	useEffect(() => {
		if (dataBlockRefreshTable) {
			refreshTable();
		}
	}, [dataBlockRefreshTable]);

	useEffect(() => {
		if (dataDataBlocks && dataDataBlocks.data) {
			setDataBlocksCatalog(dataDataBlocks.data);
		}
	}, [dataDataBlocks]);

	// ************************************************ */
	// COMPONENT ************************************** */

	return (
		<div>
			{/** ----------------------------------------------------- */}
			{/** SHOW ALERT */}
			{!showDataBlockTable && (
				<div className='mb-4'>
					<Alert
						message={t("configuration.dataBlock.warning")}
						description={t("configuration.dataBlock.warningDescription")}
						type='warning'
						showIcon
						closable
					/>
					<Button
						className='buttonStyle__2 configuration__editRootPermisionsButton'
						onClick={onClickShowForm}
					>
						{t("configuration.dataBlock.showRootConfiguration")}
					</Button>
				</div>
			)}
			{/** ----------------------------------------------------- */}
			{/** DATA BLOCK FORM */}
			{showDataBlockTable && (
				<Spin spinning={isLoading || isLoadingDataBlocks}>
					{validatePermission("data-blocks-catalog", permissions) ? (
						<>
							<DataBlocksHeader />
							<Table
								rowClassName={(record, index) => getTableRowClass(index, theme)}
								dataSource={dataBlocksCatalog}
								pagination={{
									defaultPageSize: 10,
									showSizeChanger: true,
									pageSizeOptions: ["10", "25", "50", "100"],
								}}
								size='small'
								className='mt-3'
								loading={isFetchingDataBlocks}
							>
								{/** NAME */}
								<Column
									title='Name'
									dataIndex='name'
									key='name'
									className=''
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									render={(text) => (
										<div className='generalStyles__info'>{text || "--"}</div>
									)}
								/>
								{/** ACTIONS */}
								<Column
									title={t("edgeManagement.banks.actions")}
									key='action'
									render={(_: any, record: any) => (
										<Dropdown overlay={menu} trigger={["click"]} className=''>
											<Space
												className='generalStyles__hoverCursor'
												onClick={() => {
													setSelectedItem(record);
												}}
											>
												<FontAwesomeIcon icon={faEllipsis} />
											</Space>
										</Dropdown>
									)}
								/>
							</Table>
						</>
					) : (
						<>
							<div className='generalStyles__noAccessToListTable'>
								<Empty
									description={t(
										"organizationManagement.listNotAvailableOrganizations"
									)}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							</div>
						</>
					)}
				</Spin>
			)}
		</div>
	);
}

export default DataBlocksTable;
