import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import IconDefinitionTable from "./IconDefinitionTable";
import IconDefinitionForm from "./IconDefinitionForm";
import IconDefinitionDeleteModal from "./IconDefinitionDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

function IconDefinitionPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("vendors-catalog", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<IconDefinitionTable />
					<IconDefinitionForm />
					<IconDefinitionDeleteModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
}

export default IconDefinitionPage;
