import React, { useEffect, useState } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenModalControlPanel,
	updateLayoutSelected,
	updateModalControlPanelTab,
} from "../../../redux/monitoring/monitoringSlice";
import { udpateOpenModalTotpToken } from "../../../redux/authentication/authenticationSlice";
// COMPONENTS
import MonitoringLiftElevatorFullView from "./MonitoringLiftElevatorFullView";
import MonitoringElevatorFullViewName from "./MonitoringElevatorFullViewName";
// INTERFACES
import {
	MonitoringElevatorsFullViewInterface,
	LiftInterface,
	BankFloorInterface,
	SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function MonitoringElevatorsFullView({
	layout,
	socketMessage,
	iconDefinitionElevator,
}: MonitoringElevatorsFullViewInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [lastSocketMessage, setLastSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { totpTokenIsValid } = useSelector(
		(state: RootState) => state.authentication
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickOpenModalHallCalls = () => {
		dispatch(updateLayoutSelected(layout));
		dispatch(
			updateModalControlPanelTab({
				liftType: "ELEVATOR",
				tab: "HALL-CALLS",
			})
		);
		if (
			// eslint-disable-next-line no-constant-condition
			totpTokenIsValid ||
			process.env.REACT_APP_ENVIRONMENT === "OFFLINE" ||
			true
		) {
			dispatch(updateOpenModalControlPanel(true));
		} else {
			dispatch(udpateOpenModalTotpToken(true));
		}
	};

	const checkHallCalls = (floorNo: number) => {
		let hasHallCall = false;
		if (
			lastSocketMessage.hall_calls.up.front.includes(floorNo) ||
			lastSocketMessage.hall_calls.up.rear.includes(floorNo) ||
			lastSocketMessage.hall_calls.down.front.includes(floorNo) ||
			lastSocketMessage.hall_calls.down.rear.includes(floorNo)
		) {
			hasHallCall = true;
		}
		return hasHallCall;
	};

	const checkUpDownHallCall = (floorNo: number) => {
		if (
			lastSocketMessage.hall_calls.up.front.includes(floorNo) ||
			lastSocketMessage.hall_calls.up.rear.includes(floorNo)
		) {
			return true;
		}
		return false;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLastSocketMessage(socketMessage);
	}, [socketMessage]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{layout && layout.bank_floors && (
				<div
					className='monitoring__elevatorFullView__container'
					style={{ marginTop: "-20px" }}
				>
					{layout.bank_floors.map(
						(floor: BankFloorInterface, floorIndex: number) => (
							<div>
								<div className='generalStyles__flex' key={floorIndex}>
									{/** *********************************************************** */}
									{/** FLOORS */}
									<div
										className={`monitoring__card__floor generalStyles__mlFix ${
											layout && checkHallCalls(floor.floor_no)
												? `${
														checkUpDownHallCall(floor.floor_no)
															? `monitoring__card__floor__active__green__${theme}`
															: `monitoring__card__floor__active__red__${theme}`
												  } `
												: `${
														floor.front_door
															? `monitoring__card__floor__door__${theme}`
															: `monitoring__card__floor__inactive__${theme}`
												  }`
										}`}
										role='button'
										tabIndex={0}
										onKeyDown={() => {}}
										onClick={() => {
											if (floor.front_door) {
												onClickOpenModalHallCalls();
											}
										}}
									>
										<span>
											{(floor && floor.name.toUpperCase().substring(0, 2)) ||
												floor.floor_no ||
												"--"}
										</span>
									</div>
									{/** *********************************************************** */}
									{/** DOTS AND ELEVATOR CONTAINER */}
									<div className='monitoring__elevatorFullView__dotsContainer'>
										{/** LIFTS */}
										{layout && layout.lifts && (
											<div className='generalStyles__spaceEvenly monitoring__elevatorFullView__liftContainer'>
												{layout.lifts
													.slice() // Create a shallow copy to avoid mutating the original array
													.sort((a, b) => {
														// Compare values based on a specific property (e.g., lift.name)
														if (a.position < b.position) return -1;
														if (a.position > b.position) return 1;
														return 0;
													})
													.map((lift: LiftInterface, liftIndex: number) => (
														<div>
															<div key={liftIndex}>
																<MonitoringLiftElevatorFullView
																	layout={layout}
																	lift={lift}
																	socketMessage={
																		lift &&
																		lift.id === lastSocketMessage.lift_id
																			? lastSocketMessage
																			: null
																	}
																	floorIndex={floorIndex}
																	floor={floor}
																	iconDefinitionElevator={
																		iconDefinitionElevator
																	}
																/>
															</div>
														</div>
													))}
											</div>
										)}
										{/** DOTS STYLE */}
										<div className='monitoring__elevatorFullView__dotsStyleContainer'>
											<div
												className={`${
													layout &&
													layout.floorsInUse &&
													layout.floorsInUse.includes(floor.floor_no)
														? `monitoring__card__dotStyle__${theme}__active`
														: `monitoring__card__dotStyle__${theme}__inactive`
												}`}
											/>
										</div>
										{/** UP AND DOWN HALL CALLS DOTS STYLE */}
										<div className='monitoring__elevatorFullView__dotsStyleContainer'>
											<div
												className={`${
													checkHallCalls(floor.floor_no)
														? `${
																checkUpDownHallCall(floor.floor_no)
																	? "monitoring__card__dotStyle__hallCalls__green"
																	: "monitoring__card__dotStyle__hallCalls__red"
														  }`
														: ``
												}`}
											/>
										</div>
									</div>
									{/** *********************************************************** */}
									{/** FLOORS */}
									<div
										className={`monitoring__card__floor ${
											layout && checkHallCalls(floor.floor_no)
												? `${
														checkUpDownHallCall(floor.floor_no)
															? `monitoring__card__floor__active__green__${theme}`
															: `monitoring__card__floor__active__red__${theme}`
												  } `
												: `${
														floor.rear_door
															? `monitoring__card__floor__door__${theme}`
															: `monitoring__card__floor__inactive__${theme}`
												  }`
										}`}
										role='button'
										tabIndex={0}
										onKeyDown={() => {}}
										onClick={() => {
											if (floor.rear_door) {
												onClickOpenModalHallCalls();
											}
										}}
									>
										<span>
											{(floor && floor.name.toUpperCase().substring(0, 2)) ||
												floor.floor_no ||
												"--"}
										</span>
									</div>
								</div>
								{floorIndex + 1 === layout.bank_floors.length && (
									<div className='generalStyles__flex'>
										{/** *********************************************************** */}
										{/** FLOORS */}
										<div className='monitoring__card__floor generalStyles__mlFix'>
											<span />
										</div>
										{/** *********************************************************** */}
										{/** DOTS AND ELEVATOR CONTAINER */}
										<div className='monitoring__elevatorFullView__dotsContainer'>
											{/** LIFTS */}
											{layout && layout.lifts && (
												<div className='generalStyles__spaceEvenly monitoring__elevatorFullView__liftContainer'>
													{layout.lifts
														.slice() // Create a shallow copy to avoid mutating the original array
														.sort((a, b) => {
															// Compare values based on a specific property (e.g., lift.name)
															if (a.position < b.position) return -1;
															if (a.position > b.position) return 1;
															return 0;
														})
														.map((lift: LiftInterface, liftIndex: number) => (
															<div>
																<div
																	style={{
																		width: "35px",
																		height: "30px",
																		textAlign: "center",
																	}}
																	key={liftIndex}
																>
																	<MonitoringElevatorFullViewName
																		lift={lift}
																		layout={layout}
																		socketMessage={
																			lift &&
																			lift.id === lastSocketMessage.lift_id
																				? lastSocketMessage
																				: null
																		}
																	/>
																</div>
															</div>
														))}
												</div>
											)}
										</div>
										{/** *********************************************************** */}
										{/** FLOORS */}
										<div className='monitoring__card__floor'>
											<span />
										</div>
									</div>
								)}
							</div>
						)
					)}
				</div>
			)}
		</div>
	);
}

export default MonitoringElevatorsFullView;
