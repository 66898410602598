import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tag, Tooltip } from "antd";
// REDUX
import { useSelector } from "react-redux";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import {
	BankFloorInterface,
	MonitoringModalSettingsHeaderInterface,
	SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function ControlPanelHeader({
	lift,
	layout,
	socketMessage,
	iconDefinitionElevator,
}: MonitoringModalSettingsHeaderInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: RootState) => state.home);
	const { elevatorIconDefinitions, pannelFirstState } = useSelector(
		(state: RootState) => state.monitoring
	);
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [currentPositionNameFloor, setCurrentPositionNameFloor] = useState("");
	const [nextStopNameFloor, setNextStopNameFloor] = useState("");
	const [lastShortMesage, setLastShortMessage] = useState("elOutIndicator");
	const [lastSocketMessage, setLastSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getFloorName = (socketMessage_: SocketMessageInterface) => {
		layout.bank_floors.map((floor: BankFloorInterface) => {
			if (socketMessage_.position === floor.floor_no) {
				setCurrentPositionNameFloor(floor.name);
			}
			if (
				socketMessage_.car_calls &&
				(socketMessage_.car_calls.front[0] ||
					socketMessage_.car_calls.rear[0]) &&
				(socketMessage_.car_calls.front[0] === floor.floor_no ||
					socketMessage_.car_calls.rear[0] === floor.floor_no)
			) {
				setNextStopNameFloor(floor.name);
			}
			return true;
		});
	};

	const getCarCallName = (carCall: number) => {
		let floorName = "";
		if (layout) {
			layout.bank_floors.map((floor: BankFloorInterface) => {
				if (carCall === floor.floor_no) {
					floorName = floor.name;
				}
				return true;
			});
		}
		return floorName.substring(0, 2).toUpperCase();
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLastSocketMessage(socketMessage);
	}, [socketMessage]);

	useEffect(() => {
		setLastSocketMessage(pannelFirstState);
	}, [pannelFirstState]);

	useEffect(() => {
		getFloorName(lastSocketMessage);
		if (
			iconDefinitionElevator &&
			elevatorIconDefinitions.includes(
				iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()]
			)
		) {
			setLastShortMessage(
				iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()]
			);
		}
	}, [lastSocketMessage]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`montoringView__controlPanel__${theme}`}>
			<div
				className={`monitoringView__controlPanelDetails monitoringView__controlPanelDetails__${theme}`}
			>
				<div className='generalStyles__flexCenter monitoring__100pxBox2'>
					<div>
						<div style={{ textAlign: "center" }}>
							<IconDefinitionIcons
								liftType='ELEVATOR'
								icon={lastShortMesage}
								iconSize={50}
							/>
						</div>
						<div className='generalStyles__flexCenter'>
							<div style={{ textAlign: "center" }}>
								{lastSocketMessage.service_mode.name !== "" && (
									<Tooltip
										placement='top'
										title={`${lastSocketMessage.service_mode.name} - ${lastSocketMessage.service_mode.description}`}
									>
										<Tag
											color='cyan'
											style={{
												width: "85px",
												textAlign: "center",
												marginTop: "10px",
												marginLeft: "10px",
											}}
										>
											{lastSocketMessage.service_mode.name
												.toUpperCase()
												.substring(0, 9) || "--"}
										</Tag>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='generalStyles__mlFix25 generalStyles__width100'>
					<div>
						<span className='montoring__modalSettingsHeader__liftName'>
							{lift.name || "--"}
						</span>
						<span className='generalStyles__mrFix generalStyles__mlFix'>/</span>
						<span>{layout.name || "--"}</span>
					</div>
					<div>
						<p className='generalStyles__info'>
							{lastSocketMessage.icon.description.replace("_", " ")}
						</p>
					</div>
					{/** CAR CALLS */}
					<div className='generalStyles__flex montoring__modalSettingsHeader__carCallsContainer'>
						<div>{lastSocketMessage.car_calls ? "Calls" : "No Calls"}</div>
						<div className='generalStyles__flex'>
							{lastSocketMessage.car_calls &&
								lastSocketMessage.car_calls.front.length > 0 && (
									<>
										{lastSocketMessage.car_calls.front.map(
											(carCall: number, index: number) => (
												<div
													key={index}
													className={`monitoringView__headerCarCall monitoringView__headerCarCall__${theme}`}
												>
													{getCarCallName(carCall)}
												</div>
											)
										)}
									</>
								)}
							{lastSocketMessage.car_calls &&
								lastSocketMessage.car_calls.rear.length > 0 && (
									<>
										{lastSocketMessage.car_calls.rear.map(
											(carCall: number, index: number) => (
												<div
													key={index}
													className={`monitoringView__headerCarCall monitoringView__headerCarCall__${theme}`}
												>
													{getCarCallName(carCall)}
												</div>
											)
										)}
									</>
								)}
							{/**
               * {lastSocketMessage.assigned_hall_calls &&
								lastSocketMessage.assigned_hall_calls.length > 0 && (
									<>
										{lastSocketMessage.assigned_hall_calls.map(
											(hallCall: number, index: number) => (
												<div
													key={index}
													className={`monitoringView__headerCarCall monitoringView__headerHallCall__${theme}`}
												>
													{getCarCallName(hallCall)}
												</div>
											)
										)}
									</>
								)}
               */}
						</div>
					</div>
				</div>
				<div className='generalStyles__flex mt-1'>
					<div className='montoring__modalSettingsHeader__currentFloorContainer'>
						{currentPositionNameFloor}
					</div>
					{lastSocketMessage.car_calls &&
						(lastSocketMessage.car_calls.front.length > 0 ||
							lastSocketMessage.car_calls.rear.length > 0) && (
							<>
								<div>
									<FontAwesomeIcon
										icon={faCaretRight}
										className='montoring__modalSettingsHeader__icon'
									/>
								</div>
								<div className='montoring__modalSettingsHeader__currentFloorContainer'>
									{nextStopNameFloor}
								</div>
							</>
						)}
				</div>
			</div>{" "}
		</div>
	);
}

export default ControlPanelHeader;
