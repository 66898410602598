import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tree } from "antd";
import type { DataNode } from "antd/es/tree";
// REDUX
import { useSelector } from "react-redux";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUser,
	faBan,
	faCircleCheck,
	faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
// STYLES

library.add(faUser, faBan, faCircleCheck, faShieldHalved);

function RolesDinamicRolesTree({ setMenu, roleData, connectionType }: any) {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const [treeData, setTreeData] = useState<DataNode[]>([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [openKeys, setOpenKeys] = useState([]);
	const showLeafIcon = false;
	const { openModalCreateNewRole, openModalEditRole } = useSelector(
		(state: any) => state.role
	);
	const { userLogged } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onCloseComponent = () => {
		setTreeData([]);
		setSelectedKeys([]);
		setOpenKeys([]);
		setMenu({});
	};

	/**
	 * Generate an Array with all the tree_keys in permission JSON
	 * extract tree_key from. Page/subPages/Actioins
	 * If tree_key exist in this Array, Checkbox will be marked
	 */
	const buildTreeKeysArray = () => {
		const newSelectedKeys: any = [];
		roleData.menu.pages.map((page: any) => {
			page.sub_pages.map((subPage: any) => {
				subPage.actions.map((action: any) => {
					if (action && action[connectionType]) {
						newSelectedKeys.push(action.tree_key);
					}
					return true;
				});
				return true;
			});
			return true;
		});
		setSelectedKeys(newSelectedKeys);
	};

	const buildActionsTree = (action: any) => {
		const objectAction: any = {
			title: t(`organizationManagement.roles.${action.tree_key}`),
			key: action.tree_key,
			icon: action[connectionType] ? (
				<FontAwesomeIcon
					icon={faCircleCheck}
					className='generalStyles__success generalStyles__mrFix'
				/>
			) : (
				<FontAwesomeIcon
					icon={faBan}
					className='generalStyles__error generalStyles__mrFix'
				/>
			),
			children: [],
		};
		return objectAction;
	};

	const buildSubPagesTree = (subPage: any) => {
		const objectSubPage: any = {
			title: t(`organizationManagement.roles.${subPage.tree_key}`),
			key: subPage.name,
			children: [],
		};
		subPage.actions.map((action: any) => {
			const actionResponse = buildActionsTree(action);
			return objectSubPage.children.push(actionResponse);
		});
		return objectSubPage;
	};

	const buildPagesTree = (page: any) => {
		if (page.tree_key) {
			const objectPage: any = {
				title: t(`organizationManagement.roles.${page.tree_key}`),
				key: page.name,
				children: [],
			};
			page.sub_pages.map((subPage: any) => {
				const subPageResponse = buildSubPagesTree(subPage);
				return objectPage.children.push(subPageResponse);
			});
			return objectPage;
		}
		return false;
	};

	/**
	 *
	 */
	const buildPermissionsTree = (userLoggedPermissionsObj: any) => {
		const tree: any = [];
		// BUILD TREE PERMISSIONS
		userLoggedPermissionsObj.map((page: any) => {
			const pageResponse = buildPagesTree(page);
			if (pageResponse) {
				return tree.push(pageResponse);
			}
			return false;
		});
		// SAVE TREE DATA
		setTreeData(tree);
		// IF ACTION IS EDIT FILL PERMISSIONS FROM ROLE
		if (openModalEditRole && roleData) {
			buildTreeKeysArray();
		}
	};

	const buildNewMenuPagesTree = (
		pages: Array<Object>,
		checkedKeys: Array<string>
	) => {
		const MENU = Object.freeze(pages);
		const newMenu: any = {
			active: true,
			offline: true,
			name: roleData && roleData.name ? roleData.name : "",
			pages: [],
		};
		MENU.map((page: any, pageIndex: number) => {
			newMenu.pages.push({
				actions: [],
				// active: true,
				active: page[connectionType],
				offline: true,
				name: page.name,
				sub_pages: [],
				tree_key: page.tree_key,
			});
			page.sub_pages.map((subPage: any, subPageIndex: number) => {
				newMenu.pages[pageIndex].sub_pages.push({
					actions: [],
					// active: true,
					active: subPage[connectionType],
					offline: true,
					name: subPage.name,
					sub_pages: [],
					tree_key: subPage.tree_key,
				});
				subPage.actions.map((action: any) => {
					checkedKeys.map((checkedKey: string) => {
						if (checkedKey === action.tree_key) {
							newMenu.pages[pageIndex].sub_pages[subPageIndex].actions.push({
								// active: true,
								active: action[connectionType],
								offline: true,
								name: action.name,
								tree_key: action.tree_key,
								uri: action.uri,
							});
						}
						return true;
					});
					return true;
				});
				return true;
			});
			return true;
		});
		return newMenu;
	};

	const removeBlankSubPages = (newMenu: any) => {
		for (let pageIndex = 0; pageIndex < newMenu.pages.length; pageIndex += 1) {
			for (
				let subPageIndex = 0;
				subPageIndex < newMenu.pages[pageIndex].sub_pages.length;
				subPageIndex += 1
			) {
				if (
					newMenu.pages[pageIndex].sub_pages[subPageIndex].actions.length === 0
				) {
					newMenu.pages[pageIndex].sub_pages.splice(subPageIndex, 1);
					subPageIndex -= 1;
				}
			}
		}
		return newMenu;
	};

	const removeBlankPages = (newMenu: any) => {
		for (let pageIndex = 0; pageIndex < newMenu.pages.length; pageIndex += 1) {
			if (newMenu.pages[pageIndex].sub_pages.length === 0) {
				newMenu.pages.splice(pageIndex, 1);
				pageIndex -= 1;
			}
		}
		return newMenu;
	};

	const assignPermissionsTree = (pages: any, checkedKeys: Array<string>) => {
		// BUILD NEW MENU
		let newMenu: any = buildNewMenuPagesTree(pages, checkedKeys);
		// REMOVE FALSE/BLANK SUB PAGES
		newMenu = removeBlankSubPages(newMenu);
		// REMOVE FALSE/BLANK PAGES
		newMenu = removeBlankPages(newMenu);
		// SAVE NEW MENU
		setMenu(newMenu);
	};

	const onCheck = (checkedKeys: any) => {
		if (
			userLogged &&
			userLogged.role &&
			userLogged.role.menu &&
			userLogged.role.menu.pages
		) {
			assignPermissionsTree(userLogged.role.menu.pages, checkedKeys);
			setSelectedKeys(checkedKeys);
		}
	};

	const onExpand = (expandedKeysValue: any) => {
		setOpenKeys(expandedKeysValue);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			onCloseComponent();
		};
	}, []);

	useEffect(() => {
		if (openModalCreateNewRole || openModalEditRole) {
			if (
				userLogged &&
				userLogged.role &&
				userLogged.role.menu &&
				userLogged.role.menu.pages
			) {
				buildPermissionsTree(userLogged.role.menu.pages);
			}
		}
	}, [openModalCreateNewRole, openModalEditRole]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{treeData && treeData.length > 0 && (
				<Tree
					icon={
						<FontAwesomeIcon
							icon={faShieldHalved}
							className='generalStyles__info generalStyles__mrFix'
						/>
					}
					checkable
					onExpand={onExpand}
					onCheck={onCheck}
					checkedKeys={selectedKeys}
					showLine={{ showLeafIcon }}
					showIcon
					expandedKeys={openKeys}
					treeData={treeData}
				/>
			)}
		</div>
	);
}

export default RolesDinamicRolesTree;
