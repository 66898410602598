import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import SpecialSwitchesTable from "./SpecialSwitchesTable";
import SpecialSwitchesDrawer from "./SpecialSwitchesDrawer";
import SpecialSwitchesForm from "./SpecialSwitchesForm";
import SpecialSwitchesDeleteModal from "./SpecialSwitchesDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

// eslint-disable-next-line arrow-body-style
const SpecialSwitchesPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("special-switches", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<SpecialSwitchesTable />
					<SpecialSwitchesDrawer />
					<SpecialSwitchesForm />
					<SpecialSwitchesDeleteModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default SpecialSwitchesPage;
