import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const loginAPI = createApi({
	reducerPath: "loginAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: [],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ME OFFLINE PASSWORD */
		getMeOfflinePSW: builder.query({
			query: (body: any) => ({
				url: `/users/offline-psw`,
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* POST ME OFFLINE PASSWORD */
		postMeOfflinePSW: builder.mutation({
			query: (body: any) => ({
				url: `/users/offline-psw`,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* POST USER OFFLINE PASSWORD */
		postUserPsw: builder.mutation({
			query: (body: any) => ({
				url: `/users/offline-psw/${body.body.user_id}`,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET USER OFFLINE PASSWORD */
		getUserPsw: builder.query({
			query: (body: any) => ({
				url: `/users/offline-psw/${body.body.user_id}`,
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* POST LOGIN OFFLINE */
		postLoginOffline: builder.mutation({
			query: (body: any) => ({
				url: `/offline/login`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetMeOfflinePSWQuery,
	usePostMeOfflinePSWMutation,
	useLazyGetUserPswQuery,
	usePostUserPswMutation,
	usePostLoginOfflineMutation,
} = loginAPI;
