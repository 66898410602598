import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import UsersTable from "./UsersTable";
import UsersHeader from "./UsersHeader";
import UsersCreateEditForm from "./UsersCreateEditForm";
import UsersDrawer from "./UsersDrawer";
import UsersDeleteUserModal from "./UsersDeleteUserModal";
import UsersResetPasswordModal from "./UsersResetPasswordModal";
import UsersBlockUnBlockModal from "./UsersBlockUnBlockModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

// eslint-disable-next-line arrow-body-style
const UserPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("users", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<UsersHeader />
					<UsersTable />
					<UsersCreateEditForm />
					<UsersDrawer />
					<UsersDeleteUserModal />
					<UsersResetPasswordModal />
					<UsersBlockUnBlockModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default UserPage;
