import React from "react";
// ANT DESIGN COMPONENTS
import { Button, Table } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
import CustomAmplifyCarousel from "../login/CustomAmplifyCarousel";

function SupportPolicy() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Column } = Table;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='loginStyles__body'>
			{/** ************************************************** */}
			<div className='loginStyles__loginSubBox1'>
				<CustomAmplifyCarousel />
			</div>
			<div className='loginStyles__loginSubBox2'>
				<div
					style={{
						textAlign: "justify",
						overflowY: "scroll",
						height: "100%",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent: "flex-end",
							padding: "30px",
						}}
					>
						<div>
							<Button
								style={{
									backgroundColor: "#969696",
									color: "white",
								}}
								shape='circle'
								icon={<FontAwesomeIcon icon={faX} />}
								onClick={() => {
									window.location.href = "/login";
								}}
							/>
						</div>
					</div>
					<div style={{ padding: "0px 120px 50px 120px" }}>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 1 */}
						<h2>Service & Support Policy</h2>
						<p>Last Updated Date: [23 April 2024]</p>
						<p>
							At Hyperion Solutions, we have extensive experience monitoring
							elevators, escalators, and moving walks. Our LiftNet Monitoring
							systems utilize state-of-the-art hardware and software, to provide
							the most complete package of monitoring and control features in
							the industry. LiftNet monitors all brands of elevators,
							escalators, and moving walks in a single interface that is
							accessible in the cloud or only within your secure network.
							LiftNet users experience a secure, reliable, and consistent user
							interface, regardless of the brand of elevator or escalator being
							monitored.
						</p>
						<br />
						<h4>Contacting Technical Support</h4>
						<p>
							LiftNet is building an organization focused on providing
							world-class service and support to our customers. We are proud of
							our products, our service and support, and our dedication to the
							customer. It is our duty to ensure that we meet and exceed the
							needs of our customers
						</p>
						<p>
							Our support system can be accessed via our website, email, or
							telephone. Our hours of operation are:
						</p>
						<p>Monday through Friday, from 8:30AM to 5:00PM CST</p>
						<p>
							The most efficient way to communicate with our service department
							is via our website at{" "}
							<a href='https://liftnet.com/support/'>
								https://liftnet.com/support/
							</a>
							. Submitting a ticket using the support form on the website
							automatically generates a tracking ticket that is then assigned to
							the appropriate engineer.
						</p>
						<p>
							You may alternately contact us via email at
							<a href='liftnetservice@hyperion-solutions.com'>
								liftnetservice@hyperion-solutions.com
							</a>
							. Your email request will be visible to all service personnel and
							a tracking ticket manually created
						</p>
						<p>
							You can also call our service department via phone to speak with a
							Customer Support Representative at:
						</p>
						<h5 className='generalStyles__mlFix25'>
							(847) 475-2476 or (708) 298-9661
						</h5>
						<p>
							It is essential that you leave a message if you do not talk to
							someone. Your voicemail should include your name, your company,
							the best phone number you can be reached at, and a description of
							the problem you are experiencing that includes any error code,
							part number verification, and serial number of relevant
							controllers.
						</p>
						<p>
							You will receive a call back, during our hours of operation, from
							one of our service engineers. If the reason for your call cannot
							be addressed and resolved on that initial call-back, your call
							will be escalated to our product team.
						</p>
						<p>
							Problem resolution will be conducted via phone or online with a
							TeamViewer session as appropriate.
						</p>
						<p>
							Please refrain from directly contacting our Field Service
							Engineers to report an issue. This will result in a delayed
							response to your problem. Always reach out to our service email or
							phone numbers above.
						</p>
						<br />
						<h4>Requesting On-Site Technical Support or Product Training</h4>
						<p>
							A request for on-site technical support or product training starts
							with the creation of a tracking ticket as specified above. For
							LiftNet 2 customers and those with LiftNet 1 maintenance
							agreements there is normally no additional cost. Scheduling and
							logistics will be coordinated by our Project Management team. If
							payment is necessary, a PO must be received before a visit can be
							scheduled.
						</p>
						<br />
						<h4>Service Level Agreement</h4>
						<p>
							The severity of an incoming service request is assessed based on
							the technical severity and customer impact of the problem as
							reported. Items in the support queue will be addressed based on
							the following priority levels.
						</p>
						<Table
							className='customTable'
							bordered
							pagination={false}
							dataSource={[
								{
									severityLevel: "1: Full System Outage",
									workAround: "NO",
									description:
										"An incident causing the LiftNet service to be inoperable or inaccessible to end users.",
								},
								{
									severityLevel: "2: Partial System Outage",
									workAround: "NO",
									description:
										"An incident causing a portion of the LiftNet service to be inoperable or inaccessible to end users.",
								},
								{
									severityLevel: "3: Feature Outage",
									workAround: "YES",
									description:
										"An incident that affects the operation of a portion of the LiftNet service but for which a workaround circumventing the problem is available.",
								},
								{
									severityLevel:
										"4: Functional defect, cosmetic issue, or new feature request",
									workAround: "YES",
									description:
										"An incident that doesn’t impact business or product operations or is a new feature request.",
								},
							]}
							size='small'
						>
							{/** SEVERITY LEVEL */}
							<Column
								title='Severity Level'
								dataIndex='severityLevel'
								key='severityLevel'
								render={(text) => <p>{text}</p>}
							/>
							{/** WORK AROUND */}
							<Column
								title='Work Around  Available?'
								dataIndex='workAround'
								align='center'
								key='workAround'
								render={(text) => <p>{text}</p>}
							/>
							{/** DESCRIPTION */}
							<Column
								title='Description'
								dataIndex='description'
								key='description'
								render={(text) => <p>{text}</p>}
							/>
						</Table>
						<br />
						<br />
						<h2>On-Site Field Service Cost</h2>
						<div className='generalStyles__flex'>
							<p style={{ width: "450px" }}>
								Standard Support or Training (M-F, 8am-5pm Central)
							</p>
							<p>$3,500 / day</p>
						</div>
						<div className='generalStyles__flex'>
							<p style={{ width: "450px" }}>Weekday after hours</p>
							<p>$6,000 / day</p>
						</div>
						<div className='generalStyles__flex'>
							<p style={{ width: "450px" }}>Weekends</p>
							<p>$6,500 / day</p>
						</div>
						<div className='generalStyles__flex'>
							<p style={{ width: "450px" }}>Express On-Site Support</p>
							<p>$2,500 / day surcharge for rush visits</p>
						</div>
						<br />
						<br />
						<br />
						<div>
							<Button
								onClick={() => {
									window.location.href = "/login";
								}}
								className='buttonStyle__6'
							>
								Back To Login
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SupportPolicy;
