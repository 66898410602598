import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetLiftsQuery } from "../../../redux/lifts/liftAPI";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterGetLifts() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [lifts, setLifts] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetLifts, { data, isLoading, isError, isFetching }] =
		useLazyGetLiftsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initialData = async () => {
		const token = await GETJwtToken();
		triggerGetLifts({
			page: 1,
			limit: 9999,
			token,
		});
	};

	const onChangeLift = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const index = lifts.findIndex((item: any) => item.id === newValue);
		if (lifts[index]) {
			copy.liftId = lifts[index].id;
			copy.liftName = lifts[index].name;
			if (lifts[index].bank) {
				copy.bankId = lifts[index].bank.id;
				copy.bankName = lifts[index].bank.name;
			}
		}
		copy.liftId = newValue;
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		initialData();
	}, []);

	useEffect(() => {
		const newData = structuredClone(data);
		if (newData && newData.data) {
			setLifts(newData.data);
		}
	}, [data]);

	useEffect(() => {
		setLifts([]);
	}, [isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			<Tooltip title={lifts.length === 0 ? t("report.noLifts") : ""}>
				<Select
					className='generalStyles__width100'
					disabled={lifts.length === 0}
					showSearch
					size='small'
					optionFilterProp='children'
					placeholder={t("report.selectLift")}
					loading={isLoading || isFetching}
					value={filters.liftId === "" ? null : filters.liftId}
					filterOption={(input: string, option: any) =>
						option.children.toLowerCase().includes(input.toLowerCase())
					}
					onChange={onChangeLift}
				>
					{lifts.map((lift: any) => (
						<Option key={lift.id} value={lift.id}>
							{lift.name}
						</Option>
					))}
				</Select>
			</Tooltip>
		</div>
	);
}

export default ReportsFilterGetLifts;
