import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Button, Table, Empty, Tag, Input, Select, Tooltip } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateAction,
	updateShowTable,
	updateFormAction,
} from "../../../redux/actions/actionsSlice";
import {
	useLazyGetActionsQuery,
	usePostActionMutation,
	usePutActionMutation,
} from "../../../redux/actions/actionsAPI";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import ActionsTableActions from "./ActionsTableActions";
import ActionsHeader from "./ActionsHeader";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { ActionInterface } from "../../../interfaces/Actions.interface";

function ActionsTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column, ColumnGroup } = Table;
	const BLANK_ACTION = {
		id: "",
		cmd: "",
		label: "",
		tooltip: "",
		valid_states: [],
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [isLoading, setIsLoading] = useState(false);
	const [actions, setActions] = useState<ActionInterface[]>([]);
	const [action, setAction] = useState<ActionInterface>(BLANK_ACTION);
	const [errorMessage, setErrorMessage] = useState<any>({});
	const [totalRows, setTotalRows] = useState<number>(0);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetActions,
		{
			data: dataGetActions,
			isLoading: isLoadingGetActions,
			isFetching: isFetchingGetActions,
			isError: isErrorGetActions,
			error: errorGetActions,
		},
	] = useLazyGetActionsQuery();

	const [
		triggerPostAction,
		{
			isSuccess: isSuccessPostAction,
			isLoading: isLoadingPostAction,
			isError: isErrorPostAction,
			error: errorPostAction,
			reset: resetPostAction,
		},
	] = usePostActionMutation();

	const [
		triggerPutAction,
		{
			isSuccess: isSuccessPutAction,
			isLoading: isLoadingPutAction,
			isError: isErrorPutAction,
			error: errorPutAction,
			reset: resetPutAction,
		},
	] = usePutActionMutation();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: any) => state.user);
	const {
		refreshTable,
		showTable,
		formAction,
		action: selectedAction,
	} = useSelector((state: RootState) => state.action);

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickShowForm = () => {
		setIsLoading(true);
		dispatch(updateShowTable(true));
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetActions({
			token,
		});
	};

	const onClickRefreshTable = async () => {
		getInitialData();
	};

	const onChangeFormValue = (newValue: string, variableName: string) => {
		const copy: ActionInterface = structuredClone(action);
		copy[variableName] = newValue;
		setAction(copy);
	};

	const onChangeValidStates = (newValue: string[]) => {
		const copy: ActionInterface = structuredClone(action);
		copy.valid_states = newValue;
		setAction(copy);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (formAction === "create") {
			const BODY = {
				token,
				body: action,
			};
			triggerPostAction(BODY);
		} else {
			const copy = structuredClone(action);
			delete copy.banks;
			delete copy.adapters;
			const BODY = {
				token,
				id: action.id,
				body: copy,
			};
			triggerPutAction(BODY);
		}
	};

	const resetForm = () => {
		resetPostAction();
		resetPutAction();
		setAction(BLANK_ACTION);
		setErrorMessage({});
		dispatch(updateFormAction("create"));
		dispatch(updateAction(BLANK_ACTION));
	};

	const checkFormIsValid = () => {
		let formIsValid = true;
		if (action.cmd === "" || action.label === "" || action.tooltip === "") {
			formIsValid = false;
		}
		return formIsValid;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (showTable) {
			getInitialData();
		}
	}, [showTable]);

	useEffect(() => {
		if (refreshTable) {
			onClickRefreshTable();
		}
	}, [refreshTable]);

	useEffect(() => {
		if (dataGetActions && dataGetActions.data) {
			setActions(dataGetActions.data);
			setTotalRows(dataGetActions.total);
		}
	}, [dataGetActions]);

	useEffect(() => {
		if (errorGetActions) {
			setErrorMessage(errorGetActions);
			setActions([]);
		}
	}, [errorGetActions]);

	useEffect(() => {
		resetForm();
	}, [
		isSuccessPostAction,
		isSuccessPutAction,
		isErrorPostAction,
		isErrorPutAction,
	]);

	useEffect(() => {
		if (formAction === "create") {
			setAction(BLANK_ACTION);
		}
	}, [formAction]);

	useEffect(() => {
		setAction(selectedAction);
	}, [selectedAction]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ----------------------------------------------------- */}
			{/** SHOW ALERT */}
			{!showTable && (
				<div className='mb-4'>
					<Alert
						message={t("general.warning")}
						description={t("configuration.action.warningDescription")}
						type='warning'
						showIcon
						closable
					/>
					<Button
						className='buttonStyle__2 configuration__editRootPermisionsButton'
						onClick={onClickShowForm}
					>
						{t("configuration.action.showRootConfiguration")}
					</Button>
				</div>
			)}
			{/** ----------------------------------------------------- */}
			{/** ACTION FORM */}
			{showTable && (
				<div>
					{validatePermission("permission-not-defined", permissions) ? (
						<>
							<ActionsHeader />
							<Table
								locale={{
									emptyText: (
										<TableNoDataBox
											isError={isErrorGetActions}
											errorMessage={
												errorMessage &&
												errorMessage.data &&
												errorMessage.data.message
													? errorMessage.data.message
													: "Error"
											}
											noItemsFound='No actions found'
										/>
									),
								}}
								rowClassName={(record, index) => getTableRowClass(index, theme)}
								dataSource={actions}
								pagination={{
									defaultPageSize: 10,
									showSizeChanger: true,
									pageSizeOptions: ["10", "25", "50", "100"],
								}}
								size='small'
								className='mt-3'
								loading={
									isFetchingGetActions ||
									isLoading ||
									isLoadingGetActions ||
									isLoadingPostAction ||
									isLoadingPutAction
								}
								footer={() => (
									<div className='generalStyles__flexEnd'>
										<Tag>{totalRows} Rows</Tag>
									</div>
								)}
							>
								{/** COMMAND */}
								<ColumnGroup title='Command'>
									<Column
										width={200}
										title={
											<Input
												size='small'
												placeholder='Command'
												value={formAction === "create" ? action.cmd : ""}
												disabled={formAction === "edit"}
												onChange={(
													event: React.FormEvent<HTMLInputElement>
												) => {
													onChangeFormValue(event.currentTarget.value, "cmd");
												}}
											/>
										}
										dataIndex='cmd'
										key='cmd'
										className=''
										render={(text, record: ActionInterface) => (
											<div>
												{formAction === "edit" && record.id === action.id ? (
													<Input
														size='small'
														placeholder='Command'
														value={action.cmd}
														onChange={(
															event: React.FormEvent<HTMLInputElement>
														) => {
															onChangeFormValue(
																event.currentTarget.value,
																"cmd"
															);
														}}
													/>
												) : (
													<Tag color='cyan' className='drawer__typeTag'>
														{text}
													</Tag>
												)}
											</div>
										)}
									/>
								</ColumnGroup>
								{/** VALID STATES */}
								<ColumnGroup title='Valid States'>
									<Column
										title={
											<Select
												size='small'
												mode='tags'
												style={{ width: "100%" }}
												placeholder='Tags Mode'
												value={
													formAction === "create" ? action.valid_states : []
												}
												disabled={formAction === "edit"}
												onChange={onChangeValidStates}
												options={[]}
											/>
										}
										dataIndex='validStates'
										key='validStates'
										className=''
										render={(text: any, record: ActionInterface) => (
											<div>
												{formAction === "edit" && record.id === action.id ? (
													<Select
														size='small'
														mode='tags'
														style={{ width: "100%" }}
														placeholder='Tags Mode'
														value={action.valid_states}
														onChange={onChangeValidStates}
														options={[]}
													/>
												) : (
													<div>
														{record.valid_states.map((item: string) => (
															<Tag color='magenta'>{item}</Tag>
														))}
													</div>
												)}
											</div>
										)}
									/>
								</ColumnGroup>
								{/** LABEL */}
								<ColumnGroup title='Label'>
									<Column
										title={
											<Input
												size='small'
												placeholder='Label'
												value={formAction === "create" ? action.label : ""}
												disabled={formAction === "edit"}
												onChange={(
													event: React.FormEvent<HTMLInputElement>
												) => {
													onChangeFormValue(event.currentTarget.value, "label");
												}}
											/>
										}
										dataIndex='label'
										key='label'
										className=''
										render={(text, record: ActionInterface) => (
											<div>
												{formAction === "edit" && record.id === action.id ? (
													<Input
														size='small'
														placeholder='Label'
														value={action.label}
														onChange={(
															event: React.FormEvent<HTMLInputElement>
														) => {
															onChangeFormValue(
																event.currentTarget.value,
																"label"
															);
														}}
													/>
												) : (
													<span>{text || "--"}</span>
												)}
											</div>
										)}
									/>
								</ColumnGroup>

								{/** TOOLTIP */}
								<ColumnGroup title='Tooltip'>
									<Column
										title={
											<Input
												size='small'
												placeholder='Tooltip'
												value={formAction === "create" ? action.tooltip : ""}
												disabled={formAction === "edit"}
												onChange={(
													event: React.FormEvent<HTMLInputElement>
												) => {
													onChangeFormValue(
														event.currentTarget.value,
														"tooltip"
													);
												}}
											/>
										}
										dataIndex='tooltip'
										key='tooltip'
										className=''
										render={(text, record: ActionInterface) => (
											<div>
												{formAction === "edit" && record.id === action.id ? (
													<Input
														size='small'
														placeholder='Tooltip'
														value={action.tooltip}
														onChange={(
															event: React.FormEvent<HTMLInputElement>
														) => {
															onChangeFormValue(
																event.currentTarget.value,
																"tooltip"
															);
														}}
													/>
												) : (
													<span>{text || "--"}</span>
												)}
											</div>
										)}
									/>
								</ColumnGroup>
								{/** ACTIONS */}
								<ColumnGroup
									align='center'
									title={t("edgeManagement.banks.actions")}
								>
									<Column
										align='center'
										title={
											<div className='generalStyles__flexCenter'>
												<div className=''>
													<Tooltip title={t("general.save")}>
														<Button
															className={
																formAction === "create" && checkFormIsValid()
																	? "buttonStyle__10"
																	: ""
															}
															disabled={
																!checkFormIsValid() || formAction === "edit"
															}
															onClick={onClickSave}
															icon={<FontAwesomeIcon icon={faSave} />}
														/>
													</Tooltip>
												</div>
												<div className='generalStyles__mlFix'>
													<Tooltip title={t("general.clear")}>
														<Button
															className={
																formAction === "create" ? "buttonStyle__14" : ""
															}
															onClick={resetForm}
															disabled={formAction === "edit"}
															icon={<FontAwesomeIcon icon={faX} />}
														/>
													</Tooltip>
												</div>
											</div>
										}
										key='action'
										render={(_: any, record: ActionInterface) => (
											<div>
												{formAction === "edit" && record.id === action.id ? (
													<div className='generalStyles__flexCenter'>
														<div className=''>
															<Tooltip title={t("general.save")}>
																<Button
																	className='buttonStyle__10'
																	disabled={!checkFormIsValid()}
																	onClick={onClickSave}
																	icon={<FontAwesomeIcon icon={faSave} />}
																/>
															</Tooltip>
														</div>
														<div className='generalStyles__mlFix'>
															<Tooltip title={t("general.cancel")}>
																<Button
																	className='buttonStyle__14'
																	onClick={resetForm}
																	icon={<FontAwesomeIcon icon={faX} />}
																/>
															</Tooltip>
														</div>
													</div>
												) : (
													<ActionsTableActions selectedItem={record} />
												)}
											</div>
										)}
									/>
								</ColumnGroup>
							</Table>
						</>
					) : (
						<>
							<div className='generalStyles__noAccessToListTable'>
								<Empty
									description={t(
										"organizationManagement.listNotAvailableOrganizations"
									)}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							</div>
						</>
					)}
				</div>
			)}
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostAction}
				isSuccess={isSuccessPostAction}
				requestType='POST'
				error={errorPostAction}
				name='Action'
			/>
			<GlobalAlert2
				isError={isErrorPutAction}
				isSuccess={isSuccessPutAction}
				requestType='PUT'
				error={errorPutAction}
				name='Action'
			/>
		</div>
	);
}

export default ActionsTable;
