import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Row, Col, Card, Button } from "antd";
// FONT AWESOME ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircle,
	faInfoCircle,
	faStar,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetFavoriteGridsQuery } from "../../../redux/monitoring/monitoringAPI";
import { usePutUserMutation } from "../../../redux/users/userAPI";
import {
	updateCascaderSelectMultipleSelectionOptions,
	updateTopViewLayout,
	updateDetailViewLayout,
	updateStatusViewLayout,
	updateIdGridSelected,
} from "../../../redux/monitoring/monitoringSlice";
import { updateUserLogged } from "../../../redux/home/homeSlice";
import { updateOrganizationSelectMultipleData } from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function MonitoringSelectFirstGrid() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listFavoriteGrids, setListFavoriteGrids] = useState<any>([]);
	const [gridSelected, setGridSelected] = useState<any>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { userLogged } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetFavoriteGrids,
		{ data: dataGetFavoriteGrids, isLoading, isFetching },
	] = useLazyGetFavoriteGridsQuery();
	const [putUser, { isLoading: isLoadingPutUser }] = usePutUserMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetFavoriteGrids({ token });
	};

	const saveConfiguration = async () => {
		const token = await GETJwtToken();
		const BODY = {
			id: userLogged.id,
			token,
			body: { ...userLogged, pref_grid_id: gridSelected.id },
		};
		const response = await putUser(BODY).unwrap();
		if (response && response.data) {
			dispatch(updateUserLogged(response.data));
			// Save local favorite grid
			const jsonObject = JSON.parse(gridSelected.grid);
			dispatch(updateIdGridSelected(gridSelected.id));
			dispatch(updateTopViewLayout(jsonObject.topViewLayout));
			dispatch(updateDetailViewLayout(jsonObject.detailViewLayout));
			dispatch(updateStatusViewLayout(jsonObject.statusViewLayout));
			dispatch(
				updateOrganizationSelectMultipleData(
					jsonObject.organizationSelectMultipleData
				)
			);
			dispatch(
				updateCascaderSelectMultipleSelectionOptions(
					jsonObject.cascaderSelectMultipleSelectionOptions
				)
			);
		}
	};

	const onClickFavoriteGrids = (grid: any) => {
		setGridSelected(grid);
	};

	const filterFirstGridByOrganization = (gridList: any) => {
		const newArr: any = [];
		gridList.map((grid: any) => {
			let gridIsValid = true;
			grid.organizationSelectMultipleData.map((org: any) => {
				if (org.idOrganization !== userLogged.org.id) {
					gridIsValid = false;
				}
				return true;
			});
			if (gridIsValid) {
				newArr.push(grid);
			}
			return true;
		});

		setListFavoriteGrids(newArr);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (dataGetFavoriteGrids && dataGetFavoriteGrids.data) {
			const newData: any = [];
			dataGetFavoriteGrids.data.map((grid: any) => {
				let organizationSelectMultipleData = [];
				if (grid && grid.grid) {
					const parseString = JSON.parse(grid.grid);
					organizationSelectMultipleData =
						parseString.organizationSelectMultipleData;
				}
				newData.push({ ...grid, organizationSelectMultipleData });
				return true;
			});
			if (
				userLogged &&
				userLogged.role &&
				userLogged.role.name !== process.env.REACT_APP_ROLE_MASTER_NAME
			) {
				filterFirstGridByOrganization(newData);
			} else {
				setListFavoriteGrids(newData);
			}
		}
	}, [dataGetFavoriteGrids]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div>
				<Card>
					<p>
						<span>
							<FontAwesomeIcon
								icon={faInfoCircle}
								className='generalStyles__warning generalStyles__mrFix'
							/>
						</span>{" "}
						Select your preferred layout to skip this screen and access your
						chosen layout directly during future logins
					</p>
				</Card>

				<Row gutter={12}>
					{listFavoriteGrids.map((grid: any) => (
						<Col span={6} className='mt-3'>
							<div
								className={`monitoring__selectFirst__cardRadius  ${
									gridSelected.id === grid.id
										? "monitoring__cardBorderSelected"
										: "monitoring__cardHoverWarning"
								}`}
								role='button'
								tabIndex={0}
								onClick={() => {
									onClickFavoriteGrids(grid);
								}}
								onKeyDown={() => {}}
							>
								<Card className='monitoring__selectFirst__card' hoverable>
									<div className='generalStyles__spaceBetween'>
										<div
											className={
												gridSelected.id === grid.id
													? "generalStyles__textEllipsis generalStyles__warning"
													: "generalStyles__textEllipsis monitoring__cardIconHover"
											}
										>
											{grid.name}
										</div>
										<div>
											<FontAwesomeIcon
												icon={faStar}
												border={false}
												className={
													gridSelected.id === grid.id
														? "generalStyles__warning"
														: "monitoring__cardButtonHover"
												}
											/>
										</div>
									</div>
									<div className='mt-3 monitoring__selectFirst__listContainer'>
										{grid.organizationSelectMultipleData.map((item: any) => (
											<div>
												<FontAwesomeIcon
													icon={faCircle}
													className={
														gridSelected.id === grid.id
															? "generalStyles__mrFix generalStyles__warning"
															: "generalStyles__mrFix monitoring__cardIconHover"
													}
													fontSize={7}
													border={false}
												/>
												{item.name || ""}
											</div>
										))}
									</div>
								</Card>
							</div>
						</Col>
					))}
				</Row>
				{listFavoriteGrids.length === 0 && (
					<Row gutter={16}>
						<Col span={4}>
							<Card
								className='mt-3 monitoring__selectFirst__cardRadius '
								loading={isLoading || isLoadingPutUser || isFetching}
							/>
						</Col>
						<Col span={4}>
							<Card
								className='mt-3 monitoring__selectFirst__cardRadius '
								loading={isLoading || isLoadingPutUser || isFetching}
							/>
						</Col>
						<Col span={4}>
							<Card
								className='mt-3 monitoring__selectFirst__cardRadius '
								loading={isLoading || isLoadingPutUser || isFetching}
							/>
						</Col>
						<Col span={4}>
							<Card
								className='mt-3 monitoring__selectFirst__cardRadius '
								loading={isLoading || isLoadingPutUser || isFetching}
							/>
						</Col>
						<Col span={4}>
							<Card
								className='mt-3monitoring__selectFirst__cardRadius '
								loading={isLoading || isLoadingPutUser || isFetching}
							/>
						</Col>
					</Row>
				)}
				<br />
				<div className='generalStyles__flexEnd'>
					<Button
						onClick={() => {
							saveConfiguration();
						}}
						className='buttonStyle__9'
						icon={
							<FontAwesomeIcon
								className='generalStyles__mrFix'
								icon={faStar}
								border={false}
							/>
						}
					>
						Save Configuration
					</Button>
				</div>
			</div>
		</div>
	);
}

export default MonitoringSelectFirstGrid;
