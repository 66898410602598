import {
	faArrowsRotate,
	faPlus,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	updateChannelRefreshTable,
	updateClearFilters,
	updateOpenModalCreateNewChannel,
	updateFormCreateEdit,
} from "../../../redux/channels/channelSlice";
import validatePermission from "../../../utils/validatePermissions";
// ANT DESIGN COMPONENTS

function ChannelsHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { permissions } = useSelector((state: any) => state.user);
	const [t] = useTranslation("global");
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickOpenModalCreateNewChannel = () => {
		dispatch(updateOpenModalCreateNewChannel(true));
		dispatch(updateFormCreateEdit("create"));
	};
	const onClickReloadPage = () => {
		dispatch(updateChannelRefreshTable(true));
		setTimeout(() => {
			dispatch(updateChannelRefreshTable(false));
		}, 1000);
	};

	const onClickCleanFilters = () => {
		dispatch(updateClearFilters(true));
		setTimeout(() => {
			dispatch(updateClearFilters(false));
		}, 1000);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__pageHeader'>
				{validatePermission("channels-create", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__1'
							icon={
								<FontAwesomeIcon
									icon={faPlus}
									className='generalStyles__mrFix'
								/>
							}
							onClick={onClickOpenModalCreateNewChannel}
						>
							{t("edgeManagement.channels.createNewChannel")}
						</Button>
					</div>
				)}
				{validatePermission("channels-view", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__1'
							icon={
								<FontAwesomeIcon
									icon={faArrowsRotate}
									className='generalStyles__mrFix'
								/>
							}
							onClick={() => {
								onClickReloadPage();
							}}
						>
							{t("userManagement.refreshTable")}
						</Button>
					</div>
				)}
				{validatePermission("channels-view", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__2'
							icon={
								<FontAwesomeIcon
									icon={faTrash}
									className='generalStyles__mrFix'
								/>
							}
							onClick={() => {
								onClickCleanFilters();
							}}
						>
							{t("general.clearAllFilters")}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}

export default ChannelsHeader;
