import React from "react";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
// INTERFACES
import { TableNoDataBoxInterface } from "../../../interfaces/Components.interface";

function TableNoDataBox({
	isError,
	errorMessage,
	noItemsFound,
}: TableNoDataBoxInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__flexCenter component__table__noDataBox'>
			<div>
				{isError ? (
					<>
						<FontAwesomeIcon
							icon={faWarning}
							className='component__table__noDataIcon'
						/>
						<p>{errorMessage}</p>
					</>
				) : (
					<p>{noItemsFound}</p>
				)}
			</div>
		</div>
	);
}

export default TableNoDataBox;
