import React from "react";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateBankBreadcrumb } from "../../../redux/banks/bankSlice";

library.add(faUser);

function BanksBreadcrumb() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { bankBreadcrumb } = useSelector((state: any) => state.bank);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const updateBreadCrumbBackToBanks = () => {
    dispatch(updateBankBreadcrumb([]));
  };
  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Breadcrumb className="">
      <Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
      <Breadcrumb.Item
        className={
          bankBreadcrumb.length === 0
            ? "generalStyles__breadcrumbItem"
            : "generalStyles__breadcrumbItemInactive"
        }
        onClick={updateBreadCrumbBackToBanks}
      >
        {t("header.banks")}
      </Breadcrumb.Item>
      {bankBreadcrumb.map((item: string, index: number) => (
        <Breadcrumb.Item
          className={
            bankBreadcrumb.length === index + 1
              ? "generalStyles__breadcrumbItem"
              : "generalStyles__breadcrumbItemInactive"
          }
          key={index}
        >
          {item}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default BanksBreadcrumb;
