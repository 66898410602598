import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Empty } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClock,
	faMouse,
	faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
import ActionButtonToggle from "../actionButtons/ActionButtonToggle";
import ActionButtonOnRelease from "../actionButtons/ActionButtonOnRelease";
import ActionButtonTimed from "../actionButtons/ActionButtonTimed";
// INTERFACES
import { RootState } from "../../../app/store";
import { ControlPanelActionsInterface } from "../../../interfaces/EdgeManagement.interface";

function ControlPanelActions({
	carService,
	liftId,
	actions,
	serverId,
}: ControlPanelActionsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [displayToggle, setDisplayToggle] = useState<boolean>(false);
	const [displayHoldback, setDisplayHoldback] = useState<boolean>(false);
	const [displayTurnback, setDisplayTurnback] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (actions) {
			actions.map((action: any) => {
				if (action && action.button_type && action.button_type === "TOGGLE") {
					setDisplayToggle(true);
				}
				if (action && action.button_type && action.button_type === "HOLDBACK") {
					setDisplayHoldback(true);
				}
				if (action && action.button_type && action.button_type === "TURNBACK") {
					setDisplayTurnback(true);
				}
				return true;
			});
		}
	}, [actions]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className={` generalStyles__flex montoringView__controlPanel `}
			style={{
				minHeight: "360px",
				height: "auto",
				paddingBottom: "25px",
				marginTop: "-25px",
			}}
		>
			{actions && actions.length > 0 ? (
				<>
					{/** ******************************************************** */}
					{/** TOGGLE BUTTON  */}
					{displayToggle && (
						<div
							className={`drawer__box__${theme} `}
							style={{ width: "100%", margin: "5px" }}
						>
							<div>
								<Divider
									orientation='left'
									className='generalStyles__drawerDivider'
								>
									<h5>
										<FontAwesomeIcon
											icon={faToggleOn}
											className='generalStyles__info generalStyles__mrFix'
										/>
										TOGGLE
									</h5>
								</Divider>
							</div>
							{actions && (
								<div>
									{actions
										.slice()
										.sort((a: any, b: any) =>
											a.created_at.localeCompare(b.created_at)
										)
										.map((action: any) => (
											<div className='generalStyles__flexCenter mt-1'>
												{action &&
													action.button_type &&
													action.button_type === "TOGGLE" && (
														<ActionButtonToggle
															action={action}
															liftId={liftId}
															serverId={serverId}
															carService={carService}
														/>
													)}
											</div>
										))}
								</div>
							)}
						</div>
					)}
					{/** ******************************************************** */}
					{/** HOLDBACK BUTTON  */}
					{displayHoldback && (
						<div
							className={`drawer__box__${theme} `}
							style={{ width: "100%", margin: "5px" }}
						>
							<div>
								<Divider
									orientation='left'
									className='generalStyles__drawerDividerSuccess'
								>
									<h5>
										<FontAwesomeIcon
											icon={faMouse}
											className='generalStyles__success generalStyles__mrFix'
										/>
										HOLDBACK
									</h5>
								</Divider>
							</div>
							{actions && (
								<div>
									{actions
										.slice()
										.sort((a: any, b: any) =>
											a.created_at.localeCompare(b.created_at)
										)
										.map((action: any) => (
											<div className='generalStyles__flexCenter'>
												{action &&
													action.button_type &&
													action.button_type === "HOLDBACK" && (
														<ActionButtonOnRelease
															action={action}
															liftId={liftId}
															serverId={serverId}
															carService={carService}
														/>
													)}
											</div>
										))}
								</div>
							)}
						</div>
					)}
					{/** ******************************************************** */}
					{/** TURNBACK BUTTON  */}
					{displayTurnback && (
						<div
							className={`drawer__box__${theme}`}
							style={{ width: "100%", margin: "5px" }}
						>
							<div>
								<Divider
									orientation='left'
									className='generalStyles__drawerDividerWarning'
								>
									<h5>
										<FontAwesomeIcon
											icon={faClock}
											className='generalStyles__warning generalStyles__mrFix'
										/>
										TURNBACK
									</h5>
								</Divider>
							</div>
							{actions && (
								<div>
									{actions
										.slice()
										.sort((a: any, b: any) =>
											a.created_at.localeCompare(b.created_at)
										)
										.map((action: any) => (
											<div className='generalStyles__flexCenter'>
												{action &&
													action.button_type &&
													action.button_type === "TURNBACK" && (
														<ActionButtonTimed
															action={action}
															liftId={liftId}
															serverId={serverId}
															carService={carService}
														/>
													)}
											</div>
										))}
								</div>
							)}
						</div>
					)}
				</>
			) : (
				<div
					className={`drawer__box__${theme} generalStyles__width100 generalStyles__flexCenter`}
				>
					<Empty description={<span>{t("general.noActionsAssigned")}</span>} />
				</div>
			)}
		</div>
	);
}

export default ControlPanelActions;
