import React from "react";
// ANT DESIGN COMPONENT
import { Divider } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// SWITCH THEME
import { useThemeSwitcher } from "react-css-theme-switcher";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSun,
	faMoon,
	faPaintBrush,
	faStar,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateTheme } from "../../redux/home/homeSlice";
import { usePutUserMutation } from "../../redux/users/userAPI";
// IMAGES
import lightMode from "../../assets/theme/lightMode.png";
import darkMode from "../../assets/theme/darkMode.png";
// AUTHORIZATION
import GETJwtToken from "../../redux/authentication/authentication";

function Appearance() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { switcher, themes } = useThemeSwitcher();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);
	const { userLogged } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [putUser] = usePutUserMutation();
	// ************************************************* */
	// FUNCTIONS *************************************** */

	const handleChangeTheme = async (newTheme: any) => {
		switcher({ theme: themes[newTheme] });
		localStorage.setItem("theme", newTheme);
		dispatch(updateTheme(newTheme));
		// Save favorite theme
		const token = await GETJwtToken();
		const BODY = {
			id: userLogged.id,
			token,
			body: { ...userLogged, pref_theme: newTheme },
		};
		putUser(BODY);
	};

	// ************************************************* */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			<div>
				<Divider orientation='left' className='generalStyles__drawerDivider'>
					<h5>
						<FontAwesomeIcon
							icon={faPaintBrush}
							className='generalStyles__info generalStyles__mrFix'
						/>
						{t("appearance.appearance")}
					</h5>
				</Divider>
			</div>
			{/** */}
			<div className={`drawer__box__${theme} row`}>
				<div
					className='generalStyles__flexWrap'
					style={{ justifyContent: "center", alignItems: "center" }}
				>
					{/** **************************************************** */}
					{/** DARK MODE BOX */}
					<div
						className={`settings__activeBox__${theme} ${
							theme === "dark" ? "settings__activeCard" : "settings__card"
						}`}
						role='button'
						tabIndex={0}
						onClick={() => {
							handleChangeTheme("dark");
						}}
						onKeyDown={() => {
							handleChangeTheme("dark");
						}}
					>
						<div>
							<div className='settings__borderBottom generalStyles__flex'>
								<div>
									<p className='drawer__text settings__themeTitle m-3'>
										<FontAwesomeIcon
											icon={faMoon}
											className='settings__moonIcon'
										/>
										{t("appearance.nightTheme")}
									</p>
								</div>

								{theme === "dark" ? (
									<FontAwesomeIcon
										icon={faStar}
										className='settings__activeBox'
									/>
								) : (
									<></>
								)}
							</div>
							<div>
								<img className='settings__cardImage' src={darkMode} alt='' />
							</div>
						</div>
					</div>
					{/** **************************************************** */}
					{/** LIGHT MODE BOX */}
					<div
						className={`settings__activeBox__${theme} ${
							theme === "light" ? "settings__activeCard" : "settings__card"
						}`}
						role='button'
						tabIndex={0}
						onClick={() => {
							handleChangeTheme("light");
						}}
						onKeyDown={() => {
							handleChangeTheme("light");
						}}
					>
						<div>
							<div className='settings__borderBottom generalStyles__flex'>
								<div>
									<p className='drawer__text settings__themeTitle m-3'>
										<FontAwesomeIcon
											icon={faSun}
											className='settings__sunIcon'
										/>
										{t("appearance.dayTheme")}
									</p>
								</div>
								{theme === "light" ? (
									<FontAwesomeIcon
										icon={faStar}
										className='settings__activeBox'
									/>
								) : (
									<></>
								)}
							</div>
							<div>
								<img className='settings__cardImage' src={lightMode} alt='' />
							</div>
						</div>
					</div>
					{/** **************************************************** */}
					{/** BLUE MODE BOX */}
					<div
						className={`settings__activeBox__${theme} ${
							theme === "blue" ? "settings__activeCard" : "settings__card"
						}`}
						role='button'
						tabIndex={0}
						onClick={() => {
							handleChangeTheme("blue");
						}}
						onKeyDown={() => {
							handleChangeTheme("blue");
						}}
					>
						<div>
							<div className='settings__borderBottom generalStyles__flex'>
								<div>
									<p className='drawer__text settings__themeTitle m-3'>
										<FontAwesomeIcon
											icon={faPaintBrush}
											className='settings__moonIcon'
										/>
										{t("appearance.defaultTheme")}
									</p>
								</div>
								{theme === "blue" ? (
									<FontAwesomeIcon
										icon={faStar}
										className='settings__activeBox'
									/>
								) : (
									<></>
								)}
							</div>
							<div>
								<img className='settings__cardImage' src={darkMode} alt='' />
							</div>
						</div>
					</div>
					{/** **************************************************** */}
				</div>
			</div>
		</div>
	);
}

export default Appearance;
