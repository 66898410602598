// INTERFACE
import {
	OffsetsInterface,
	DefinitionByteInterface,
	SpecialSwitchInterface,
	SelectedItemsOffsetsTableInterface,
} from "../interfaces/Configurations.interface";

/**
 *
 * @name GLOBAL
 * @param date
 * @returns Formated date
 */
export const formatDate = (date: any) => {
	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const newDate = new Date(date);
	const year = newDate.getFullYear();
	const month = newDate.getMonth();
	const day = newDate.getDay();
	const hour = newDate.getHours();
	const minute = newDate.getMinutes();
	const second = newDate.getSeconds();
	return `${monthNames[month]} ${day} - ${year} - ${hour}:${minute}:${second}`;
};

/**
 *
 * @name GLOBAL
 * @param disableFlag
 * @param theme
 * @returns CSS class depending on active flag
 */
export const styleDeleteButton = (disableFlag: boolean, theme: string) => {
	if (disableFlag) {
		if (theme === "dark" || theme === "blue") {
			return "buttonStyle__inactive__1__dark generalStyles__mrFix";
		}
		return "buttonStyle__inactive__1 generalStyles__mrFix";
	}
	return "buttonStyle__2 generalStyles__mrFix";
};

/**
 *
 * @name Configuration
 * @param offsetType
 * @param offsets
 * @description 1) Receive the name of and offset type and an array of offsets
 * @description 2) Validate the number of bytes for the especific offset type name
 * @returns  Return the total bytes asigned to that specific offet type
 */
export const getOffsetsCountByConfiguration = (
	offsetType: string,
	offsets: OffsetsInterface[]
) => {
	let totalBytes = 0;
	if (offsetType && offsets) {
		offsets.map((item: OffsetsInterface) => {
			if (
				item &&
				item.offset_type &&
				item.offset_type.name &&
				item.offset_type.name === offsetType
			) {
				totalBytes = item.byte_count;
			}
			return true;
		});
	}
	return totalBytes;
};

/**
 *
 * @name Configuration
 * @param offsetType
 * @param offsets
 * @description 1) Get offset type ID
 * @returns  Return offset type ID by name
 */
export const getOffsetIdByName = (
	offsetType: string,
	offsets: OffsetsInterface[]
) => {
	if (offsets) {
		for (let x = 0; x < offsets.length; x += 1) {
			if (offsets[x].offset_type.name === offsetType) {
				return offsets[x].offset_type.id;
			}
		}
	}
	return "";
};

/**
 *
 * @name Configuration
 * @param carName
 * @param offsets
 * @description 1) Get offset type ID
 * @returns  Return offset type ID by name
 */
export const getOffsetIdByCarName = (
	carName: string,
	offsets: OffsetsInterface[]
) => {
	if (offsets) {
		for (let x = 0; x < offsets.length; x += 1) {
			if (offsets[x].name === carName) {
				return offsets[x].id;
			}
		}
	}
	return "";
};

/**
 *
 * @name Configuration
 * @param offsetType
 * @param offsets
 * @description 1) Receive the name of and offset type and an array of offsets
 * @description 2) Validate the number of bytes for the especific offset type name
 * @returns  Return an object with all the attributes of an specific offset type
 */
export const getOffsetsAttributesByConfiguration = (
	offsetType: string,
	offsets: OffsetsInterface[]
) => {
	let attributes = {};
	if (offsetType && offsets) {
		offsets.map((item: OffsetsInterface) => {
			if (
				item &&
				item.offset_type &&
				item.offset_type.name &&
				item.offset_type.attributes &&
				item.offset_type.name === offsetType
			) {
				attributes = item.offset_type.attributes;
			}
			return true;
		});
	}
	return attributes;
};

/**
 *
 * @name Configuration
 * @param offsetType
 * @param offsets
 * @description 1) Get offset type object and find the definitions object by offset type
 * @returns  Return an array with all the definitions already created by specific offset type
 */
export const getDefinitionsArrayByOffsetType = (
	offsetType: string,
	offsets: OffsetsInterface[]
) => {
	let definitions: any[] = [];
	if (offsetType && offsets) {
		offsets.map((item: OffsetsInterface) => {
			if (
				item &&
				item.offset_type &&
				item.offset_type.name &&
				item.offset_type.name === offsetType &&
				item.definitions &&
				item.definitions.length > 0
			) {
				definitions = item.definitions;
			}
			return true;
		});
	}
	return definitions;
};

/**
 *
 * @name Configuration
 * @param offsets
 * @description 1) Get offsets data and select wich is selected
 * @returns  Return an object with all the selected offsets by configuration
 */
export const buildSelectedItemsOffsetsTable = (offsets: OffsetsInterface[]) => {
	const selectedItems: SelectedItemsOffsetsTableInterface[] = [];
	if (offsets && offsets.length > 0) {
		offsets.map((item: OffsetsInterface) =>
			selectedItems.push({
				key: item.offset_type.name,
				name: item.offset_type.name,
			})
		);
	}
	const uniqueSelectedItems = selectedItems.filter(
		(obj: SelectedItemsOffsetsTableInterface, index: number) =>
			index === selectedItems.findIndex((o: any) => obj.name === o.name)
	);
	return uniqueSelectedItems;
};

/**
 *
 * @name Configuration
 * @param offsets
 * @description 1) Get offsets data and build offsets object to display in table
 * @returns  Return an object with all the configuration to display in table
 */
export const buildDataOffsetsTable = (offsets: OffsetsInterface[]) => {
	const dataOffsetsTable: any = [];
	// Create columns
	if (offsets && offsets.length > 0) {
		offsets.map((item: OffsetsInterface) =>
			dataOffsetsTable.push({
				description: "",
				// liftName: item.name,
				liftName: `Car ${item.position}`,
				liftnumber: item.position,
				statusArr: [],
			})
		);
	}
	const uniqueSelectedItems = dataOffsetsTable.filter(
		(obj: any, index: number) =>
			index ===
			dataOffsetsTable.findIndex((o: any) => obj.liftName === o.liftName)
	);
	// Build offsets logic and distribution
	if (uniqueSelectedItems.length > 0) {
		for (let x = 0; x < uniqueSelectedItems.length; x += 1) {
			offsets.map((item: any) => {
				if (uniqueSelectedItems[x].liftName === `Car ${item.position}`) {
					for (let y = 0; y < item.byte_count; y += 1) {
						uniqueSelectedItems[x].statusArr.push({
							key: `${item.offset_type.name}-${y}`,
							name: `${item.offset_type.name}-${y + 1}`,
							offset: item.initial_value,
							keyIndex: y,
							byteCount: item.byte_count,
						});
					}
				}
				return true;
			});
		}
	}
	return uniqueSelectedItems;
};

/**
 *
 * @name Configuration
 * @param offsetType
 * @param offsets
 * @description 1) Receive an excel array and separate it in individual rows
 * @description 2) Receive an array of definitions bytes and fill with the new data
 * @returns  Return the new definitions byte array with the excel data as shortName and long Name
 */
export const onChangeTextAreaCopyAndPasteFromExcelColumn = (
	value: string,
	byte: number,
	bit: number,
	totalBytes: number,
	definitionBytes: DefinitionByteInterface[],
	columnName: string
) => {
	let excelData: string[] = [];
	excelData = value.toString().split(/\n/);
	/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
	// Generate and fill status bit from excel data
	const newDefinitionBytes: any = structuredClone(definitionBytes);
	let byteCounter = byte;
	let bitCounter = bit;
	let remaindingBits = 0;
	if (totalBytes > 1) {
		if (byte + 1 === totalBytes) {
			remaindingBits = 8 - bit;
		} else if (byte < totalBytes) {
			remaindingBits = (totalBytes - byte) * 8 - bit;
		}
	} else {
		remaindingBits = 256 - bit;
	}
	/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-* */
	for (let x = 0; x < remaindingBits; x += 1) {
		if (excelData[x]) {
			newDefinitionBytes[byteCounter].bits[bitCounter][columnName] =
				excelData[x];
		}
		bitCounter += 1;
		if (bitCounter >= 8 && totalBytes > 1) {
			bitCounter = 0;
			byteCounter += 1;
		}
	}
	return newDefinitionBytes;
};

export const onChangeTextAreaCopyAndPasteFromExcel = (
	value: string,
	index: number,
	definitionBytes: DefinitionByteInterface[],
	offsetType: string
) => {
	let excelData: string[] = [];
	if (value) {
		if (offsetType === "FAULT") {
			excelData = value.toString().split(/[0-9]?[0-9]\t[0-9]?[0-9]/);
		} else if (offsetType === "STATUS" || offsetType === "CARSERVICE") {
			excelData = value.toString().split(/ [0-9]?[0-9]\t/);
		}
	}
	// Generate and fill status bit from excel data
	const newDefinitionBytes = [...definitionBytes];
	if (offsetType === "FAULT") {
		excelData.splice(0, 1);
		for (let x = 0; x < excelData.length; x += 1) {
			let rowExcelData: any = [];
			rowExcelData = excelData[x].split("\t");
			newDefinitionBytes[index].bits[x].longName = rowExcelData[2];
		}
	} else if (offsetType === "STATUS" || offsetType === "CARSERVICE") {
		for (let x = 0; x < excelData.length; x += 1) {
			let rowExcelData = [];
			rowExcelData = excelData[x].split("\t");
			if (x === 0) {
				newDefinitionBytes[index].bits[x].shortName = rowExcelData[1];
				newDefinitionBytes[index].bits[x].longName = rowExcelData[2];
			} else {
				newDefinitionBytes[index].bits[x].shortName = rowExcelData[0];
				newDefinitionBytes[index].bits[x].longName = rowExcelData[1];
			}
		}
	}
	return newDefinitionBytes;
};

/**
 *
 * @name Configuration
 * @param definitionBytes
 * @param specialSwitches
 * @description 1) Receive and array of definitions bytes and special switches object
 * @description 2) Assign special switches data and build new definitions byte array
 * @returns Return the new definitions byte array with special switch name and id assigned
 */
export const addSpecialSwitchesToDefinitions = (
	definitionBytes: DefinitionByteInterface[],
	specialSwitches: SpecialSwitchInterface[]
) => {
	const newDefinitionBytes = [...definitionBytes];
	for (let index = 0; index < specialSwitches.length; index += 1) {
		if (specialSwitches[index].status === "Valid") {
			newDefinitionBytes[specialSwitches[index].byte - 1].bits[
				specialSwitches[index].bit
			].specialSwitchName = specialSwitches[index].specialSwitchName;
			newDefinitionBytes[specialSwitches[index].byte - 1].bits[
				specialSwitches[index].bit
			].specialSwitchId = specialSwitches[index].id;
		}
	}
	return newDefinitionBytes;
};

/**
 *
 * @name Configuration
 * @param value
 * @param byteCount
 * @description 1) Check if the string is and excel data stringify
 * @returns Return boolean response if string is an excel string or a simple string
 */
export const validateIfStringIsExcelData = (
	excelString: string,
	byteCount: number
) => {
	//
	let excelData = [];
	let isExcelData = false;
	if (excelString) {
		excelData = excelString.toString().split("\n");
	}
	if (excelData.length > byteCount) {
		isExcelData = true;
	}
	return isExcelData;
};

/**
 *
 * @name GLOBAL
 * @param index
 * @description get index and return row class for table dark and light
 * @returns css class name for row dark and light theme
 */
export const getTableRowOffsetClass = (
	index: number,
	theme: any,
	byte: number
) => {
	if (byte % 2) {
		if (theme === "dark") {
			return "table-row-dark";
		}
		return "table-row-light";
	}
	return "";
};

/**
 *
 * @name GLOBAL
 * @param index
 * @description get index and return row class for table dark and light
 * @returns css class name for row dark and light theme
 */
export const getTableRowClass = (index: number, theme: any) => {
	if (index % 2 !== 0) {
		return `table-row-${theme}`;
	}
	return "";
};

/**
 *
 * @name MONITORING VIEW
 * @param bank
 * @description get bank object and check if render or not
 * @returns boolean value
 */
export const checkBankIsPinned = (item: any, pinedBanks: any) => {
	let displayBank = true;
	if (pinedBanks.length > 0) {
		displayBank = false;
		pinedBanks.map((item2: any) => {
			if (item2 === item.id) {
				displayBank = true;
			}
			return true;
		});
	}
	return displayBank;
};

export const generateExcelAndDownload = (data: any, reportName: string) => {
	// add 1 row to generate headers
	data.splice(0, 0, data[0]);
	let csv = "";
	// Loop the array of objects
	for (let row = 0; row < data.length; row += 1) {
		const keysAmount = Object.keys(data[row]).length;
		let keysCounter = 0;
		// If this is the first row, generate the headings
		if (row === 0) {
			// Loop each property of the object
			// eslint-disable-next-line no-restricted-syntax
			for (const key in data[row]) {
				if (key) {
					// This is to not add a comma at the last cell
					// The '\r\n' adds a new line
					csv += key + (keysCounter + 1 < keysAmount ? "," : "\r\n");
					keysCounter += 1;
				}
			}
		} else {
			// eslint-disable-next-line no-restricted-syntax
			for (const key in data[row]) {
				if (key) {
					csv += data[row][key] + (keysCounter + 1 < keysAmount ? "," : "\r\n");
					keysCounter += 1;
				}
			}
		}
		keysCounter = 0;
	}
	// Once we are done looping, download the .csv by creating a link
	const link = document.createElement("a");
	const selectedId = reportName;
	link.id = selectedId;
	link.setAttribute(
		"href",
		`data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`
	);
	link.setAttribute("download", `${reportName}.csv`);
	document.body.appendChild(link);
	const test123 = document.querySelector<HTMLElement>(`#${selectedId}`);
	if (test123) {
		test123.click();
	}
	data.splice(0, 1);
	csv = "";
};

/**
 *
 * @name FILTERS
 * @param filters
 * @description get filter values and return a string to concat to URL GET string
 * @returns filter string
 */
export const buildFilterString = (filters: any) => {
	let searchAndFilterString = "";
	// NAME **************************************************
	if (filters.filterName && filters.filterName !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=name&filter=",
			filters.filterName
		);
	}
	// FIRST NAME **************************************************
	if (filters.filterFirstName && filters.filterFirstName !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=first_name&filter=",
			filters.filterFirstName
		);
	}
	// MIDDLE NAME **************************************************
	if (filters.filterMiddleName && filters.filterMiddleName !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=middle_name&filter=",
			filters.filterMiddleName
		);
	}
	// LAST NAME **************************************************
	if (filters.filterLastName && filters.filterLastName !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=last_name&filter=",
			filters.filterLastName
		);
	}
	// EMAIL **************************************************
	if (filters.filterEmail && filters.filterEmail !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=email&filter=",
			filters.filterEmail
		);
	}
	// IP ****************************************************
	if (filters.filterIP && filters.filterIP !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=ip&filter=",
			filters.filterIP
		);
	}
	// SOCKET ADDRESS ****************************************************
	if (filters.filterSocketAddress && filters.filterSocketAddress !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=socket_address&filter=",
			filters.filterSocketAddress
		);
	}
	// INDEX ****************************************************
	if (filters.filterIndex && filters.filterIndex !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=index&filter=",
			filters.filterIndex
		);
	}
	// ORG TYPE **********************************************
	if (filters.filterOrgType && filters.filterOrgType.length > 0) {
		filters.filterOrgType.map((item: string) => {
			searchAndFilterString = searchAndFilterString.concat(
				"&filter_target=type&filter=",
				item
			);
			return true;
		});
	}
	// ORG NAME **********************************************
	if (
		filters.filterOrganizationName &&
		filters.filterOrganizationName.length !== ""
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=org_id&filter=",
			filters.filterOrganizationName
		);
	}
	// ORG FULL NAME **********************************************
	if (
		filters.filterOrganizationFullName &&
		filters.filterOrganizationFullName.length !== ""
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=organization_id&filter=",
			filters.filterOrganizationFullName
		);
	}
	// ADDRESS ***********************************************
	if (filters.filterAddress && filters.filterAddress !== "") {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=address&filter=",
			filters.filterAddress
		);
	}
	// STATUS ACTIVE / INACTIVE / ALL ******************************
	if (
		filters.filterStatus &&
		filters.filterStatus !== "" &&
		filters.filterStatus !== "ALL"
	) {
		let filterStatus = "false";
		if (filters.filterStatus === "ACTIVE") {
			filterStatus = "true";
		}
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=active&filter=",
			filterStatus
		);
	}
	// AUTH TYPE PASSWORD / APIKEY ******************************
	if (
		filters.filterAuthType &&
		filters.filterAuthType !== "" &&
		filters.filterAuthType !== "ALL"
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=auth_type&filter=",
			filters.filterAuthType
		);
	}
	// ADAPTER TYPE WEBSOCKET / REST / SOAP ******************************
	if (
		filters.filterAdapterType &&
		filters.filterAdapterType !== "" &&
		filters.filterAdapterType !== "ALL"
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=type&filter=",
			filters.filterAdapterType
		);
	}
	// LIFT TYPE ELEVATOR / ESCALATOR / MOVINGWALK ******************************
	if (
		filters.filterLiftType &&
		filters.filterLiftType !== "" &&
		filters.filterLiftType !== "ALL"
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=type&filter=",
			filters.filterLiftType
		);
	}
	// LIFT TYPE FULL NAME ELEVATOR / ESCALATOR / MOVINGWALK ******************************
	if (
		filters.filterLiftTypeFullName &&
		filters.filterLiftTypeFullName !== "" &&
		filters.filterLiftTypeFullName !== "ALL"
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=lift_type&filter=",
			filters.filterLiftTypeFullName
		);
	}
	// PROTOCOL TYPE ******************************
	if (
		filters.filterProtocolType &&
		filters.filterProtocolType !== "" &&
		filters.filterProtocolType !== "ALL"
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=protocol_type&filter=",
			filters.filterProtocolType
		);
	}
	// CHANNEL TYPE TCP / ADAPTER / SERIAL ******************************
	if (
		filters.filterChannelType &&
		filters.filterChannelType !== "" &&
		filters.filterChannelType !== "ALL"
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=type&filter=",
			filters.filterChannelType
		);
	}
	// USER COGNITO STATUS  ******************************
	if (
		filters.filterUserStatus &&
		filters.filterUserStatus !== "" &&
		filters.filterUserStatus !== "ALL"
	) {
		searchAndFilterString = searchAndFilterString.concat(
			"&filter_target=status&filter=",
			filters.filterUserStatus
		);
	}
	// Final String ******************************************
	if (searchAndFilterString.length > 3) {
		return searchAndFilterString;
	}
	return "";
};

/**
 *
 * @name GLOBAL
 * @param ipString
 * @description check if string is an IP valid with port
 * @returns boolean with true/false IP string is valid
 */
export const validateIpAndPortString = (ipString: string) => {
	const isValid =
		/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[1-9](\d){0,3})$/.test(
			ipString
		);
	return isValid;
};

/**
 *
 * @name CONFIGURATION_CATALOG/OFFSETS
 * @param configuration_data_block
 * @description get data block data and preparate offsets data
 * @returns offsets object
 */
export const buildOffsetsTypeDataObject = (newData: any) => {
	const copy = structuredClone(newData);
	copy.offsets.map((offset: any, indexOffset: number) => {
		copy.offset_types.map((offsetType: any) => {
			if (offset && offsetType && offset.offset_type_id === offsetType.id) {
				copy.offsets[indexOffset].offset_type = offsetType;
			}
			return true;
		});
		return true;
	});
	return copy;
};

/**
 *
 * @name CONFIGURATION_CATALOG/OFFSETS
 * @param configuration_data_block
 * @description Build full table data in data block/offsets view
 * @returns offsets table
 */
export const buildOffsetsFullTableData = (data_: any, offsets: any) => {
	// Build data to create offsets table
	let newArr: any = [];
	if (data_.length > 0) {
		newArr = data_;
	} else {
		newArr = buildDataOffsetsTable(offsets);
	}
	newArr.sort(
		(a: any, b: any) => parseFloat(a.liftnumber) - parseFloat(b.liftnumber)
	);
	const newData: any = [];
	if (newArr && newArr.length > 0) {
		for (let x = 0; x < newArr[0].statusArr.length; x += 1) {
			const extractNumber = newArr[0].statusArr[x].name.match(/(\d+)/);
			const parcialName = newArr[0].statusArr[x].name.replace(
				extractNumber[0].toString(),
				""
			);
			const name = parcialName.slice(0, -1);
			const byte = extractNumber[0];
			// const myArray = newArr[0].statusArr[x].name.split("-");
			const newObj: any = {
				id: newArr[0].statusArr[x].name,
				name,
				byte,
				byteCount: newArr[0].statusArr[x].totalItems,
				offsetTypePosition: newArr[0].statusArr[x].offsetTypePosition,
			};
			for (let y = 0; y < newArr.length; y += 1) {
				newObj[`${newArr[y].liftName}${newArr[y].statusArr[x].name}`] =
					newArr[y].statusArr[x].offset;
			}
			newData.push(newObj);
		}
	}
	newData.sort(
		(a: any, b: any) =>
			parseFloat(a.offsetTypePosition) - parseFloat(b.offsetTypePosition)
	);
	return [newArr, newData];
};

/**
 *
 * @name CONFIGURATION_CATALOG/OFFSETS
 * @param configuration_data_block
 * @description Build full table data in data block/offsets view
 * @returns offsets table
 */
export const buildOffsetsRangeTableData = (newData: any) => {
	let newArr: any = [];
	let selectedItems: any = [];
	// Check if component to create is form configuration or table view
	selectedItems = buildSelectedItemsOffsetsTable(newData.offsets);
	// Build data to create offsets table
	newArr = buildDataOffsetsTable(newData.offsets);
	newArr.sort(
		(a: any, b: any) => parseFloat(a.liftnumber) - parseFloat(b.liftnumber)
	);
	return [selectedItems, newArr];
};

/**
 *
 * @name CONFIGURATION_CATALOG/DEFINITIONS
 * @param configuration_definitions
 * @description Build definitions bytes array
 * @returns definitions table
 */
export const buildDefinitionsByteArray = (
	totalBytes: number,
	offsetTypeName: string
) => {
	const newObject = [];
	let totalBits = 7;
	if (totalBytes === 1) {
		totalBits = 255;
	}
	for (let item = 0; item < totalBytes; item += 1) {
		const subObj: DefinitionByteInterface = {
			name: `${offsetTypeName} byte ${item + 1}`,
			isValid: false,
			bits: [],
		};
		for (let index2 = 0; index2 <= totalBits; index2 += 1) {
			subObj.bits.push({
				byte: item + 1,
				bit: index2,
				shortName: "",
				longName: "",
				activePosition: false,
				priority: 0,
				specialSwitchName: "",
				specialSwitchId: "",
				alert: false,
				log: false,
				severity: "",
				confirmSeconds: 0,
				helpId: 0,
				group: 0,
				pagingScheme: 0,
				thirdPartyPosition: 0,
				code: "",
				faultSpect: 0,
				idDefinition: "",
			});
		}
		newObject.push(subObj);
	}
	return newObject;
};
