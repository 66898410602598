import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faBan,
	faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

function TableColumnStatusUser({ text }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className=''>
			{text === "ACTIVE" && (
				<Tag
					color='success'
					icon={
						<FontAwesomeIcon
							icon={faCheckCircle}
							className='generalStyles__mrFix'
						/>
					}
					className='drawer__tag'
				>
					{t("userManagement.active")}
				</Tag>
			)}
			{text === "NEW" && (
				<Tag
					color='warning'
					icon={
						<FontAwesomeIcon
							icon={faCircleExclamation}
							className='generalStyles__mrFix'
						/>
					}
					className='drawer__tag'
				>
					{t("userManagement.newUser")}
				</Tag>
			)}
			{text === "BLOCKED" && (
				<Tag
					color='error'
					icon={
						<FontAwesomeIcon icon={faBan} className='generalStyles__mrFix' />
					}
					className='drawer__tag'
				>
					{t("userManagement.lock")}
				</Tag>
			)}{" "}
		</div>
	);
}

export default TableColumnStatusUser;
