import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Spin } from "antd";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteUserMutation } from "../../../redux/users/userAPI";
import { updateOpenModalDeleteUser } from "../../../redux/users/userSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import ModalDeleteItemContainer from "../../components/modal/ModalDeleteItemContainer";
import GlobalAlert2 from "../../home/GlobalAlert2";

function UsersDeleteUserModal() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { user, openModalDeleteUser } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerDeleteUser,
		{ isLoading, isSuccess, isError, error, reset: resetDeleteUser },
	] = useDeleteUserMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenModalDeleteUser(false));
	};

	const onClickDelete = async () => {
		const token = await GETJwtToken();
		triggerDeleteUser({ id: user.id, token });
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (openModalDeleteUser) {
			resetDeleteUser();
		}
	}, [openModalDeleteUser]);

	useEffect(() => {
		dispatch(updateOpenModalDeleteUser(false));
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={onClickClose}
			closable={false}
			onCancel={onClickClose}
			open={openModalDeleteUser}
			footer={null}
		>
			<Spin spinning={isLoading}>
				<ModalDeleteItemContainer
					name={user.email}
					onClickDelete={onClickDelete}
					onClickClose={onClickClose}
				/>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='DELETE'
				error={error}
				name='User'
			/>
		</Modal>
	);
}

export default UsersDeleteUserModal;
