/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faLock,
	faSearch,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	useLazyGetBanksQuery,
	useLazyGetBanksByServerQuery,
} from "../../../redux/banks/bankAPI";
import {
	updateOpenDrawerBank,
	updateBank,
	updateBankBreadcrumb,
	updateIdBankSelected,
	updateNameBankSelected,
} from "../../../redux/banks/bankSlice";
import { updateServerBreadcrumb } from "../../../redux/servers/serverSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
import FilterSelectLiftType from "../../components/filters/FilterSelectLiftType";
import FilterSelectStatus from "../../components/filters/FilterSelectStatus";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import BanksTableAction from "./BanksTableAction";
// UTILS
import { getTableRowClass, buildFilterString } from "../../../utils/utils";
// INTERFACES
import {
	BanksTableInterface,
	BankInterface,
} from "../../../interfaces/Bank.interface";
import { RootState } from "../../../app/store";
import { TablePaginationInterface } from "../../../interfaces/Global.interface";

const BanksTable = ({ renderComponent }: BanksTableInterface) => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [banks, setBanks] = useState<BankInterface[]>([]);
	const [errorMessage, setErrorMessage] = useState<any>({});
	const [totalRows, setTotalRows] = useState<number>(0);
	const [idBankSelected, setIdBankSelected] = useState("");
	const [filterName, setFilterName] = useState("");
	const [filterLiftType, setFilterLiftType] = useState("");
	const [filterStatus, setFilterStatus] = useState("");
	const [pagination, setPagination] = useState<TablePaginationInterface>({
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ["10", "20", "50", "100"],
		total: 0,
	});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme, userLogged } = useSelector((state: RootState) => state.home);
	const { bankRefreshTable, clearFilters } = useSelector(
		(state: RootState) => state.bank
	);
	const { idServerSelected, serverBreadcrumb } = useSelector(
		(state: RootState) => state.server
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetBanks,
		{
			data: dataGetBanks,
			isLoading: isLoadingGetBanks,
			isError: isErrorGetBanks,
			isFetching: isFetchingGetBanks,
			error: errorGetBanks,
		},
	] = useLazyGetBanksQuery();
	const [
		triggerGetBanksByServer,
		{
			data: dataGetBanksByServer,
			isLoading: isLoadingGetBanksByServer,
			isError: isErorGetBanksByServer,
			isFetching: isFetchingGetBanksByServer,
			error: errorGetBanksByServer,
		},
	] = useLazyGetBanksByServerQuery();

	// ************************************************ */
	// TABLE ACTIONS ************************************ */
	const { Column } = Table;

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onCellNameClick = (record: any) => {
		if (record) {
			if (renderComponent === "serverPage") {
				dispatch(updateServerBreadcrumb([...serverBreadcrumb, record.name]));
				dispatch(updateIdBankSelected(record.id));
				dispatch(updateNameBankSelected(record.name));
				dispatch(updateBank(record));
			} else {
				dispatch(updateBankBreadcrumb([record.name]));
				dispatch(updateIdBankSelected(record.id));
				dispatch(updateNameBankSelected(record.name));
				dispatch(updateBank(record));
			}
		}
	};

	const onCellClick = (record: BankInterface) => {
		dispatch(updateBank(record));
		dispatch(updateOpenDrawerBank(true));
	};

	const getDataByBank = async (
		page: number,
		limit: number,
		searchAndFilterString: string
	) => {
		const token = await GETJwtToken();
		triggerGetBanks({
			page,
			limit,
			token,
			orgId: idBankSelected,
			searchAndFilterString,
		});
	};

	const getDataByServer = async (
		page: number,
		limit: number,
		searchAndFilterString: string
	) => {
		const token = await GETJwtToken();
		triggerGetBanksByServer({
			page,
			limit,
			token,
			orgId: idServerSelected,
			searchAndFilterString,
		});
	};

	const handleTableChange = async (pagination_: any) => {
		setPagination({
			...pagination,
			current: pagination_.current,
			pageSize: pagination_.pageSize,
			total: pagination_.total,
		});
		const searchAndFilterString = buildFilterString({
			filterName,
			filterLiftType,
			filterStatus,
		});
		if (renderComponent === "serverPage") {
			getDataByServer(
				pagination_.current,
				pagination_.pageSize,
				searchAndFilterString
			);
		} else {
			getDataByBank(
				pagination_.current,
				pagination_.pageSize,
				searchAndFilterString
			);
		}
	};

	const refreshTable = () => {
		const searchAndFilterString = buildFilterString({
			filterName,
			filterLiftType,
			filterStatus,
		});
		setTotalRows(0);
		if (renderComponent === "serverPage") {
			getDataByServer(1, 10, searchAndFilterString);
		} else {
			getDataByBank(1, 10, searchAndFilterString);
		}
	};

	const getInitialData = () => {
		if (renderComponent === "serverPage") {
			getDataByServer(1, 10, "");
		} else {
			getDataByBank(1, 10, "");
		}
	};

	useEffect(() => {
		if (clearFilters) {
			setFilterName("");
			setFilterLiftType("");
			setFilterStatus("");
			getInitialData();
		}
	}, [clearFilters]);

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (userLogged && userLogged.org) {
			setIdBankSelected(userLogged.org.id);
			getInitialData();
		}
	}, []);

	useEffect(() => {
		if (dataGetBanks && dataGetBanks.data) {
			setPagination({
				...pagination,
				total: dataGetBanks.total,
			});
			setTotalRows(dataGetBanks.total);
			setBanks(dataGetBanks.data);
		} else if (dataGetBanksByServer && dataGetBanksByServer.data) {
			setPagination({
				...pagination,
				total: dataGetBanksByServer.total,
			});
			setTotalRows(dataGetBanksByServer.total);
			setBanks(dataGetBanksByServer.data);
		}
	}, [dataGetBanks, dataGetBanksByServer]);

	useEffect(() => {
		if (errorGetBanks || errorGetBanksByServer) {
			setErrorMessage(errorGetBanks || errorGetBanksByServer);
			setBanks([]);
		}
	}, [errorGetBanks, errorGetBanksByServer]);

	useEffect(() => {
		if (bankRefreshTable) {
			refreshTable();
		}
	}, [bankRefreshTable]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("banks-view", permissions) ? (
				<Table
					locale={{
						emptyText: (
							<TableNoDataBox
								isError={isErrorGetBanks || isErorGetBanksByServer}
								errorMessage={
									errorMessage && errorMessage.data && errorMessage.data.message
										? errorMessage.data.message
										: "Error"
								}
								noItemsFound='No banks found'
							/>
						),
					}}
					rowClassName={(record, index) => getTableRowClass(index, theme)}
					className='customTable'
					dataSource={banks}
					loading={
						isLoadingGetBanks ||
						isFetchingGetBanks ||
						isLoadingGetBanksByServer ||
						isFetchingGetBanksByServer
					}
					rowKey={(record) => record.id}
					size='small'
					pagination={pagination}
					onChange={handleTableChange}
					scroll={{ x: 900 }}
					footer={() => (
						<div className='generalStyles__flexEnd'>
							<Tag>{totalRows} Rows</Tag>
						</div>
					)}
				>
					{/** NAME */}
					<Column
						onCell={(record) => ({
							onClick: () => {
								onCellNameClick(record);
							},
						})}
						title={t("edgeManagement.banks.bankName")}
						dataIndex='name'
						key='name'
						className='generalStyles__tableHeaderLink'
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						filterDropdown={() => (
							<FilterSearchText
								placeHolder={t("general.name")}
								filterValue={filterName}
								setFilterValue={setFilterName}
								refreshTable={refreshTable}
								refreshRequireParamenters
							/>
						)}
						width='200px'
						render={(text) => (
							<>
								<div className=''>{text}</div>
							</>
						)}
					/>
					{/** TYPE -- */}
					<Column
						onCell={(record: BankInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("edgeManagement.lifts.type")}
						dataIndex='type'
						key='type'
						width='100px'
						className=''
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						filterDropdown={() => (
							<FilterSelectLiftType
								placeHolder={t("general.liftType")}
								setFilterValue={setFilterLiftType}
								filterValue={filterLiftType}
								refreshTable={refreshTable}
							/>
						)}
						render={(text) => (
							<>
								<div className=''>
									{text === "ELEVATOR" && (
										<Tag color='blue' className='drawer__typeTag'>
											{text}
										</Tag>
									)}
									{text === "ESCALATOR" && (
										<Tag color='volcano' className='drawer__typeTag'>
											{text}
										</Tag>
									)}
									{text === "MOVINGWALK" && (
										<Tag color='green' className='drawer__typeTag'>
											{text}
										</Tag>
									)}
									{text === "BOT" && (
										<Tag color='magenta' className='drawer__typeTag'>
											{text}
										</Tag>
									)}
									{text === "GPIO" && (
										<Tag color='cyan' className='drawer__typeTag'>
											{text}
										</Tag>
									)}
								</div>
							</>
						)}
					/>
					{/** BANK TYPE -- */}
					<Column
						onCell={(record: BankInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("edgeManagement.banks.configurationType")}
						dataIndex='configurationType'
						key='configurationType'
						width='100px'
						className=''
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						render={(text, record: any) => (
							<>
								<div className=''>
									{record && record.adapter ? (
										<Tag color='purple' className='drawer__typeTag'>
											ADAPTER
										</Tag>
									) : (
										<Tag color='green' className='drawer__typeTag'>
											CONFIGURATION
										</Tag>
									)}
								</div>
							</>
						)}
					/>

					{/** SERVER NAME */}
					<Column
						onCell={(record: BankInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						width='150px'
						title={t("edgeManagement.servers.server")}
						render={(text, record: any) => (
							<>
								<div className=''>
									{record &&
									record.channel &&
									record.channel.server &&
									record.channel.server.name
										? record.channel.server.name
										: ""}
								</div>
							</>
						)}
					/>
					{/** CHANNEL NAME */}
					<Column
						onCell={(record: BankInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						width='150px'
						title={t("edgeManagement.channels.channel")}
						render={(text, record: any) => (
							<>
								<div className=''>
									{record && record.channel && record.channel.name
										? record.channel.name
										: "--"}
								</div>
							</>
						)}
					/>
					{/** TOTAL LIFTS */}
					<Column
						onCell={(record: BankInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						width='75px'
						title={t("edgeManagement.lifts.lifts")}
						dataIndex='total'
						key='total'
						render={(text, record: any) => (
							<>
								<div className=''>
									{record && record.lifts ? record.lifts.length : 0}
								</div>
							</>
						)}
					/>
					{/** STATUS */}
					<Column
						onCell={(record: BankInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("userManagement.status")}
						dataIndex='status'
						key='status'
						width='150px'
						className=''
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						filterDropdown={() => (
							<FilterSelectStatus
								placeHolder={t("general.status")}
								setFilterValue={setFilterStatus}
								filterValue={filterStatus}
								refreshTable={refreshTable}
							/>
						)}
						render={(text, record: any) => (
							<>
								<div className=''>
									{record.active && (
										<Tag
											color='success'
											icon={
												<FontAwesomeIcon
													icon={faCheckCircle}
													className='generalStyles__mrFix'
												/>
											}
											className='drawer__tag'
										>
											{t("general.active")}
										</Tag>
									)}
									{!record.active && (
										<Tag
											color='error'
											icon={
												<FontAwesomeIcon
													icon={faLock}
													className='generalStyles__mrFix'
												/>
											}
											className='drawer__tag'
										>
											{t("general.lock")}
										</Tag>
									)}
								</div>
							</>
						)}
					/>
					{/** ACTIONS */}
					<Column
						title={t("edgeManagement.banks.actions")}
						key='action'
						width='150px'
						render={(_: any, record: BankInterface) => (
							<div>
								<BanksTableAction selectedItem={record} />
							</div>
						)}
					/>
				</Table>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty
							description={t("edgeManagement.banks.listNotAvailable")}
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default BanksTable;
