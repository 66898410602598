import React from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterLiftType() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeLiftType = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.liftType = newValue;
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Select
				showSearch
				optionFilterProp='children'
				className='generalStyles__width100'
				allowClear={false}
				value={filters.liftType}
				onChange={onChangeLiftType}
				size='small'
			>
				{[
					{ id: "ALL", name: "All" },
					{ id: "ELEVATOR", name: "Elevator" },
					{ id: "ESCALATOR", name: "Escalator" },
					{ id: "MOVINGWALK", name: "Movingwalk" },
				].map((item: any) => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))}
			</Select>
		</div>
	);
}

export default ReportsFilterLiftType;
