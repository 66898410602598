import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const organizationAPI = createApi({
	reducerPath: "organizationAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: [
		"tagGetOrganizations",
		"tagGetOrganizationByOrganization",
		"tagGetOrganizationsAndChilds",
		"tagPostOrganization",
		"tagPutOrganization",
		"tagGetHolidays",
	],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ALL ORGANIZATIONS */
		getOrganizations: builder.query({
			query: (body: any) => ({
				url: `/orgs/`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetOrganizations"],
		}),
		//* *********************************************************** */
		//* GET ORGANIZATIONS BY ORGANIZATION */
		getOrganizationsByOrganization: builder.query({
			query: (body: any) => ({
				url: `/orgs/?filter_target=organization_id&filter=${body.orgId}&page=${body.page}&limit=${body.limit}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetOrganizationByOrganization"],
		}),
		//* *********************************************************** */
		//* GET SPECIFIC ORGANIZATION AND ITS CHILDRENS */
		getOrganizationAndChilds: builder.query({
			query: (body: any) => ({
				url: `/orgs/${body.orgId}`,
				// url: `/orgs/${body.orgId}&filter_target=name&filter=hello`,
				// url: `/orgs/${body.orgId}/?&page=${body.page}&limit=${body.limit}${body.searchAndFilterString || ""}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetOrganizationsAndChilds"],
		}),
		//* *********************************************************** */
		//* CREATE AN ORGANIZATION */
		postOrganization: builder.mutation({
			query: (body: any) => ({
				url: "/orgs/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [
				"tagGetOrganizations",
				"tagGetOrganizationByOrganization",
				"tagGetOrganizationsAndChilds",
			],
		}),
		//* *********************************************************** */
		//* EDIT AN ORGANIZATION */
		putOrganization: builder.mutation({
			query: (body: any) => ({
				url: `/orgs/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [
				"tagGetOrganizations",
				"tagGetOrganizationByOrganization",
				"tagGetOrganizationsAndChilds",
			],
		}),
		//* *********************************************************** */
		//* DELETE AN ORGANIZATION */
		deleteOrganization: builder.mutation({
			query: (body: any) => ({
				url: `/orgs/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: [
				"tagGetOrganizations",
				"tagGetOrganizationByOrganization",
				"tagGetOrganizationsAndChilds",
			],
		}),
		//* *********************************************************** */
		//* GET ALL HOLIDAYS */
		getHolidays: builder.query({
			query: (body: any) => ({
				url: `/holidays/?filter_target=organization_id&filter=${body.orgId}&sort=month&sort_direction=desc&limit=100`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetHolidays"],
		}),
		//* *********************************************************** */
		//* CREATE AN HOLIDAY */
		postHoliday: builder.mutation({
			query: (body: any) => ({
				url: "/holidays/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetHolidays"],
		}),
		//* *********************************************************** */
		//* EDIT AN HOLIDAY */
		putHoliday: builder.mutation({
			query: (body: any) => ({
				url: `/holidays/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetHolidays"],
		}),
		//* *********************************************************** */
		//* DELETE AN HOLIDAY */
		deleteHoliday: builder.mutation({
			query: (body: any) => ({
				url: `/holidays/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetHolidays"],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetOrganizationsQuery,
	useLazyGetOrganizationsByOrganizationQuery,
	useLazyGetOrganizationAndChildsQuery,
	usePostOrganizationMutation,
	usePutOrganizationMutation,
	useDeleteOrganizationMutation,
	useLazyGetHolidaysQuery,
	usePostHolidayMutation,
	usePutHolidayMutation,
	useDeleteHolidayMutation,
} = organizationAPI;
