import React from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button, Divider, Empty } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// TIMEZONE
import moment from "moment-timezone";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableCells, faWarning } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerRows } from "../../../redux/reports/reportsSlice";

function ReportsDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerRows, reportRow, filterTimezone } = useSelector(
		(state: any) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerRows(false));
	};

	const checkVariableIsNotObjectOrArray = (value: any) => {
		let isValid = true;
		if (Array.isArray(value)) {
			isValid = false;
		}
		if (typeof value === "object" && value !== null && !Array.isArray(value)) {
			isValid = false;
		}
		return isValid;
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerRows}
		>
			{/** ***************************************************************** */}
			{/* GENERAL ROW INFORMATION */}
			<div>
				<Divider orientation='left' className='generalStyles__drawerDivider'>
					<h5>
						<FontAwesomeIcon
							icon={faTableCells}
							className='generalStyles__info generalStyles__mrFix'
						/>
						Record Information
					</h5>
				</Divider>
			</div>
			{/* DINAMIC ROW */}
			<div className={`drawer__box__${theme}`}>
				{reportRow && (
					<>
						{Object.entries(reportRow).map(([key, value]: any) => (
							<div key={key}>
								{checkVariableIsNotObjectOrArray(value) && (
									<div className='generalStyles__flex'>
										<div>
											<p
												className={
													isMobile ? "drawer__titleMobile" : "drawer__title"
												}
											>
												{key}:
											</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>
												{value && value !== "" ? value : "--"}
											</p>
										</div>
									</div>
								)}
							</div>
						))}
					</>
				)}
			</div>
			<br />
			{/** ***************************************************************** */}
			{/* SERVICE INTERRUPTIONS */}
			{reportRow && reportRow.service_interruptions && (
				<>
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faWarning}
									className='generalStyles__info generalStyles__mrFix'
								/>
								Service Interruption
							</h5>
						</Divider>
					</div>
					{reportRow.service_interruptions.length === 0 && (
						<div className={`drawer__box__${theme} mt-2`}>
							<Empty />
						</div>
					)}
					{/* DINAMIC ROW */}
					{reportRow && (
						<>
							{reportRow.service_interruptions.map(
								(serviceInterruption: any) => (
									<div className={`drawer__box__${theme} mt-2`}>
										{Object.entries(serviceInterruption).map(
											([key, value]: any) => (
												<div key={key}>
													{checkVariableIsNotObjectOrArray(value) &&
														key !== "bank" &&
														key !== "car" && (
															<div className='generalStyles__flex'>
																<div>
																	<p
																		className={
																			isMobile
																				? "drawer__titleMobile"
																				: "drawer__title"
																		}
																	>
																		{key}:
																	</p>
																</div>
																<div className='drawer__textContainer'>
																	<p className='drawer__text'>
																		{[
																			"created_at",
																			"synced_at",
																			"start_timestamp",
																			"end_timestamp",
																			"timestamp",
																			"out_of_service_timestamp",
																		].includes(key)
																			? moment(value)
																					.tz(
																						filterTimezone &&
																							filterTimezone.tzCode &&
																							filterTimezone.tzCode !== ""
																							? filterTimezone.tzCode
																							: moment.tz.guess()
																					)
																					.format("MMMM Do YYYY, h:mm:ss a")
																			: value}
																	</p>
																</div>
															</div>
														)}
												</div>
											)
										)}
										<div className='generalStyles__flex'>
											<div>
												<p
													className={
														isMobile ? "drawer__titleMobile" : "drawer__title"
													}
												>
													Duration:
												</p>
											</div>
											<div className='drawer__textContainer'>
												<p className='drawer__text'>
													{serviceInterruption && serviceInterruption.duration && (
														<>
															<span>
																{serviceInterruption.duration.days >= 0
																	? `Days: ${serviceInterruption.duration.days} `
																	: "--"}
															</span>
															<span>
																{serviceInterruption.duration.hours >= 0
																	? `Hours: ${serviceInterruption.duration.hours} `
																	: "--"}
															</span>
															<span>
																{serviceInterruption.duration.minutes >= 0
																	? `Minutes: ${serviceInterruption.duration.minutes} `
																	: "--"}
															</span>
															<span>
																{serviceInterruption.duration.seconds >= 0
																	? `Seconds: ${serviceInterruption.duration.seconds} `
																	: "--"}
															</span>
														</>
													)}
												</p>
											</div>
										</div>
									</div>
								)
							)}
						</>
					)}
				</>
			)}
			{/** ************************************************* */}
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button
						type='default'
						onClick={onClickClose}
						className='buttonStyle__5'
					>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default ReportsDrawer;
