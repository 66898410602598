import React from "react";
// ANT DESIGN COMPONENTS
import { Input, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// UTILS
import { validateIpAndPortString } from "../../../utils/utils";
// INTERFACES
import { FormInputIPandPortInterface } from "../../../interfaces/Global.interface";

function FormInputIPandPort({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
}: FormInputIPandPortInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__text'>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Input
						className='generalStyles__input'
						size='small'
						value={inputValue}
						onChange={(event: React.FormEvent<HTMLInputElement>) => {
							inputOnChange(event.currentTarget.value, inputVariableName);
						}}
						status={
							validateIpAndPortString(inputValue || "") || inputValue === ""
								? ""
								: "error"
						}
					/>
				</div>
				<div style={{ marginRight: "-5px" }}>
					<Tooltip title={`${t("tooltip.socketAddress")}`} placement='top'>
						<FontAwesomeIcon
							icon={faCircleInfo}
							className='generalStyles__infoTooltipIcon'
						/>
					</Tooltip>
				</div>
			</div>
		</div>
	);
}

export default FormInputIPandPort;
