import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const chartAPI = createApi({
	reducerPath: "chartAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: [],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET FAULT CHARTS */
		postFaultChart: builder.mutation({
			query: (body: any) => ({
				url: "/reports/faults/chart",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET TRAFFIC TRIPS AND FROM FLOOR CHART */
		postTrafficTripsToAndFromAFloor: builder.mutation({
			query: (body: any) => ({
				url: "/reports/traffic/chart/trips",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET TRAFFIC HALL CALL CHART */
		postTrafficHallCall: builder.mutation({
			query: (body: any) => ({
				url: "/reports/traffic/chart/hall-button",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET TRAFFIC DISTRIBUTION BY HOURLY INTERVAL */
		postTrafficDistribution: builder.mutation({
			query: (body: any) => ({
				url: "/reports/traffic/chart/distribution",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET TRAFFIC WAIT TIME */
		postTrafficWaitTime: builder.mutation({
			query: (body: any) => ({
				url: "/reports/traffic/chart/wait-time",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET CAR USE DAY  */
		postCarUseDay: builder.mutation({
			query: (body: any) => ({
				url: "/reports/usage/chart/day",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET CAR USE COMPARISONS */
		postCarUseComparisons: builder.mutation({
			query: (body: any) => ({
				url: "/reports/usage/chart/comparison",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
		//* GET CAR USE DOOR TIMES */
		postCarUseTimes: builder.mutation({
			query: (body: any) => ({
				url: "/reports/usage/chart/door-times",
				body: body.body,
				method: "post",
				headers: { authorization: body.token },
			}),
		}),
		//* *********************************************************** */
	}),
});

export const {
	usePostFaultChartMutation,
	usePostTrafficTripsToAndFromAFloorMutation,
	usePostTrafficHallCallMutation,
	usePostTrafficDistributionMutation,
	usePostTrafficWaitTimeMutation,
	usePostCarUseDayMutation,
	usePostCarUseComparisonsMutation,
	usePostCarUseTimesMutation,
} = chartAPI;
