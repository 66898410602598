import React from "react";
import { Auth } from "aws-amplify";
// ANT DESIGN COMPONENTS
import { Modal, Button, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateUserLogged } from "../../redux/home/homeSlice";
import { usePostTermnsAndConditionsMutation } from "../../redux/users/userAPI";
// AUTHORIZATION
import GETJwtToken from "../../redux/authentication/authentication";
// COMPONENTS
import EulaText from "../termsAndConditions/EulaText";

function TermsAndConditions() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { userLogged } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [postTermsAndConditions, { isLoading }] =
		usePostTermnsAndConditionsMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickAccept = async () => {
		const token = await GETJwtToken();
		const response = await postTermsAndConditions({
			token,
			body: userLogged,
		}).unwrap();
		if (
			response &&
			response.data &&
			response.data.user &&
			response.data.user.accepted_terms
		) {
			dispatch(updateUserLogged(response.data.user));
		}
	};

	const signOut = async () => {
		await Auth.signOut();
		window.location.href = `${process.env.REACT_APP_LOGIN_ROUTE}`;
	};
	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={onClickAccept}
			closable={false}
			open={!userLogged.accepted_terms}
			width='50%'
			footer={
				<div className='generalStyles__flexEnd mt-1'>
					<div>
						<Button type='default' className='buttonStyle__2' onClick={signOut}>
							{t("header.signOut")}
						</Button>
					</div>
					<div className='generalStyles__mlFix'>
						<Button
							type='default'
							className='buttonStyle__1'
							onClick={onClickAccept}
						>
							{t("general.accept")}
						</Button>
					</div>
				</div>
			}
		>
			<Spin spinning={isLoading}>
				{/** ************************* */}
				{/** BODY */}
				<div style={{ height: "55vh", overflowY: "scroll", padding: "15px" }}>
					<EulaText />
				</div>
			</Spin>
		</Modal>
	);
}

export default TermsAndConditions;
