/* eslint-disable camelcase */
import React, { useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Button, Spin } from "antd";
// JSON EDITOR
import JSONInput from "react-json-editor-ajrm";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateRootPermissions,
	updateOpenModalEditPermissionsRoot,
} from "../../../redux/configuration/configurationSlice";
// STYLES
import { localeEn } from "../../../utils/jsonEditor";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function RootPermissionsJsonViewer() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { userLogged } = useSelector((state: any) => state.home);
	const [menu, setMenu] = useState({});
	const [showJsonEditor, setShowJsonEditor] = useState(false);
	const [showSpin, setShowSpin] = useState(false);
	const [masterRoleId, setMasterRoleId] = useState("");
	const [newMenu, setNewMenu] = useState("");
	const [t] = useTranslation("global");

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleShowJsonViewer = () => {
		if (
			userLogged &&
			userLogged.role &&
			userLogged.role.name === process.env.REACT_APP_ROLE_MASTER_NAME &&
			userLogged.role.id
		) {
			setMasterRoleId(userLogged.role.id);
		}
		if (
			userLogged &&
			userLogged.role &&
			userLogged.role.menu &&
			userLogged.role.menu
		) {
			setMenu(userLogged.role.menu);
			setShowSpin(true);
			setShowJsonEditor(true);
			setTimeout(() => {
				setShowSpin(false);
			}, 1000);
		}
	};

	const handleChangePermissions = async () => {
		const token = await GETJwtToken();
		const BODY = {
			id: masterRoleId,
			body: {
				active: true,
				menu: newMenu,
				name: userLogged.role.name,
			},
			token,
		};
		dispatch(updateRootPermissions(BODY));
		dispatch(updateOpenModalEditPermissionsRoot(true));
	};

	const handleChangeJson = (values: any) => {
		setNewMenu(values.json);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{!showJsonEditor && (
				<div className='mb-4'>
					<Alert
						message={t("configuration.rootPermissions.warning")}
						description={t("configuration.rootPermissions.warningDescription")}
						type='warning'
						showIcon
						closable
					/>
					<Button
						className='buttonStyle__2 configuration__editRootPermisionsButton'
						onClick={handleShowJsonViewer}
					>
						{t("configuration.rootPermissions.showRootConfiguration")}
					</Button>
				</div>
			)}
			{showJsonEditor && (
				<Spin spinning={showSpin}>
					<div>
						<div className='mb-4'>
							<Button
								className='buttonStyle__2 '
								onClick={handleChangePermissions}
							>
								{t("configuration.saveChanges")}
							</Button>
							<Button
								className='generalStyles__mlFix'
								type='default'
								onClick={() => {
									setShowJsonEditor(false);
								}}
							>
								{t("configuration.cancel")}
							</Button>
						</div>
						<JSONInput
							placeholder={menu} // data to display
							theme='light_mitsuketa_tribute'
							locale={localeEn}
							viewOnly={!showJsonEditor}
							onChange={handleChangeJson}
							colors={{
								string: "#DAA520", // overrides theme colors with whatever color value you want
							}}
							waitAfterKeyPress={3000}
							height='100'
							width='100%'
						/>
					</div>
				</Spin>
			)}
		</div>
	);
}

export default RootPermissionsJsonViewer;
