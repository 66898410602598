import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenModalCreateNewRole,
	updateRoleRefreshTable,
} from "../../../redux/roles/roleSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
// INTERFACES
import { RootState } from "../../../app/store";

function RolesHeader() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { permissions } = useSelector((state: RootState) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewRole = () => {
		dispatch(updateOpenModalCreateNewRole(true));
	};

	const onClickReloadPage = () => {
		dispatch(updateRoleRefreshTable(true));
		setTimeout(() => {
			dispatch(updateRoleRefreshTable(false));
		}, 1000);
	};

	return (
		<div className='generalStyles__pageHeader'>
			{validatePermission("roles-create", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						type='primary'
						icon={
							<FontAwesomeIcon icon={faPlus} className='generalStyles__mrFix' />
						}
						onClick={handleOpenModalCreateNewRole}
					>
						{t("organizationManagement.createNewRole")}
					</Button>
				</div>
			)}
			{validatePermission("roles-view", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						className='buttonStyle__8'
						icon={
							<FontAwesomeIcon
								icon={faArrowsRotate}
								className='generalStyles__mrFix'
							/>
						}
						onClick={() => {
							onClickReloadPage();
						}}
					>
						{t("organizationManagement.refreshTable")}
					</Button>
				</div>
			)}
		</div>
	);
}

export default RolesHeader;
