import React from "react";
// ANT DESIGN COMPONENTS
import { Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsDrawerSortFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeSortDirection = (newValue: boolean) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.sortAsc = newValue;
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`drawer__box__${theme}`}>
			{/* SORT DIRECTION */}
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>{t("general.sortDirection")}:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					<Switch
						style={{ width: "70px" }}
						checkedChildren='ASC'
						unCheckedChildren='DESC'
						checked={filters.sortAsc}
						onChange={onChangeSortDirection}
					/>
				</div>
			</div>
		</div>
	);
}

export default ReportsDrawerSortFilters;
