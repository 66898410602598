import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
	Button,
	Form,
	Input,
	Select,
	InputNumber,
	Spin,
	Divider,
	Drawer,
	Switch,
	Tooltip,
} from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBuildingCircleCheck,
	faBuilding,
	faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	usePostOrganizationMutation,
	useLazyGetOrganizationAndChildsQuery,
} from "../../../redux/organizations/organizationAPI";
import {
	updateOpenModalCreateSubOrganization,
	updateOrganizationAndChilds,
} from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import {
	useGetCountriesMutation,
	useGetStatesMutation,
	useGetCitiesMutation,
} from "../../../redux/countriesStatesCitiesAPI";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

const { Option } = Select;
library.add(faBuildingCircleCheck, faBuilding);

function OrganizationsCreateSubOrganizationForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [country, setCountry] = useState("");
	const [listCountries, setListCountries] = useState<any[]>([]);
	const [listStates, setListStates] = useState<any[]>([]);
	const [listCities, setListCities] = useState<any[]>([]);
	const [listOrganizations, setListOrganizations] = useState<any[]>([]);
	const [idOrganizationSelected, setIdOrganizationSelected] = useState("");
	const [masterID, setMasterID] = useState("");
	const [type, setType] = useState("SUBORG");
	const [useParentData, setUseParentData] = useState(false);
	const [staticOrganization, setStaticOrganization] = useState("");
	const [
		isLoadingGetOrganizationAndChilds,
		setIsLoadingGetOrganizationAndChilds,
	] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		organization: organizationData,
		openModalCreateSubOrganization,
		organizationAndChilds,
	} = useSelector((state: any) => state.organization);
	const { userLogged, theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [getCountries, { data: countries }] = useGetCountriesMutation();
	const [
		getStates,
		{ data: states, isLoading: isLoadingStates, reset: resetGetStates },
	] = useGetStatesMutation();
	const [
		getCities,
		{ data: cities, isLoading: isLoadingCities, reset: resetGetCities },
	] = useGetCitiesMutation();
	const [
		postOrganization,
		{
			isSuccess: isSuccessPostOrganization,
			isLoading: isLoadingPostOrganization,
			isError: isErrorPostOrganization,
			error: errorPostOrganization,
			reset: resetPostOrganization,
		},
	] = usePostOrganizationMutation();
	const [triggerGetOrganizationAndChilds] =
		useLazyGetOrganizationAndChildsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const cleanOrganizationsList = () => {
		dispatch(
			updateOrganizationAndChilds({
				childs: [],
			})
		);
	};

	const onClose = () => {
		cleanOrganizationsList();
		dispatch(updateOpenModalCreateSubOrganization(false));
	};

	const onResetStateCity = () => {
		form.setFieldsValue({
			state: "",
			city: "",
		});
		setListCities([]);
		resetGetCities();
	};

	const handleCloseModal = () => {
		cleanOrganizationsList();
		dispatch(updateOpenModalCreateSubOrganization(false));
	};

	const handleChangeCountry = (value: string) => {
		onResetStateCity();
		setCountry(value);
		getStates(value);
	};

	const handleChangeState = (value: string) => {
		getCities({ country, state: value });
	};

	const onFinish = async (values: any) => {
		const token = await GETJwtToken();
		const BODY = {
			body: {
				Name: values.name,
				Address: values.address,
				phone: values.phone.toString(),
				phone_code: values.dialCode.toString(),
				Type: type,
				Country: values.country,
				parent_id:
					idOrganizationSelected !== "" ? idOrganizationSelected : masterID,
				State: values.state,
				City: values.city,
			},
			token,
		};
		postOrganization(BODY);
	};

	const getOrganizationAndChilds = async (orgId: string) => {
		const token = await GETJwtToken();
		setIsLoadingGetOrganizationAndChilds(true);
		const data = await triggerGetOrganizationAndChilds({
			orgId,
			token,
		}).unwrap();
		setIsLoadingGetOrganizationAndChilds(false);
		if (
			data &&
			data.data &&
			data.data.children &&
			data.data.children.length > 0
		) {
			const BODY = {
				childs: [
					...organizationAndChilds.childs,
					{
						level: organizationAndChilds.childs.length + 1,
						data: data.data.children,
						orgSelected: orgId,
					},
				],
			};
			dispatch(updateOrganizationAndChilds(BODY));
		}
	};

	const handleChangeOrganization = (value: string, level: number) => {
		const totalChilds = organizationAndChilds.childs.length;
		// if we select an organization, automatically type becomes SUBORG
		setType("SUBORG");
		// if we select a new value
		if (value) {
			setIdOrganizationSelected(value);
			getOrganizationAndChilds(value);
		}
		// if we select the last value of the list
		else if (totalChilds === level) {
			setIdOrganizationSelected(
				organizationAndChilds.childs[level - 1].orgSelected
			);
			form.setFieldsValue({ [`assignToOrganization${level}`]: undefined });
		}
		// if we select a middle value
		else {
			const newArray = [];
			setIdOrganizationSelected(
				organizationAndChilds.childs[level - 1].orgSelected
			);
			for (let index = 0; index < level; index += 1) {
				newArray.push(organizationAndChilds.childs[index]);
			}
			for (let index = level + 1; index <= totalChilds; index += 1) {
				form.setFieldsValue({ [`assignToOrganization${index}`]: undefined });
			}
			const BODY = {
				childs: newArray,
			};
			dispatch(updateOrganizationAndChilds(BODY));
		}
	};

	const validateCurrentOrganizationType = () => {
		if (userLogged && userLogged.org) {
			if (
				userLogged.org.type &&
				userLogged.org.type !== process.env.REACT_APP_ORGANIZATION_MASTER_TYPE
			) {
				setType("SUBORG");
			}
			setStaticOrganization(organizationData.name ? organizationData.name : "");
		}
		setMasterID(organizationData.id);
	};

	const onChangeFillParentData = (checked: boolean) => {
		setUseParentData(checked);
		if (checked) {
			form.setFieldsValue({
				address: organizationData.address,
				phone: parseInt(organizationData.phone, 10),
				dialCode: parseInt(organizationData.phone_code, 10),
				country: organizationData.country || "",
				state: organizationData.state || "",
				city: organizationData.city || "",
				// parentOrganization: organization.parent_id,
			});
		} else {
			form.setFieldsValue({
				name: "",
				address: "",
				phone: "",
				dialCode: "",
				country: "",
				state: "",
				city: "",
				// parentOrganization: organization.parent_id,
			});
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalCreateSubOrganization) {
			resetPostOrganization();
			resetGetStates();
			resetGetCities();
			setUseParentData(false);
			setListStates([]);
			setListCities([]);
			form.resetFields();
			if (openModalCreateSubOrganization) {
				getCountries({});
			}
			validateCurrentOrganizationType();
			getOrganizationAndChilds(organizationData.id);
		}
	}, [openModalCreateSubOrganization]);

	useEffect(() => {
		if (countries && countries.data && countries.data.length > 0) {
			setListCountries(countries.data);
		}
	}, [countries]);

	useEffect(() => {
		if (
			states &&
			states.data &&
			states.data.states &&
			states.data.states.length > 0
		) {
			setListStates(states.data.states);
		}
	}, [states]);

	useEffect(() => {
		if (cities && cities.data && cities.data.length > 0) {
			setListCities(cities.data);
		}
	}, [cities]);

	useEffect(() => {
		cleanOrganizationsList();
		dispatch(updateOpenModalCreateSubOrganization(false));
	}, [isSuccessPostOrganization, isErrorPostOrganization]);

	useEffect(() => {
		if (organizationAndChilds && organizationAndChilds.childs) {
			setListOrganizations(organizationAndChilds.childs);
		}
	}, [organizationAndChilds]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClose}
			closable={false}
			open={openModalCreateSubOrganization}
		>
			<Spin
				spinning={
					isLoadingPostOrganization ||
					isLoadingCities ||
					isLoadingStates ||
					isLoadingGetOrganizationAndChilds
				}
				tip={t("general.loading")}
			>
				<Form form={form} layout='vertical' onFinish={onFinish}>
					{/** ************************************************** */}
					{/** SUB ORGANIZATION NAME **************************** */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faBuildingCircleCheck}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{t("organizationManagement.organizationName")}
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme}`}>
						<div className='row'>
							{/** NAME */}
							<div className='col-md-12 mt-3'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.organizationName")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='name'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.name"
													),
												},
												{
													max: 50,
													message: t(
														"organizationManagement.formRequiredMessage.nameMax"
													),
												},
											]}
										>
											<Input className='generalStyles__input' />
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
						</div>
					</div>
					<br />
					{/** ************************************************** */}
					{/** CREATE SUB ORGANIZATION FORM ********************* */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faBuilding}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{t("organizationManagement.subOrganization")}
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme}`}>
						<div className='container'>
							<div className='row'>
								{/** FILL WITH PARENT ORGANIZATION INFORMATION */}
								<div className='col-12 mt-2'>
									<Switch
										checked={useParentData}
										onChange={onChangeFillParentData}
										className='generalStyles__mlFix'
									/>
									<span className='generalStyles__mlFix'>
										Fill with{" "}
										<b className='generalStyles__info'>
											{organizationData && organizationData.name
												? organizationData.name
												: "--"}
										</b>{" "}
										information
									</span>
									<Divider />
								</div>
								{/** ADDRESS */}
								<div className='col-md-12 mt-3'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span className='generalStyles__inputFlexRequired'>
												*
											</span>
											<span>{t("organizationManagement.address")}</span>
										</div>
										<div className='generalStyles__width100'>
											<Form.Item
												name='address'
												rules={[
													{
														required: true,
														message: t(
															"organizationManagement.formRequiredMessage.address"
														),
													},
													{
														max: 100,
														message: t(
															"organizationManagement.formRequiredMessage.addressMax"
														),
													},
												]}
											>
												<Input className='generalStyles__input' />
											</Form.Item>
										</div>
										<div className='generalStyles__infoTooltipIconBlank' />
									</div>
								</div>
							</div>
							{/** PHONE */}
							<div className='col-md-12 mt-3'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>

										<span>{t("organizationManagement.phoneNumber")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='phone'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.phone"
													),
													type: "number",
												},
												{
													type: "number",
													max: 999999999,
													message: t(
														"organizationManagement.formRequiredMessage.phoneMax"
													),
												},
											]}
										>
											<InputNumber
												addonBefore={
													<Form.Item
														name='dialCode'
														rules={[
															{
																required: true,
																message: t(
																	"organizationManagement.formRequiredMessage.dialCode"
																),
															},
															{
																type: "number",
																max: 99999,
																message: t(
																	"organizationManagement.formRequiredMessage.dialCodeMax"
																),
															},
														]}
														noStyle
													>
														<InputNumber
															prefix='+'
															className='generalStyles__inputNumber'
														/>
													</Form.Item>
												}
												className='generalStyles__width100'
											/>
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
							{/** COUNTRY */}
							<div className='col-md-12 mt-3'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.country")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='country'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.country"
													),
												},
											]}
										>
											<Select
												showSearch
												optionFilterProp='children'
												onChange={handleChangeCountry}
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
											>
												{listCountries.map((c) => (
													<Option key={c.name} value={c.name}>
														{c.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
							{/** STATE */}
							<div className='col-md-12 mt-3'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.state")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='state'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.state"
													),
												},
											]}
										>
											<Select
												disabled={listStates.length === 0}
												showSearch
												optionFilterProp='children'
												onChange={handleChangeState}
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
											>
												{listStates.map((st) => (
													<Option key={st.name} value={st.name}>
														{st.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<Tooltip
										title={t("tooltip.organizationState")}
										placement='top'
										className='generalStyles__mlFix mt-2'
									>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>
								</div>
							</div>
							{/** CITY */}
							<div className='col-md-12 mt-3'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span>{t("organizationManagement.city")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item name='city'>
											<Select
												disabled={listCities.length === 0}
												showSearch
												optionFilterProp='children'
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
											>
												{listCities.map((c) => (
													<Option key={c} value={c}>
														{c}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<Tooltip
										title={t("tooltip.organizationCity")}
										placement='top'
										className='generalStyles__mlFix mt-2'
									>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>{" "}
								</div>
							</div>
						</div>
					</div>

					<br />
					<br />
					{/** ************************************************** */}
					{/** ASSIGN TO ORGANIZATION FORM ********************** */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faBuildingCircleCheck}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{t("organizationManagement.assignToOrganization")}
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme} generalStyles__flexCenter`}>
						<div className='row'>
							{/** STATIC ORGANIZATION */}
							<div className='col-12'>
								{staticOrganization !== "" && (
									<>
										<span className='generalStyles__staticInputLabel'>
											{t("organizationManagement.organization")}
										</span>
										<Input
											className='generalStyles__input mt-1 mb-3'
											value={staticOrganization}
											disabled
										/>
									</>
								)}
							</div>
							{/** ASSIGN TO ORGANIZATION */}
							<div className='col-12'>
								{listOrganizations.map((organization: any) => (
									<Form.Item
										key={organization.level}
										name={`assignToOrganization${organization.level}`}
										label={
											<>
												<span className='drawer__text'>
													{t("organizationManagement.assignToSubOrganization")}
												</span>
											</>
										}
										rules={[
											{
												required: organization.level === 0,
												message:
													organization.level === 0
														? t(
																"userManagement.formRequiredMessage.organization"
														  )
														: t(
																"userManagement.formRequiredMessage.subOrganization"
														  ),
											},
										]}
									>
										<Select
											showSearch
											optionFilterProp='children'
											onChange={(value) => {
												handleChangeOrganization(value, organization.level);
											}}
											allowClear
											filterOption={(input: any, option: any) =>
												option.children
													.toLowerCase()
													.includes(input.toLowerCase())
											}
										>
											{organization.data.map((item: any) => (
												<Option key={item.id} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								))}
							</div>
						</div>
					</div>
					{/** ************************************************** */}
					{/** CLOSE AND SAVE BUTTONS *************************** */}
					<div className='mt-4'>
						<div>
							<Button
								type='default'
								onClick={handleCloseModal}
								className='buttonStyle__3'
							>
								{t("general.close")}
							</Button>
							<Button
								htmlType='submit'
								disabled={isLoadingPostOrganization}
								className='buttonStyle__3'
								type='primary'
							>
								{t("organizationManagement.save")}
							</Button>
						</div>
					</div>
				</Form>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostOrganization}
				isSuccess={isSuccessPostOrganization}
				requestType='POST'
				error={errorPostOrganization}
				name='Organization'
			/>
		</Drawer>
	);
}

export default OrganizationsCreateSubOrganizationForm;
