import React from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// INTERFACES
import { FormSelectInterface } from "../../../interfaces/Global.interface";

function FormSelect({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
	inputOptions,
}: FormSelectInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Select
						className='generalStyles__width100'
						size='small'
						onChange={(e: any) => {
							inputOnChange(e, inputVariableName);
						}}
						value={inputValue}
					>
						{inputOptions.map((item: any) => (
							<Option key={item} value={item}>
								{item}
							</Option>
						))}
					</Select>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormSelect;
