import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
	Drawer,
	Button,
	Form,
	Input,
	Select,
	InputNumber,
	Spin,
	Divider,
	Tooltip,
} from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBuildingCircleCheck,
	faBuilding,
	faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutOrganizationMutation } from "../../../redux/organizations/organizationAPI";
import { updateOpenModalEditOrganization } from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import {
	useGetCountriesMutation,
	useGetStatesMutation,
	useGetCitiesMutation,
} from "../../../redux/countriesStatesCitiesAPI";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

const { Option } = Select;
library.add(faBuildingCircleCheck, faBuilding);

function OrganizationsEditOrganizationForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const [form] = Form.useForm();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [country, setCountry] = useState("");
	const [listCountries, setListCountries] = useState<any[]>([]);
	const [listStates, setListStates] = useState<any[]>([]);
	const [listCities, setListCities] = useState<any[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { organization, organizationList, openModalEditOrganization } =
		useSelector((state: any) => state.organization);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [getCountries, { data: countries }] = useGetCountriesMutation();
	const [getStates, { data: states, isLoading: isLoadingStates }] =
		useGetStatesMutation();
	const [getCities, { data: cities, isLoading: isLoadingCities }] =
		useGetCitiesMutation();
	//
	const [
		putOrganization,
		{
			isSuccess: isSuccessPutOrganization,
			isError: isErrorPutOrganization,
			error: errorPutOrganization,
			isLoading: isLoadingPutOrganization,
			reset: resetPutOrganization,
		},
	] = usePutOrganizationMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onReset = () => {
		form.resetFields();
	};

	const onResetStateCity = () => {
		form.setFieldsValue({
			state: "",
			city: "",
		});
	};

	const handleCloseModal = () => {
		dispatch(updateOpenModalEditOrganization(false));
	};

	const handleChangeCountry = (value: string) => {
		onResetStateCity();
		setCountry(value);
		getStates(value);
	};

	const handleChangeState = (value: string) => {
		getCities({ country, state: value });
	};

	const onFinish = async (values: any) => {
		const token = await GETJwtToken();
		const BODY = {
			id: organization.id,
			body: {
				Name: values.name,
				Address: values.address,
				phone: values.phone.toString(),
				phone_code: values.dialCode.toString(),
				Type: organization.type,
				Country: values.country,
				parent_id: organization.parent_id,
				State: values.state,
				City: values.city,
			},
			token,
		};
		putOrganization(BODY);
	};

	const onClose = () => {
		onReset();
		dispatch(updateOpenModalEditOrganization(false));
	};

	const onFill = () => {
		form.setFieldsValue({
			name: organization.name || "",
			address: organization.address,
			phone: parseInt(organization.phone, 10),
			dialCode: parseInt(organization.phone_code, 10),
			type: organization.type || "",
			country: organization.country || "",
			state: organization.state || "",
			city: organization.city || "",
			parentOrganization: organization.parent_id,
		});
	};

	const fillOrganizationsObject = () => {
		//
		const cleanList: any = [];
		if (organizationList && organizationList.length > 0) {
			// eslint-disable-next-line array-callback-return
			organizationList.map((item: any) => {
				if (item.type === "MASTER" || item.type === "ORG") {
					cleanList.push(item);
				}
			});
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		onFill();
		resetPutOrganization();
		if (openModalEditOrganization) {
			getCountries({});
		}
	}, [openModalEditOrganization]);

	useEffect(() => {
		if (countries && countries.data && countries.data.length > 0) {
			setListCountries(countries.data);
		}
	}, [countries]);

	useEffect(() => {
		if (
			states &&
			states.data &&
			states.data.states &&
			states.data.states.length > 0
		) {
			setListStates(states.data.states);
		}
	}, [states]);

	useEffect(() => {
		if (cities && cities.data && cities.data.length > 0) {
			setListCities(cities.data);
		}
	}, [cities]);

	useEffect(() => {
		dispatch(updateOpenModalEditOrganization(false));
	}, [isSuccessPutOrganization, isErrorPutOrganization]);

	useEffect(() => {
		fillOrganizationsObject();
	}, [organizationList]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClose}
			closable={false}
			open={openModalEditOrganization}
		>
			<Spin
				spinning={
					isLoadingPutOrganization || isLoadingCities || isLoadingStates
				}
				tip={t("general.loading")}
			>
				{/** ************************************************** */}
				{/** EIDT ORGANIZATION FORM *************************** */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faBuilding}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("organizationManagement.editOrganization")}
						</h5>
					</Divider>
				</div>
				<Form form={form} layout='vertical' onFinish={onFinish}>
					<div className='container'>
						<div className={`drawer__box__${theme} row`}>
							{/** NAME */}
							<div className='col-md-12 mt-3'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.organizationName")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='name'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.name"
													),
												},
												{
													max: 50,
													message: t(
														"organizationManagement.formRequiredMessage.nameMax"
													),
												},
											]}
										>
											<Input className='generalStyles__input' size='small' />
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
							{/** ADDRESS */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.address")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='address'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.address"
													),
												},
												{
													max: 100,
													message: t(
														"organizationManagement.formRequiredMessage.addressMax"
													),
												},
											]}
										>
											<Input className='generalStyles__input' size='small' />
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
							{/** PHONE */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.phoneNumber")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='phone'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.phone"
													),
													type: "number",
												},
												{
													type: "number",
													max: 999999999,
													message: t(
														"organizationManagement.formRequiredMessage.phoneMax"
													),
												},
											]}
										>
											<InputNumber
												size='small'
												addonBefore={
													<Form.Item
														name='dialCode'
														rules={[
															{
																required: true,
																message: t(
																	"organizationManagement.formRequiredMessage.dialCode"
																),
															},
															{
																type: "number",
																max: 99999,
																message: t(
																	"organizationManagement.formRequiredMessage.dialCodeMax"
																),
															},
														]}
														noStyle
													>
														<InputNumber
															prefix='+'
															className='generalStyles__inputNumber'
															size='small'
														/>
													</Form.Item>
												}
												className='generalStyles__width100'
											/>
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
							{/** COUNTRY */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.country")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='country'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.country"
													),
												},
											]}
										>
											<Select
												showSearch
												optionFilterProp='children'
												onChange={handleChangeCountry}
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												size='small'
											>
												{listCountries.map((c) => (
													<Option key={c.name} value={c.name}>
														{c.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
							{/** STATE */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.state")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='state'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.state"
													),
												},
											]}
										>
											<Select
												disabled={listStates.length === 0}
												showSearch
												optionFilterProp='children'
												onChange={handleChangeState}
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												size='small'
											>
												{listStates.map((st) => (
													<Option key={st.name} value={st.name}>
														{st.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<Tooltip
										title={t("tooltip.organizationState")}
										placement='top'
										className='generalStyles__mlFix'
									>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>
								</div>
							</div>
							{/** CITY */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span>{t("organizationManagement.city")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item name='city'>
											<Select
												disabled={listCities.length === 0}
												showSearch
												optionFilterProp='children'
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												size='small'
											>
												{listCities.map((c) => (
													<Option key={c} value={c}>
														{c}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<Tooltip
										title={t("tooltip.organizationCity")}
										placement='top'
										className='generalStyles__mlFix'
									>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>
								</div>
							</div>
						</div>

						<br />
					</div>
					{/** ************************************************** */}
					{/** CLOSE AND SAVE BUTTONS *************************** */}
					<div className='mt-4'>
						<div>
							<Button onClick={handleCloseModal} className='buttonStyle__3'>
								{t("organizationManagement.close")}
							</Button>
							<Button
								htmlType='submit'
								disabled={isLoadingPutOrganization}
								className='buttonStyle__3'
								type='primary'
							>
								{t("general.save")}
							</Button>
						</div>
					</div>
				</Form>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPutOrganization}
				isSuccess={isSuccessPutOrganization}
				requestType='PUT'
				error={errorPutOrganization}
				name='Organization'
			/>
		</Drawer>
	);
}

export default OrganizationsEditOrganizationForm;
