import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Tooltip, Button, message } from "antd";
// TIMEZONE
import moment from "moment-timezone";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetLiftReportQuery } from "../../../redux/reports/reportsAPI";
import {
	updateFaults,
	updateExistsFaultsInLiftSelected,
} from "../../../redux/monitoring/monitoringSlice";
// INTERFACES
import { RootState } from "../../../app/store";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

function ControlPanelFaults() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Column } = Table;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [faults, setFaults] = useState<any>([]);
	const [isLoadingFaults, setIsLoadingFaults] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { liftSelected, existsFaultsInLiftSelected } = useSelector(
		(state: RootState) => state.monitoring
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isError, isFetching }] =
		useLazyGetLiftReportQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getFaultMessages = async (liftId: string) => {
		const token: string = await GETJwtToken();
		triggerGetReport({
			page: 1,
			limit: 10,
			token: token || "",
			searchAndFilterString: `start_date=${moment(new Date())
				.subtract(15, "days")
				.format("YYYY-MM-DD")}T00:00:00.000Z&end_date=${moment(
				new Date()
			).format(
				"YYYY-MM-DD"
			)}T23:59:59.999Z&filter_target=msg.type%7Clift_id&filter=FAULT%7C${liftId}&timezone=${moment.tz.guess()}`,
		});
	};

	const clearFaults = () => {
		dispatch(updateExistsFaultsInLiftSelected(false));
		dispatch(
			updateFaults({
				liftId: liftSelected.id,
				type: "LIFT",
			})
		);
		setIsLoadingFaults(true);
		setTimeout(() => {
			setIsLoadingFaults(false);
		}, 1000);
		message.success({
			type: "success",
			content: `${t("monitoring.faultsCleared")}`,
		});
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (liftSelected && liftSelected.id) {
			getFaultMessages(liftSelected.id);
		}
	}, [liftSelected]);

	useEffect(() => {
		if (data) {
			setFaults(data.data);
		}
	}, [data]);

	useEffect(() => {
		setFaults([]);
	}, [isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className='monitoringView__detailsBox'
			style={{ minHeight: "400px", height: "auto" }}
		>
			<div
				className={`montoringView__controlPanel montoringView__controlPanel__${theme}`}
				style={{
					minHeight: "400px",
					height: "auto",
					paddingBottom: "25px",
				}}
			>
				{/** FAULTS */}
				<Table
					rowClassName={(record, index) => getTableRowClass(index, theme)}
					className='customTable'
					dataSource={faults}
					loading={isLoading || isFetching || isLoadingFaults}
					rowKey={(record) => record.id}
					size='small'
				>
					{/** Index */}
					<Column
						title='No'
						width='50px'
						render={(text, record: any, index: number) => (
							<>
								<div className='generalStyles__info'>{index + 1}</div>
							</>
						)}
					/>
					{/** Created at */}
					<Column
						title='Created at'
						dataIndex='created_at'
						key='created_at'
						render={(text) => (
							<Tooltip
								placement='top'
								title={moment(text)
									.tz(moment.tz.guess())
									.format("MMMM Do YYYY, h:mm:ss a")}
							>
								<div>
									{moment(text)
										.tz(moment.tz.guess())
										.format("MMMM Do YYYY, h:mm:ss a")}
								</div>{" "}
							</Tooltip>
						)}
					/>
					{/** Short MSG */}
					<Column
						title='Message'
						dataIndex='short_msg'
						key='short_msg'
						render={(text, record: any) => (
							<Tooltip
								placement='top'
								title={
									record && record.msg && record.msg.short_msg
										? record.msg.short_msg
										: "--"
								}
							>
								<div>
									{record && record.msg && record.msg.short_msg
										? record.msg.short_msg
										: "--"}{" "}
								</div>
							</Tooltip>
						)}
					/>
				</Table>
				<div className='generalStyles__flexEnd '>
					{existsFaultsInLiftSelected && (
						<Button onClick={clearFaults} className='buttonStyle__4 mt-3'>
							Clear Faults
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default ControlPanelFaults;
