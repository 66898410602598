import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerOrganization } from "../../../redux/organizations/organizationSlice";
// INTERFACE
import { OrganizationInterface } from "../../../interfaces/Organizations.interface";
import { RootState } from "../../../app/store";
// COMPONENTS
import DrawerOrganizationInformation from "../../components/drawer/DrawerOrganizationInformation";

function OrganizationDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<OrganizationInterface>();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { organization, openDrawerOrganization } = useSelector(
		(state: RootState) => state.organization
	);

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerOrganization(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (organization) {
			setData(organization);
		}
	}, [organization]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerOrganization}
		>
			{data && (
				<div>
					{/** ************************************************* */}
					{/** COMPONENT ORGANIZATION INFORMATION  */}
					<DrawerOrganizationInformation data={data || {}} />
				</div>
			)}
			<br />
			{/** CLOSE BUTTON */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button
						type='default'
						onClick={onClickClose}
						className='buttonStyle__5'
					>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default OrganizationDrawer;
