import React from "react";
// ANT DESIGN COMPONENTS
import { DatePicker } from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterDateYear() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChange = (date: any, dateString: any) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment()
			.year(parseInt(dateString, 10))
			.startOf("year")
			.tz(filters.timezoneCode || moment.tz.guess())
			.toISOString();
		copy.endDate = moment()
			.year(parseInt(dateString, 10))
			.endOf("year")
			.tz(filters.timezoneCode || moment.tz.guess())
			.toISOString();
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************* */}
			{/** BODY */}
			<DatePicker
				picker='year'
				className='generalStyles__width100'
				style={{ height: "24px" }}
				onChange={onChange}
				value={moment(filters.startDate)}
				allowClear={false}
			/>
		</div>
	);
}

export default ReportsFilterDateYear;
