import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Button, Table, Empty, Tag, Input, Tooltip, Select } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateFormAction,
	updateGroup,
	updateOpenDrawer,
} from "../../../redux/groups/groupsSlice";
import {
	useLazyGetGroupsQuery,
	usePostGroupMutation,
	usePutGroupMutation,
} from "../../../redux/groups/groupsAPI";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import { useLazyGetMessagesTypeQuery } from "../../../redux/messageType/messagesTypeAPI";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import GroupsTableActions from "./GroupsTableActions";
import GroupsHeader from "./GroupsHeader";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { TablePaginationInterface } from "../../../interfaces/Global.interface";
import { OrganizationInterface } from "../../../interfaces/Organizations.interface";
import { GroupInterface } from "../../../interfaces/Groups.interface";
import { MessageTypeInterface } from "../../../interfaces/MessageType.interface";

function GroupsTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column, ColumnGroup } = Table;
	const { Option } = Select;
	const BLANK_GROUP = {
		id: "",
		name: "",
		message_type_id: 0,
		organization_id: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<GroupInterface[]>([]);
	const [group, setGroup] = useState<GroupInterface>(BLANK_GROUP);
	const [errorMessage, setErrorMessage] = useState<any>({});
	const [totalRows, setTotalRows] = useState<number>(0);
	const [organizations, setOrganizations] = useState<OrganizationInterface[]>(
		[]
	);
	const [messageTypes, setMessageTypes] = useState<MessageTypeInterface[]>([]);
	const [pagination, setPagination] = useState<TablePaginationInterface>({
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ["10", "20", "50", "100"],
		total: 0,
	});
	const [showIsLoading, setShowIsloading] = useState<boolean>(false);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetGroups,
		{
			data: dataGetGroups,
			isLoading: isLoadingGetGroups,
			isFetching: isFetchingGetGroups,
			isError: isErrorGetGroups,
			error: errorGetGroups,
		},
	] = useLazyGetGroupsQuery();

	const [
		triggerPostGroup,
		{
			isSuccess: isSuccessPostGroup,
			isLoading: isLoadingPostGroup,
			isError: isErrorPostGroup,
			error: errorPostGroup,
			reset: resetPostGroup,
		},
	] = usePostGroupMutation();

	const [
		triggerPutGroup,
		{
			isSuccess: isSuccessPutGroup,
			isLoading: isLoadingPutGroup,
			isError: isErrorPutGroup,
			error: errorPutGroup,
			reset: resetPutGroup,
		},
	] = usePutGroupMutation();

	const [
		triggerGetOrganizations,
		{ data: dataGetOrganizations, isLoading: isLoadingGetOrganizations },
	] = useLazyGetOrganizationAndChildsQuery();

	const [
		triggerGetMessageTypes,
		{ data: dataGetMessageTypes, isLoading: isLoadingGetMessageTypes },
	] = useLazyGetMessagesTypeQuery();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme, userLogged } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: any) => state.user);
	const {
		refreshTable,
		formAction,
		group: selectedGroup,
	} = useSelector((state: RootState) => state.group);

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getData = async (page: number, limit: number) => {
		const token = await GETJwtToken();
		triggerGetGroups({
			page,
			limit,
			token,
		});
		triggerGetOrganizations({
			page: 1,
			limit: 9999,
			token,
			orgId: userLogged.org.id,
		});
		triggerGetMessageTypes({
			token,
		});
	};

	const getInitialData = () => {
		getData(1, 10);
	};

	const handleTableChange = async (pagination_: any) => {
		setPagination({
			...pagination,
			current: pagination_.current,
			pageSize: pagination_.pageSize,
			total: pagination_.total,
		});
		getData(pagination_.current, pagination_.pageSize);
	};

	const onClickRefreshTable = async () => {
		setErrorMessage({});
		setTotalRows(0);
		setPagination({
			...pagination,
			current: 1,
			pageSize: 10,
		});
		getData(1, 10);
	};

	const onChangeFormValue = (
		newValue: string | number,
		variableName: string
	) => {
		const copy: GroupInterface = structuredClone(group);
		copy[variableName] = newValue;
		setGroup(copy);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (formAction === "CREATE") {
			const BODY = {
				token,
				body: group,
			};
			triggerPostGroup(BODY);
		} else {
			const copy = structuredClone(group);
			const BODY = {
				token,
				id: group.id,
				body: copy,
			};
			triggerPutGroup(BODY);
		}
	};

	const resetForm = () => {
		resetPostGroup();
		resetPutGroup();
		setGroup(BLANK_GROUP);
		setErrorMessage({});
		dispatch(updateFormAction("CREATE"));
		dispatch(updateGroup(BLANK_GROUP));
	};

	const checkFormIsValid = () => {
		let formIsValid = true;
		if (group.name === "") {
			formIsValid = false;
		}
		return formIsValid;
	};

	const onCellClick = (record: GroupInterface) => {
		if (record) {
			dispatch(updateGroup(record));
			dispatch(updateOpenDrawer(true));
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setShowIsloading(true);
		setTimeout(() => {
			getInitialData();
			setShowIsloading(false);
		}, 1000);
	}, []);

	useEffect(() => {
		if (refreshTable) {
			onClickRefreshTable();
		}
	}, [refreshTable]);

	useEffect(() => {
		if (dataGetGroups && dataGetGroups.data) {
			setPagination({
				...pagination,
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: dataGetGroups.total,
			});
			setData(dataGetGroups.data);
			setTotalRows(dataGetGroups.total);
		}
	}, [dataGetGroups]);

	useEffect(() => {
		if (errorGetGroups) {
			setErrorMessage(errorGetGroups);
			setData([]);
		}
	}, [errorGetGroups]);

	useEffect(() => {
		resetForm();
	}, [
		isSuccessPostGroup,
		isSuccessPutGroup,
		isErrorPostGroup,
		isErrorPutGroup,
	]);

	useEffect(() => {
		if (formAction === "CREATE") {
			setGroup(BLANK_GROUP);
		}
	}, [formAction]);

	useEffect(() => {
		setGroup(selectedGroup);
	}, [selectedGroup]);

	useEffect(() => {
		if (dataGetOrganizations && dataGetOrganizations.data) {
			if (dataGetOrganizations.data.children) {
				setOrganizations([
					{
						id: dataGetOrganizations.data.id,
						address: dataGetOrganizations.data.address,
						city: dataGetOrganizations.data.city,
						country: dataGetOrganizations.data.country,
						name: dataGetOrganizations.data.name,
						phone_code: dataGetOrganizations.data.phone_code,
						phone: dataGetOrganizations.data.phone,
						state: dataGetOrganizations.data.state,
						type: dataGetOrganizations.data.type,
					},
					...dataGetOrganizations.data.children,
				]);
			} else {
				setOrganizations(dataGetOrganizations.data);
			}
		}
	}, [dataGetOrganizations]);

	useEffect(() => {
		if (dataGetMessageTypes && dataGetMessageTypes.data) {
			setMessageTypes(dataGetMessageTypes.data);
		}
	}, [dataGetMessageTypes]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ----------------------------------------------------- */}
			{/** TABLE */}
			<div>
				{validatePermission("permission-not-defined", permissions) ? (
					<>
						<GroupsHeader />
						<Table
							locale={{
								emptyText: (
									<TableNoDataBox
										isError={isErrorGetGroups}
										errorMessage={
											errorMessage &&
											errorMessage.data &&
											errorMessage.data.message
												? errorMessage.data.message
												: "Error"
										}
										noItemsFound='No groups found'
									/>
								),
							}}
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							dataSource={data}
							pagination={pagination}
							size='small'
							onChange={handleTableChange}
							className='mt-3'
							loading={
								isFetchingGetGroups ||
								isLoadingGetGroups ||
								isLoadingPostGroup ||
								isLoadingPutGroup ||
								isLoadingGetOrganizations ||
								isLoadingGetMessageTypes ||
								showIsLoading
							}
							footer={() => (
								<div className='generalStyles__flexEnd'>
									<Tag>{totalRows} Rows</Tag>
								</div>
							)}
						>
							{/** NAME */}
							<ColumnGroup title='Name'>
								<Column
									onCell={(record: GroupInterface) => ({
										onClick: () => {
											if (formAction === "CREATE") {
												onCellClick(record);
											}
										},
									})}
									width={200}
									title={
										<Input
											size='small'
											placeholder='Name'
											value={formAction === "CREATE" ? group.name : ""}
											disabled={formAction === "EDIT"}
											onChange={(event: React.FormEvent<HTMLInputElement>) => {
												onChangeFormValue(event.currentTarget.value, "name");
											}}
										/>
									}
									dataIndex='name'
									key='name'
									className=''
									render={(text, record: GroupInterface) => (
										<div>
											{formAction === "EDIT" && record.id === group.id ? (
												<Input
													size='small'
													placeholder='Name'
													value={group.name}
													onChange={(
														event: React.FormEvent<HTMLInputElement>
													) => {
														onChangeFormValue(
															event.currentTarget.value,
															"name"
														);
													}}
												/>
											) : (
												<span>{text}</span>
											)}
										</div>
									)}
								/>
							</ColumnGroup>
							{/** MESSAGE TYPE */}
							<ColumnGroup title={t("general.messageType")}>
								<Column
									onCell={(record: GroupInterface) => ({
										onClick: () => {
											if (formAction === "CREATE") {
												onCellClick(record);
											}
										},
									})}
									width={200}
									title={
										<Select
											showSearch
											optionFilterProp='children'
											className='generalStyles__width100'
											allowClear
											size='small'
											loading={isLoadingGetMessageTypes}
											placeholder={t("general.messageType")}
											value={
												formAction === "CREATE" && group.message_type_id > 0
													? group.message_type_id
													: null
											}
											onChange={(value) => {
												onChangeFormValue(value, "message_type_id");
											}}
										>
											{messageTypes.map((item: MessageTypeInterface) => (
												<Option key={item.id} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									}
									dataIndex='messageType'
									key='messageType'
									className=''
									render={(text, record: GroupInterface) => (
										<div>
											{formAction === "EDIT" && record.id === group.id ? (
												<Select
													showSearch
													optionFilterProp='children'
													className='generalStyles__width100'
													allowClear
													size='small'
													value={group.message_type_id}
													onChange={(value) => {
														onChangeFormValue(value, "message_type_id");
													}}
												>
													{messageTypes.map((item: MessageTypeInterface) => (
														<Option key={item.id} value={item.id}>
															{item.name}
														</Option>
													))}
												</Select>
											) : (
												<span>
													{record &&
													record.message_type &&
													record.message_type.name
														? record.message_type.name
														: "--"}
												</span>
											)}
										</div>
									)}
								/>
							</ColumnGroup>
							{/** ORGANIZATION */}
							<ColumnGroup title={t("userManagement.organization")}>
								<Column
									onCell={(record: GroupInterface) => ({
										onClick: () => {
											if (formAction === "CREATE") {
												onCellClick(record);
											}
										},
									})}
									width={200}
									title={
										<Select
											showSearch
											placeholder={t("userManagement.organization")}
											optionFilterProp='children'
											className='generalStyles__width100'
											loading={isLoadingGetOrganizations}
											allowClear
											size='small'
											value={
												formAction === "CREATE" && group.organization_id !== ""
													? group.organization_id
													: null
											}
											onChange={(value) => {
												onChangeFormValue(value, "organization_id");
											}}
										>
											{organizations.map((item: OrganizationInterface) => (
												<Option key={item.id} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									}
									dataIndex='organizationId'
									key='organizationId'
									className=''
									render={(text, record: GroupInterface) => (
										<div>
											{formAction === "EDIT" && record.id === group.id ? (
												<Select
													showSearch
													optionFilterProp='children'
													className='generalStyles__width100'
													allowClear
													size='small'
													value={group.organization_id}
													onChange={(value) => {
														onChangeFormValue(value, "organization_id");
													}}
												>
													{organizations.map((item: OrganizationInterface) => (
														<Option key={item.id} value={item.id}>
															{item.name}
														</Option>
													))}
												</Select>
											) : (
												<span>
													{record &&
													record.organization &&
													record.organization.name
														? record.organization.name
														: "--"}
												</span>
											)}
										</div>
									)}
								/>
							</ColumnGroup>
							{/** ACTIONS */}
							<ColumnGroup
								align='center'
								title={t("edgeManagement.banks.actions")}
								width={200}
							>
								<Column
									align='center'
									width={200}
									title={
										<div className='generalStyles__flexCenter'>
											<div className=''>
												<Tooltip title={t("general.save")}>
													<Button
														className={
															formAction === "CREATE" && checkFormIsValid()
																? "buttonStyle__10"
																: ""
														}
														disabled={
															!checkFormIsValid() || formAction === "EDIT"
														}
														onClick={onClickSave}
														icon={<FontAwesomeIcon icon={faSave} />}
													/>
												</Tooltip>
											</div>
											<div className='generalStyles__mlFix'>
												<Tooltip title={t("general.clear")}>
													<Button
														className={
															formAction === "CREATE" ? "buttonStyle__14" : ""
														}
														onClick={resetForm}
														disabled={formAction === "EDIT"}
														icon={<FontAwesomeIcon icon={faX} />}
													/>
												</Tooltip>
											</div>
										</div>
									}
									key='action'
									render={(_: any, record: GroupInterface) => (
										<div>
											{formAction === "EDIT" && record.id === group.id ? (
												<div className='generalStyles__flexCenter'>
													<div className=''>
														<Tooltip title={t("general.save")}>
															<Button
																className='buttonStyle__10'
																disabled={!checkFormIsValid()}
																onClick={onClickSave}
																icon={<FontAwesomeIcon icon={faSave} />}
															/>
														</Tooltip>
													</div>
													<div className='generalStyles__mlFix'>
														<Tooltip title={t("general.cancel")}>
															<Button
																className='buttonStyle__14'
																onClick={resetForm}
																icon={<FontAwesomeIcon icon={faX} />}
															/>
														</Tooltip>
													</div>
												</div>
											) : (
												<GroupsTableActions selectedItem={record} />
											)}
										</div>
									)}
								/>
							</ColumnGroup>
						</Table>
					</>
				) : (
					<>
						<div className='generalStyles__noAccessToListTable'>
							<Empty
								description={t(
									"organizationManagement.listNotAvailableOrganizations"
								)}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						</div>
					</>
				)}
			</div>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostGroup}
				isSuccess={isSuccessPostGroup}
				requestType='POST'
				error={errorPostGroup}
				name='Group'
			/>
			<GlobalAlert2
				isError={isErrorPutGroup}
				isSuccess={isSuccessPutGroup}
				requestType='PUT'
				error={errorPutGroup}
				name='Group'
			/>
		</div>
	);
}

export default GroupsTable;
