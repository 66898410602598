import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// ANT DESIGN COMPONENTS
import { Select, Button } from "antd";

function FilterSelectAuthType({
	placeHolder,
	setFilterValue,
	filterValue,
	refreshTable,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__searchDropdown'>
			<Select
				showSearch
				optionFilterProp='children'
				size='small'
				allowClear
				style={{ width: 250 }}
				filterOption={(input: any, option: any) =>
					option.children.toLowerCase().includes(input.toLowerCase())
				}
				onChange={(value) => {
					setFilterValue(value);
				}}
				placeholder={placeHolder}
				value={filterValue}
			>
				{["PASSWORD", "APIKEY", "ALL"].map((item: any) => (
					<Option key={item} value={item}>
						{item}
					</Option>
				))}
			</Select>
			<div className='generalStyles__flexEnd mt-3'>
				<div>
					<Button
						type='primary'
						size='small'
						style={{ width: 90 }}
						onClick={refreshTable}
					>
						{t("general.search")}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default FilterSelectAuthType;
