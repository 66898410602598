import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import IconDefinitionIcons from "./IconDefinitionIcons";

function IconDefinitionMovingWalk({ onChangeValue, icon, iconType }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const [t] = useTranslation("global");
	const STATUS = [
		{
			name: "Backward",
			icon: "weBackward",
		},
		{
			name: "Fordward",
			icon: "weFordward",
		},
		{
			name: "Locked",
			icon: "weLocked",
		},
		{
			name: "Stopped",
			icon: "weStopped",
		},
		{
			name: "Warning",
			icon: "weWarning",
		},
		{
			name: "Not Allowed",
			icon: "weNotAllowed",
		},
		{
			name: "Run",
			icon: "weRun",
		},
	];
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openFormIconDefinitionAction } = useSelector(
		(state: any) => state.tool
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (iconType === "STATUS") {
			setData(STATUS);
		}
	}, [iconType]);

	useEffect(() => {
		onChangeValue("", "icon");
	}, [data]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ICON TYPE */}
			<div className='col-md-12 mt-3'>
				<div className='generalStyles__flex'>
					<div className='generalStyles__inputFlexName'>
						<span className='generalStyles__inputFlexRequired'>*</span>
						<span className='drawer__text'>
							{t("general.icon")} {t("general.type")}
						</span>
					</div>
					<div className='generalStyles__width100'>
						<Select
							showSearch
							optionFilterProp='children'
							className='generalStyles__width100'
							allowClear={false}
							size='small'
							value={iconType}
							disabled={openFormIconDefinitionAction === "update"}
							onChange={(e) => {
								onChangeValue(e, "iconType");
							}}
						>
							{["STATUS"].map((item: string) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</div>
					<div className='generalStyles__infoTooltipIconBlank' />
				</div>
			</div>
			{/** ICON */}
			<div className='col-md-12 mt-3'>
				<div className='generalStyles__flex'>
					<div className='generalStyles__inputFlexName'>
						<span className='generalStyles__inputFlexRequired'>*</span>
						<span className='drawer__text'>{t("general.icon")}</span>
					</div>
					<div className='generalStyles__width100'>
						<Select
							showSearch
							optionFilterProp='children'
							className='generalStyles__width100'
							allowClear={false}
							size='small'
							value={icon}
							disabled={openFormIconDefinitionAction === "update"}
							onChange={(e) => {
								onChangeValue(e, "icon");
							}}
						>
							{data.map((item: any) => (
								<Option key={item.icon} value={item.icon}>
									<IconDefinitionIcons
										liftType='MOVINGWALK'
										icon={item.icon}
										iconSize={20}
									/>
									<span className='generalStyles__mlFix'>{item.name}</span>
								</Option>
							))}
						</Select>
					</div>
					<div className='generalStyles__infoTooltipIconBlank' />
				</div>
			</div>
		</div>
	);
}

export default IconDefinitionMovingWalk;
