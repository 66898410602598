/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { ChannelInterface } from "../../interfaces/EdgeManagement.interface";

export interface ChannelState {
	channel: ChannelInterface;
	channelTableParams: Object;
	channelList: Array<any>;
	openDrawerChannel: boolean;
	openModalCreateNewChannel: boolean;
	formCreateEdit: string;
	channelRefreshTable: boolean;
	clearFilters: boolean;
	openModalDeleteChannel: boolean;
	openModalEditChannel: boolean;
	renderComponent: String;
}

const initialState: ChannelState = {
	channel: {
		id: "",
		active: true,
		index: "1",
		name: "/dev/ttyS",
		server_id: "",
		type: "SERIAL",
		baud_rate: 1,
		channel_id: "",
		heartbeat_frequency: 1,
		reporting_socket_address: "",
		socket_address: "",
		vendor_socket_address: "",
		message_type: "LN-PRTCL",
	},
	channelTableParams: {
		pagination: {
			current: 1,
			pageSize: 10,
			showSizeChanger: true,
			pageSizeOptions: ["10", "20", "50", "100"],
		},
	},
	openDrawerChannel: false,
	formCreateEdit: "CREATE",
	channelList: [],
	openModalCreateNewChannel: false,
	channelRefreshTable: false,
	clearFilters: false,
	openModalDeleteChannel: false,
	openModalEditChannel: false,
	renderComponent: "channels",
};

export const channelSlice = createSlice({
	name: "channel",
	initialState,
	reducers: {
		updateChannel: (state, action: PayloadAction<ChannelInterface>) => {
			state.channel = action.payload;
		},
		updateChannelTableParams: (state, action: PayloadAction<Object>) => {
			state.channelTableParams = action.payload;
		},
		updateChannelList: (state, action: PayloadAction<any>) => {
			state.channelList = action.payload;
		},
		updateOpenDrawerChannel: (state, action: PayloadAction<boolean>) => {
			state.openDrawerChannel = action.payload;
		},
		updateFormCreateEdit: (state, action: PayloadAction<string>) => {
			state.formCreateEdit = action.payload;
		},
		updateOpenModalCreateNewChannel: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.openModalCreateNewChannel = action.payload;
		},
		updateChannelRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.channelRefreshTable = action.payload;
		},
		updateClearFilters: (state, action: PayloadAction<boolean>) => {
			state.clearFilters = action.payload;
		},
		updateOpenModalDeleteChannel: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteChannel = action.payload;
		},
		updateOpenModalEditChannel: (state, action: PayloadAction<boolean>) => {
			state.openModalEditChannel = action.payload;
		},
		updateRenderComponent: (state, action: PayloadAction<String>) => {
			state.renderComponent = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateChannel,
	updateChannelTableParams,
	updateChannelList,
	updateOpenDrawerChannel,
	updateFormCreateEdit,
	updateOpenModalCreateNewChannel,
	updateChannelRefreshTable,
	updateClearFilters,
	updateOpenModalDeleteChannel,
	updateOpenModalEditChannel,
	updateRenderComponent,
} = channelSlice.actions;

export default channelSlice.reducer;
