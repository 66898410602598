/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES

export interface GroupState {
	group: any;
	openDrawer: boolean;
	openForm: boolean;
	refreshTable: boolean;
	formAction: string;
	openModalDelete: boolean;
}

const initialState: GroupState = {
	group: {},
	openDrawer: false,
	openForm: false,
	refreshTable: false,
	formAction: "CREATE",
	openModalDelete: false,
};

export const groupSlice = createSlice({
	name: "group",
	initialState,
	reducers: {
		updateGroup: (state, action: PayloadAction<any>) => {
			state.group = action.payload;
		},
		updateOpenDrawer: (state, action: PayloadAction<boolean>) => {
			state.openDrawer = action.payload;
		},
		updateOpenForm: (state, action: PayloadAction<boolean>) => {
			state.openForm = action.payload;
		},
		updateRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.refreshTable = action.payload;
		},
		updateFormAction: (state, action: PayloadAction<string>) => {
			state.formAction = action.payload;
		},
		updateOpenModalDelete: (state, action: PayloadAction<boolean>) => {
			state.openModalDelete = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateGroup,
	updateOpenDrawer,
	updateOpenForm,
	updateRefreshTable,
	updateFormAction,
	updateOpenModalDelete,
} = groupSlice.actions;

export default groupSlice.reducer;
