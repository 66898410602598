import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(fetchBaseQuery(), { maxRetries });

export const groupAPI = createApi({
	reducerPath: "groupAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetGroups"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET GROUPS */
		getGroups: builder.query({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/groups/?&page=${body.page}&limit=${body.limit}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetGroups"],
		}),
		//* *********************************************************** */
		//* POST GROUP */
		postGroup: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/groups/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetGroups"],
		}),
		//* *********************************************************** */
		//* PUT GROUP */
		putGroup: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/groups/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetGroups"],
		}),
		//* *********************************************************** */
		//* DELETE GROUP */
		deleteGroup: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/groups/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetGroups"],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetGroupsQuery,
	usePostGroupMutation,
	usePutGroupMutation,
	useDeleteGroupMutation,
} = groupAPI;
