import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Dropdown, Empty, Menu, Space, Table, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faEllipsis,
	faLock,
	faPenToSquare,
	faSearch,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	useLazyGetChannelsQuery,
	useLazyGetChannelsByServerQuery,
} from "../../../redux/channels/channelAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
import FilterSelectChannelType from "../../components/filters/FilterSelectChannelType";
import {
	updateChannel,
	updateChannelList,
	updateChannelTableParams,
	updateOpenDrawerChannel,
	updateOpenModalDeleteChannel,
	updateOpenModalCreateNewChannel,
	updateFormCreateEdit,
} from "../../../redux/channels/channelSlice";
import { buildFilterString, getTableRowClass } from "../../../utils/utils";

const { Column } = Table;

function ChannelsTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);
	const [selectedItem, setSelectedItem] = useState<any>({});
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [channels, setChannels] = useState<any[]>([]);
	const [filterName, setFilterName] = useState("");
	const [filterChannelType, setFilterChannelType] = useState("");
	const [filterSocketAddress, setFilterSocketAddress] = useState("");
	const [filterIndex, setFilterIndex] = useState("");
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const {
		channelTableParams,
		renderComponent,
		channelRefreshTable,
		clearFilters,
	} = useSelector((state: any) => state.channel);
	const { userLogged } = useSelector((state: any) => state.home);
	const { server } = useSelector((state: any) => state.server);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetChannelsTable,
		{
			data: dataChannels,
			isLoading: isLoadingChannels,
			isError: isErrorChannels,
			isFetching: isFetchingChannels,
		},
	] = useLazyGetChannelsQuery();
	const [
		triggerGetChannelsByServerTable,
		{
			data: dataChannelsByServer,
			isLoading: isLoadingChannelsByServer,
			isError: isErrorChannelsByServer,
			isFetching: isFetchingChannelsByServer,
		},
	] = useLazyGetChannelsByServerQuery();

	// ************************************************ */
	// ACTIONS **************************************** */
	const handleOpenModalDelete = () => {
		dispatch(updateChannel(selectedItem));
		dispatch(updateOpenModalDeleteChannel(true));
	};
	const handleOpenModalEdit = () => {
		dispatch(updateChannel(selectedItem));
		dispatch(updateOpenModalCreateNewChannel(true));
		dispatch(updateFormCreateEdit("edit"));
	};
	const manageActions = () => {
		const EDIT = validatePermission("channels-edit", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalEdit();
							}}
							onKeyDown={() => {
								handleOpenModalEdit();
							}}
						>
							<span>
								<FontAwesomeIcon
									className='generalStyles__info generalStyles__mrFix'
									icon={faPenToSquare}
								/>
								{t("general.edit")}
							</span>
						</div>
					),
					key: "1",
			  }
			: null;
		const DELETE = validatePermission("channels-delete", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalDelete();
							}}
							onKeyDown={() => {
								handleOpenModalDelete();
							}}
						>
							<span>
								<FontAwesomeIcon
									className='generalStyles__info generalStyles__mrFix'
									icon={faTrash}
								/>
								{t("general.delete")}
							</span>
						</div>
					),
					key: "2",
			  }
			: null;

		return [EDIT, DELETE];
	};
	const menu = <Menu items={manageActions()} />;
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleTableChange = async (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		dispatch(
			updateChannelTableParams({
				pagination,
				filters,
				...sorter,
			})
		);
		//
		const token = await GETJwtToken();
		if (renderComponent === "servers") {
			triggerGetChannelsByServerTable({
				page: pagination.current,
				limit: pagination.pageSize,
				token,
				serverId: server.id,
			});
		} else {
			triggerGetChannelsTable({
				page: pagination.current,
				limit: pagination.pageSize,
				token,
			});
		}
	};
	const refreshTable = async () => {
		const searchAndFilterString = buildFilterString({
			filterName,
			filterChannelType,
			filterIndex,
			filterSocketAddress,
		});
		dispatch(
			updateChannelTableParams({
				pagination: {
					current: 1,
					pageSize: 10,
					total: channelTableParams.pagination.total,
				},
				filters: {},
				sorter: {},
			})
		);
		//
		const token = await GETJwtToken();

		if (renderComponent === "servers") {
			triggerGetChannelsByServerTable({
				page: 1,
				limit: 10,
				token,
				serverId: server.id,
				orgId: userLogged.org.id,
				searchAndFilterString,
			});
		} else {
			triggerGetChannelsTable({
				page: 1,
				limit: 10,
				token,
				orgId: userLogged.org.id,
				searchAndFilterString,
			});
		}
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		if (renderComponent === "servers") {
			triggerGetChannelsByServerTable({
				page: channelTableParams.pagination.current,
				limit: channelTableParams.pagination.pageSize,
				token,
				serverId: server.id,
			});
		} else {
			triggerGetChannelsTable({
				page: channelTableParams.pagination.current,
				limit: channelTableParams.pagination.pageSize,
				token,
			});
		}
	};

	const onClickClearFilters = async () => {
		setFilterName("");
		dispatch(
			updateChannelTableParams({
				pagination: {
					current: 1,
					pageSize: 10,
					total: channelTableParams.pagination.total,
				},
				filters: {},
				sorter: {},
			})
		);
		//
		const token = await GETJwtToken();
		if (renderComponent === "servers") {
			triggerGetChannelsByServerTable({
				page: 1,
				limit: 10,
				token,
				orgId: userLogged.org.id,
				serverId: server.id,
			});
		} else {
			triggerGetChannelsTable({
				page: 1,
				limit: 10,
				token,
				orgId: userLogged.org.id,
			});
		}
	};
	const onCellClick = (record: any) => {
		if (record) {
			dispatch(updateChannel(record));
			dispatch(updateOpenDrawerChannel(true));
		}
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		getInitialData();
	}, [renderComponent]);

	useEffect(() => {
		// ALL CHANNELS
		if (dataChannels && dataChannels.data && renderComponent === "channels") {
			dispatch(
				updateChannelTableParams({
					...channelTableParams,
					pagination: {
						...channelTableParams.pagination,
						total: dataChannels.total,
					},
				})
			);
			setChannels(dataChannels.data);
			dispatch(updateChannelList(dataChannels.data));
		}
		// CHANNELS BY SERVER
		if (
			dataChannelsByServer &&
			dataChannelsByServer.data &&
			renderComponent === "servers"
		) {
			dispatch(
				updateChannelTableParams({
					...channelTableParams,
					pagination: {
						...channelTableParams.pagination,
						total: dataChannelsByServer.total,
					},
				})
			);
			setChannels(dataChannelsByServer.data);
			dispatch(updateChannelList(dataChannelsByServer.data));
		}
	}, [dataChannels, dataChannelsByServer]);

	useEffect(() => {
		if (channelRefreshTable) {
			refreshTable();
		}
	}, [channelRefreshTable]);

	useEffect(() => {
		if (clearFilters) {
			onClickClearFilters();
		}
	}, [clearFilters]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			<>
				{validatePermission("channels-view", permissions) ? (
					<>
						{!isErrorChannels && !isErrorChannelsByServer ? (
							<Table
								rowClassName={(record, index) => getTableRowClass(index, theme)}
								className='customTable'
								dataSource={channels}
								loading={
									isLoadingChannels ||
									isFetchingChannels ||
									isFetchingChannelsByServer ||
									isLoadingChannelsByServer
								}
								rowKey={(record) => record.id}
								size='small'
								pagination={channelTableParams.pagination}
								onChange={handleTableChange}
								scroll={{ x: 900 }}
							>
								{/** NAME */}
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									title={t("general.name")}
									dataIndex='name'
									key='name'
									filterIcon={<FontAwesomeIcon icon={faSearch} />}
									filterDropdown={() => (
										<FilterSearchText
											placeHolder={t("general.name")}
											filterValue={filterName}
											setFilterValue={setFilterName}
											refreshTable={refreshTable}
											refreshParameters={[]}
										/>
									)}
									render={(text, record: any) => (
										<>
											<div className=''>{record.name}</div>
										</>
									)}
								/>
								{/** TYPE */}
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									title={t("general.type")}
									dataIndex='type'
									key='type'
									filterIcon={<FontAwesomeIcon icon={faSearch} />}
									filterDropdown={() => (
										<FilterSelectChannelType
											placeHolder={t("general.type")}
											filterValue={filterChannelType}
											setFilterValue={setFilterChannelType}
											refreshTable={refreshTable}
										/>
									)}
									render={(text) => (
										<>
											<div className=''>
												{text === "TCP" && (
													<Tag color='blue' className='drawer__typeTag'>
														{text}
													</Tag>
												)}
												{text === "ADAPTER" && (
													<Tag color='orange' className='drawer__typeTag'>
														{text}
													</Tag>
												)}
												{text === "SERIAL" && (
													<Tag color='green' className='drawer__typeTag'>
														{text}
													</Tag>
												)}
												{text === "TCP_BOT" && (
													<Tag color='magenta' className='drawer__typeTag'>
														{text}
													</Tag>
												)}
											</div>
										</>
									)}
								/>
								{/** SERVER */}
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									title={t("edgeManagement.servers.server")}
									dataIndex='server'
									key='server'
									render={(text, record: any) => (
										<>
											<div className=''>
												{record.server && record.server.name
													? record.server.name
													: ""}
											</div>
										</>
									)}
								/>
								{/** SOCKET ADDRESS */}
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									title={t("edgeManagement.channels.socketAddress")}
									dataIndex='socketAddress'
									key='socketAddress'
									filterIcon={<FontAwesomeIcon icon={faSearch} />}
									filterDropdown={() => (
										<FilterSearchText
											placeHolder={t("edgeManagement.channels.socketAddress")}
											filterValue={filterSocketAddress}
											setFilterValue={setFilterSocketAddress}
											refreshTable={refreshTable}
											refreshParameters={[]}
										/>
									)}
									render={(text, record: any) => (
										<>
											<div className=''>{record.socket_address || "--"}</div>
										</>
									)}
								/>
								{/** INDEX */}
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									title={t("edgeManagement.channels.index")}
									dataIndex='portNumber'
									key='portNumber'
									filterIcon={<FontAwesomeIcon icon={faSearch} />}
									filterDropdown={() => (
										<FilterSearchText
											placeHolder={t("edgeManagement.channels.index")}
											filterValue={filterIndex}
											setFilterValue={setFilterIndex}
											refreshTable={refreshTable}
											refreshParameters={[]}
										/>
									)}
									render={(text, record: any) => (
										<>
											<div className=''>{record.index || "--"}</div>
										</>
									)}
								/>
								{/** BAUD RATE */}
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									title={t("edgeManagement.servers.baudRate")}
									dataIndex='baud_rate'
									key='baud_rate'
									filterIcon={<FontAwesomeIcon icon={faSearch} />}
									filterDropdown={() => (
										<FilterSearchText
											placeHolder={t("edgeManagement.servers.baudRate")}
											filterValue={filterIndex}
											setFilterValue={setFilterIndex}
											refreshTable={refreshTable}
											refreshParameters={[]}
										/>
									)}
									render={(text) => (
										<>
											<div className=''>{text || "--"}</div>
										</>
									)}
								/>
								{/** STATUS */}
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									title={t("userManagement.status")}
									dataIndex='status'
									key='status'
									width='150px'
									className=''
									render={(text, record: any) => (
										<>
											<div className=''>
												{record.active && (
													<Tag
														color='success'
														icon={
															<FontAwesomeIcon
																icon={faCheckCircle}
																className='generalStyles__mrFix'
															/>
														}
														className='drawer__tag'
													>
														{t("general.active")}
													</Tag>
												)}
												{!record.active && (
													<Tag
														color='error'
														icon={
															<FontAwesomeIcon
																icon={faLock}
																className='generalStyles__mrFix'
															/>
														}
														className='drawer__tag'
													>
														{t("general.lock")}
													</Tag>
												)}
											</div>
										</>
									)}
								/>
								{/** ACTIONS */}
								<Column
									title={t("general.actions")}
									key='action'
									width='100px'
									render={(_: any, record: any) => (
										<Dropdown overlay={menu} trigger={["click"]}>
											<Space
												className='generalStyles__hoverCursor'
												onClick={() => {
													setSelectedItem(record);
												}}
											>
												<FontAwesomeIcon icon={faEllipsis} />
											</Space>
										</Dropdown>
									)}
								/>
							</Table>
						) : (
							<Alert
								message='errors'
								description='errors'
								type='error'
								showIcon
								className='mt-3'
							/>
						)}
					</>
				) : (
					<>
						<div className='generalStyles__noAccessToListTable'>
							<Empty
								description={t("general.listNotAvailable")}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						</div>
					</>
				)}
			</>
		</div>
	);
}

export default ChannelsTable;
