// ************************************************* */
// FUNCTIONS *************************************** */
const validatePermission = (permission: string, permissions: any) => {
	let permissionValid = false;
	// -*-*-*-*-*-*-*-*
	if (permission === "permission-not-defined") {
		permissionValid = true;
	}
	// -*-*-*-*-*-*-*-*
	if (permissions && permissions.pages) {
		// VALIDATE PAGES PERMISSIONS
		permissions.pages.map((page: any) => {
			if (
				page.tree_key === permission &&
				(process.env.REACT_APP_EDGE_SERVER_CONNECTION === "EDGE"
					? page.active
					: page.offline)
			) {
				permissionValid = true;
			}
			if (page.sub_pages) {
				// VALIDATE SUB PAGES PERMISSIONS
				page.sub_pages.map((subPage: any) => {
					if (
						subPage.tree_key === permission &&
						(process.env.REACT_APP_EDGE_SERVER_CONNECTION === "EDGE"
							? subPage.active
							: subPage.offline)
					) {
						permissionValid = true;
					}
					if (subPage.actions) {
						// VALIDATE ACTIONS PERMISSIONS
						subPage.actions.map((action: any) => {
							if (
								action.tree_key === permission &&
								(process.env.REACT_APP_EDGE_SERVER_CONNECTION === "EDGE"
									? action.active
									: action.offline)
							) {
								permissionValid = true;
							}
							return true;
						});
					}
					return true;
				});
			}
			return true;
		});
	}
	return permissionValid;
};

export default validatePermission;
