import React from "react";
// ANT DESIGN COMPONENTS
import { Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// INTERFACES
import { FormSwitchActiveInactiveInterface } from "../../../interfaces/Global.interface";

function FormSwitchActiveInactive({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
}: FormSwitchActiveInactiveInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Switch
						onChange={(e) => {
							inputOnChange(e, inputVariableName);
						}}
						checkedChildren={t("general.active")}
						unCheckedChildren={t("general.inactive")}
						checked={inputValue}
					/>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormSwitchActiveInactive;
