export default {
  data: [
    { minFloors: 1, maxFloors: 3, cardHeight: 2 },
    { minFloors: 4, maxFloors: 7, cardHeight: 3 },
    { minFloors: 8, maxFloors: 10, cardHeight: 4 },
    { minFloors: 11, maxFloors: 15, cardHeight: 5 },
    { minFloors: 16, maxFloors: 19, cardHeight: 6 },
    { minFloors: 20, maxFloors: 23, cardHeight: 7 },
    { minFloors: 24, maxFloors: 99, cardHeight: 8 },
  ],
  data2: [
    { minFloors: 1, maxFloors: 3, cardHeight: 2 },
    { minFloors: 4, maxFloors: 7, cardHeight: 3 },
    { minFloors: 8, maxFloors: 10, cardHeight: 4 },
    { minFloors: 11, maxFloors: 15, cardHeight: 5 },
    { minFloors: 16, maxFloors: 19, cardHeight: 6 },
    { minFloors: 20, maxFloors: 23, cardHeight: 7 },
    { minFloors: 24, maxFloors: 27, cardHeight: 8 },
    { minFloors: 28, maxFloors: 31, cardHeight: 9 },
    { minFloors: 32, maxFloors: 35, cardHeight: 10 },
    { minFloors: 36, maxFloors: 39, cardHeight: 11 },
    { minFloors: 40, maxFloors: 43, cardHeight: 12 },
    { minFloors: 44, maxFloors: 47, cardHeight: 13 },
    { minFloors: 48, maxFloors: 51, cardHeight: 14 },
    { minFloors: 52, maxFloors: 55, cardHeight: 15 },
    { minFloors: 56, maxFloors: 59, cardHeight: 16 },
    { minFloors: 60, maxFloors: 63, cardHeight: 17 },
    { minFloors: 64, maxFloors: 67, cardHeight: 18 },
    { minFloors: 68, maxFloors: 71, cardHeight: 19 },
    { minFloors: 72, maxFloors: 75, cardHeight: 20 },
    { minFloors: 76, maxFloors: 79, cardHeight: 21 },
    { minFloors: 80, maxFloors: 83, cardHeight: 22 },
    { minFloors: 84, maxFloors: 87, cardHeight: 23 },
    { minFloors: 88, maxFloors: 91, cardHeight: 24 },
    { minFloors: 92, maxFloors: 95, cardHeight: 25 },
    { minFloors: 96, maxFloors: 99, cardHeight: 26 },
    { minFloors: 100, maxFloors: 103, cardHeight: 27 },
  ],
};
