import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Tag, Button, Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUserShield,
	faBuildingCircleCheck,
	faUserLock,
	faCheckCircle,
	faBan,
	faElevator,
	faGear,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerLiftConfiguration } from "../../../redux/lifts/liftSlice";

library.add(
	faUserShield,
	faBuildingCircleCheck,
	faUserLock,
	faCheckCircle,
	faBan
);

function LiftsConfiguration() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>({});
	const [floors, setFloors] = useState<any>([]);
	const [floorsBelowLobby, setFloorsBelowLobby] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { lift, openDrawerLiftConfiguration } = useSelector(
		(state: any) => state.lift
	);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerLiftConfiguration(false));
	};

	const buildConfiguration = () => {
		// build floors configuration
		const newFloorArr: any = [];
		if (
			lift &&
			lift.bank &&
			lift.bank.floor_count &&
			lift.bank.floor_count > 0
		) {
			for (let x = 0; x < lift.bank.floor_count; x += 1) {
				if (lift && lift.bank && lift.bank.rear_doors) {
					newFloorArr.push({
						type: "floor",
						active: true,
						floorNo: x + 1,
						rearDoor: true,
					});
				} else {
					newFloorArr.push({ type: "floor", active: true, floorNo: x + 1 });
				}
			}
			setFloors(newFloorArr);
		}
		// build floors configuration
		const newFloorBelowLobbyArr: any = [];
		if (
			lift &&
			lift.bank &&
			lift.bank.floor_below_lobby &&
			lift.bank.floor_below_lobby > 0
		) {
			for (let x = 0; x < lift.bank.floor_below_lobby; x += 1) {
				if (lift && lift.bank && lift.bank.rear_doors) {
					newFloorArr.push({
						type: "floorBelowLobby",
						active: true,
						floorNo: x + 1,
						rearDoor: true,
					});
				} else {
					newFloorArr.push({
						type: "floorBelowLobby",
						active: true,
						floorNo: x + 1,
					});
				}
			}
			setFloorsBelowLobby(newFloorBelowLobbyArr);
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openDrawerLiftConfiguration) {
			if (lift) {
				setData(lift);
				buildConfiguration();
			}
		}
	}, [openDrawerLiftConfiguration]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerLiftConfiguration}
		>
			{data && (
				<div>
					{/** ************************************************* */}
					{/** LIFT INFORMATION */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faElevator}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{t("edgeManagement.lifts.liftInformation")}
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme}`}>
						{/* ID */}
						<div className='generalStyles__flex'>
							<div>
								<p
									className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								>
									ID:
								</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data && data.id ? data.id : "--"}
								</p>
							</div>
						</div>
						{/* NAME */}
						<div className='generalStyles__flex'>
							<div>
								<p
									className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								>
									{t("edgeManagement.lifts.board")}
								</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data && data.board ? data.board : "--"}
								</p>
							</div>
						</div>
						{/* TYPE */}
						<div className='generalStyles__flex'>
							<div>
								<p
									className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								>
									{t("edgeManagement.lifts.type")}
								</p>
							</div>
							<div className='drawer__textContainer'>
								<div className='drawer__text'>
									{data && data.type ? (
										<>
											<div className=''>
												{data.type === "ELEVATOR" && (
													<Tag color='blue' className='drawer__typeTag'>
														{data.type}
													</Tag>
												)}
												{data.type === "ESCALATOR" && (
													<Tag color='volcano' className='drawer__typeTag'>
														{data.type}
													</Tag>
												)}
												{data.type === "MOVINGWALK" && (
													<Tag color='green' className='drawer__typeTag'>
														{data.type}
													</Tag>
												)}{" "}
											</div>
										</>
									) : (
										"--"
									)}
								</div>
							</div>
						</div>
						{/* READ DOORS */}
						<div className='generalStyles__flex'>
							<div>
								<p
									className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								>
									{t("configuration.configurationFiles.rearDoors")}
								</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data && data.bank && data.bank.rear_doors ? (
										<>
											<Tag color='green' className='drawer__typeTag'>
												ACTIVE
											</Tag>
										</>
									) : (
										<Tag color='red' className='drawer__typeTag'>
											INACTIVE
										</Tag>
									)}
								</p>
							</div>
						</div>
						{/* FLOOR COUNT */}
						<div className='generalStyles__flex'>
							<div>
								<p
									className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								>
									{t("configuration.configurationFiles.floors")}
								</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data && data.bank && data.bank.floor_count
										? data.bank.floor_count
										: "--"}
								</p>
							</div>
						</div>
						{/* FLOOR BELOW LOBY */}
						<div className='generalStyles__flex'>
							<div>
								<p
									className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								>
									{t("edgeManagement.banks.floorsBelowLobby")}
								</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data && data.bank && data.bank.floor_below_lobby
										? data.bank.floor_below_lobby
										: "--"}
								</p>
							</div>
						</div>
					</div>
					<br />
					<br />
					{/** ************************************************* */}
					{/** LIFT CONFIGURATION */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faGear}
									className='generalStyles__info generalStyles__mrFix'
								/>
								Lift {t("general.configuration")}
							</h5>
						</Divider>
					</div>
					{floors.map((item: any, index: number) => (
						<div className={`drawer__box__${theme} mt-3`} key={index}>
							{/* NAME */}
							<div className='generalStyles__flex'>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										{t("configuration.configurationFiles.name")}
									</p>
								</div>
								<div className='drawer__textContainer'>
									<p className='drawer__text'>
										{item.type} {item.floorNo}
									</p>
								</div>
							</div>
							{/* STATUS */}
							<div className='generalStyles__flex'>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										{t("configuration.configurationFiles.status")}
									</p>
								</div>
								<div className='drawer__textContainer'>
									<p className='drawer__text'>
										<Switch
											checkedChildren={t("general.active")}
											unCheckedChildren={t("general.lock")}
											checked={item.active}
										/>
									</p>
								</div>
							</div>
							{/* FRONT DOORS */}
							<div className='generalStyles__flex'>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										{t("configuration.configurationFiles.frontDoors")}
									</p>
								</div>
								<div className='drawer__textContainer'>
									<p className='drawer__text'>
										<Switch
											checkedChildren={t("general.active")}
											unCheckedChildren={t("general.lock")}
											checked={item.active}
										/>
									</p>
								</div>
							</div>
							{/* REAR DOORS */}
							{item && item.rearDoor && (
								<div className='generalStyles__flex'>
									<div>
										<p
											className={
												isMobile ? "drawer__titleMobile" : "drawer__title"
											}
										>
											{t("configuration.configurationFiles.rearDoors")}
										</p>
									</div>
									<div className='drawer__textContainer'>
										<p className='drawer__text'>
											<Switch
												checkedChildren={t("general.active")}
												unCheckedChildren={t("general.lock")}
												checked={item.active}
											/>
										</p>
									</div>
								</div>
							)}
						</div>
					))}
					{floorsBelowLobby.map((item: any, index: number) => (
						<div className={`drawer__box__${theme} mt-3`} key={index}>
							{item.floorNo} {item.type}
						</div>
					))}
					{/** ************************************************* */}
					{/** ************************************************* */}
				</div>
			)}
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button
						type='default'
						onClick={onClickClose}
						className='buttonStyle__5'
					>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default LiftsConfiguration;
