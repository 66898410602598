import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
// STYLES
import unauthorizedImage from "../../assets/error/cop2.png";

library.add(faUser);

function UnauthorizedPage() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  // eslint-disable-next-line no-unused-vars
  const [t] = useTranslation("global");
  const [showScreen, setShowScreen] = useState(false);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    setTimeout(() => {
      setShowScreen(true);
    }, 1000);
  }, []);
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {showScreen && (
        <>
          <div className="generalStyles__flexCenter">
            <img src={unauthorizedImage} alt="" />
          </div>
          <br />
          <br />
          <div className="generalStyles__flexCenter ">
            <Alert
              message={t("general.unauthorizedTitle")}
              description={t("general.unauthorizedDescription")}
              type="error"
              showIcon
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UnauthorizedPage;
