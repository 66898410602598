import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { usePostTrafficSummaryFloorMutation } from "../../../redux/reports/reportsAPI";
// COMPONENTS
import ReportsTable from "../ReportsTable";
import ReportsHeader from "../ReportsHeader";
import ReportsDrawerFilters from "../drawer/ReportsDrawerFilters";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const ReportTrafficHallCallSummary = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [loadPage, setLoadPage] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isError, error }] =
		usePostTrafficSummaryFloorMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLoadPage(true);
		setTimeout(() => {
			setLoadPage(false);
		}, 1000);
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={loadPage}>
			{validatePermission("permission-not-defined", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "REPORT",
							levelTypeName: "BANKS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: [
								"floor_name",
								"0 - 10",
								"11 - 20",
								"11 - 30",
								"31 - 40",
								"41 - 50",
								"51 - 60",
								"61 - 70",
								"71 - 80",
								"81 - 90",
								"91 - 120",
								"121 - 150",
								"151 - 210",
								"211 - 300",
								"300+",
								"total_calls",
								"average_time",
							],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText2",
							displayDownloadCSVReport: true,
							displayDownloadPDFReport: false,
							reportName: "reportTrafficHallCallSummary",
							reportServiceName: "",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: true,
							displayDateByRange: true,
							displayAdvanceFilterCharts: false,
							displayAdvanceFiltersTable: true,
							chartFaultType: "DAY",
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
						}}
					/>
					<ReportsTable
						response={data}
						triggerGetReport={triggerGetReport}
						isLoading={isLoading}
						isError={isError}
						error={error}
					/>
					<ReportsDrawerFilters />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</Spin>
	);
};

export default ReportTrafficHallCallSummary;
