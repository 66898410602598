import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useLazyGetServersByOrganizationQuery } from "../../../redux/servers/serverAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { SelectServerByOrganizationInterface } from "../../../interfaces/Global.interface";

function SelectServerByOrganization({
	idOrganization,
	value,
	onChangeServer,
	disabledInput,
}: SelectServerByOrganizationInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [servers, setServers] = useState([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetServersByOrganization, { data, isLoading }] =
		useLazyGetServersByOrganizationQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetServersByOrganization({ token, orgId: idOrganization });
	};

	const onChangeIdServer = (id: string) => {
		const index = servers.findIndex((x: any) => x.id === id);
		onChangeServer(servers[index]);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (idOrganization !== "") {
			initData();
		}
	}, [idOrganization]);

	useEffect(() => {
		if (data && data.data) {
			setServers(data.data);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Select
			showSearch
			filterOption={(input: any, option: any) =>
				option.children.toLowerCase().includes(input.toLowerCase())
			}
			className='generalStyles__width100'
			size='small'
			loading={isLoading}
			value={value}
			onChange={onChangeIdServer}
			disabled={idOrganization === "" || disabledInput}
		>
			{servers.map((item: any) => (
				<Option key={item.id} value={item.id}>
					{item.name}
				</Option>
			))}
		</Select>
	);
}

export default SelectServerByOrganization;
