import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(fetchBaseQuery(), { maxRetries });

export const monitoringAPI = createApi({
	reducerPath: "monitoringAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetOrganizationCascader", "tagGetFavoriteGrids"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ALL ORGANIZATIONS AND ITS CASCADER DATA */
		getOrganizationsCascader: builder.query({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/monitor-view/`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetOrganizationCascader"],
		}),
		//* *********************************************************** */
		//* GET FAVORITE GRIDS */
		getFavoriteGrids: builder.query({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/monitor-grid/?&page=1&limit=9999`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetFavoriteGrids"],
		}),
		//* *********************************************************** */
		//* POST FAVORITE GRIDS */
		postFavoriteGrids: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/monitor-grid/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetFavoriteGrids"],
		}),
		//* *********************************************************** */
		//* PUT FAVORITE GRIDS */
		putFavoriteGrids: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/monitor-grid/${body.id}`,
				method: "put",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetFavoriteGrids"],
		}),
		//* *********************************************************** */
		//* DELETE FAVORITE GRIDS */
		deleteFavoriteGrids: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/monitor-grid/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetFavoriteGrids"],
		}),
		//* *********************************************************** */
		//* POST SEND COMMANDS */
		postMonitoringCommand: builder.mutation({
			query: (body: any) => ({
				url: `${
					process.env.REACT_APP_EDGE_SERVER_CONNECTION === "OFFLINE"
						? process.env.REACT_APP_LIFT_NET_LOCAL_API_OFFLINE
						: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE
				}${
					process.env.REACT_APP_EDGE_SERVER_CONNECTION === "OFFLINE"
						? "/monitoring/command"
						: "/monitor-grid/command"
				}${
					process.env.REACT_APP_EDGE_SERVER_CONNECTION === "OFFLINE"
						? ""
						: `/${body.serverId}`
				}`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* POST REFRESH ALL OFFLINE */
		postMonitoringCommandRefreshAllOffline: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_LOCAL_API_OFFLINE}/monitoring/refresh-command/${body.serverId}`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* POST EMULATOR COMMAND */
		postEmulatorCommand: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_WEB_SOCKET_EMULATOR}/monitor-grid/command`,
				method: "post",
				body: body.body,
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetOrganizationsCascaderQuery,
	useLazyGetFavoriteGridsQuery,
	usePostFavoriteGridsMutation,
	usePutFavoriteGridsMutation,
	useDeleteFavoriteGridsMutation,
	usePostMonitoringCommandMutation,
	usePostMonitoringCommandRefreshAllOfflineMutation,
	usePostEmulatorCommandMutation,
} = monitoringAPI;
