import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const channelAPI = createApi({
	reducerPath: "channelAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetChannels"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ALL CHANNELS */
		getChannels: builder.query({
			query: (body: any) => ({
				url: `/channels/?&page=${body.page}&limit=${body.limit}${
					body.searchAndFilterString || ""
				}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetChannels"],
		}),
		//* *********************************************************** */
		//* GET CHANNELS BY SERVER */
		getChannelsByServer: builder.query({
			query: (body: any) => ({
				url: `/channels/?filter_target=server_id&filter=${body.serverId}&page=${
					body.page
				}&limit=${body.limit}${body.searchAndFilterString || ""}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetChannels"],
		}),
		//* *********************************************************** */
		//* CREATE CHANNEL */
		postChannel: builder.mutation({
			query: (body: any) => ({
				url: "/channels/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetChannels"],
		}),
		//* *********************************************************** */
		//* EDIT CHANNEL */
		putChannel: builder.mutation({
			query: (body: any) => ({
				url: `/channels/${body.body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetChannels"],
		}),
		//* *********************************************************** */
		//* DELETE CHANNEL */
		deleteChannel: builder.mutation({
			query: (body: any) => ({
				url: `/channels/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetChannels"],
		}),
	}),
});

export const {
	useLazyGetChannelsQuery,
	useLazyGetChannelsByServerQuery,
	usePostChannelMutation,
	usePutChannelMutation,
	useDeleteChannelMutation,
} = channelAPI;
