import React, { useEffect, useState, useRef } from "react";
// ANT DESIGN COMPONENTS
import { List, Tag, Empty, Tooltip } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateTerminalLastShortMessage,
	updateTerminalAutoScroll,
} from "../../../redux/monitoring/monitoringSlice";
// UTILS
import {
	getTableRowClass,
	generateExcelAndDownload,
} from "../../../utils/utils";
// COMPONENTS
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import {
	SocketMessageInterface,
	TerminalExcelReportInterface,
	TerminalTableInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function TerminalTable({
	socketMessage,
	iconDefinitionElevator,
}: TerminalTableInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const scrollRef = useRef<null | HTMLDivElement>(null);
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [socketMessages, setSocketMessages] = useState<
		SocketMessageInterface[]
	>([]);
	const [rowSelected, setRowSelected] = useState(0);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { terminalResetTable, terminalAutoScroll, terminalDownloadReport } =
		useSelector((state: RootState) => state.monitoring);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickRow = (newValue: SocketMessageInterface, index: number) => {
		dispatch(updateTerminalAutoScroll(false));
		dispatch(updateTerminalLastShortMessage(newValue));
		setRowSelected(index);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (socketMessage) {
			const copy = structuredClone(socketMessages);
			copy.push(socketMessage);
			setSocketMessages(copy);
			if (terminalAutoScroll) {
				dispatch(updateTerminalLastShortMessage(socketMessage));
			}
		}
		//
		if (scrollRef.current && terminalAutoScroll) {
			scrollRef.current?.scrollIntoView();
		}
	}, [socketMessage]);

	useEffect(() => {
		if (terminalResetTable) {
			setSocketMessages([]);
			setRowSelected(0);
		}
	}, [terminalResetTable]);

	useEffect(() => {
		if (terminalAutoScroll) {
			setRowSelected(0);
		}
	}, [terminalAutoScroll]);

	useEffect(() => {
		if (terminalDownloadReport) {
			// Generate excel data
			const copy = structuredClone(socketMessages);
			const newArr: TerminalExcelReportInterface[] = [];
			copy.map((message: SocketMessageInterface) => {
				newArr.push({
					id: message.id,
					bank_id: message.bank_id,
					lift_id: message.lift_id,
					short_message: message.icon.name,
					message: message.icon.description,
					message_type: message.icon.type,
					lift_type: message.lift_type,
					current_floor:
						message && message.position && message.position >= 0
							? message.position
							: 0,
					car_calls:
						message && message.car_calls
							? JSON.stringify(message.car_calls).replaceAll(",", "-")
							: "",
					hall_calls:
						message && message.hall_calls
							? JSON.stringify(message.hall_calls).replaceAll(",", "-")
							: "",
					icon: iconDefinitionElevator[message.icon.name.toLowerCase()],
				});
				return true;
			});
			//
			generateExcelAndDownload(newArr, "replayFile");
		}
	}, [terminalDownloadReport]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************* */}
			{/* HEADERS */}
			<div className={`generalStyles__flex terminal__tableHeader__${theme}`}>
				<div
					className={`terminal__tableHeader__50 terminal__tableHeader__${theme}`}
				>
					{" "}
					No
				</div>
				<div
					className={`terminal__tableHeader__100 terminal__tableHeader__${theme}`}
				>
					Lift Type
				</div>
				<div
					className={`terminal__tableHeader__100 terminal__tableHeader__${theme}`}
				>
					MSG Type
				</div>
				<div
					className={`terminal__tableHeader__100 terminal__tableHeader__${theme}`}
				>
					Icon
				</div>
				<div
					className={`terminal__tableHeader__100 terminal__tableHeader__${theme}`}
				>
					Short Message
				</div>
				<div
					className={`terminal__tableHeader__200 terminal__tableHeader__${theme}`}
				>
					Message
				</div>
				<div
					className={`terminal__tableHeader__100 terminal__tableHeader__${theme}`}
				>
					Current Floor
				</div>
				<div
					className={`terminal__tableHeader__200 terminal__tableHeader__${theme}`}
				>
					Car Calls
				</div>
				<div
					className={`terminal__tableHeader__200 terminal__tableHeader__${theme}`}
				>
					Hall Calls
				</div>
				<div
					className={`terminal__tableHeader__200 terminal__tableHeader__${theme}`}
				>
					Assigned Hall Calls
				</div>
			</div>
			{/** ************************* */}
			{/* SOCKET MESSAGES LIST */}
			{socketMessages.length > 0 ? (
				<div>
					<List
						className='terminal__table__listContainer'
						itemLayout='horizontal'
						size='large'
						dataSource={socketMessages}
						renderItem={(message: SocketMessageInterface, index: number) => (
							<div
								className={`terminal__table__listItem  ${
									rowSelected === index + 1
										? "terminal__tableRowSelected"
										: getTableRowClass(index, theme)
								}`}
								ref={scrollRef}
								role='button'
								tabIndex={0}
								onClick={() => {
									onClickRow(message, index + 1);
								}}
								onKeyDown={() => {}}
								id={index.toString()}
							>
								{/** NO */}
								<div className='terminal__tableHeader__50'>{index + 1}</div>
								{/** LIFT TYPE */}
								<div className='terminal__tableHeader__100 terminal__tableTagFix'>
									{message && message.lift_type ? (
										<>
											{message.lift_type === "ELEVATOR" && (
												<Tag color='blue' className='terminal__typeTag'>
													{message.lift_type.toLowerCase()}
												</Tag>
											)}
											{message.lift_type === "ESCALATOR" && (
												<Tag color='orange' className='terminal__typeTag'>
													{message.lift_type.toLowerCase()}
												</Tag>
											)}
											{message.lift_type === "MOVINGWALK" && (
												<Tag color='green' className='terminal__typeTag'>
													{message.lift_type.toLowerCase()}
												</Tag>
											)}
										</>
									) : (
										<Tag color='red'>Error</Tag>
									)}
								</div>
								{/** MESSAGE TYPE */}
								<div className='terminal__tableHeader__100 terminal__tableTagFix'>
									{message && message.icon && message.icon.type ? (
										<>
											{message.icon.type.toUpperCase() === "STATUS" && (
												<Tag color='green' className='terminal__typeTag'>
													{message.icon.type.toLowerCase()}
												</Tag>
											)}
											{message.icon.type.toUpperCase() === "FAULT" && (
												<Tag color='red' className='terminal__typeTag'>
													{message.icon.type.toLowerCase()}
												</Tag>
											)}
											{message.icon.type.toUpperCase() !== "STATUS" &&
												message.icon.type !== "FAULT" && (
													<Tag color='orange' className='terminal__typeTag'>
														{message.icon.type.toLowerCase()}
													</Tag>
												)}
										</>
									) : (
										<Tag color='red'>Error</Tag>
									)}
								</div>
								{/** ICON */}
								<div className='terminal__tableHeader__100 terminal__tableIconFix'>
									{message && message.icon && message.icon.name ? (
										<IconDefinitionIcons
											liftType='ELEVATOR'
											icon={
												iconDefinitionElevator[message.icon.name.toLowerCase()]
											}
											iconSize={20}
										/>
									) : (
										<Tag color='red'>Error</Tag>
									)}
								</div>
								{/** SHORT MESSAGE */}
								<div className='terminal__tableHeader__100'>
									{message && message.icon && message.icon.name ? (
										<Tooltip title={message.icon.name}>
											{message.icon.name}
										</Tooltip>
									) : (
										<Tag color='red'>Error</Tag>
									)}
								</div>
								{/** MESSAGE */}
								<div className='terminal__tableHeader__200'>
									{message && message.icon && message.icon.description ? (
										<Tooltip title={message.icon.description}>
											{message.icon.description}
										</Tooltip>
									) : (
										<Tag color='red'>Error</Tag>
									)}
								</div>
								{/** CURRENT FLOOR */}
								<div className='terminal__tableHeader__100'>
									{message && message.position >= 0 ? (
										<Tag className='terminal__positionTag' color='cyan'>
											{message.position}
										</Tag>
									) : (
										<Tag color='red'>Error</Tag>
									)}
								</div>
								{/** CAR CALLS */}
								<div className='terminal__tableHeader__200'>
									{message.lift_type === "ELEVATOR" && (
										<>
											{message.car_calls.front.map((carCall: number) => (
												<>
													<Tag className='terminal__positionTag' color='blue'>
														{carCall}
													</Tag>
												</>
											))}
											{message.car_calls.rear.map((carCall: number) => (
												<>
													<Tag className='terminal__positionTag' color='blue'>
														{carCall}
													</Tag>
												</>
											))}
										</>
									)}
								</div>
								{/** UP HALL CALLS */}
								<div className='terminal__tableHeader__200'>
									{message.lift_type === "ELEVATOR" && (
										<>
											{message.hall_calls.up.front.map((hallCall: number) => (
												<>
													<Tag className='terminal__positionTag' color='green'>
														{hallCall}
													</Tag>
												</>
											))}
											{message.hall_calls.up.rear.map((hallCall: number) => (
												<>
													<Tag className='terminal__positionTag' color='green'>
														{hallCall}
													</Tag>
												</>
											))}
											{message.hall_calls.down.front.map((hallCall: number) => (
												<>
													<Tag className='terminal__positionTag' color='red'>
														{hallCall}
													</Tag>
												</>
											))}
											{message.hall_calls.down.rear.map((hallCall: number) => (
												<>
													<Tag className='terminal__positionTag' color='red'>
														{hallCall}
													</Tag>
												</>
											))}
										</>
									)}
								</div>
								{/** UP ASSIGNED HALL CALLS */}
								<div className='terminal__tableHeader__200'>
									{message.hall_calls.up_assigned.front.map(
										(hallCall: number) => (
											<>
												<Tag className='terminal__positionTag' color='green'>
													{hallCall}
												</Tag>
											</>
										)
									)}
									{message.hall_calls.up_assigned.rear.map(
										(hallCall: number) => (
											<>
												<Tag className='terminal__positionTag' color='green'>
													{hallCall}
												</Tag>
											</>
										)
									)}
									{message.hall_calls.down_assigned.front.map(
										(hallCall: number) => (
											<>
												<Tag className='terminal__positionTag' color='red'>
													{hallCall}
												</Tag>
											</>
										)
									)}
									{message.hall_calls.down_assigned.rear.map(
										(hallCall: number) => (
											<>
												<Tag className='terminal__positionTag' color='red'>
													{hallCall}
												</Tag>
											</>
										)
									)}
								</div>
							</div>
						)}
					/>
				</div>
			) : (
				<div
					className={`terminal__table__emptyContainer terminal__tableHeader__${theme}`}
				>
					<Empty />
				</div>
			)}
		</div>
	);
}

export default TerminalTable;
