import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
	Button,
	Form,
	Input,
	Select,
	InputNumber,
	Spin,
	Divider,
	Drawer,
	Tooltip,
} from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	usePostOrganizationMutation,
	useLazyGetOrganizationAndChildsQuery,
} from "../../../redux/organizations/organizationAPI";
import {
	updateOpenModalCreateNewOrganization,
	updateOrganizationAndChilds,
} from "../../../redux/organizations/organizationSlice";
import {
	useGetCountriesMutation,
	useGetStatesMutation,
	useGetCitiesMutation,
} from "../../../redux/countriesStatesCitiesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationSelectOrganizationSubOrganization from "./OrganizationSelectOrganizationSubOrganization";
import GlobalAlert2 from "../../home/GlobalAlert2";

const { Option } = Select;

function OrganizationsCreateOrganizationForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [country, setCountry] = useState("");
	const [listCountries, setListCountries] = useState<any[]>([]);
	const [listStates, setListStates] = useState<any[]>([]);
	const [listCities, setListCities] = useState<any[]>([]);
	const [masterID, setMasterID] = useState("");
	const [
		isLoadingGetOrganizationAndChilds,
		setIsLoadingGetOrganizationAndChilds,
	] = useState(false);
	const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] =
		useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalCreateNewOrganization, organizationAndChilds } = useSelector(
		(state: any) => state.organization
	);
	const { userLogged, theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [getCountries, { data: countries }] = useGetCountriesMutation();
	const [
		getStates,
		{ data: states, isLoading: isLoadingStates, reset: resetGetStates },
	] = useGetStatesMutation();
	const [
		getCities,
		{ data: cities, isLoading: isLoadingCities, reset: resetGetCities },
	] = useGetCitiesMutation();
	const [
		postOrganization,
		{
			isSuccess: isSuccessPostOrganization,
			isLoading: isLoadingPostOrganization,
			isError: isErrorPostOrganization,
			error: errorPostOrganization,
			reset: resetPostOrganization,
		},
	] = usePostOrganizationMutation();
	const [triggerGetOrganizationAndChilds] =
		useLazyGetOrganizationAndChildsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const cleanOrganizationsList = () => {
		dispatch(
			updateOrganizationAndChilds({
				childs: [],
			})
		);
	};

	const onClose = () => {
		cleanOrganizationsList();
		dispatch(updateOpenModalCreateNewOrganization(false));
	};

	const onResetStateCity = () => {
		form.setFieldsValue({
			state: "",
			city: "",
		});
		setListCities([]);
		resetGetCities();
	};

	const handleCloseModal = () => {
		cleanOrganizationsList();
		dispatch(updateOpenModalCreateNewOrganization(false));
	};

	const handleChangeCountry = (value: string) => {
		onResetStateCity();
		setCountry(value);
		getStates(value);
	};

	const handleChangeState = (value: string) => {
		getCities({ country, state: value });
	};

	const onFinish = async (values: any) => {
		const token = await GETJwtToken();
		if (userLogged && userLogged.org) {
			let parentId = userLogged.org.id;
			let type = "ORG";
			if (
				organizationAndChilds &&
				organizationAndChilds.data &&
				organizationAndChilds.data.idOrganizationSelected &&
				organizationAndChilds.data.idOrganizationSelected !==
					process.env.REACT_APP_MASTER_ORG_ID
			) {
				type = "SUBORG";
			}
			if (
				organizationAndChilds &&
				organizationAndChilds.data &&
				organizationAndChilds.data.idOrganizationSelected
			) {
				parentId = organizationAndChilds.data.idOrganizationSelected;
			}
			const BODY = {
				body: {
					Name: values.name,
					Address: values.address,
					phone: values.phone.toString(),
					phone_code: values.dialCode.toString(),
					Type: type,
					Country: values.country,
					parent_id: parentId,
					State: values.state,
					City: values.city,
				},
				token,
			};
			postOrganization(BODY);
		}
	};

	const getOrganizationAndChilds = async (orgId: string) => {
		const token = await GETJwtToken();
		setIsLoadingGetOrganizationAndChilds(true);
		const data = await triggerGetOrganizationAndChilds({
			orgId,
			token,
		}).unwrap();
		setIsLoadingGetOrganizationAndChilds(false);
		if (
			data &&
			data.data &&
			data.data.children &&
			data.data.children.length > 0
		) {
			const BODY = {
				childs: [
					...organizationAndChilds.childs,
					{
						level: organizationAndChilds.childs.length + 1,
						data: data.data.children,
						orgSelected: orgId,
					},
				],
			};
			dispatch(updateOrganizationAndChilds(BODY));
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalCreateNewOrganization) {
			resetPostOrganization();
			resetGetStates();
			resetGetCities();
			setListStates([]);
			setListCities([]);
			form.resetFields();
			if (openModalCreateNewOrganization) {
				getCountries({});
			}

			getOrganizationAndChilds(masterID);
		}
	}, [openModalCreateNewOrganization]);

	useEffect(() => {
		if (countries && countries.data && countries.data.length > 0) {
			setListCountries(countries.data);
		}
	}, [countries]);

	useEffect(() => {
		if (
			states &&
			states.data &&
			states.data.states &&
			states.data.states.length > 0
		) {
			setListStates(states.data.states);
		}
	}, [states]);

	useEffect(() => {
		if (cities && cities.data && cities.data.length > 0) {
			setListCities(cities.data);
		}
	}, [cities]);

	useEffect(() => {
		cleanOrganizationsList();
		dispatch(updateOpenModalCreateNewOrganization(false));
	}, [isSuccessPostOrganization, isErrorPostOrganization]);

	useEffect(() => {
		if (userLogged && userLogged.org && userLogged.org.id) {
			setMasterID(userLogged.org.id);
		}
	}, [userLogged]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClose}
			closable={false}
			open={openModalCreateNewOrganization}
		>
			<Spin
				spinning={
					isLoadingPostOrganization ||
					isLoadingCities ||
					isLoadingStates ||
					isLoadingGetOrganizationAndChilds ||
					organizationsAndChildsIsLoading
				}
				tip={t("general.loading")}
			>
				{/** ************************************************** */}
				{/** CREATE ORGANIZATION FORM  */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faBuilding}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("organizationManagement.createNewOrganization")}
						</h5>
					</Divider>
				</div>
				<Form form={form} layout='vertical' onFinish={onFinish}>
					<div className={`drawer__box__${theme}`}>
						<div className='container'>
							<div className='row'>
								{/** NAME */}
								<div className='col-md-12 mt-3'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span className='generalStyles__inputFlexRequired'>
												*
											</span>
											<span>{t("general.name")}</span>
										</div>
										<div className='generalStyles__width100'>
											<Form.Item
												name='name'
												rules={[
													{
														required: true,
														message: t(
															"organizationManagement.formRequiredMessage.name"
														),
													},
													{
														max: 50,
														message: t(
															"organizationManagement.formRequiredMessage.nameMax"
														),
													},
												]}
											>
												<Input className='generalStyles__input' size='small' />
											</Form.Item>
										</div>
										<div className='generalStyles__infoTooltipIconBlank' />
									</div>
								</div>
								{/** ADDRESS */}
								<div className='col-md-12'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span className='generalStyles__inputFlexRequired'>
												*
											</span>
											<span>{t("organizationManagement.address")}</span>
										</div>
										<div className='generalStyles__width100'>
											<Form.Item
												name='address'
												rules={[
													{
														required: true,
														message: t(
															"organizationManagement.formRequiredMessage.address"
														),
													},
													{
														max: 100,
														message: t(
															"organizationManagement.formRequiredMessage.addressMax"
														),
													},
												]}
											>
												<Input className='generalStyles__input' size='small' />
											</Form.Item>
										</div>
										<div className='generalStyles__infoTooltipIconBlank' />
									</div>
								</div>
							</div>
							{/** PHONE */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>

										<span>{t("organizationManagement.phoneNumber")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='phone'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.phone"
													),
													type: "number",
												},
												{
													type: "number",
													max: 999999999,
													message: t(
														"organizationManagement.formRequiredMessage.phoneMax"
													),
												},
											]}
										>
											<InputNumber
												size='small'
												addonBefore={
													<Form.Item
														name='dialCode'
														rules={[
															{
																required: true,
																message: t(
																	"organizationManagement.formRequiredMessage.dialCode"
																),
															},
															{
																type: "number",
																max: 99999,
																message: t(
																	"organizationManagement.formRequiredMessage.dialCodeMax"
																),
															},
														]}
														noStyle
													>
														<InputNumber
															prefix='+'
															className='generalStyles__inputNumber'
															size='small'
														/>
													</Form.Item>
												}
												className='generalStyles__width100'
											/>
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
							{/** COUNTRY */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.country")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='country'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.country"
													),
												},
											]}
										>
											<Select
												showSearch
												optionFilterProp='children'
												onChange={handleChangeCountry}
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												size='small'
											>
												{listCountries.map((c) => (
													<Option key={c.name} value={c.name}>
														{c.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
							{/** STATE */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("organizationManagement.state")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item
											name='state'
											rules={[
												{
													required: true,
													message: t(
														"organizationManagement.formRequiredMessage.state"
													),
												},
											]}
										>
											<Select
												disabled={listStates.length === 0}
												showSearch
												optionFilterProp='children'
												onChange={handleChangeState}
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												size='small'
											>
												{listStates.map((st) => (
													<Option key={st.name} value={st.name}>
														{st.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<Tooltip
										title={t("tooltip.organizationState")}
										placement='top'
										className='generalStyles__mlFix'
									>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>
								</div>
							</div>
							{/** CITY */}
							<div className='col-md-12'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span>{t("organizationManagement.city")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Form.Item name='city'>
											<Select
												disabled={listCities.length === 0}
												showSearch
												optionFilterProp='children'
												filterOption={(input: any, option: any) =>
													option.children
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												size='small'
											>
												{listCities.map((c) => (
													<Option key={c} value={c}>
														{c}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<Tooltip
										title={t("tooltip.organizationCity")}
										placement='top'
										className='generalStyles__mlFix'
									>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>
								</div>
							</div>
						</div>
					</div>
					<div className='container'>
						<br />
						{/** ************************************************** */}
						{/** COMPONENT - GET ORGANIZATION AND CHILDS  */}
						<OrganizationSelectOrganizationSubOrganization
							setOrganizationsAndChildsIsLoading={
								setOrganizationsAndChildsIsLoading
							}
						/>
					</div>
					{/** ************************************************** */}
					{/** CLOSE AND SAVE BUTTONS  */}
					<div className='mt-4'>
						<div>
							<Button
								type='default'
								onClick={handleCloseModal}
								className='buttonStyle__3'
							>
								{t("organizationManagement.close")}
							</Button>
							<Button
								htmlType='submit'
								disabled={isLoadingPostOrganization}
								className='buttonStyle__3'
								type='primary'
							>
								{t("general.save")}
							</Button>
						</div>
					</div>
				</Form>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostOrganization}
				isSuccess={isSuccessPostOrganization}
				requestType='POST'
				error={errorPostOrganization}
				name='Organization'
			/>
		</Drawer>
	);
}

export default OrganizationsCreateOrganizationForm;
