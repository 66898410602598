import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import MonitoringGrid from "./MonitoringGrid";
import MonitoringHeader from "./MonitoringHeader";
import BanksDrawer from "../../edgeManagement/banks/BanksDrawer";
import MonitoringModalSaveNewGrid from "./MonitoringModalSaveNewGrid";
import MonitoringModalManageGrids from "./MonitoringModalManageGrids";
import MonitoringSelectFirstGrid from "./MonitoringSelectFirstGrid";
import AuthenticationTOTP from "../../authentication/AuthenticationTOTP";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACECES
import { RootState } from "../../../app/store";

const MonitoringPage = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: RootState) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("organizations", permissions) ? (
				<div>
					{
						// userLogged &&
						// userLogged.pref_grid_id &&
						// userLogged.pref_grid_id !== "" &&
						// userLogged.pref_grid_id !== "00000000-0000-0000-0000-000000000000" ? (
						// eslint-disable-next-line no-constant-condition
						true ? (
							<>
								<MonitoringHeader />
								<MonitoringGrid />
								<BanksDrawer />
								<MonitoringModalSaveNewGrid />
								<MonitoringModalManageGrids />
								<AuthenticationTOTP />
							</>
						) : (
							<MonitoringSelectFirstGrid />
						)
					}
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default MonitoringPage;
