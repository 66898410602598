import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";

function TableColumnProtocolType({ protocolType }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className=''>
			{protocolType === "MCE" && (
				<Tag color='magenta' className='drawer__typeTag'>
					{protocolType}
				</Tag>
			)}
			{protocolType === "MODBUS" && (
				<Tag color='cyan' className='drawer__typeTag'>
					{protocolType}
				</Tag>
			)}
			{protocolType === "BEAGLE" && (
				<Tag color='volcano' className='drawer__typeTag'>
					{protocolType}
				</Tag>
			)}{" "}
			{protocolType === "LN1" && (
				<Tag color='orange' className='drawer__typeTag'>
					{protocolType}
				</Tag>
			)}{" "}
			{protocolType === "DILIGENT" && (
				<Tag color='gold' className='drawer__typeTag'>
					{protocolType}
				</Tag>
			)}{" "}
			{protocolType === "BACNET" && (
				<Tag color='lime' className='drawer__typeTag'>
					{protocolType}
				</Tag>
			)}{" "}
			{protocolType === "RVU" && (
				<Tag color='green' className='drawer__typeTag'>
					{protocolType}
				</Tag>
			)}{" "}
			{protocolType === "" && (
				<Tag color='red' className='drawer__typeTag'>
					N/A
				</Tag>
			)}{" "}
		</div>
	);
}

export default TableColumnProtocolType;
