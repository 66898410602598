import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateExistsFaultsInMonitoringView } from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import {
	MonitoringLiftElevatorFullViewInterface,
	BankFloorInterface,
	SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

const MonitoringLiftElevatorFullView: React.FC<
	MonitoringLiftElevatorFullViewInterface
> = ({
	layout,
	socketMessage,
	floorIndex,
	iconDefinitionElevator,
	floor,
	lift,
}) => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [hasFaults, setHasFaults] = useState<boolean>(false);
	const [floorConfig, setFloorConfig] = useState<any>([]);
	const [lastShortMesage, setLastShortMessage] = useState("elOutIndicator");
	const [lastSocketMessage, setLastSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { elevatorIconDefinitions, faults } = useSelector(
		(state: RootState) => state.monitoring
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (
			socketMessage &&
			socketMessage.lift_id &&
			lift &&
			lift.id === socketMessage.lift_id
		) {
			setLastSocketMessage(socketMessage);
		}
	}, [socketMessage]);

	useEffect(() => {
		if (
			elevatorIconDefinitions.includes(
				iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()]
			)
		) {
			setLastShortMessage(
				iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()]
			);
		}
		// FAULT MESSAGES
		if (
			lastSocketMessage.icon.type.toUpperCase() === "FAULT" &&
			lift &&
			lift.id === lastSocketMessage.lift_id
		) {
			setHasFaults(true);
			dispatch(updateExistsFaultsInMonitoringView(true));

			setTimeout(() => {
				setHasFaults(false);
			}, 1000 * 60 * 60);
		}
	}, [lastSocketMessage]);

	useEffect(() => {
		if (
			faults &&
			faults.liftId &&
			faults.liftId !== "" &&
			lift &&
			faults.type === "LIFT" &&
			faults.liftId === lift.id
		) {
			setHasFaults(false);
		}
		if (faults && faults.type === "ALL") {
			setHasFaults(false);
		}
	}, [faults]);

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const getRearFrontDoorConfiguration = (bankFloors: BankFloorInterface[]) => {
		const newFloorConfig: any = [];
		bankFloors.map((item: BankFloorInterface) =>
			floorConfig.push({
				[item.floor_no]: {
					frontDoor: item.front_door || false,
					rearDoor: item.rear_door || false,
				},
			})
		);
		setFloorConfig(newFloorConfig);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (layout && layout.bank_floors) {
			getRearFrontDoorConfiguration(layout.bank_floors);
		}
	}, [layout]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className={`${hasFaults ? `monitoringView__dangerElevator   ` : ""}${
				hasFaults && floorIndex === 0
					? `monitoringView__dangerElevator__top `
					: ""
			}${
				hasFaults && floorIndex === layout.bank_floors.length - 1
					? `monitoringView__dangerElevator__bottom`
					: ""
			} monitoring__card__liftContainer__${theme} ${
				floorIndex === 0 ? "monitoring__card__liftContainerBorderTop " : ""
			} ${
				floorIndex === layout.bank_floors.length - 1
					? "monitoring__card__liftContainerBorderBottom"
					: ""
			}`}
		>
			{floor && floor.floor_no === lastSocketMessage.position && (
				<IconDefinitionIcons
					liftType='ELEVATOR'
					icon={lastShortMesage}
					iconSize={20}
				/>
			)}
			{/** HALL CALLS UP */}
			{(lastSocketMessage.hall_calls.up_assigned.rear.includes(
				floor.floor_no
			) ||
				lastSocketMessage.hall_calls.up_assigned.front.includes(
					floor.floor_no
				)) &&
				floor.floor_no !== lastSocketMessage.position && (
					<IconDefinitionIcons
						liftType='ELEVATOR'
						icon='elGoalGreen'
						iconSize={20}
					/>
				)}
			{/** HALL CALLS DOWN */}
			{(lastSocketMessage.hall_calls.down_assigned.front.includes(
				floor.floor_no
			) ||
				lastSocketMessage.hall_calls.down_assigned.rear.includes(
					floor.floor_no
				)) &&
				floor.floor_no !== lastSocketMessage.position && (
					<IconDefinitionIcons
						liftType='ELEVATOR'
						icon='elGoalRed'
						iconSize={20}
					/>
				)}
			{/** FRONT CAR CALLS */}
			{lastSocketMessage.car_calls.front.includes(floor.floor_no) &&
				!lastSocketMessage.hall_calls.up_assigned.front.includes(
					floor.floor_no
				) &&
				!lastSocketMessage.hall_calls.down_assigned.front.includes(
					floor.floor_no
				) &&
				floor.floor_no !== lastSocketMessage.position && (
					<IconDefinitionIcons
						liftType='ELEVATOR'
						icon='elGoal'
						iconSize={20}
					/>
				)}
			{/** REAR CAR CALLS */}
			{lastSocketMessage.car_calls.rear.includes(floor.floor_no) &&
				!lastSocketMessage.hall_calls.up_assigned.rear.includes(
					floor.floor_no
				) &&
				!lastSocketMessage.hall_calls.down_assigned.rear.includes(
					floor.floor_no
				) &&
				floor.floor_no !== lastSocketMessage.position && (
					<IconDefinitionIcons
						liftType='ELEVATOR'
						icon='elGoal'
						iconSize={20}
					/>
				)}
		</div>
	);
};

// Wrap the component with React.memo
const MemoizedMonitoringLiftElevatorFullView = React.memo(
	MonitoringLiftElevatorFullView
);

export default MemoizedMonitoringLiftElevatorFullView;
