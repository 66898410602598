/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthenticationState {
  openModalTotpToken: boolean;
  totpTokenIsValid: boolean;
  totpToken: string;
}

const initialState: AuthenticationState = {
  openModalTotpToken: false,
  totpTokenIsValid: false,
  totpToken: ""
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    udpateOpenModalTotpToken: (state, action: PayloadAction<boolean>) => {
      state.openModalTotpToken = action.payload;
    },
    updateTotpTokenIsValid: (state, action: PayloadAction<boolean>) => {
      state.totpTokenIsValid = action.payload;
    },
    updateTotpToken: (state, action: PayloadAction<string>) => {
      state.totpToken = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  udpateOpenModalTotpToken,
  updateTotpTokenIsValid,
  updateTotpToken
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
