import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingShield } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function DrawerBankInformation({ data }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <br />
      {/** ************************************************* */}
      {/** BANK INFORMATION */}
      <div>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faBuildingShield}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t("edgeManagement.banks.bankInformation")}
          </h5>
        </Divider>
      </div>
      <div className={`drawer__box__${theme}`}>
        {/* BANK ID */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              ID:
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data && data.id ? data.id : "--"}</p>
          </div>
        </div>
        {/* BANK NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("general.name")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.name ? data.name : "--"}
            </p>
          </div>
        </div>
        {/* STATUS */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              Status:
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.active ? (
                <Tag color="green" className="drawer__typeTag">
                  {t("general.active")}
                </Tag>
              ) : (
                <Tag color="red" className="drawer__typeTag">
                  {t("general.inactive")}
                </Tag>
              )}
            </p>
          </div>
        </div>
        {/* TYPE */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.lifts.type")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.type === "ELEVATOR" && (
                <Tag color="blue" className="drawer__typeTag">
                  {data.type}
                </Tag>
              )}
              {data && data.type === "ESCALATOR" && (
                <Tag color="volcano" className="drawer__typeTag">
                  {data.type}
                </Tag>
              )}
              {data && data.type === "MOVINGWALK" && (
                <Tag color="green" className="drawer__typeTag">
                  {data.type}
                </Tag>
              )}
            </p>
          </div>
        </div>
        {/* BANK LIFTS */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.lifts.lifts")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.number_of_lifts ? data.number_of_lifts : "0"}
            </p>
          </div>
        </div>
        {/* BANK LIFTS */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.lifts.liftsAssigned")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.lifts ? data.lifts.length : "0"}
            </p>
          </div>
        </div>
        {/* BOARD */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("configuration.configurationFiles.board")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.board ? data.board : "0"}
            </p>
          </div>
        </div>
        {data && data.type && data.type === "ELEVATOR" && (
          <>
            {/* NUMBER OF STOPS */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  Total {t("configuration.configurationFiles.stops")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data && data.stops ? data.stops : "0"}
                </p>
              </div>
            </div>
            {/* NUMBER OF STOPS BELOW LOBBY */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("configuration.configurationFiles.floorsBelowLobby")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data && data.floor_below_lobby
                    ? data.floor_below_lobby
                    : "0"}
                </p>
              </div>
            </div>
          </>
        )}
        {data && data.type && data.type !== "ELEVATOR" && (
          <>
            {/* DIRECTION */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("general.direction")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data && data.direction ? data.direction : "--"}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DrawerBankInformation;
