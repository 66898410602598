import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
	Drawer,
	DatePicker,
	Input,
	Button,
	Empty,
	Spin,
	Tooltip,
	Divider,
} from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import {
	faBuilding,
	faCalendarDays,
	faEdit,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerHolidays } from "../../../redux/organizations/organizationSlice";
import {
	useLazyGetHolidaysQuery,
	usePostHolidayMutation,
	useDeleteHolidayMutation,
	usePutHolidayMutation,
} from "../../../redux/organizations/organizationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACE
import { HolidayInterface } from "../../../interfaces/Organizations.interface";
import { RootState } from "../../../app/store";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";

function OrganizationsDrawerHolidays() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [holidays, setHolidays] = useState<HolidayInterface[]>([]);
	const [date, setDate] = useState<string>("2024-01-01");
	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [action, setAction] = useState<string>("create");
	const [idHoliday, setIdHoliday] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerHolidays, organization } = useSelector(
		(state: RootState) => state.organization
	);
	const { theme } = useSelector((state: RootState) => state.home);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetData,
		{ data, isLoading: isLoadingGetData, isFetching: isFetchingGetData },
	] = useLazyGetHolidaysQuery();
	const [triggerPostHoliday, { isLoading: isLoadingPostHoliday }] =
		usePostHolidayMutation();
	const [triggerDeleteHoliday, { isLoading: isLoadingDeleteHoliday }] =
		useDeleteHolidayMutation();
	const [triggerPutHoliday, { isLoading: isLoadingPutHoliday }] =
		usePutHolidayMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const launchIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onClickClose = () => {
		dispatch(updateOpenDrawerHolidays(false));
	};

	const clearForm = () => {
		setName("");
		setDate(`${moment().year().toString()}-01-01`);
		setDescription("");
		setAction("create");
	};

	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetData({
			token,
			orgId: organization.id,
		});
	};

	const onChangeDate = (date_: any, dateString: string) => {
		setDate(dateString);
	};

	const onChangeName = (newValue: string) => {
		setName(newValue);
	};

	const onChangeDescription = (newValue: string) => {
		setDescription(newValue);
	};

	const onClickCreate = async () => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				day: parseInt(moment(date).format("D"), 10),
				description,
				month: parseInt(moment(date).format("M"), 10),
				name,
				organization_id: organization.id,
			},
			id: idHoliday,
		};
		if (action === "create") {
			triggerPostHoliday(BODY);
		} else {
			triggerPutHoliday(BODY);
		}
		clearForm();
	};

	const onClickDelete = async (id: string) => {
		const token = await GETJwtToken();
		clearForm();
		triggerDeleteHoliday({
			token,
			id,
		});
	};

	const onClickEdit = (holiday: HolidayInterface) => {
		launchIsLoading();
		setAction("edit");
		setIdHoliday(holiday.id);
		setName(holiday.name);
		setDescription(holiday.description);
		setDate(
			`${moment().year().toString()}-${holiday.month < 10 ? "0" : ""}${
				holiday.month
			}-${holiday.day < 10 ? "0" : ""}${holiday.day}`
		);
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (openDrawerHolidays) {
			initData();
		} else {
			clearForm();
		}
	}, [openDrawerHolidays]);

	useEffect(() => {
		if (data && data.data && data.data.length > 0) {
			setHolidays(data.data);
		} else {
			setHolidays([]);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerHolidays}
		>
			<Spin
				spinning={
					isLoadingGetData ||
					isLoadingPostHoliday ||
					isFetchingGetData ||
					isLoadingDeleteHoliday ||
					isLoading ||
					isLoadingPutHoliday
				}
			>
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faBuilding}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("organizationManagement.organizationInformation")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{/* ID */}
					<div className='generalStyles__flex'>
						<div>
							<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
								ID:
							</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>
								{organization.id ? organization.id : "--"}
							</p>
						</div>
					</div>
					{/* NAME */}
					<div className='generalStyles__flex'>
						<div>
							<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
								{t("adapter.name")}:
							</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>
								{organization.name ? organization.name : "--"}
							</p>
						</div>
					</div>
				</div>
				{/** ************************************************* */}
				{/** HOLIDAYS */}
				<br />
				<DrawerRowDivider icon={faCalendarDays} title={t("general.holidays")} />
				<div className={`drawer__box__${theme} row`}>
					{/** HOLIDAYS  */}
					{holidays && holidays.length > 0 ? (
						<>
							{holidays.map((holiday: HolidayInterface) => (
								<div className='generalStyles__flex drawer__container'>
									<div>
										<Tooltip title={holiday.name}>
											<p
												className={
													isMobile ? "drawer__titleMobile" : "drawer__title"
												}
												style={{ paddingTop: "3px" }}
											>
												<span>
													{moment()
														.month(holiday.month - 1)
														.format("MMMM")}
												</span>{" "}
												- <span>{holiday.day}</span>
											</p>
										</Tooltip>
									</div>
									<div className='drawer__titleMobile generalStyles__width100 mt-1'>
										<Tooltip title={holiday.name}>
											<span>{holiday.name}</span>
										</Tooltip>
									</div>
									<div>
										<Tooltip title={t("general.edit")}>
											<FontAwesomeIcon
												onClick={() => {
													onClickEdit(holiday);
												}}
												icon={faEdit}
												className='generalStyles__info generalStyles__mrFix'
												style={{
													fontSize: "22px",
													paddingTop: "5px",
													paddingLeft: "10px",
												}}
											/>
										</Tooltip>
									</div>
									<div>
										<Tooltip title={t("general.delete")}>
											<FontAwesomeIcon
												icon={faTrash}
												onClick={() => {
													onClickDelete(holiday.id);
												}}
												className='generalStyles__warning generalStyles__mrFix'
												style={{
													fontSize: "22px",
													paddingTop: "5px",
													paddingLeft: "5px",
												}}
											/>
										</Tooltip>
									</div>
								</div>
							))}
						</>
					) : (
						<Empty />
					)}
				</div>
				{action === "edit" && (
					<div className='mt-4'>
						<div className='generalStyles__flexEnd'>
							<Button
								type='primary'
								className='buttonStyle__3'
								onClick={() => {
									setAction("create");
									setIdHoliday("");
									clearForm();
									launchIsLoading();
								}}
							>
								{t("general.create")} {t("general.holiday")}
							</Button>
							<br />
						</div>
					</div>
				)}
				{/** ************************************************* */}
				{/** CREATE HOLIDAYS */}
				<br />
				<DrawerRowDivider
					icon={action === "create" ? faCalendarDays : faEdit}
					title={`${t(`general.${action}`)} ${t("general.holiday")}`}
				/>
				<div className={`drawer__box__${theme} row`}>
					{/** ID  */}
					{action === "edit" && (
						<div className='generalStyles__flex drawer__container'>
							<div>
								<p
									className={isMobile ? "drawer__titleMobile" : "drawer__title"}
									style={{ paddingTop: "8px" }}
								>
									ID
								</p>
							</div>
							<div className='drawer__textContainer generalStyles__width100'>
								<Input value={idHoliday} disabled />{" "}
							</div>
						</div>
					)}
					{/** NAME  */}
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p
								className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								style={{ paddingTop: "8px" }}
							>
								{t("general.name")}
							</p>
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							<Input
								value={name}
								onChange={(event: React.FormEvent<HTMLInputElement>) => {
									onChangeName(event.currentTarget.value);
								}}
							/>{" "}
						</div>
					</div>
					{/** DESCRIPTION  */}
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p
								className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								style={{ paddingTop: "8px" }}
							>
								{t("general.description")}
							</p>
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							<Input
								value={description}
								onChange={(event: React.FormEvent<HTMLInputElement>) => {
									onChangeDescription(event.currentTarget.value);
								}}
							/>{" "}
						</div>
					</div>
					{/** MULTIPLE DATE PICKER  */}
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p
								className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								style={{ paddingTop: "8px" }}
							>
								{t("general.date")}
							</p>
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							<DatePicker
								format='YYYY-MM-DD'
								className='generalStyles__width100'
								value={moment(date)}
								onChange={onChangeDate}
							/>
						</div>
					</div>
				</div>
				<div className='mt-4'>
					<div className=''>
						<Button
							type='default'
							onClick={onClickClose}
							className='buttonStyle__3'
						>
							{t("general.close")}
						</Button>
						<Button
							type='primary'
							className='buttonStyle__3'
							onClick={onClickCreate}
						>
							{action === "create" ? (
								<>{t("general.create")}</>
							) : (
								<>{t("general.save")}</>
							)}
						</Button>
						<br />
					</div>
				</div>
			</Spin>
		</Drawer>
	);
}

export default OrganizationsDrawerHolidays;
