import React from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterDateTypeCharts() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeDateType = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.dateType = newValue;
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Select
				showSearch
				optionFilterProp='children'
				className='generalStyles__width100'
				allowClear={false}
				value={filters.dateType}
				onChange={onChangeDateType}
				size='small'
			>
				{[
					{ id: "TODAY", name: "Day" },
					{ id: "WEEK", name: "Week" },
					{ id: "MONTH", name: "Month" },
				].map((item: any) => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))}
			</Select>
		</div>
	);
}

export default ReportsFilterDateTypeCharts;
