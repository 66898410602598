import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerSpecialSwitchCatalog } from "../../../redux/tools/toolsSlice";

function SpecialSwitchesCatalogDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { specialSwitchCatalog, openDrawerSpecialSwitchCatalog } = useSelector(
		(state: any) => state.tool
	);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerSpecialSwitchCatalog(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerSpecialSwitchCatalog}
		>
			{/** ************************************************* */}
			{/** SPECIAL SWITCH INFORMATION */}
			<div>
				<Divider orientation='left' className='generalStyles__drawerDivider'>
					<h5>
						<FontAwesomeIcon
							icon={faGear}
							className='generalStyles__info generalStyles__mrFix'
						/>
						{t(
							"configuration.specialSwitchesConfiguration.specialSwitchesInformationCatalog"
						)}
					</h5>
				</Divider>
			</div>
			<div className={`drawer__box__${theme}`}>
				{/* ID */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							ID
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{specialSwitchCatalog.id ? specialSwitchCatalog.id : "--"}
						</p>
					</div>
				</div>
				{/* NAME */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("configuration.configurationFiles.name")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{specialSwitchCatalog.name ? specialSwitchCatalog.name : "--"}
						</p>
					</div>
				</div>
			</div>
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button
						type='default'
						onClick={onClickClose}
						className='buttonStyle__5'
					>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default SpecialSwitchesCatalogDrawer;
