import React from "react";
// ANT DESIGN COMPONENTS
import { Checkbox } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFiltersCheckboxCars() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const CheckboxGroup = Checkbox.Group;
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChange = (list: any) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.selectedCarsList = list;
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<CheckboxGroup
				options={filters.carsList}
				value={filters.selectedCarsList}
				onChange={onChange}
			/>
		</div>
	);
}

export default ReportsFiltersCheckboxCars;
