import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateRefreshTable } from "../../../redux/groups/groupsSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

library.add(faPlus, faArrowsRotate);

function GroupsHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleReloadPage = () => {
		dispatch(updateRefreshTable(true));
		setTimeout(() => {
			dispatch(updateRefreshTable(false));
		}, 1000);
	};

	return (
		<div className='generalStyles__spaceBetween'>
			<div className='generalStyles__mlFix mt-1' />
			{validatePermission("permission-not-defined", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						className='buttonStyle__10'
						icon={<FontAwesomeIcon icon={faArrowsRotate} />}
						onClick={() => {
							handleReloadPage();
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default GroupsHeader;
