import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import {
	Drawer,
	Divider,
	Checkbox,
	Input,
	Button,
	Spin,
	Table,
	InputNumber,
	Empty,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faElevator } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutBankMutation } from "../../../redux/banks/bankAPI";
import {
	updateOpenDrawerBankFloorNames,
	updateBank,
} from "../../../redux/banks/bankSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import DrawerBankInformation from "../../components/drawer/DrawerBankInformation";
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import {
	BankFloorInterface,
	LiftInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { BankInterface } from "../../../interfaces/Bank.interface";
import { RootState } from "../../../app/store";

const { Column, ColumnGroup } = Table;

function BanksFloorNames() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const BLANK_BANK: BankInterface = {
		id: "",
		active: false,
		board: 0,
		columns: 0,
		floor_below_lobby: 0,
		floor_count: 0,
		logical_port: 0,
		name: "",
		number_of_lifts: 0,
		channel_id: "",
		rear_doors: false,
		stops: 0,
		type: "",
		control_panel: "",
		bank_floors: [],
		lifts: [],
		server_id: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<BankInterface>(BLANK_BANK);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerBankFloorNames, bank } = useSelector(
		(state: RootState) => state.bank
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		putBank,
		{ isSuccess, isError, error, isLoading, reset: resetPutBank },
	] = usePutBankMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClose = () => {
		dispatch(updateOpenDrawerBankFloorNames(false));
	};

	const onChangeInputTextValue = (
		newValue: any,
		bankFloorId: string,
		inputName: string
	) => {
		if (data.bank_floors) {
			const index = data.bank_floors.findIndex(
				(x: any) => x.id === bankFloorId
			);
			if (index >= 0) {
				const copy: any = structuredClone(data);
				copy.bank_floors[index][inputName] = newValue;
				setData(copy);
			}
		}
	};

	const onChangeDoorLift = (
		newValue: any,
		liftId: string,
		bankFloorId: string,
		doorType: string
	) => {
		const copy = structuredClone(data);
		if (copy.lifts) {
			const liftIndex = copy.lifts.findIndex((x: any) => x.id === liftId);
			if (liftIndex >= 0) {
				if (
					copy &&
					copy.lifts &&
					copy.lifts[liftIndex] &&
					copy.lifts[liftIndex].lift_floors
				) {
					const floorIndex = copy.lifts[liftIndex].lift_floors.findIndex(
						(x: any) => x.bank_floor_id === bankFloorId
					);
					if (floorIndex >= 0) {
						if (doorType === "front") {
							copy.lifts[liftIndex].lift_floors[floorIndex].front_door =
								newValue.target.checked;
						} else if (doorType === "rear") {
							copy.lifts[liftIndex].lift_floors[floorIndex].rear_door =
								newValue.target.checked;
						}
					}
				}
			}
		}
		setData(copy);
	};

	const onChangeDoorBank = (
		newValue: any,
		bankFloorId: string,
		doorType: string
	) => {
		const copy = structuredClone(data);
		if (copy.bank_floors) {
			const index = copy.bank_floors.findIndex(
				(x: any) => x.id === bankFloorId
			);
			if (index >= 0) {
				if (doorType === "front") {
					copy.bank_floors[index].front_door = newValue.target.checked;
				} else {
					copy.bank_floors[index].rear_door = newValue.target.checked;
				}
				setData(copy);
			}
		}
	};

	const handleCloseDrawer = () => {
		dispatch(updateBank(BLANK_BANK));
		dispatch(updateOpenDrawerBankFloorNames(false));
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		//
		const copy: any = structuredClone(data);
		delete copy.data_blocks;
		delete copy.lifts;
		const BODY = {
			id: bank.id,
			body: {
				...copy,
				lift_floors: data.lifts,
				control_panel: JSON.stringify({
					carcall: {
						code: "cc",
						map: [
							{
								offset: "3c",
								target_bit: "01",
								new_bit: "10",
							},
						],
					},
					rear_carcall: {
						code: "rcc",
						map: [
							{
								offset: "3c",
								new_byte: "1a",
							},
						],
					},
					close_door: {
						code: "cd",
						map: [
							{
								offset: "3c",
								target_bit: "01",
								new_bit: "10",
							},
						],
					},
					open_door: {
						code: "od",
						map: [
							{
								offset: "3c",
								new_byte: "1a",
							},
						],
					},
					hall_call: {
						code: "hc",
						map: [
							{
								offset: "3c",
								target_bit: "01",
								new_bit: "10",
							},
						],
					},
					secure: {
						code: "sec",
						map: [
							{
								offset: "3c",
								new_byte: "10",
							},
						],
					},
				}),
			},
			token,
		};
		putBank(BODY);
	};

	const doorCheckedValue = (
		liftId: string,
		bankFloorId: string,
		doorType: string
	) => {
		if (data.lifts) {
			const liftIndex = data.lifts.findIndex((x: any) => x.id === liftId);
			if (liftIndex >= 0) {
				const floorIndex = data.lifts[liftIndex].lift_floors.findIndex(
					(x: any) => x.bank_floor_id === bankFloorId
				);
				if (floorIndex >= 0) {
					if (doorType === "front") {
						return data.lifts[liftIndex].lift_floors[floorIndex].front_door;
					}
					return data.lifts[liftIndex].lift_floors[floorIndex].rear_door;
				}
			}
		}
		return false;
	};

	const checkInterminateCheckBox = (
		returnValue: string,
		object: any,
		valueName: string
	) => {
		// Extracting the values of the property
		const values = object.map((obj: any) => obj[valueName]);
		// Checking if all values are true
		const allTrue = values.every((value: any) => value === true);
		// Checking if all values are false
		const allFalse = values.every((value: any) => value === false);
		// Checking if there are mixed values
		const mixedValues = !allTrue && !allFalse;
		if (returnValue === "mixed") {
			return mixedValues;
		}
		if (allTrue) {
			return true;
		}
		return false;
	};

	const checkAllBoxes = (
		e: any,
		objectName: string,
		valueName: string,
		liftId: string
	) => {
		if (objectName === "bank_floors" && data.bank_floors) {
			const copy: any = structuredClone(data);
			copy.bank_floors.map((item: any, index: number) => {
				copy.bank_floors[index][valueName] = e.target.checked;
				return true;
			});
			setData(copy);
		} else if (objectName === "lifts" && data.lifts) {
			const copy: any = structuredClone(data);
			const liftIndex = copy.lifts.findIndex((x: any) => x.id === liftId);
			if (liftIndex >= 0) {
				copy.lifts[liftIndex].lift_floors.map((item: any, index: number) => {
					copy.lifts[liftIndex].lift_floors[index][valueName] =
						e.target.checked;
					return true;
				});
			}
			setData(copy);
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			resetPutBank();
		};
	}, [openDrawerBankFloorNames]);

	useEffect(() => {
		dispatch(updateOpenDrawerBankFloorNames(false));
	}, [isSuccess, isError]);

	useEffect(() => {
		if (bank && bank.bank_floors) {
			const sorterByIndex = structuredClone(bank.bank_floors);
			sorterByIndex.sort((a: any, b: any) => b.floor_index - a.floor_index);
			const copy: any = structuredClone(bank);
			copy.bank_floors = sorterByIndex;
			setData(copy);
		}
	}, [bank]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='50%'
			placement='right'
			onClose={onClose}
			closable={false}
			open={openDrawerBankFloorNames}
		>
			<Spin spinning={isLoading} tip={t("general.loading")}>
				<div>
					{/** ************************************************* */}
					{/** BANK INFORMATION */}
					<DrawerBankInformation data={bank} />
					{/** ************************************************* */}
					{/** FLOORS */}
					<br />
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faElevator}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{t("edgeManagement.banks.floorNames")}
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme}`}>
						{data && data.bank_floors ? (
							<Table
								rowClassName={(record, index) => getTableRowClass(index, theme)}
								className='customTable'
								dataSource={data.bank_floors}
								rowKey={(record) => record.id}
								pagination={{
									defaultPageSize: 9999,
									showSizeChanger: false,
								}}
								size='small'
								scroll={{ x: "900" }}
							>
								<ColumnGroup>
									{/** FLOOR INDEX */}
									<Column
										title='Floor Index'
										dataIndex='floor_index'
										key='floor_index'
										className=''
										filterSearch
										width='50px'
										render={(text, record: BankFloorInterface) => (
											<InputNumber
												placeholder={t("edgeManagement.banks.floorName")}
												value={text}
												onChange={(e) => {
													onChangeInputTextValue(e, record.id, "floor_index");
												}}
												bordered={false}
											/>
										)}
									/>
									{/** FLOOR NO */}
									<Column
										title='Floor No'
										dataIndex='floor_no'
										key='floor_no'
										className=''
										filterSearch
										width='50px'
										render={(text, record: BankFloorInterface) => (
											<InputNumber
												placeholder={t("edgeManagement.banks.floorName")}
												value={text}
												onChange={(e) => {
													onChangeInputTextValue(e, record.id, "floor_no");
												}}
												bordered={false}
											/>
										)}
									/>
									{/** POSITION */}
									<Column
										title='Position'
										dataIndex='position'
										key='position'
										className=''
										filterSearch
										width='50px'
										render={(text, record: BankFloorInterface) => (
											<InputNumber
												placeholder={t("edgeManagement.banks.floorName")}
												value={text}
												onChange={(e) => {
													onChangeInputTextValue(e, record.id, "position");
												}}
												bordered={false}
											/>
										)}
									/>
									{/** NAME */}
									<Column
										title={t("general.name")}
										dataIndex='name'
										key='name'
										className='generalStyles__tableHeaderLink'
										filterSearch
										width='50px'
										render={(text, record: BankFloorInterface) => (
											<div className='edgeManagement__floorNameName'>
												<Input
													placeholder={t("edgeManagement.banks.floorName")}
													value={text}
													onChange={(e) => {
														onChangeInputTextValue(
															e.target.value,
															record.id,
															"name"
														);
													}}
													bordered={false}
												/>
											</div>
										)}
									/>
								</ColumnGroup>
								{/** BANK FLOORS */}
								<ColumnGroup title='Bank Floors'>
									<Column
										title={
											<div>
												<span className='generalStyles__mrFix'>F</span>
												<span>
													<Checkbox
														indeterminate={checkInterminateCheckBox(
															"mixed",
															data.bank_floors,
															"front_door"
														)}
														checked={checkInterminateCheckBox(
															"all",
															data.bank_floors,
															"front_door"
														)}
														onChange={(e) => {
															checkAllBoxes(e, "bank_floors", "front_door", "");
														}}
														className='edgeManagement__floorNameTootle'
													/>
												</span>
											</div>
										}
										align='center'
										width={70}
										render={(text, record: BankFloorInterface) => (
											<>
												<Checkbox
													className='edgeManagement__floorNameTootle'
													checked={record.front_door}
													onChange={(e) => {
														onChangeDoorBank(e, record.id, "front");
													}}
												/>
											</>
										)}
									/>
									<Column
										title={
											<div>
												<span className='generalStyles__mrFix'>R</span>
												<span>
													<Checkbox
														indeterminate={checkInterminateCheckBox(
															"mixed",
															data.bank_floors,
															"rear_door"
														)}
														checked={checkInterminateCheckBox(
															"all",
															data.bank_floors,
															"rear_door"
														)}
														onChange={(e) => {
															checkAllBoxes(e, "bank_floors", "rear_door", "");
														}}
														className='edgeManagement__floorNameTootle'
													/>
												</span>
											</div>
										}
										align='center'
										width={70}
										render={(text, record: BankFloorInterface) => (
											<>
												<Checkbox
													className='edgeManagement__floorNameTootle'
													checked={record.rear_door}
													onChange={(e) => {
														onChangeDoorBank(e, record.id, "rear");
													}}
												/>
											</>
										)}
									/>
								</ColumnGroup>
								{/** LIFTS */}
								{data && data.lifts && (
									<>
										{data.lifts
											.slice() // Create a shallow copy to avoid mutating the original array
											.sort((a: any, b: any) => {
												// Compare values based on a specific property (e.g., lift.name)
												if (a.position < b.position) return -1;
												if (a.position > b.position) return 1;
												return 0;
											})
											.map((lift: LiftInterface) => (
												<ColumnGroup title={lift.name} key={lift.id}>
													<Column
														title={
															<div>
																<span className='generalStyles__mrFix'>F</span>
																<span>
																	<Checkbox
																		indeterminate={checkInterminateCheckBox(
																			"mixed",
																			lift.lift_floors,
																			"front_door"
																		)}
																		checked={checkInterminateCheckBox(
																			"all",
																			lift.lift_floors,
																			"front_door"
																		)}
																		onChange={(e) => {
																			checkAllBoxes(
																				e,
																				"lifts",
																				"front_door",
																				lift.id
																			);
																		}}
																		className='edgeManagement__floorNameTootle'
																	/>
																</span>
															</div>
														}
														align='center'
														width={100}
														render={(text, record: BankFloorInterface) => (
															<>
																<Checkbox
																	className='edgeManagement__floorNameTootle'
																	checked={doorCheckedValue(
																		lift.id,
																		record.id,
																		"front"
																	)}
																	onChange={(e) => {
																		onChangeDoorLift(
																			e,
																			lift.id,
																			record.id,
																			"front"
																		);
																	}}
																/>
															</>
														)}
													/>
													<Column
														title={
															<div>
																<span className='generalStyles__mrFix'>R</span>
																<span>
																	<Checkbox
																		indeterminate={checkInterminateCheckBox(
																			"mixed",
																			lift.lift_floors,
																			"rear_door"
																		)}
																		checked={checkInterminateCheckBox(
																			"all",
																			lift.lift_floors,
																			"rear_door"
																		)}
																		onChange={(e) => {
																			checkAllBoxes(
																				e,
																				"lifts",
																				"rear_door",
																				lift.id
																			);
																		}}
																		className='edgeManagement__floorNameTootle'
																	/>
																</span>
															</div>
														}
														align='center'
														width={100}
														render={(text, record: BankFloorInterface) => (
															<>
																<Checkbox
																	className='edgeManagement__floorNameTootle'
																	checked={doorCheckedValue(
																		lift.id,
																		record.id,
																		"rear"
																	)}
																	onChange={(e) => {
																		onChangeDoorLift(
																			e,
																			lift.id,
																			record.id,
																			"rear"
																		);
																	}}
																/>
															</>
														)}
													/>
												</ColumnGroup>
											))}
									</>
								)}
							</Table>
						) : (
							<Empty description='No configuration found' />
						)}
					</div>
					{/** CLOSE AND SUBMIT BUTTONS */}
					<div className='mt-4'>
						<div>
							<Button
								type='default'
								onClick={handleCloseDrawer}
								className='buttonStyle__3'
							>
								{t("organizationManagement.close")}
							</Button>
							<Button
								type='default'
								onClick={onClickSave}
								className='buttonStyle__3'
							>
								{t("general.save")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='PUT'
				error={error}
				name='Bank'
			/>
		</Drawer>
	);
}

export default BanksFloorNames;
