import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Drawer } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenGlobalTerminal,
	updateLiftSelected,
	updateBankSelected,
	updateTerminalAutoScroll,
	updateTerminalIdLiftSelected,
	updateTerminalLastShortMessage,
	updateTerminalResetTable,
	updateTerminalShowJsonObject,
	updateTerminalShowAllMessages,
} from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import TerminalFilters from "./TerminalFilters";
import TerminalTable from "./TerminalTable";
import TerminalLift from "./TerminalLift";
import TerminalJsonViewer from "./TerminalJsonViewer";
// INTERFACES
import { TerminalPageInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function TerminalPage({
	socketMessage,
	layouts,
	iconDefinitionElevator,
}: TerminalPageInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		openGlobalTerminal,
		terminalShowJsonObject,
		terminalShowAllMessages,
	} = useSelector((state: RootState) => state.monitoring);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onCloseDrawer = () => {
		dispatch(updateOpenGlobalTerminal(false));
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			dispatch(
				updateLiftSelected({
					id: "",
					bank_id: "",
					control_panel: {},
					direction: "",
					name: "",
					position: 0,
					type: "",
					lift_floors: [],
				})
			);
			dispatch(
				updateBankSelected({
					id: "",
					active: false,
					board: 0,
					columns: 0,
					floor_below_lobby: 0,
					floor_count: 0,
					logical_port: 0,
					name: "",
					number_of_lifts: 0,
					channel_id: "",
					rear_doors: false,
					stops: 0,
					type: "",
					control_panel: {},
					bank_floors: [],
					lifts: [],
				})
			);
			dispatch(updateTerminalAutoScroll(true));
			dispatch(updateTerminalIdLiftSelected(""));
			dispatch(updateTerminalLastShortMessage(BLANK_SOCKET_MESSAGE));
			dispatch(updateTerminalResetTable(false));
			dispatch(updateTerminalShowJsonObject(false));
			dispatch(updateTerminalShowAllMessages(false));
		};
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			closable={false}
			onClose={onCloseDrawer}
			open={openGlobalTerminal}
			placement='bottom'
			height={550}
			title={<TerminalFilters />}
			bodyStyle={{ padding: "0px" }}
			maskStyle={{ backdropFilter: "blur(5px)" }}
		>
			<div className={`terminal__drawer__container__${theme}`}>
				<div className='generalStyles__flex'>
					{!terminalShowAllMessages && (
						<div className='terminal__liftContainer'>
							<TerminalLift
								layouts={layouts}
								iconDefinitionElevator={iconDefinitionElevator}
							/>
						</div>
					)}
					<div className='terminal__drawer__table'>
						<div className='terminal__drawer__tableContainer'>
							<TerminalTable
								socketMessage={socketMessage}
								iconDefinitionElevator={iconDefinitionElevator}
							/>
						</div>
					</div>
					{terminalShowJsonObject && (
						<div className='terminal__jsonContainer'>
							<TerminalJsonViewer />
						</div>
					)}
				</div>
			</div>
		</Drawer>
	);
}

export default TerminalPage;
