import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";

function ActionButtonPopover({ action, timer, toggleAction, type }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getTitleStyles = () => {
		if (type === "TOGGLE") {
			return "generalStyles__info";
		}
		if (type === "TURNBACK") {
			return "generalStyles__warning";
		}
		if (type === "HOLDBACK") {
			return "generalStyles__success";
		}
		return "";
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__flex'>
			<div>
				<div className='generalStyles__flex mt-1'>
					<div className={getTitleStyles()} style={{ width: "100px" }}>
						{t("general.label")}:
					</div>
					{"  "}
					<div>{action.label}</div>
				</div>
				{timer > 0 && (
					<div className='generalStyles__flex mt-1'>
						<div className={getTitleStyles()} style={{ width: "100px" }}>
							{t("general.activeTime")}:
						</div>
						{"  "}
						<div>
							<Tag color='green'>{timer}s</Tag>
						</div>
					</div>
				)}
				<div className='generalStyles__flex mt-1'>
					<div className={getTitleStyles()} style={{ width: "100px" }}>
						{t("general.validStates")}:
					</div>
					{"  "}
					{action.valid_states.map((state: string) => (
						<Tag color='cyan'>{state}</Tag>
					))}
				</div>
			</div>
			{toggleAction && (
				<div
					className='generalStyles__mlFix25'
					style={{ borderLeft: "solid 1px #303030", paddingLeft: "25px" }}
				>
					<div className='generalStyles__flex mt-1'>
						<div className={getTitleStyles()} style={{ width: "100px" }}>
							{t("general.label")}:
						</div>
						{"  "}
						<div>{toggleAction.label}</div>
					</div>
					<div className='generalStyles__flex mt-1'>
						<div className={getTitleStyles()} style={{ width: "100px" }}>
							{t("general.validStates")}:
						</div>
						{"  "}
						{toggleAction.valid_states.map((state: string) => (
							<Tag color='cyan'>{state}</Tag>
						))}
					</div>
				</div>
			)}
		</div>
	);
}

export default ActionButtonPopover;
