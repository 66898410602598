/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import {
	ReportSliceFiltersInterface,
	ReportsSliceViews,
} from "../../interfaces/Reports.interface";

export interface ReportState {
	openModalDownloadReport: boolean;
	openDrawerFilters: boolean;
	openDrawerRows: boolean;
	reportRow: any;
	reportRefreshTable: boolean;
	// ********************************
	filters: ReportSliceFiltersInterface;
	views: ReportsSliceViews;
}

const initialState: ReportState = {
	openModalDownloadReport: false,
	openDrawerFilters: false,
	openDrawerRows: false,
	reportRow: {},
	reportRefreshTable: false,
	// ********************************
	filters: {
		reportType: "REPORT",
		levelTypeName: "",
		displaySelectTypeLevel: false,
		liftType: "ALL",
		startDate: "",
		endDate: "",
		dateType: "MONTH",
		organizationId: "",
		organizationName: "",
		serverName: "",
		serverId: "",
		liftName: "",
		liftId: "",
		bankName: "",
		bankId: "",
		banks: [],
		displayAdvanceFiltersExclusions: false,
		exclusionTimeList: [],
		exclusionDateList: [],
		exclusionTime: false,
		exclusionDate: false,
		exclusionSaturdays: false,
		exclusionSundays: false,
		exclusionHolidays: false,
		timezoneCode: "",
		timezoneLabel: "",
		timezoneUTC: "",
		timezoneName: "",
		timezoneLocal: false,
		tableListColumns: [],
		tableSelectedColumns: [],
		useTablePagination: false,
		defaultColumns: [],
		displayAdvanceFiltersOffsets: false,
		disabledOffsets: false,
		defaultOffsets: [],
		offsetsList: [],
		infoDescriptionText: "",
		displayDownloadCSVReport: false,
		displayDownloadPDFReport: false,
		displayRecentWeeksPicket: false,
		weeksNumber: 5,
		limitNumber: 10,
		reportName: "",
		reportServiceName: "",
		sortAsc: true,
		getFirstDefaultValue: true,
		carsList: [],
		selectedCarsList: [],
		chartType: "bar_stack_vertical",
		chartFaultType: "DAY",
		displayAdvanceFilterCharts: false,
		displayAdvanceFiltersTable: false,
		displayDateByRange: true,
		displayInputLimitItems: false,
		displayChartMirror: false,
		chartMirror: false,
	},
	views: {
		displayTimeRanges: false,
		displayDateRanges: false,
	},
};

export const reportSlice = createSlice({
	name: "report",
	initialState,
	reducers: {
		updateOpenAlertDownloadReport: (state, action: PayloadAction<boolean>) => {
			state.openModalDownloadReport = action.payload;
		},
		updateOpenDrawerFilters: (state, action: PayloadAction<boolean>) => {
			state.openDrawerFilters = action.payload;
		},
		updateReportRow: (state, action: PayloadAction<any>) => {
			state.reportRow = action.payload;
		},
		updateOpenDrawerRows: (state, action: PayloadAction<boolean>) => {
			state.openDrawerRows = action.payload;
		},
		updateReportRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.reportRefreshTable = action.payload;
		},
		updateFilters: (
			state,
			action: PayloadAction<ReportSliceFiltersInterface>
		) => {
			state.filters = action.payload;
		},
		updateViews: (state, action: PayloadAction<ReportsSliceViews>) => {
			state.views = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateOpenAlertDownloadReport,
	updateOpenDrawerFilters,
	updateOpenDrawerRows,
	updateReportRow,
	updateReportRefreshTable,
	updateFilters,
	updateViews,
} = reportSlice.actions;

export default reportSlice.reducer;
