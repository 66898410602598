import React from "react";
// ANT DESIGN COMPONENTS
// COMPONENTS
import MapViewMappedin from "./mappedin/MapViewMappedin";

function MapViewPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			Mappedin2
			<div>
				<MapViewMappedin />
				<br />
			</div>
		</div>
	);
}

export default MapViewPage;
