import React from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterAdvanceDrawerBanks() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const onChangeBank = (newValue: string) => {
		const index = filters.banks.findIndex(
			(object: any) => object.id === newValue
		);
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		if (filters.banks[index]) {
			copy.bankId = filters.banks[index].id;
			copy.bankName = filters.banks[index].name;
			if (filters.banks[index].channel && filters.banks[index].channel.server) {
				copy.serverId = filters.banks[index].channel.server.id;
				copy.serverName = filters.banks[index].channel.server.name;
				if (filters.banks[index].channel.server.org) {
					copy.organizationId = filters.banks[index].channel.server.org.id;
					copy.organizationName = filters.banks[index].channel.server.org.name;
				}
			}
		}
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			<Tooltip title={filters.banks.length === 0 ? t("report.noBanks") : ""}>
				<Select
					className='generalStyles__width100'
					disabled={filters.banks.length === 0}
					showSearch
					optionFilterProp='children'
					placeholder={t("report.selectBank")}
					value={filters.bankId === "" ? null : filters.bankId}
					size='small'
					filterOption={(input: string, option: any) =>
						option.children.toLowerCase().includes(input.toLowerCase())
					}
					onChange={onChangeBank}
				>
					{filters.banks.map((bank: any) => (
						<Option key={bank.id} value={bank.id}>
							{bank.name}
						</Option>
					))}
				</Select>
			</Tooltip>
		</div>
	);
}

export default ReportsFilterAdvanceDrawerBanks;
