import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Tag, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleCheck,
	faCircleExclamation,
	faGear,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerOffsetType } from "../../../redux/tools/toolsSlice";

function OffsetTypeDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { offsetType, openDrawerOffsetType } = useSelector(
		(state: any) => state.tool
	);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerOffsetType(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerOffsetType}
		>
			{/** ************************************************* */}
			{/** OFFSET TYPE INFORMATION */}
			<div>
				<Divider orientation='left' className='generalStyles__drawerDivider'>
					<h5>
						<FontAwesomeIcon
							icon={faGear}
							className='generalStyles__info generalStyles__mrFix'
						/>
						{t("configuration.offsetTypeConfiguration.offsetTypeInformation")}
					</h5>
				</Divider>
			</div>
			<div className={`drawer__box__${theme}`}>
				{/* ID */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							ID
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{offsetType.id ? offsetType.id : "--"}
						</p>
					</div>
				</div>
				{/* NAME */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("configuration.configurationFiles.name")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{offsetType.name ? offsetType.name : "--"}
						</p>
					</div>
				</div>
				{/* LABEL COLOR */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("configuration.offsetTypeConfiguration.labelColor")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<Tag
							color={offsetType.color || ""}
							style={{
								textAlign: "center",
								width: "100%",
								marginBottom: "5px",
								paddingBottom: "5px",
							}}
						>
							{offsetType.color}
						</Tag>
					</div>
				</div>
			</div>
			{/** ************************************************* */}
			{/** ATTRIBUTES */}
			<br />
			<div>
				<Divider orientation='left' className='generalStyles__drawerDivider'>
					<h5>
						<FontAwesomeIcon
							icon={faTable}
							className='generalStyles__info generalStyles__mrFix'
						/>
						{t("configuration.offsetTypeConfiguration.attributes")}
					</h5>
				</Divider>
			</div>
			<div className={`drawer__box__${theme}`}>
				{offsetType && offsetType.attributes && (
					<div>
						{Object.keys(offsetType.attributes).map((e, i) => (
							<div className='generalStyles__flex' key={i}>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										{e.replace(/([A-Z])/g, " $1").trim()}
									</p>
								</div>
								<div className='drawer__textContainer'>
									{offsetType.attributes[e] ? (
										<Tag
											color='success'
											icon={
												<FontAwesomeIcon
													icon={faCircleCheck}
													className='generalStyles__mrFix'
												/>
											}
											className='drawer__tag'
										>
											{t("general.active")}
										</Tag>
									) : (
										<Tag
											color='error'
											icon={
												<FontAwesomeIcon
													icon={faCircleExclamation}
													className='generalStyles__mrFix'
												/>
											}
											className='drawer__tag'
										>
											{t("general.inactive")}
										</Tag>
									)}
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button
						type='default'
						onClick={onClickClose}
						className='buttonStyle__5'
					>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default OffsetTypeDrawer;
