import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(fetchBaseQuery(), { maxRetries });

export const messageTypeAPI = createApi({
	reducerPath: "messageTypeAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetMessagesType"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET MESSAGE TYPES */
		getMessagesType: builder.query({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/message-types/?&page=1&limit=9999`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetMessagesType"],
		}),
		//* *********************************************************** */
		//* POST MESSAGE TYPE */
		postMessageType: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/message-types/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetMessagesType"],
		}),
		//* *********************************************************** */
		//* PUT MESSGE TYPE */
		putMessageType: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/message-types/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetMessagesType"],
		}),
		//* *********************************************************** */
		//* DELETE MESSAGE TYPE */
		deleteMessageType: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/message-types/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetMessagesType"],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetMessagesTypeQuery,
	usePostMessageTypeMutation,
	usePutMessageTypeMutation,
	useDeleteMessageTypeMutation,
} = messageTypeAPI;
