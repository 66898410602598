import React, { useEffect, useState } from "react";
// REDUX
import { useSelector } from "react-redux";
// ICONS DARK FORDWARD SEQUENCE
import fordwardDark1 from "../../../assets/icons/movingWalk/we-dark-forward-1.svg";
import fordwardDark2 from "../../../assets/icons/movingWalk/we-dark-forward-2.svg";
import fordwardDark3 from "../../../assets/icons/movingWalk/we-dark-forward-3.svg";
import fordwardDark4 from "../../../assets/icons/movingWalk/we-dark-forward-4.svg";
import fordwardDark5 from "../../../assets/icons/movingWalk/we-dark-forward-5.svg";
import fordwardDark6 from "../../../assets/icons/movingWalk/we-dark-forward-6.svg";
// ICONS DARK BACKWARD SEQUENCE
import backwardDark1 from "../../../assets/icons/movingWalk/we-dark-backward-1.svg";
import backwardDark2 from "../../../assets/icons/movingWalk/we-dark-backward-2.svg";
import backwardDark3 from "../../../assets/icons/movingWalk/we-dark-backward-3.svg";
import backwardDark4 from "../../../assets/icons/movingWalk/we-dark-backward-4.svg";
import backwardDark5 from "../../../assets/icons/movingWalk/we-dark-backward-5.svg";
import backwardDark6 from "../../../assets/icons/movingWalk/we-dark-backward-6.svg";
// ICONS LIGHT FORDWARD SEQUENCE
import fordwardLight1 from "../../../assets/icons/movingWalk/we-light-forward-1.svg";
import fordwardLight2 from "../../../assets/icons/movingWalk/we-light-forward-2.svg";
import fordwardLight3 from "../../../assets/icons/movingWalk/we-light-forward-3.svg";
import fordwardLight4 from "../../../assets/icons/movingWalk/we-light-forward-4.svg";
import fordwardLight5 from "../../../assets/icons/movingWalk/we-light-forward-5.svg";
import fordwardLight6 from "../../../assets/icons/movingWalk/we-light-forward-6.svg";
// ICONS LIGHT BACKWARD SEQUENCE
import backwardLight1 from "../../../assets/icons/movingWalk/we-light-backward-1.svg";
import backwardLight2 from "../../../assets/icons/movingWalk/we-light-backward-2.svg";
import backwardLight3 from "../../../assets/icons/movingWalk/we-light-backward-3.svg";
import backwardLight4 from "../../../assets/icons/movingWalk/we-light-backward-4.svg";
import backwardLight5 from "../../../assets/icons/movingWalk/we-light-backward-5.svg";
import backwardLight6 from "../../../assets/icons/movingWalk/we-light-backward-6.svg";
// ICONS STATUS
import iconLockedDark from "../../../assets/icons/movingWalk/we-dark-locked.svg";
import iconLockedLight from "../../../assets/icons/movingWalk/we-light-locked.svg";
import iconDarkStoped from "../../../assets/icons/movingWalk/we-dark-stopped.svg";
import iconLightStoped from "../../../assets/icons/movingWalk/we-light-stopped.svg";
// INTERFACES
import { MonitoringIconsMovingWalksInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function MonitoringIconsMovingWalks({
	lift,
	shortMessage,
	iconDefinitionMovingwalk,
}: MonitoringIconsMovingWalksInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [intervalIndex, setIntervalIndex] = useState(1);
	const [liftDirection, setLiftDirection] = useState("");
	const [liftStatus, setLiftStatus] = useState("STOPED");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		setTimeout(() => {
			if (intervalIndex === 6) {
				setIntervalIndex(1);
			} else {
				setIntervalIndex(intervalIndex + 1);
			}
		}, 700);
	}, [intervalIndex]);

	useEffect(() => {
		const newShortMessage = shortMessage.toLowerCase();
		if (newShortMessage && newShortMessage !== "") {
			if (iconDefinitionMovingwalk[newShortMessage] === "weFordward") {
				setLiftDirection("FORDWARD");
			} else if (iconDefinitionMovingwalk[newShortMessage] === "weBackward") {
				setLiftDirection("BACKWARD");
			} else if (iconDefinitionMovingwalk[newShortMessage] === "weStopped") {
				setLiftDirection("STOPED");
			} else if (newShortMessage === "run") {
				setLiftStatus("RUN");
			}
		}
	}, [shortMessage]);

	useEffect(() => {
		if (lift && lift.direction) {
			setLiftDirection(lift.direction);
		}
	}, [lift]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='monitoringView__controlPanelActionIcon__escalator'>
			{lift && liftDirection && liftStatus ? (
				<>
					{/** **************************************************************** */}
					{/** STOPED DARK / BLUE  */}
					{liftStatus === "STOPED" && (theme === "dark" || theme === "blue") && (
						<div>
							<img src={iconDarkStoped} alt='' />
						</div>
					)}
					{/** STOPED LILGHT  */}
					{liftStatus === "STOPED" && theme === "light" && (
						<div>
							<img src={iconLightStoped} alt='' />
						</div>
					)}
					{/** FORDWARD DARK / BLUE SEQUENCE */}
					{liftStatus === "RUN" &&
						liftDirection === "FORDWARD" &&
						(theme === "dark" || theme === "blue") && (
							<div>
								{intervalIndex === 1 && <img src={fordwardDark1} alt='' />}
								{intervalIndex === 2 && <img src={fordwardDark2} alt='' />}
								{intervalIndex === 3 && <img src={fordwardDark3} alt='' />}
								{intervalIndex === 4 && <img src={fordwardDark4} alt='' />}
								{intervalIndex === 5 && <img src={fordwardDark5} alt='' />}
								{intervalIndex === 6 && <img src={fordwardDark6} alt='' />}
							</div>
						)}
					{/** BACKWARD DARK / BLUE SEQUENCE */}
					{liftStatus === "RUN" &&
						liftDirection === "BACKWARD" &&
						(theme === "dark" || theme === "blue") && (
							<div>
								{intervalIndex === 1 && <img src={backwardDark1} alt='' />}
								{intervalIndex === 2 && <img src={backwardDark2} alt='' />}
								{intervalIndex === 3 && <img src={backwardDark3} alt='' />}
								{intervalIndex === 4 && <img src={backwardDark4} alt='' />}
								{intervalIndex === 5 && <img src={backwardDark5} alt='' />}
								{intervalIndex === 6 && <img src={backwardDark6} alt='' />}
							</div>
						)}
					{/** FORDWARD LIGHT SEQUENCE */}
					{liftStatus === "RUN" &&
						liftDirection === "FORDWARD" &&
						theme === "light" && (
							<div>
								{intervalIndex === 1 && <img src={fordwardLight1} alt='' />}
								{intervalIndex === 2 && <img src={fordwardLight2} alt='' />}
								{intervalIndex === 3 && <img src={fordwardLight3} alt='' />}
								{intervalIndex === 4 && <img src={fordwardLight4} alt='' />}
								{intervalIndex === 5 && <img src={fordwardLight5} alt='' />}
								{intervalIndex === 6 && <img src={fordwardLight6} alt='' />}
							</div>
						)}
					{/** BACKWARD LIGHT SEQUENCE */}
					{liftStatus === "RUN" &&
						liftDirection === "BACKWARD" &&
						theme === "light" && (
							<div>
								{intervalIndex === 1 && <img src={backwardLight1} alt='' />}
								{intervalIndex === 2 && <img src={backwardLight2} alt='' />}
								{intervalIndex === 3 && <img src={backwardLight3} alt='' />}
								{intervalIndex === 4 && <img src={backwardLight4} alt='' />}
								{intervalIndex === 5 && <img src={backwardLight5} alt='' />}
								{intervalIndex === 6 && <img src={backwardLight6} alt='' />}
							</div>
						)}
				</>
			) : (
				<>
					{/** NOT ALLOWED LIGHT */}
					{theme === "light" && (
						<div>
							<img src={iconLockedLight} alt='' />
						</div>
					)}
					{/** NOT ALLOWED LIGHT */}
					{(theme === "dark" || theme === "blue") && (
						<div>
							<img src={iconLockedDark} alt='' />
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default MonitoringIconsMovingWalks;
