import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Select, Tooltip, InputNumber } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleInfo,
	faPlugCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetAdaptersQuery } from "../../../redux/adapters/adapterAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { AdaptersAssignAdapterInterface } from "../../../interfaces/Adapter.interface";

function AdaptersAssignAdapter({
	liftType,
	setFloorsAndDirectionData,
}: AdaptersAssignAdapterInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>({
		numberOfLifts: 0,
		numberOfStops: 2,
		maxNumberOfStops: 0,
		numberOfFloorsBelowLobby: 0,
		liftDirection: "NA",
		configurationId: "",
		adapterId: "",
	});
	const [adapters, setAdapters] = useState<any>([]);
	const [adapterSelected, setAdapterSelected] = useState("");
	const [liftDirection, setLiftDirection] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetAdapters, { data: dataGetAdapters }] =
		useLazyGetAdaptersQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetAdapters({
			token,
			page: 1,
			limit: 9999,
		});
	};

	const onChangeData = (newValue: any, nameValue: any) => {
		const copy = structuredClone(data);
		copy[nameValue] = newValue;
		setData(copy);
	};

	const onChangeAdapter = (newValue: string) => {
		setAdapterSelected(newValue);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (dataGetAdapters && dataGetAdapters.data) {
			setAdapters(dataGetAdapters.data);
		}
	}, [dataGetAdapters]);

	useEffect(() => {
		const copy = structuredClone(data);
		copy.adapterId = adapterSelected;
		setData(copy);
	}, [adapterSelected]);

	useEffect(() => {
		// Update lif type acording lift selection
		setAdapterSelected("");
		if (liftType === "ESCALATOR") {
			setLiftDirection(["UP", "DOWN"]);
			setData({
				numberOfLifts: 0,
				numberOfStops: 2,
				maxNumberOfStops: 0,
				numberOfFloorsBelowLobby: 0,
				liftDirection: "UP",
				configurationId: "",
				adapterId: "",
			});
		} else if (liftType === "MOVINGWALK") {
			setLiftDirection(["FORWARD", "BACKWARD"]);
			setData({
				numberOfLifts: 0,
				numberOfStops: 2,
				maxNumberOfStops: 0,
				numberOfFloorsBelowLobby: 0,
				liftDirection: "FORWARD",
				configurationId: "",
				adapterId: "",
			});
		} else if (liftType === "ELEVATOR") {
			setData({
				numberOfLifts: 0,
				numberOfStops: 2,
				maxNumberOfStops: 0,
				numberOfFloorsBelowLobby: 0,
				liftDirection: "NA",
				configurationId: "",
				adapterId: "",
			});
		}
	}, [liftType]);

	useEffect(() => {
		setFloorsAndDirectionData(data);
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Divider orientation='left' className='generalStyles__drawerDivider'>
				<h5>
					<FontAwesomeIcon
						icon={faPlugCircleCheck}
						className='generalStyles__info generalStyles__mrFix'
					/>
					{t("general.adapter")}
				</h5>
			</Divider>
			<div className={`drawer__box__${theme}`}>
				{/** NAME */}
				<div className='generalStyles__flex drawer__container'>
					<div>
						<p className='drawer__title'>
							<span className='generalStyles__inputFlexRequired'>*</span>
							{t("general.name")}
						</p>
					</div>
					<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
						<Select
							className='generalStyles__width100'
							size='small'
							onChange={(e: any) => {
								onChangeAdapter(e);
							}}
							value={adapterSelected}
						>
							{adapters.map((item: any) => (
								<Option key={item.id} value={item.id}>
									{item.name}
								</Option>
							))}
						</Select>
						<div className='generalStyles__infoTooltipIconBlank' />
					</div>
				</div>
				{/** Configuration type ELEVATOR */}
				{liftType === "ELEVATOR" && (
					<>
						{/** TOTAL LIFTS */}
						<div className='generalStyles__flex drawer__container'>
							<div>
								<p className='drawer__title'>
									{" "}
									<span className='generalStyles__inputFlexRequired'>*</span>
									Total {t("configuration.configurationFiles.lifts")}
								</p>
							</div>
							<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
								<InputNumber
									className='generalStyles__inputNumberFull'
									precision={0}
									value={data.numberOfLifts}
									onChange={(e: any) => {
										onChangeData(e, "numberOfLifts");
									}}
									size='small'
									disabled={adapterSelected === ""}
								/>
								<div className='generalStyles__infoTooltipIconBlank' />
							</div>
						</div>
						{/** STOPS */}
						<div className='generalStyles__flex drawer__container'>
							<div>
								<p className='drawer__title'>
									{" "}
									<span className='generalStyles__inputFlexRequired'>*</span>
									{t("configuration.configurationFiles.stops")}
								</p>
							</div>
							<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
								<InputNumber
									className='generalStyles__inputNumberFull'
									precision={0}
									value={data.numberOfStops}
									onChange={(e: any) => {
										onChangeData(e, "numberOfStops");
									}}
									size='small'
									disabled={adapterSelected === ""}
								/>
								<div className='generalStyles__infoTooltipIconBlank'>
									<Tooltip
										title={`${t("tooltip.maxStopsAllowed")} ${
											data.maxNumberOfStops
										}`}
										placement='top'
									>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>
								</div>
							</div>
						</div>
					</>
				)}
				{/** ESCALATOR / MOVINGWALK DIRECTION */}
				{(liftType === "ESCALATOR" || liftType === "MOVINGWALK") && (
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p className='drawer__title'>
								{" "}
								<span className='generalStyles__inputFlexRequired'>*</span>
								{t("general.direction")}
							</p>
						</div>
						<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
							<Select
								value={data.liftDirection}
								onChange={(e: any) => {
									onChangeData([e], ["liftDirection"]);
								}}
								className='generalStyles__width100'
								size='small'
							>
								{liftDirection.map((item: any) => (
									<Option key={item} value={item}>
										{item}
									</Option>
								))}
							</Select>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default AdaptersAssignAdapter;
