/* eslint-disable */
import { MappedinLocation, TGetVenueOptions } from "@mappedin/mappedin-js";
import "@mappedin/mappedin-js/lib/mappedin.css";
import { useEffect, useMemo, useState } from "react";
import useMapView from "./useMapView";
import useSelectedLocation from "./useSelectedLocation";
import useVenue from "./useVenue";
import "./styles.css";

function MapViewMappedin() {
	const [searchQuery, setSearchQuery] = useState<string>("");

	// See Trial API key Terms and Conditions
	// https://developer.mappedin.com/api-keys
	const options = useMemo<TGetVenueOptions>(
		() => ({
			venue: "mappedin-demo-mall",
			clientId: "5eab30aa91b055001a68e996",
			clientSecret: "RJyRXKcryCMy4erZqqCbuB1NbR66QTGNXVE0x3Pg6oCIlUR1",
		}),
		[]
	);
	const venue = useVenue(options);
	const { elementRef, mapView } = useMapView(venue);
	const { selectedLocation, setSelectedLocation } =
		useSelectedLocation(mapView);

	return (
		<div id='app'>
			<div id='ui'>
				<div>{`Selected: ${selectedLocation?.name ?? "None"}`}</div>
			</div>
			<div id='map-container' ref={elementRef}></div>
		</div>
	);
}

export default MapViewMappedin;
