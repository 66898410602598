import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Divider, Drawer, Input, Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalCreateNewServer } from "../../../redux/servers/serverSlice";
import { usePostServerMutation } from "../../../redux/servers/serverAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationSelectOrganizationSubOrganization from "../../organizationManagement/organizations/OrganizationSelectOrganizationSubOrganization";
import GlobalAlert2 from "../../home/GlobalAlert2";

function ServersCreateForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] =
		useState(false);
	const [name, setName] = useState("");
	const [serverIp, setServerIp] = useState("");
	const [serverIpIsValid, setServerIpIsValid] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalCreateNewServer } = useSelector(
		(state: any) => state.server
	);
	const { theme } = useSelector((state: any) => state.home);
	const { organizationAndChilds } = useSelector(
		(state: any) => state.organization
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postServer,
		{
			isSuccess: isSuccessPostServer,
			isLoading: isLoadingPostServer,
			isError: isErrorPostServer,
			error: errorPostServer,
			reset: resetPostServer,
		},
	] = usePostServerMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onChangeServerIp = (newValue: string) => {
		setServerIp(newValue);
		if (
			/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}(\/\d+)?$/.test(
				newValue
			)
		) {
			setServerIpIsValid(true);
		} else {
			setServerIpIsValid(false);
		}
	};

	const onClickClose = () => {
		dispatch(updateOpenModalCreateNewServer(false));
		setOrganizationsAndChildsIsLoading(false);
		setName("");
		setServerIp("");
		setServerIpIsValid(false);
	};

	const onFinish = async () => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				banks: [],
				ip: serverIp,
				name,
				channels: [],
				org_id: organizationAndChilds.data.idOrganizationSelected
					? organizationAndChilds.data.idOrganizationSelected
					: "",
			},
		};
		postServer(BODY);
	};

	const onChangeName = (newValue: string) => {
		setName(newValue);
	};

	const initData = () => {
		showIsLoading();
		if (openModalCreateNewServer) {
			resetPostServer();
		}
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, [openModalCreateNewServer]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPostServer, isErrorPostServer]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openModalCreateNewServer}
		>
			<Spin
				spinning={
					isLoadingPostServer || organizationsAndChildsIsLoading || isLoading
				}
				tip={t("general.loading")}
			>
				{/** ---------------------------------------------------- */}
				{/** SERVER INFORMATION  */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faServer}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("edgeManagement.servers.createNewServer")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme} row`}>
					{/** NAME  */}
					<div className='col-md-12'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>
									{t("edgeManagement.servers.serverName")}
								</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									className='generalStyles__input'
									onChange={(event: React.FormEvent<HTMLInputElement>) => {
										onChangeName(event.currentTarget.value);
									}}
									value={name}
									size='small'
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** SERVER IP  */}
					<div className='col-md-12 mt-3'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>
									{t("edgeManagement.servers.ip")}
								</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									className='generalStyles__input'
									onChange={(newValue: React.FormEvent<HTMLInputElement>) => {
										onChangeServerIp(newValue.currentTarget.value);
									}}
									value={serverIp}
									size='small'
									status={!serverIpIsValid && serverIp !== "" ? "error" : ""}
								/>
							</div>
							<div>
								<Tooltip title={t("tooltip.ipFormatValid")} placement='left'>
									<FontAwesomeIcon
										icon={faCircleInfo}
										className='generalStyles__infoTooltipIcon'
									/>
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
				{/** ---------------------------------------------------- */}
				{/** GET ORGANIZATION AND CHILDS  */}
				<div className='container'>
					<OrganizationSelectOrganizationSubOrganization
						setOrganizationsAndChildsIsLoading={
							setOrganizationsAndChildsIsLoading
						}
					/>
				</div>
				<br />
				{/** ---------------------------------------------------- */}
				<div className='mt-4'>
					<div>
						<Button
							type='default'
							onClick={onClickClose}
							className='buttonStyle__3'
						>
							{t("edgeManagement.close")}
						</Button>
						<Button
							onClick={onFinish}
							disabled={
								isLoadingPostServer ||
								name === "" ||
								serverIp === "" ||
								!serverIpIsValid
							}
							type='primary'
							className='buttonStyle__3'
						>
							{t("general.save")}
						</Button>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostServer}
				isSuccess={isSuccessPostServer}
				requestType='POST'
				error={errorPostServer}
				name='Sever'
			/>
		</Drawer>
	);
}

export default ServersCreateForm;
