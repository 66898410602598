/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { BankInterface } from "../../interfaces/Bank.interface";

export interface BankState {
	bank: BankInterface;
	idBankSelected: String;
	offsetsTable: Array<any>;
	generateOffsetsConfirm: boolean;
	openModalGenerateOffsets: boolean;
	nameBankSelected: String;
	openModalCreateEditBank: boolean;
	openModalDeleteBank: boolean;
	openModalOffsetsTable: boolean;
	openModalActivateDeactivate: boolean;
	openDrawerLiftsPosition: boolean;
	openDrawerBank: boolean;
	openDrawerBankFloorNames: boolean;
	bankRefreshTable: boolean;
	bankLiftsRefreshTable: boolean;
	bankBreadcrumb: Array<any>;
	bankUpdateBreadcrumb: Object;
	clearFilters: boolean;
	openDrawerAssignActions: boolean;
	formAction: string;
}

const initialState: BankState = {
	bank: {
		id: "",
		name: "",
		type: "ELEVATOR",
		channel_id: "",
		server_id: "",
		columns: 0,
		stops: 0,
		number_of_lifts: 0,
		logical_port: 0,
		floor_count: 0,
		floor_below_lobby: 0,
		board: 1,
		rear_doors: false,
		active: true,
		control_panel: "",
		lifts: [],
		offsets: [],
		floor_names: [],
		direct_link: "",
		configurations: [],
		data_blocks: [],
	},
	idBankSelected: "",
	offsetsTable: [],
	generateOffsetsConfirm: false,
	openModalGenerateOffsets: false,
	nameBankSelected: "",
	openModalCreateEditBank: false,
	openModalDeleteBank: false,
	openModalOffsetsTable: false,
	openModalActivateDeactivate: false,
	openDrawerLiftsPosition: false,
	openDrawerBank: false,
	openDrawerBankFloorNames: false,
	bankRefreshTable: false,
	bankLiftsRefreshTable: false,
	bankBreadcrumb: [],
	bankUpdateBreadcrumb: {
		update: false,
		id: "",
		name: "",
	},
	clearFilters: false,
	openDrawerAssignActions: false,
	formAction: "CREATE",
};

export const bankSlice = createSlice({
	name: "bank",
	initialState,
	reducers: {
		updateBank: (state, action: PayloadAction<BankInterface>) => {
			state.bank = action.payload;
		},
		updateIdBankSelected: (state, action: PayloadAction<String>) => {
			state.idBankSelected = action.payload;
		},
		updateOffsetsTable: (state, action: PayloadAction<any>) => {
			state.offsetsTable = action.payload;
		},
		updateGenerateOffsetsConfirm: (state, action: PayloadAction<boolean>) => {
			state.generateOffsetsConfirm = action.payload;
		},
		updateOpenModalGenerateOffsets: (state, action: PayloadAction<boolean>) => {
			state.openModalGenerateOffsets = action.payload;
		},
		updateNameBankSelected: (state, action: PayloadAction<String>) => {
			state.nameBankSelected = action.payload;
		},
		updateOpenModalCreateEditBank: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateEditBank = action.payload;
		},
		updateOpenModalDeleteBank: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteBank = action.payload;
		},
		updateOpenModalOffsetsTable: (state, action: PayloadAction<boolean>) => {
			state.openModalOffsetsTable = action.payload;
		},
		updateOpenModalActivateDeactivate: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.openModalActivateDeactivate = action.payload;
		},
		updateOpenDrawerLiftsPosition: (state, action: PayloadAction<boolean>) => {
			state.openDrawerLiftsPosition = action.payload;
		},
		updateOpenDrawerBank: (state, action: PayloadAction<boolean>) => {
			state.openDrawerBank = action.payload;
		},
		updateOpenDrawerBankFloorNames: (state, action: PayloadAction<boolean>) => {
			state.openDrawerBankFloorNames = action.payload;
		},
		updateBankRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.bankRefreshTable = action.payload;
		},
		updateBankLiftsRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.bankLiftsRefreshTable = action.payload;
		},
		updateBankBreadcrumb: (state, action: PayloadAction<any>) => {
			state.bankBreadcrumb = action.payload;
		},
		updateBankUpdateBreadcrumb: (state, action: PayloadAction<Object>) => {
			state.bankUpdateBreadcrumb = action.payload;
		},
		updateClearFilters: (state, action: PayloadAction<boolean>) => {
			state.clearFilters = action.payload;
		},
		updateOpenDrawerAssignActions: (state, action: PayloadAction<boolean>) => {
			state.openDrawerAssignActions = action.payload;
		},
		updateFormAction: (state, action: PayloadAction<string>) => {
			state.formAction = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateBank,
	updateIdBankSelected,
	updateOffsetsTable,
	updateGenerateOffsetsConfirm,
	updateOpenModalGenerateOffsets,
	updateNameBankSelected,
	updateOpenModalCreateEditBank,
	updateOpenModalDeleteBank,
	updateOpenModalOffsetsTable,
	updateOpenModalActivateDeactivate,
	updateOpenDrawerLiftsPosition,
	updateOpenDrawerBank,
	updateOpenDrawerBankFloorNames,
	updateBankRefreshTable,
	updateBankLiftsRefreshTable,
	updateBankBreadcrumb,
	updateBankUpdateBreadcrumb,
	updateClearFilters,
	updateOpenDrawerAssignActions,
	updateFormAction,
} = bankSlice.actions;

export default bankSlice.reducer;
