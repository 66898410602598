import { faArrowsRotate, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	updateAdapterRefreshTable,
	updateOpenModalCreateNewAdapter,
	updateFormCreateEdit,
} from "../../../redux/adapters/adapterSlice";
import validatePermission from "../../../utils/validatePermissions";
// ANT DESIGN COMPONENTS

function AdaptersHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { permissions } = useSelector((state: any) => state.user);
	const [t] = useTranslation("global");
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewAdapter = () => {
		dispatch(updateOpenModalCreateNewAdapter(true));
		dispatch(updateFormCreateEdit("create"));
	};
	const handleReloadPage = () => {
		dispatch(updateAdapterRefreshTable(true));
		setTimeout(() => {
			dispatch(updateAdapterRefreshTable(false));
		}, 1000);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__pageHeader'>
				{validatePermission("adapters-create", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__1'
							icon={
								<FontAwesomeIcon
									icon={faPlus}
									className='generalStyles__mrFix'
								/>
							}
							onClick={handleOpenModalCreateNewAdapter}
						>
							{t("adapter.createNewAdapter")}
						</Button>
					</div>
				)}
				{validatePermission("adapters-view", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__8'
							icon={
								<FontAwesomeIcon
									icon={faArrowsRotate}
									className='generalStyles__mrFix'
								/>
							}
							onClick={() => {
								handleReloadPage();
							}}
						>
							{t("userManagement.refreshTable")}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}

export default AdaptersHeader;
