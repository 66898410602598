import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Switch, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../app/store";
import { TableListColumnsInterface } from "../../interfaces/Reports.interface";

function ReportsDrawerSelectColumnsFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const CUSTOM_COLUM_NAMES: any = {
		up_time_target: "Availability",
	};
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const dinamicColumnTitle = (title: string) => {
		if (CUSTOM_COLUM_NAMES[title]) {
			return CUSTOM_COLUM_NAMES[title].toUpperCase();
		}
		return title.toUpperCase().replaceAll("_", " ");
	};

	const onChangeColumn = (newValue: boolean, columnName: string) => {
		const copy = structuredClone(filters);
		if (newValue) {
			copy.tableSelectedColumns.push(columnName);
			dispatch(updateFilters(copy));
		} else {
			const newCopy = copy.tableSelectedColumns.filter(
				(e: string) => e !== columnName
			);
			copy.tableSelectedColumns = newCopy;
			dispatch(updateFilters(copy));
		}
	};
	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{filters.tableListColumns.length > 0 && (
				<>
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faTable}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{t("general.columns")}
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme} generalStyles__flexWrap`}>
						{/* OFFSETS */}
						{filters.tableListColumns.map(
							(column: TableListColumnsInterface) => (
								<div
									className='generalStyles__flex mt-2 mb-2 '
									style={{ width: "50%", paddingRight: "15px" }}
									key={column.label}
								>
									<div className='generalStyles__mrFix generalStyles__mlFix'>
										<Switch
											checked={filters.tableSelectedColumns.includes(
												column.label
											)}
											onChange={(checked) => {
												onChangeColumn(checked, column.label);
											}}
										/>
									</div>
									<div className='generalStyles__textEllipsis'>
										<Tooltip title={column.label}>
											<span className=''>
												{" "}
												{dinamicColumnTitle(column.label)}
											</span>
										</Tooltip>
									</div>
								</div>
							)
						)}
					</div>
				</>
			)}
		</div>
	);
}

export default ReportsDrawerSelectColumnsFilters;
