import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Button, Table, Empty, Tag, Input, Tooltip, Select, Switch } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
    updateShowTable,
    updateFormAction,
    updateMessage,
} from "../../../redux/messages/messagesSlice";
import {
    useLazyGetMessagesQuery,
    usePostMessageMutation,
    usePutMessageMutation,
} from "../../../redux/messages/messagesAPI";
import {
    useLazyGetMessagesTypeQuery,
} from "../../../redux/messageType/messagesTypeAPI";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import MessageTableActions from "./MessagesTableActions";
import MessageHeader from "./MessagesHeader";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { MessageInterface } from "../../../interfaces/Message.interface";

function MessageTable() {
    // ************************************************ */
    // GLOBAL VARIABLES ******************************* */
    const dispatch = useDispatch();
    const [t] = useTranslation("global");
    const { Column, ColumnGroup } = Table;
    const BLANK_MESSAGE = {
        id: "",
        message_type_id: "",
        position: false,
        short_message: ""
    };
    const { Option } = Select;
    // ************************************************ */
    // USE STATE VARIABLES **************************** */
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<MessageInterface[]>([]);
    const [message, setMessage] =
        useState<MessageInterface>(BLANK_MESSAGE);
    const [errorMessage, setErrorMessage] = useState<any>({});
    const [totalRows, setTotalRows] = useState<number>(0);
    const [listMessageType, setListMessageType] = useState<any>([]);
    // ************************************************ */
    // SERVICES AND API CALLS ************************* */
    const [
        triggerGetMessages,
        {
            data: dataGetMessages,
            isLoading: isLoadingGetMessages,
            isFetching: isFetchingGetMessages,
            isError: isErrorGetMessages,
            error: errorGetMessages,
        },
    ] = useLazyGetMessagesQuery();

    const [
        triggerPostMessage,
        {
            isSuccess: isSuccessPostMessage,
            isLoading: isLoadingPostMessage,
            isError: isErrorPostMessage,
            error: errorPostMessage,
            reset: resetPostMessage,
        },
    ] = usePostMessageMutation();

    const [
        triggerPutMessage,
        {
            isSuccess: isSuccessPutMessage,
            isLoading: isLoadingPutMessage,
            isError: isErrorPutMessage,
            error: errorPutMessage,
            reset: resetPutMessage,
        },
    ] = usePutMessageMutation();
    const [
        triggerGetMessageTypes,
        {
            data: dataGetMessageTypes
        },
    ] = useLazyGetMessagesTypeQuery();
    // ************************************************ */
    // REDUX SLICE VARIABLES ************************** */
    const { theme } = useSelector((state: RootState) => state.home);
    const { permissions } = useSelector((state: any) => state.user);
    const {
        refreshTable,
        showTable,
        formAction,
        message: selectedMessage,
    } = useSelector((state: RootState) => state.message);

    // ************************************************ */
    // FUNCTIONS ************************************** */
    const onClickShowForm = () => {
        setIsLoading(true);
        dispatch(updateShowTable(true));
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const getInitialData = async () => {
        const token = await GETJwtToken();
        triggerGetMessages({
            token,
        });
        triggerGetMessageTypes({
            token,
        });
    };

    const onClickRefreshTable = async () => {
        getInitialData();
    };

    const onChangeFormValue = (newValue: any, variableName: string) => {
        const copy: MessageInterface = structuredClone(message);
        copy[variableName] = newValue;
        setMessage(copy);
    };

    const onClickSave = async () => {
        const token = await GETJwtToken();
        if (formAction === "CREATE") {
            const BODY = {
                token,
                body: message,
            };
            triggerPostMessage(BODY);
        } else {
            const copy = structuredClone(message);
            const BODY = {
                token,
                id: message.id,
                body: copy,
            };
            triggerPutMessage(BODY);
        }
    };

    const resetForm = () => {
        resetPostMessage();
        resetPutMessage();
        setMessage(BLANK_MESSAGE);
        setErrorMessage({});
        dispatch(updateFormAction("CREATE"));
        dispatch(updateMessage(BLANK_MESSAGE));
    };

    const checkFormIsValid = () => {
        let formIsValid = true;
        if (message.name === "") {
            formIsValid = false;
        }
        return formIsValid;
    };
    const getMessageTypeName = (id: string) => {
        const index = listMessageType.findIndex(
            (object: any) => object.id === id
        );
        if (index >= 0) {
            return listMessageType[index].name
        }
        return "--"
    };
    // ************************************************* */
    // USE EFFECT ************************************** */
    useEffect(() => {
        if (showTable) {
            getInitialData();
        }
    }, [showTable]);

    useEffect(() => {
        if (refreshTable) {
            onClickRefreshTable();
        }
    }, [refreshTable]);

    useEffect(() => {
        if (dataGetMessages && dataGetMessages.data) {
            setData(dataGetMessages.data);
            setTotalRows(dataGetMessages.total);
        }
    }, [dataGetMessages]);

    useEffect(() => {
        if (errorGetMessages) {
            setErrorMessage(errorGetMessages);
            setData([]);
        }
    }, [errorGetMessages]);

    useEffect(() => {
        resetForm();
    }, [
        isSuccessPostMessage,
        isSuccessPutMessage,
        isErrorPostMessage,
        isErrorPutMessage,
    ]);

    useEffect(() => {
        if (formAction === "CREATE") {
            setMessage(BLANK_MESSAGE);
        }
    }, [formAction]);

    useEffect(() => {
        setMessage(selectedMessage);
    }, [selectedMessage]);

    useEffect(() => {
        if (dataGetMessageTypes && dataGetMessageTypes.data) {
            setListMessageType(dataGetMessageTypes.data);
        }
    }, [dataGetMessageTypes]);
    // ************************************************ */
    // COMPONENT ************************************** */
    return (
        <div>
            {/** ----------------------------------------------------- */}
            {/** SHOW ALERT */}
            {!showTable && (
                <div className='mb-4'>
                    <Alert
                        message={t("general.warning")}
                        description={t("configuration.message.warningDescription")}
                        type='warning'
                        showIcon
                        closable
                    />
                    <Button
                        className='buttonStyle__2 configuration__editRootPermisionsButton'
                        onClick={onClickShowForm}
                    >
                        {t("configuration.message.showRootConfiguration")}
                    </Button>
                </div>
            )}
            {/** ----------------------------------------------------- */}
            {/** MESSAGE FORM */}
            {showTable && (
                <div>
                    {validatePermission("permission-not-defined", permissions) ? (
                        <>
                            <MessageHeader />
                            <Table
                                locale={{
                                    emptyText: (
                                        <TableNoDataBox
                                            isError={isErrorGetMessages}
                                            errorMessage={
                                                errorMessage &&
                                                    errorMessage.data &&
                                                    errorMessage.data.message
                                                    ? errorMessage.data.message
                                                    : "Error"
                                            }
                                            noItemsFound='No actions found'
                                        />
                                    ),
                                }}
                                rowClassName={(record, index) => getTableRowClass(index, theme)}
                                dataSource={data}
                                pagination={{
                                    defaultPageSize: 10,
                                    showSizeChanger: true,
                                    pageSizeOptions: ["10", "25", "50", "100"],
                                }}
                                size='small'
                                className='mt-3'
                                loading={
                                    isFetchingGetMessages ||
                                    isLoading ||
                                    isLoadingGetMessages ||
                                    isLoadingPostMessage ||
                                    isLoadingPutMessage
                                }
                                footer={() => (
                                    <div className='generalStyles__flexEnd'>
                                        <Tag>{totalRows} Rows</Tag>
                                    </div>
                                )}
                            >
                                {/** SHORT MESSAGE */}
                                <ColumnGroup title='Short Message'>
                                    <Column
                                        width={200}
                                        title={
                                            <Input
                                                size='small'
                                                placeholder='Short Message'
                                                value={formAction === "CREATE" ? message.short_message : ""}
                                                disabled={formAction === "EDIT"}
                                                onChange={(
                                                    event: any
                                                ) => {
                                                    onChangeFormValue(event.currentTarget.value, "short_message");
                                                }}
                                            />
                                        }
                                        dataIndex='short_message'
                                        key='short_message'
                                        className=''
                                        render={(text, record: MessageInterface) => (
                                            <div>
                                                {formAction === "EDIT" && record.id === message.id ? (
                                                    <Input
                                                        size='small'
                                                        placeholder='Short Message'
                                                        value={message.short_message}
                                                        onChange={(
                                                            event: any
                                                        ) => {
                                                            onChangeFormValue(
                                                                event.currentTarget.value,
                                                                "short_message"
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <Tag color='cyan' className='drawer__typeTag'>
                                                        {text}
                                                    </Tag>
                                                )}
                                            </div>
                                        )}
                                    />
                                </ColumnGroup>
                                {/** MESSAGE TYPES */}
                                <ColumnGroup title='Message type'>
                                    <Column
                                        title={
                                            <Select
                                                className='generalStyles__width100'
                                                onChange={(
                                                    value
                                                ) => {
                                                    onChangeFormValue(value, "message_type_id");
                                                }}
                                                disabled={listMessageType.length === 0}
                                                showSearch
                                                size='small'
                                                value={message.message_type_id}
                                                optionFilterProp='children'
                                                filterOption={(input: string, option: any) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                            >
                                                {listMessageType.map((item: any) => (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        }
                                        dataIndex='message_type_id'
                                        key='message_type_id'
                                        className=''
                                        render={(text: any, record: any) => (
                                            <div>
                                                {formAction === "EDIT" && record.id === message.id ? (
                                                    <Select
                                                        className='generalStyles__width100'
                                                        onChange={(
                                                            value
                                                        ) => {
                                                            onChangeFormValue(value, "message_type_id");
                                                        }}
                                                        disabled={listMessageType.length === 0}
                                                        showSearch
                                                        size='small'
                                                        value={message.message_type_id}
                                                        optionFilterProp='children'
                                                        filterOption={(input: string, option: any) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase())
                                                        }
                                                    >
                                                        {listMessageType.map((item: any) => (
                                                            <Option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                ) : (

                                                    <Tag color='cyan' className='drawer__typeTag'>
                                                        {getMessageTypeName(text)}
                                                    </Tag>
                                                )}
                                            </div>
                                        )}
                                    />
                                </ColumnGroup>
                                {/** POSITION */}
                                <ColumnGroup title='Position'>
                                    <Column
                                        title={
                                            <Switch
                                                onChange={(e) => {
                                                    onChangeFormValue(e, "position");
                                                }}
                                                disabled={formAction === 'EDIT'}
                                                checkedChildren={t("configuration.message.yes")}
                                                unCheckedChildren={t("configuration.message.no")}
                                                checked={formAction === 'EDIT' ? false : message.position}
                                            />
                                        }
                                        dataIndex='position'
                                        key='position'
                                        className=''
                                        render={(text: any, record: any) => (
                                            <div>
                                                {formAction === "EDIT" && record.id === message.id ? (
                                                    <Switch
                                                        onChange={(e) => {
                                                            onChangeFormValue(e, "position");
                                                        }}
                                                        checkedChildren={t("configuration.message.yes")}
                                                        unCheckedChildren={t("configuration.message.no")}
                                                        checked={message.position}
                                                    />
                                                ) : (
                                                    <><Switch
                                                        disabled
                                                        onChange={(e) => {
                                                            onChangeFormValue(e, "position");
                                                        }}
                                                        checkedChildren={t("configuration.message.yes")}
                                                        unCheckedChildren={t("configuration.message.no")}
                                                        checked={text}
                                                    /></>
                                                )}
                                            </div>
                                        )}
                                    />
                                </ColumnGroup>
                                {/** ACTIONS */}
                                <ColumnGroup
                                    align='center'
                                    title={t("edgeManagement.banks.actions")}
                                    width={200}
                                >
                                    <Column
                                        align='center'
                                        width={200}
                                        title={
                                            <div className='generalStyles__flexCenter'>
                                                <div className=''>
                                                    <Tooltip title={t("general.save")}>
                                                        <Button
                                                            className={
                                                                formAction === "CREATE" && checkFormIsValid()
                                                                    ? "buttonStyle__10"
                                                                    : ""
                                                            }
                                                            disabled={
                                                                !checkFormIsValid() || formAction === "EDIT"
                                                            }
                                                            onClick={onClickSave}
                                                            icon={<FontAwesomeIcon icon={faSave} />}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                <div className='generalStyles__mlFix'>
                                                    <Tooltip title={t("general.clear")}>
                                                        <Button
                                                            className={
                                                                formAction === "CREATE" ? "buttonStyle__14" : ""
                                                            }
                                                            onClick={resetForm}
                                                            disabled={formAction === "EDIT"}
                                                            icon={<FontAwesomeIcon icon={faX} />}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        }
                                        key='action'
                                        render={(_: any, record: MessageInterface) => (
                                            <div>
                                                {formAction === "EDIT" &&
                                                    record.id === message.id ? (
                                                    <div className='generalStyles__flexCenter'>
                                                        <div className=''>
                                                            <Tooltip title={t("general.save")}>
                                                                <Button
                                                                    className='buttonStyle__10'
                                                                    disabled={!checkFormIsValid()}
                                                                    onClick={onClickSave}
                                                                    icon={<FontAwesomeIcon icon={faSave} />}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                        <div className='generalStyles__mlFix'>
                                                            <Tooltip title={t("general.cancel")}>
                                                                <Button
                                                                    className='buttonStyle__14'
                                                                    onClick={resetForm}
                                                                    icon={<FontAwesomeIcon icon={faX} />}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <MessageTableActions selectedItem={record} />
                                                )}
                                            </div>
                                        )}
                                    />
                                </ColumnGroup>
                            </Table>
                        </>
                    ) : (
                        <>
                            <div className='generalStyles__noAccessToListTable'>
                                <Empty
                                    description={t(
                                        "organizationManagement.listNotAvailableOrganizations"
                                    )}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
            {/** ---------------------------------------------------- */}
            {/** GLOBAL ALERT */}
            <GlobalAlert2
                isError={isErrorPostMessage}
                isSuccess={isSuccessPostMessage}
                requestType='POST'
                error={errorPostMessage}
                name='Message Type'
            />
            <GlobalAlert2
                isError={isErrorPutMessage}
                isSuccess={isSuccessPutMessage}
                requestType='PUT'
                error={errorPutMessage}
                name='Message Type'
            />
        </div>
    );
}

export default MessageTable;
