import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// MUI CHARTS
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import Box from "@mui/material/Box";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong, faUpLong } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// UTILS
import {
	getChartBackgroundColor,
	getChartTextColor,
	getChartTicklineColor,
	generateLinesData,
	getChartAxisHighlight,
	getAxisLabels,
} from "./utils";
// INTERFACES
import { RootState } from "../../../app/store";

function ChartLine({
	data,
	isLoading,
	bottomLabel,
	leftLabel,
	topLabel,
	topLabelIcon,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [labels, setLabels] = useState<string[]>([]);
	const [seriesData, setSeriesData] = useState<any>([]);
	const [localSelectedCarList, setLocalCarList] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const arraysAreEqual = (arr1: any, arr2: any) => {
		if (arr1.length !== arr2.length) {
			return false;
		}

		return arr1.every((value: any, index: any) => value === arr2[index]);
	};

	const getCharNoDataMessage = () => {
		if (filters.carsList.length === 0) {
			return "general.noData";
		}
		if (localSelectedCarList.length === 0) {
			return "general.selectSomeCar";
		}
		if (data && data.length === 0) {
			return "general.monthHasNoData";
		}
		return "general.noData";
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (localSelectedCarList && localSelectedCarList.length > 0 && data) {
			let curveType = "linear";
			if (filters.chartType === "line") {
				curveType = "linear";
			} else if (filters.chartType === "line_natural") {
				curveType = "natural";
			} else if (filters.chartType === "step_before") {
				curveType = "stepBefore";
			} else if (filters.chartType === "step_after") {
				curveType = "stepAfter";
			}
			const responseSeriesData = generateLinesData(
				data,
				curveType,
				localSelectedCarList,
				filters.chartFaultType,
				filters.chartMirror
			);
			setSeriesData(responseSeriesData);
			const responseLabels = getAxisLabels(data, filters.chartFaultType);
			setLabels(responseLabels);
		} else {
			setSeriesData([]);
		}
	}, [localSelectedCarList, filters.chartType, filters.chartMirror]);

	useEffect(() => {
		if (!arraysAreEqual(filters.selectedCarsList, localSelectedCarList)) {
			setLocalCarList(filters.selectedCarsList);
		}
	}, [filters.selectedCarsList]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Box sx={{ width: "100%", padding: "10px" }}>
			<div
				className='generalStyles__width100 generalStyles__textCenter'
				style={{
					fontSize: "20px",
					fontWeight: "bolder",
					backgroundColor: `${getChartBackgroundColor(theme)}`,
					color: `${getChartTextColor(theme)}`,
					paddingTop: "20px",
					paddingBottom: "5px",
				}}
			>
				<span>{topLabel}</span>
				{topLabelIcon === "UP" && (
					<FontAwesomeIcon
						icon={faUpLong}
						fontSize={23}
						className='generalStyles__mlFix generalStyles__success'
					/>
				)}
				{topLabelIcon === "DOWN" && (
					<FontAwesomeIcon
						icon={faDownLong}
						fontSize={23}
						className='generalStyles__mlFix generalStyles__error'
					/>
				)}
			</div>
			<Spin spinning={isLoading}>
				<div className='generalStyles__flex'>
					<div
						style={{
							fontSize: "20px",
							fontWeight: "bolder",
							backgroundColor: `${getChartBackgroundColor(theme)}`,
							textAlign: "center",
							color: `${getChartTextColor(theme)}`,
							writingMode: "vertical-rl",
							transform: "scale(-1)",
							marginLeft: "-1px",
							paddingRight: "25px",
						}}
					>
						<span>
							{filters.chartType === "bar_horizontal" ||
							filters.chartType === "bar_stack_horizontal"
								? bottomLabel
								: leftLabel}
						</span>
					</div>
					<LineChart
						axisHighlight={{ x: "line", y: "line" }}
						slotProps={{
							legend: {
								direction: "row",
								position: { vertical: "top", horizontal: "middle" },
								labelStyle: {
									fontSize: 14,
									fill: `${getChartTextColor(theme)}`,
								},
							},
							// Custom loading message
							loadingOverlay: { message: "Loading..." },
							// Custom message for empty chart
							noDataOverlay: { message: t(`${getCharNoDataMessage()}`) },
						}}
						sx={{
							[`.${axisClasses.root}`]: {
								[`.${axisClasses.tick}, .${axisClasses.line}`]: {
									stroke: `${getChartTicklineColor(theme)}`,
									strokeWidth: 3,
								},
								[`.${axisClasses.tickLabel}`]: {
									fill: `${getChartTextColor(theme)}`,
								},
							},
							[`.MuiChartsLegend-series	`]: {
								stroke: `${getChartAxisHighlight(theme)}`,
							},
							[`.MuiChartsAxisHighlight-root `]: {
								stroke: `${getChartAxisHighlight(theme)}`,
							},
							[`.css-1f57y8b `]: {
								fill: `${getChartTextColor(theme)}`,
							},
							borderRadius: "7px",
							backgroundColor: `${getChartBackgroundColor(theme)}`,
						}}
						series={seriesData}
						xAxis={[{ scaleType: "point", data: labels }]}
						height={450}
					/>
				</div>
				<div
					className='generalStyles__width100 generalStyles__textCenter'
					style={{
						fontSize: "20px",
						fontWeight: "bolder",
						backgroundColor: `${getChartBackgroundColor(theme)}`,
						color: `${getChartTextColor(theme)}`,
						paddingBottom: "10px",
						marginTop: "-5px",
					}}
				>
					<span>
						{filters.chartType === "bar_horizontal" ||
						filters.chartType === "bar_stack_horizontal"
							? leftLabel
							: bottomLabel}
					</span>
					<br />
					<span className='generalStyles__info'>ChartLabel</span>
				</div>
			</Spin>
		</Box>
	);
}

export default ChartLine;
