import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateTotpTokenIsValid } from "../../../redux/authentication/authenticationSlice";
import { useLazyGetUsersQuery } from "../../../redux/users/userAPI";
import {
	useLazyGetUserPswQuery,
	usePostUserPswMutation,
} from "../../../redux/login/loginAPI";
import {
	updateOpenDrawerUser,
	updateUser,
	updateOpenModalPsw,
} from "../../../redux/users/userSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
import FilterSelectOrganization from "../../components/filters/FilterSelectOrganization";
import FilterSelectUserStatus from "../../components/filters/FilterSelectUserStatus";
// UTILS
import { getTableRowClass, buildFilterString } from "../../../utils/utils";
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableColumnStatusUser from "../../components/table/TableColumnStatusUser";
import AuthenticationTOTP from "../../authentication/AuthenticationTOTP";
import UsersModalPsw from "./UsersModalPsw";
import TableNoDataBox from "../../components/table/TableNoDataBox";
import UsersTableActions from "./UsersTableActions";
import GlobalAlert2 from "../../home/GlobalAlert2";

const UsersTable = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");
	const { Column } = Table;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [users, setUsers] = useState<any[]>([]);
	const [filterName, setFilterName] = useState("");
	const [filterEmail, setFilterEmail] = useState("");
	const [filterOrganizationName, setFilterOrganizationName] = useState("");
	const [filterUserStatus, setFilterUserStatus] = useState("");
	const [userPsw, setUserPsw] = useState("");
	const [pswType, setPswType] = useState("");
	const [errorMessage, setErrorMessage] = useState<any>("");
	const [pagination, setPagination] = useState<any>({
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ["10", "20", "50", "100"],
		total: 0,
	});
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { userRefreshTable, permissions, user } = useSelector(
		(state: any) => state.user
	);
	const { openDrawerUser } = useSelector((state: any) => state.home);
	const { totpTokenIsValid } = useSelector(
		(state: any) => state.authentication
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetUsersTable,
		{ data, isLoading, isError, isFetching, error },
	] = useLazyGetUsersQuery();
	const [
		triggerGetUserPsw,
		{ data: dataUserPsw, isLoading: isLoadingUserPsw },
	] = useLazyGetUserPswQuery();
	const [
		triggerPostUserPsw,
		{
			data: dataPostUserPsw,
			isLoading: isLoadingPostUserPsw,
			isError: isErrorPostUserPsw,
			isSuccess: isSuccessPostUserPsw,
			error: errorPostUserPsw,
		},
	] = usePostUserPswMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const onCellClick = (record: any) => {
		if (record) {
			dispatch(updateUser(record));
			dispatch(updateOpenDrawerUser(true));
		}
	};

	const getData = async (page: number, limit: number) => {
		const searchAndFilterString = buildFilterString({
			filterFirstName: filterName,
			filterMiddleName: filterName,
			filterLastName: filterName,
			filterEmail,
			filterOrganizationFullName: filterOrganizationName,
			filterUserStatus,
		});
		const token = await GETJwtToken();
		triggerGetUsersTable({
			page,
			limit,
			token,
			searchAndFilterString,
		});
	};

	const handleTableChange = async (pagination_: any) => {
		setPagination({
			...pagination,
			current: pagination_.current,
			pageSize: pagination_.pageSize,
			total: pagination_.total,
		});
		getData(pagination_.current, pagination_.pageSize);
	};

	const refreshTable = async () => {
		setFilterName("");
		setFilterEmail("");
		setFilterOrganizationName("");
		setFilterUserStatus("");
		setPagination({
			...pagination,
			current: 1,
			pageSize: 10,
		});
		getData(1, 10);
	};

	const TotpTokenIsValid = async () => {
		if (totpTokenIsValid) {
			const token = await GETJwtToken();
			if (pswType === "get") {
				triggerGetUserPsw({
					token,
					body: {
						user_id: user.id,
					},
				});
			} else {
				triggerPostUserPsw({
					token,
					body: {
						user_id: user.id,
					},
				});
			}
		}
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getData(1, 10);
	}, []);

	useEffect(() => {
		if (data && data.data) {
			setPagination({
				...pagination,
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: data.total,
			});
			setUsers(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			setUsers([]);
			setPagination({
				...pagination,
				current: 1,
				pageSize: 10,
				total: 0,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (userRefreshTable) {
			refreshTable();
		}
	}, [userRefreshTable]);

	useEffect(() => {
		if (totpTokenIsValid && !openDrawerUser) {
			TotpTokenIsValid();
			setTimeout(() => {
				dispatch(updateTotpTokenIsValid(false));
			}, 1000);
		}
	}, [totpTokenIsValid]);

	useEffect(() => {
		if (dataUserPsw && dataUserPsw.offline_password) {
			dispatch(updateOpenModalPsw(true));
			setUserPsw(dataUserPsw.offline_password);
		}
	}, [dataUserPsw]);

	useEffect(() => {
		if (dataPostUserPsw && dataPostUserPsw.offline_password) {
			dispatch(updateOpenModalPsw(true));
			setUserPsw(dataPostUserPsw.offline_password);
		}
	}, [dataPostUserPsw]);

	useEffect(() => {
		if (error) {
			setErrorMessage(error);
			setUsers([]);
			setPagination({
				current: 1,
				pageSize: 10,
				showSizeChanger: true,
				pageSizeOptions: ["10", "20", "50", "100"],
				total: 0,
			});
		}
	}, [error]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			<>
				{validatePermission("users-view", permissions) ? (
					<Table
						locale={{
							emptyText: (
								<TableNoDataBox
									isError={isError}
									errorMessage={
										errorMessage &&
										errorMessage.data &&
										errorMessage.data.message
											? errorMessage.data.message
											: "Error"
									}
									noItemsFound='No users found'
								/>
							),
						}}
						rowClassName={(record, index) => getTableRowClass(index, theme)}
						className='customTable'
						dataSource={users}
						loading={
							isLoading ||
							isFetching ||
							isLoadingUserPsw ||
							isLoadingPostUserPsw
						}
						rowKey={(record) => record.id}
						size='small'
						pagination={pagination}
						onChange={handleTableChange}
						scroll={{ x: 900 }}
						footer={() => (
							<div className='generalStyles__flexEnd'>
								<Tag>{pagination.total} Users</Tag>
							</div>
						)}
					>
						{/** NAME */}
						<Column
							onCell={(record) => ({
								onClick: () => {
									onCellClick(record);
								},
							})}
							title={t("userManagement.name")}
							dataIndex='first_name'
							key='first_name'
							width='200px'
							filterIcon={<FontAwesomeIcon icon={faSearch} />}
							filterDropdown={() => (
								<FilterSearchText
									placeHolder={t("general.name")}
									filterValue={filterName}
									setFilterValue={setFilterName}
									refreshTable={refreshTable}
									refreshParameters={[]}
								/>
							)}
							render={(text, record: any) => (
								<div className='generalStyles__ellipsis__200px'>
									{record.first_name || ""} {record.middle_name || ""}{" "}
									{record.last_name || ""}
								</div>
							)}
						/>
						{/** EMAIL */}
						<Column
							onCell={(record) => ({
								onClick: () => {
									onCellClick(record);
								},
							})}
							title={t("userManagement.email")}
							dataIndex='email'
							key='email'
							filterIcon={<FontAwesomeIcon icon={faSearch} />}
							filterDropdown={() => (
								<FilterSearchText
									placeHolder={t("userManagement.email")}
									filterValue={filterEmail}
									setFilterValue={setFilterEmail}
									refreshTable={refreshTable}
									refreshParameters={[]}
								/>
							)}
							render={(text) => <div>{text || "--"}</div>}
						/>
						{/** ORGANIZATION */}
						<Column
							onCell={(record) => ({
								onClick: () => {
									onCellClick(record);
								},
							})}
							title={t("organizationManagement.organization")}
							dataIndex='org'
							key='org'
							filterIcon={<FontAwesomeIcon icon={faFilter} />}
							filterDropdown={() => (
								<FilterSelectOrganization
									placeHolder={t("organizationManagement.organization")}
									setFilterValue={setFilterOrganizationName}
									filterValue={filterOrganizationName}
									refreshTable={refreshTable}
									refreshParameters={[]}
								/>
							)}
							render={(text) => <div>{text.name || "--"}</div>}
						/>
						{/** ROLE */}
						<Column
							onCell={(record) => ({
								onClick: () => {
									onCellClick(record);
								},
							})}
							title={t("userManagement.role")}
							dataIndex='role'
							key='role'
							render={(text) => <div>{text.name || "--"}</div>}
						/>
						{/** STATUS */}
						<Column
							onCell={(record) => ({
								onClick: () => {
									onCellClick(record);
								},
							})}
							title={t("userManagement.status")}
							dataIndex='status'
							key='status'
							className=''
							filterIcon={<FontAwesomeIcon icon={faFilter} />}
							filterDropdown={() => (
								<FilterSelectUserStatus
									placeHolder={t("general.status")}
									setFilterValue={setFilterUserStatus}
									filterValue={filterUserStatus}
									refreshTable={refreshTable}
									refreshParameters={[]}
								/>
							)}
							render={(text) => <TableColumnStatusUser text={text} />}
						/>
						{/** ACTIONS */}
						<Column
							title={t("userManagement.actions")}
							key='action'
							width='100px'
							render={(_: any, record: any) => (
								<UsersTableActions
									selectedItem={record}
									setPswType={setPswType}
								/>
							)}
						/>
					</Table>
				) : (
					<>
						<div className='generalStyles__noAccessToListTable'>
							<Empty
								description={t("userManagement.listNotAvailable")}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						</div>
					</>
				)}
			</>
			{/** ********************************************* */}
			{/** AUTHENTICATION TOPTP TOKEN */}
			<AuthenticationTOTP />
			{/** ********************************************* */}
			{/** MODAL PASSWORD */}
			<UsersModalPsw userPsw={userPsw} />
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostUserPsw}
				isSuccess={isSuccessPostUserPsw}
				requestType='POST'
				error={errorPostUserPsw}
				name='User'
			/>
		</div>
	);
};

export default UsersTable;
