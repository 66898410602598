import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// ANT DESIGN COMPONENTS
import { Empty } from "antd";
// COMPONENTS
import MonitoringLiftEscalatorFullView from "./MonitoringLiftEscalatorFullView";
// INTERFACES
import {
	MonitoringEscalatorsFullViewInterface,
	LiftInterface,
} from "../../../interfaces/EdgeManagement.interface";

function MonitoringEscalatorsFullView({
	layout,
	socketMessage,
	iconDefinitionEscalator,
}: MonitoringEscalatorsFullViewInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************** */}
			{/** LIFTS */}
			{layout && layout.lifts && layout.lifts.length === 0 ? (
				<>
					<Empty
						imageStyle={{ height: 40 }}
						description={
							<span>{t("edgeManagement.lifts.liftsNotAsigned")}</span>
						}
					/>
				</>
			) : (
				<>
					{layout && layout.lifts && (
						<div>
							<div
								className={` generalStyles__flex`}
								style={{ justifyContent: "center" }}
							>
								{layout.lifts.map((lift: LiftInterface) => (
									<MonitoringLiftEscalatorFullView
										lift={lift}
										iconDefinitionEscalator={iconDefinitionEscalator}
										socketMessage={socketMessage}
										layout={layout}
									/>
								))}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default MonitoringEscalatorsFullView;
