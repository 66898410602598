import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tooltip } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// INTERFACES
import { MonitoringHeartBeatInterface } from "../../../interfaces/EdgeManagement.interface";

function MonitoringHeartBeat({ socketMessage }: MonitoringHeartBeatInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [heartBeat, setHeartBeat] = useState<number>(0);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	useEffect(() => {
		if (socketMessage) {
			if (
				socketMessage.heartbeat &&
				socketMessage.heartbeat >= 0 &&
				socketMessage.heartbeat !== heartBeat
			) {
				setHeartBeat(socketMessage.heartbeat);
			}
		}
	}, [socketMessage]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__mrFix'>
			{heartBeat === 0 && (
				<Tooltip title={t("monitoring.noTcpConnection")}>
					<FontAwesomeIcon icon={faCircle} className='generalStyles__error' />
				</Tooltip>
			)}
			{heartBeat === 1 && (
				<Tooltip title={t("monitoring.lowTcpConnection")}>
					<FontAwesomeIcon icon={faCircle} className='' />
				</Tooltip>
			)}
			{heartBeat === 2 && (
				<Tooltip title={t("monitoring.mediumTcpConnection")}>
					<FontAwesomeIcon icon={faCircle} className='generalStyles__warning' />
				</Tooltip>
			)}
			{heartBeat === 3 && (
				<Tooltip title={t("monitoring.highTcpConnection")}>
					<FontAwesomeIcon icon={faCircle} className='generalStyles__success' />
				</Tooltip>
			)}
		</div>
	);
}

export default MonitoringHeartBeat;
