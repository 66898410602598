import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
import CustomAmplifyCarousel from "../login/CustomAmplifyCarousel";

function SlaPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='loginStyles__body'>
			{/** ************************************************** */}
			<div className='loginStyles__loginSubBox1 d-none d-sm-block'>
				<CustomAmplifyCarousel />
			</div>
			<div className='loginStyles__loginSubBox2'>
				<div
					style={{
						textAlign: "justify",
						overflowY: "scroll",
						height: "100%",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent: "flex-end",
							padding: "30px",
						}}
					>
						<div>
							<Button
								style={{
									backgroundColor: "#969696",
									color: "white",
								}}
								shape='circle'
								icon={<FontAwesomeIcon icon={faX} />}
								onClick={() => {
									window.location.href = "/login";
								}}
							/>
						</div>
					</div>
					<div style={{ padding: "0px 120px 50px 120px" }}>
						{/** ************************************************** */}
						{/** LIFNET EULA TEMPLATE PAGE 1 */}
						<h2>LiftNet Service, Support, and Training Policy</h2>
						<p>Last Updated Date: [10 October 2023]</p>
						<span className='loginStyles__underLiying'>Technical Support</span>
						<p>
							At Hyperion Solutions we pride ourselves on offering world-class
							service and support to our customers. We are proud of our
							products, our service and support, and our dedication to the
							customer. At the end of the day, it is the Hyperion LiftNet name,
							and it is our duty to ensure that every customer’s needs are met,
							if not exceeded.
						</p>
						<p>
							Our support system can be accessed via email or by telephone. Our
							hours of operation are
						</p>
						<br />
						<h5 className='generalStyles__flexCenter'>
							Monday through Friday, from 8:30AM to 5:00PM CST
						</h5>
						<p>
							The most efficient way to communicate with our service department,
							is by using the liftnetservice@hyperion-solutions.com email
							address.
						</p>
						<p>
							Your email request will be visible to all service personnel, as it
							is part of a distribution list. The service inbox is also manned
							by our Customer Service Representative(s), who in turn ‘dispatch’
							calls to the first tech/engineer that is available, as/if
							necessary.
						</p>
						<p>
							You can also call our service department directly. For phone
							support, call (847) 475-2476 to speak with a Customer Service
							Representative
						</p>
						<p>
							It is essential that you leave a message if you do not talk to
							someone. Your voicemail should include your name, your company,
							the best phone number you can be reached at, and a description of
							the problem you are experiencing, any error code, system behavior,
							part number verification, parts availability, and ordering, etc.
						</p>
						<p>
							You will receive a call back, during our hours of operation, from
							one of our customer service representatives, provided at no charge
							for the lifetime of your LiftNet product - guaranteed. If the
							reason for your call cannot be addressed and resolved on that call
							back, your call will be escalated to one of our factory trained
							engineers.
						</p>
						<p>
							Please refrain from calling our technicians and engineers
							directly, via their cell or office phones, to report an issue.
							They are often on the road, training customers and integrators, or
							addressing service issues. Calling them directly may/will result
							in delays to response times, and issue resolution. For the
							promptest resolution to all your service needs, please use our
							service email, or call our service line.
						</p>
						{/** ************************************************** */}
						{/** LIFNET EULA TEMPLATE PAGE 2 */}
						<span className='loginStyles__underLiying'>
							Requesting Remote Application and Technical Support
						</span>
						<p>
							The first step in requesting remote service or application support
							is contacting our service team. Our customer service
							representative will evaluate your case and process your request.
							Customers requesting remote technical support and assistance will
							be contacted within 4 business hours by an engineer or technician.
							Additional support will be arranged as applicable utilizing
							telephone, technical documents, and remote diagnosis via
							TeamViewer (or similar), and/or shipment of replacement/test
							components. Any non-warrantied components forwarded for testing by
							the customer will require an RMA and/or Purchase Order and
							shipping arrangements.
						</p>
						<span className='loginStyles__underLiying'>
							Requesting On-site Application and Technical Support
						</span>
						<p>
							The first step in requesting on-site service or application
							support is calling our service line. Our customer service
							representative will evaluate your case and process your request.
							Customers requesting on-site support from LiftNet engineers will
							be contacted within 4 hours by an engineer or technician. The
							possibility of remote support will be explored as a timelier
							solution to the customer’s problem. If on-site support is
							determined to be the best solution, an engineer will be assigned
							and will make all necessary travel arrangements. Any non-
							warrantied components required for on-site testing will require an
							RMA and standard Purchase Order and shipping arrangements. Any
							customer requesting on-site support will be advised that Express
							Onsite Support is also available. No non-warranty travel can/will
							be booked without an appropriate Purchase Order (PO).
						</p>
						<span className='loginStyles__underLiying'>
							Express On-site Application and Technical Support
						</span>
						<p>
							Express On-Site Application and Technical Support is available at
							the request of any customer. Any customer requesting on-site
							support will be advised that Express On-Site Application and
							Technical Support is available subject to a surcharge. An Express
							On-Site Application and Technical Support Surcharge of $2500.00
							per day will apply. Express On-Site Application and
							Technical support will have an engineer at the customer site
							within two days (48 hr.) except due to travel delays or
							interruptions. Customers requesting on-site support by a LiftNet
							engineer will be contacted within 4 hours by an engineer or
							technician. An engineer will be assigned, and the engineer will
							make the necessary travel arrangements. Any non-warrantied
							components required for testing will require an RMA and a standard
							Purchase Order and shipping arrangements. No non-warranty travel
							can/will be booked without an appropriate Purchase Order (PO).
						</p>
						<br />
						<div>
							<Button
								onClick={() => {
									window.location.href = "/login";
								}}
								className='buttonStyle__6'
							>
								Back To Login
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SlaPage;
