import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// MUI CHARTS
import { BarChart, BarChartProps } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { HighlightItemData } from "@mui/x-charts/context";
import Box from "@mui/material/Box";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong, faUpLong } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// CSS CUSTOM STYLES
import {
	getChartBackgroundColor,
	getChartTextColor,
	getChartTicklineColor,
	generateBarsDataFault,
	getChartAxisHighlight,
	getAxisLabels,
} from "./utils";
// INTERFACES
import { RootState } from "../../../app/store";

function ChartBar({
	data,
	isLoading,
	bottomLabel,
	leftLabel,
	topLabel,
	topLabelIcon,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [highlightedItem, setHighLightedItem] =
		useState<HighlightItemData | null>(null);
	const [labels, setLabels] = useState<string[]>([]);
	const [seriesData, setSeriesData] = useState<any>([]);
	const [seriesDataStack, setSeriesDataStack] = useState<any>([]);
	const [layout, setLayout] = useState<"vertical" | "horizontal">("horizontal");
	const [cardHeight, setCardHeight] = useState<number>(450);
	const [localSelectedCarList, setLocalCarList] = useState<any>([]);
	const [chartLabel, setChartLabel] = useState("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	// eslint-disable-next-line no-undef
	const chartSettingsH: Partial<BarChartProps> = {
		yAxis: [
			{
				id: "barCategoriesY",
				data: labels,
				scaleType: "band",
				tickLabelStyle: {
					fontSize: 12,
				},
				tickLabelPlacement: "middle",
				tickPlacement: "middle",
			},
		],
	};
	// eslint-disable-next-line no-undef
	const chartSettingsV: Partial<BarChartProps> = {
		xAxis: [
			{
				id: "barCategoriesX",
				data: labels,
				scaleType: "band",
				tickLabelStyle: {
					fontSize: 12,
				},
				tickLabelPlacement: "middle",
				tickPlacement: "middle",
			},
		],
		yAxis: undefined,
	};

	const getCharNoDataMessage = () => {
		if (filters.carsList.length === 0) {
			return "general.noData";
		}
		if (localSelectedCarList.length === 0) {
			return "general.selectSomeCar";
		}
		if (data && data.length === 0) {
			return "general.monthHasNoData";
		}
		return "general.noData";
	};

	const arraysAreEqual = (arr1: any, arr2: any) => {
		if (arr1.length !== arr2.length) {
			return false;
		}

		return arr1.every((value: any, index: any) => value === arr2[index]);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (localSelectedCarList && localSelectedCarList.length > 0 && data) {
			let faultType = filters.chartFaultType.toLowerCase();
			if (filters.chartFaultType === "LIFT") {
				faultType = "fault";
			}
			setSeriesData(
				generateBarsDataFault(
					data,
					false,
					localSelectedCarList,
					faultType,
					filters.chartMirror
				)
			);
			setSeriesDataStack(
				generateBarsDataFault(
					data,
					true,
					localSelectedCarList,
					faultType,
					filters.chartMirror
				)
			);
			const responseLabels = getAxisLabels(data, faultType);
			setLabels(responseLabels);
			if (
				filters.chartFaultType === "DAY" &&
				responseLabels &&
				responseLabels[0] &&
				responseLabels[0] !== ""
			) {
				setChartLabel(
					moment(responseLabels[0], "DD/MM").format("MMMM").toUpperCase()
				);
			} else if (
				filters.chartFaultType === "FAULT" ||
				filters.chartFaultType === "POSITION" ||
				filters.chartFaultType === "LIFT" ||
				filters.chartFaultType === "FLOOR"
			) {
				setChartLabel(
					`${moment(filters.startDate).format("MMMM Do YYYY")} / ${moment(
						filters.endDate
					).format("MMMM Do YYYY")}`
				);
			}
		} else {
			setSeriesData([]);
			setSeriesDataStack([]);
			setChartLabel("");
			setLabels(getAxisLabels(data, "fault"));
		}
	}, [localSelectedCarList, data, filters.chartMirror]);

	useEffect(() => {
		if (
			filters.chartType === "bar_vertical" ||
			filters.chartType === "bar_stack_vertical"
		) {
			setLayout("vertical");
			setCardHeight(450);
		} else if (
			filters.chartType === "bar_horizontal" ||
			filters.chartType === "bar_stack_horizontal"
		) {
			setLayout("horizontal");
			setCardHeight(labels.length * 50);
		}
	}, [filters.chartType]);

	useEffect(() => {
		if (!arraysAreEqual(filters.selectedCarsList, localSelectedCarList)) {
			setLocalCarList(filters.selectedCarsList);
		}
	}, [filters.selectedCarsList]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Box sx={{ width: "100%", padding: "10px" }}>
			<div
				className='generalStyles__width100 generalStyles__textCenter'
				style={{
					fontSize: "20px",
					fontWeight: "bolder",
					backgroundColor: `${getChartBackgroundColor(theme)}`,
					color: `${getChartTextColor(theme)}`,
					paddingTop: "20px",
					paddingBottom: "5px",
				}}
			>
				<span>{topLabel}</span>
				{topLabelIcon === "UP" && (
					<FontAwesomeIcon
						icon={faUpLong}
						fontSize={23}
						className='generalStyles__mlFix generalStyles__success'
					/>
				)}
				{topLabelIcon === "DOWN" && (
					<FontAwesomeIcon
						icon={faDownLong}
						fontSize={23}
						className='generalStyles__mlFix generalStyles__error'
					/>
				)}
			</div>
			<Spin spinning={isLoading}>
				<div className='generalStyles__flex'>
					<div
						style={{
							fontSize: "20px",
							fontWeight: "bolder",
							backgroundColor: `${getChartBackgroundColor(theme)}`,
							textAlign: "center",
							color: `${getChartTextColor(theme)}`,
							writingMode: "vertical-rl",
							transform: "scale(-1)",
							marginLeft: "-1px",
							paddingRight: "25px",
						}}
					>
						<span>
							{filters.chartType === "bar_horizontal" ||
							filters.chartType === "bar_stack_horizontal"
								? bottomLabel
								: leftLabel}
						</span>
					</div>
					<BarChart
						loading={isLoading}
						axisHighlight={{ x: "line", y: "line" }}
						slotProps={{
							legend: {
								direction: "row",
								position: { vertical: "top", horizontal: "middle" },
								labelStyle: {
									fontSize: 14,
									fill: `${getChartTextColor(theme)}`,
								},
							},
							// Custom loading message
							loadingOverlay: { message: "Loading..." },
							// Custom message for empty chart
							noDataOverlay: { message: t(`${getCharNoDataMessage()}`) },
						}}
						sx={{
							[`.${axisClasses.root}`]: {
								[`.${axisClasses.tick}, .${axisClasses.line}`]: {
									stroke: `${getChartTicklineColor(theme)}`,
									strokeWidth: 3,
								},
								[`.${axisClasses.tickLabel}`]: {
									fill: `${getChartTextColor(theme)}`,
								},
							},
							[`.MuiBarLabel-root	`]: {
								stroke: "white",
							},
							[`.MuiChartsAxisHighlight-root `]: {
								stroke: `${getChartAxisHighlight(theme)}`,
							},
							[`.css-1f57y8b `]: {
								fill: `${getChartTextColor(theme)}`,
							},
							backgroundColor: `${getChartBackgroundColor(theme)}`,
						}}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...(layout === "vertical" ? chartSettingsV : chartSettingsH)}
						series={
							filters.chartType === "bar_stack_vertical" ||
							filters.chartType === "bar_stack_horizontal"
								? seriesDataStack
								: seriesData
						}
						barLabel='value'
						height={cardHeight}
						tooltip={{ trigger: "item" }}
						highlightedItem={highlightedItem}
						onHighlightChange={setHighLightedItem}
						layout={layout}
					/>
				</div>
				<div
					className='generalStyles__width100 generalStyles__textCenter'
					style={{
						fontSize: "20px",
						fontWeight: "bolder",
						backgroundColor: `${getChartBackgroundColor(theme)}`,
						color: `${getChartTextColor(theme)}`,
						paddingBottom: "10px",
						marginTop: "-5px",
					}}
				>
					<span>
						{filters.chartType === "bar_horizontal" ||
						filters.chartType === "bar_stack_horizontal"
							? leftLabel
							: bottomLabel}
					</span>
					<br />
					<span className='generalStyles__info'>{chartLabel}</span>
				</div>
			</Spin>
		</Box>
	);
}

export default ChartBar;
