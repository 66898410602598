import React, { useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateConfigurationFileBreadcrumb,
	updateShowSpecialSwitches,
} from "../../../redux/configurationFiles/configurationFilesSlice";
// COMPONENTS
import OffsetsTable from "./OffsetsTable";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const DefinitionsPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		dispatch(updateShowSpecialSwitches(false));
		dispatch(updateConfigurationFileBreadcrumb([]));
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("offsets", permissions) ? (
				<div>
					{/** HEADER AND STEPER */}
					<div className={`layout__contentPage__${theme}`}>
						<OffsetsTable />
					</div>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default DefinitionsPage;
