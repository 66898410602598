import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Divider, Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEthernet,
	faPlugCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateOpenModalCreateNewChannel } from "../../../redux/channels/channelSlice";
import {
	usePostChannelMutation,
	usePutChannelMutation,
} from "../../../redux/channels/channelAPI";
// COMPONENTS
import ServersAssignServer from "../servers/ServersAssignServer";
import FormInputText from "../../components/form/FormInputText";
import FormInputNumber from "../../components/form/FormInputNumber";
import FormInputIPandPort from "../../components/form/FormInputIPandPort";
import FormSwitchActiveInactive from "../../components/form/FormSwitchActiveInactive";
import FormSelect from "../../components/form/FormSelect";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { validateIpAndPortString } from "../../../utils/utils";
// INTERFACES
import { ChannelInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function ChannelsCreateEditForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const BLANK_CHANNEL: ChannelInterface = {
		id: "",
		active: true,
		index: "1",
		name: "/dev/ttyS",
		server_id: "",
		type: "SERIAL",
		baud_rate: 1,
		channel_id: "",
		heartbeat_frequency: 1,
		reporting_socket_address: "",
		socket_address: "",
		vendor_socket_address: "",
		message_type: "LN-PRTCL",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [channel, setChannel] = useState<ChannelInterface>(BLANK_CHANNEL);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [serverId, setServerId] = useState<string>("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const {
		openModalCreateNewChannel,
		formCreateEdit,
		channel: selectedChannel,
	} = useSelector((state: RootState) => state.channel);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postChannel,
		{
			isSuccess: isSuccessPostChannel,
			isLoading: isLoadingPostChannel,
			isError: isErrorPostChannel,
			error: errorPostChannel,
			reset: resetPostChannel,
		},
	] = usePostChannelMutation();

	const [
		putChannel,
		{
			isSuccess: isSuccessPutChannel,
			isLoading: isLoadingPutChannel,
			isError: isErrorPutChannel,
			error: errorPutChannel,
			reset: resetPutChannel,
		},
	] = usePutChannelMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	/**
	 * If channel type change, the full form is reset to empty values
	 */
	const onChangeType = (newValue: any, inputName: string) => {
		if (inputName === "type") {
			showIsLoading();
			setChannel({
				id: "",
				active: true,
				index: "1",
				name: newValue === "SERIAL" ? "/dev/ttyS" : "",
				server_id: "",
				type: newValue,
				baud_rate: 1,
				channel_id: "",
				heartbeat_frequency: 1,
				reporting_socket_address: "",
				socket_address: "",
				vendor_socket_address: "",
				message_type: "LN-PRTCL",
			});
		}
	};

	/**
	 * If some form value change, dinac change channel value
	 */
	const onChangeFormValue = (newValue: any, inputName: string) => {
		const copy: any = structuredClone(channel);
		copy[inputName] = newValue;
		setChannel(copy);
	};

	const channelSerialIsValid = () => {
		let isValid = false;
		if (
			channel.index !== "" &&
			channel.name !== "" &&
			channel.baud_rate > 0 &&
			serverId !== ""
		) {
			isValid = true;
		}
		return isValid;
	};

	const channelTcpIsValid = () => {
		let isValid = false;
		if (
			channel.index !== "" &&
			channel.name !== "" &&
			channel.baud_rate > 0 &&
			validateIpAndPortString(channel.socket_address || "") &&
			serverId !== ""
		) {
			isValid = true;
		}
		return isValid;
	};

	const channelTcpBotIsValid = () => {
		let isValid = false;
		if (
			channel.index !== "" &&
			channel.name !== "" &&
			validateIpAndPortString(channel.socket_address || "") &&
			serverId !== ""
		) {
			isValid = true;
		}
		return isValid;
	};

	const channelAdapterIsValid = () => {
		let isValid = false;
		if (
			channel.index !== "" &&
			channel.name !== "" &&
			validateIpAndPortString(channel.socket_address || "") &&
			validateIpAndPortString(channel.vendor_socket_address || "") &&
			validateIpAndPortString(channel.reporting_socket_address || "") &&
			(channel.heartbeat_frequency ? channel.heartbeat_frequency > 0 : true) &&
			serverId !== ""
		) {
			isValid = true;
		}
		return isValid;
	};

	const checkFormIsValid = () => {
		let formIsValid = false;
		if (channel.type === "SERIAL") {
			formIsValid = channelSerialIsValid();
		} else if (channel.type === "TCP") {
			formIsValid = channelTcpIsValid();
		} else if (channel.type === "TCP_BOT") {
			formIsValid = channelTcpBotIsValid();
		} else if (channel.type === "ADAPTER") {
			formIsValid = channelAdapterIsValid();
		}
		return formIsValid;
	};

	const onClickClose = () => {
		dispatch(updateOpenModalCreateNewChannel(false));
		resetPostChannel();
		setServerId("");
		setChannel(BLANK_CHANNEL);
		resetPutChannel();
	};

	const postNewChannel = async () => {
		const token = await GETJwtToken();
		const BODY: any = {
			active: channel.active,
			banks: [],
			banks2delete: [],
			index: channel.index,
			name: channel.name,
			type: channel.type,
			message_type: channel.message_type,
			server_id: serverId,
		};
		if (channel.type === "SERIAL") {
			BODY.baud_rate = channel.baud_rate;
		} else if (channel.type === "TCP") {
			BODY.baud_rate = channel.baud_rate;
			BODY.socket_address = channel.socket_address;
		} else if (channel.type === "TCP_BOT") {
			BODY.baud_rate = channel.baud_rate;
			BODY.socket_address = channel.socket_address;
		} else if (channel.type === "ADAPTER") {
			BODY.baud_rate = 0;
			BODY.port_number = 0;
			BODY.socket_address = channel.socket_address;
			BODY.vendor_socket_address = channel.vendor_socket_address;
			BODY.reporting_socket_address = channel.reporting_socket_address;
			BODY.heartbeat_frequency = channel.heartbeat_frequency;
		}
		postChannel({
			token,
			body: BODY,
		});
	};

	const putOldChannel = async () => {
		const token = await GETJwtToken();
		const copy = structuredClone(channel);
		delete copy.banks;
		putChannel({ token, body: copy, id: channel.id });
	};

	const onClickSave = async () => {
		if (formCreateEdit === "create") {
			postNewChannel();
		} else if (formCreateEdit === "edit") {
			putOldChannel();
		}
	};

	const fillEditFormData = () => {
		setChannel(selectedChannel);
		setServerId(selectedChannel.server_id);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (formCreateEdit === "edit") {
			fillEditFormData();
		}
		showIsLoading();
	}, [openModalCreateNewChannel]);

	useEffect(() => {
		onClickClose();
	}, [
		isSuccessPostChannel,
		isErrorPostChannel,
		isSuccessPutChannel,
		isErrorPutChannel,
	]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openModalCreateNewChannel}
		>
			<Spin spinning={isLoadingPostChannel || isLoading || isLoadingPutChannel}>
				<div>
					{/** ------------------------------------------------------------- */}
					{/** GLOBAL DATA  */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faEthernet}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{formCreateEdit === "edit" ? (
									<> {t("edgeManagement.channels.editChannel")}</>
								) : (
									<> {t("edgeManagement.channels.createChannel")}</>
								)}
							</h5>
						</Divider>
					</div>
					{/** ------------------------------------------------------------- */}
					{/** CHANNEL FORM  */}
					<div className='container'>
						<div className={`drawer__box__${theme} row`}>
							{/** CHANNEL TYPE  */}
							<FormSelect
								inputLabelName={t("edgeManagement.lifts.type")}
								inputValue={channel.type}
								inputOnChange={onChangeType}
								inputVariableName='type'
								inputOptions={["SERIAL", "TCP", "TCP_BOT", "ADAPTER"]}
							/>
							{/** ******************************************** */}
							{/** COMPONENTS FORM - INPUT INDEX */}
							<FormInputText
								inputValue={channel.index}
								inputLabelName='Index'
								inputOnChange={onChangeFormValue}
								inputVariableName='index'
							/>
							{/** ******************************************** */}
							{/** COMPONENTS FORM - INPUT NAME */}
							<FormInputText
								inputValue={channel.name}
								inputLabelName={t("general.name")}
								inputOnChange={onChangeFormValue}
								inputVariableName='name'
							/>
							{/** ******************************************** */}
							{/** COMPONENTS FORM - SELECT MESSAGE TYPE */}
							<FormSelect
								inputLabelName={t("edgeManagement.channels.messageType")}
								inputValue={channel.message_type}
								inputOnChange={onChangeFormValue}
								inputVariableName='message_type'
								inputOptions={["LN-PRTCL", "LN2-PRTCL"]}
							/>
							{/** ******************************************** */}
							{/** COMPONENTS FORM - SWITCH ACTIVE / INACTIVE */}
							<FormSwitchActiveInactive
								inputValue={channel.active}
								inputLabelName={t("general.status")}
								inputOnChange={onChangeFormValue}
								inputVariableName='active'
							/>
						</div>
					</div>
					<br />
					{/** ------------------------------------------------------------- */}
					{/** SERIAL , ADAPTER AND TCP DATA  */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faPlugCircleCheck}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{channel.type === "SERIAL" && "Serial"}
								{channel.type === "TCP" && "TCP"}
								{channel.type === "ADAPTER" && "Adapter"}
								{channel.type === "TCP_BOT" && "TCP Bot"}
							</h5>
						</Divider>
					</div>
					<div className='container'>
						<div className={`drawer__box__${theme} row`}>
							{/** ******************************************** */}
							{/** COMPONENTS FORM - INPUT BAUD RATE */}
							{(channel.type === "SERIAL" ||
								channel.type === "TCP" ||
								channel.type === "TCP_BOT") && (
								<FormInputNumber
									inputValue={channel.baud_rate}
									inputLabelName={t("edgeManagement.servers.baudRate")}
									inputOnChange={onChangeFormValue}
									inputVariableName='baud_rate'
								/>
							)}
							{/** ******************************************** */}
							{/** COMPONENTS FORM - INPUT SOCKET ADDRESS */}
							{(channel.type === "ADAPTER" ||
								channel.type === "TCP" ||
								channel.type === "TCP_BOT") && (
								<FormInputIPandPort
									inputValue={
										channel.socket_address ? channel.socket_address : ""
									}
									inputLabelName={t("edgeManagement.channels.socketAddress")}
									inputOnChange={onChangeFormValue}
									inputVariableName='socket_address'
								/>
							)}
							{/** ******************************************** */}
							{/** COMPONENTS FORM - INPUT VENDOR SOCKET ADDRESS */}
							{channel.type === "ADAPTER" && (
								<FormInputIPandPort
									inputValue={
										channel.vendor_socket_address
											? channel.vendor_socket_address
											: ""
									}
									inputLabelName={t(
										"edgeManagement.channels.vendorSocketAddress"
									)}
									inputOnChange={onChangeFormValue}
									inputVariableName='vendor_socket_address'
								/>
							)}
							{/** ******************************************** */}
							{/** COMPONENTS FORM - INPUT REPORTING SOCKET ADDRESS */}
							{channel.type === "ADAPTER" && (
								<FormInputIPandPort
									inputValue={
										channel.reporting_socket_address
											? channel.reporting_socket_address
											: ""
									}
									inputLabelName={t(
										"edgeManagement.channels.reportingSocketAddress"
									)}
									inputOnChange={onChangeFormValue}
									inputVariableName='reporting_socket_address'
								/>
							)}
							{/** ******************************************** */}
							{/** COMPONENTS FORM - INPUT HEART BEAT FREQUENCY */}
							{channel.type === "ADAPTER" && (
								<FormInputNumber
									inputValue={
										channel.heartbeat_frequency
											? channel.heartbeat_frequency
											: 0
									}
									inputLabelName={t(
										"edgeManagement.channels.heartbeatFrequency"
									)}
									inputOnChange={onChangeFormValue}
									inputVariableName='heartbeat_frequency'
								/>
							)}
						</div>
					</div>
					<br />
					{/** ------------------------------------------------------------- */}
					{/** SERVER  */}
					<ServersAssignServer
						serverId={serverId}
						setServerId={setServerId}
						disableComponent={formCreateEdit === "edit"}
					/>
				</div>
				{/** ---------------------------------------------------- */}
				{/** CLOSE AND SAVE BUTTON */}
				<div className='mt-4'>
					<div>
						<Button
							type='default'
							onClick={onClickClose}
							className='buttonStyle__3'
						>
							{t("edgeManagement.close")}
						</Button>
						<Button
							type='primary'
							className='buttonStyle__3'
							onClick={onClickSave}
							disabled={!checkFormIsValid()}
						>
							{t("general.save")}
						</Button>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostChannel}
				isSuccess={isSuccessPostChannel}
				requestType='POST'
				error={errorPostChannel}
				name='Channel'
			/>
			<GlobalAlert2
				isError={isErrorPutChannel}
				isSuccess={isSuccessPutChannel}
				requestType='PUT'
				error={errorPutChannel}
				name='Channel'
			/>
		</Drawer>
	);
}

export default ChannelsCreateEditForm;
