import React from "react";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// INTERFACES
import { TableActionsMenuItemInterface } from "../../../interfaces/Components.interface";

function TableActionsMenuItem({
	icon,
	text,
	onClick,
}: TableActionsMenuItemInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className='generalStyles__tableActionBox'
			role='button'
			tabIndex={0}
			onClick={() => {
				onClick();
			}}
			onKeyDown={() => {
				onClick();
			}}
		>
			<span>
				<FontAwesomeIcon
					className='generalStyles__info generalStyles__mrFix'
					icon={icon}
				/>
				{text}
			</span>
		</div>
	);
}

export default TableActionsMenuItem;
