import React from "react";
import { Route, Routes } from "react-router-dom";
// COMPONENTS
import LoginPage from "../pages/login/LoginPage";
import LoginOfflinePage from "../pages/loginOffline/LoginOfflinePage";
import NotFoundPage from "../pages/unauthorized/NotFoundPage";
import Layout from "../pages/home/Layout";
import MssaPage from "../pages/termsAndConditions/MssaPage";
import EulaPage from "../pages/termsAndConditions/EulaPage";
import SlaPage from "../pages/termsAndConditions/SlaPage";
import SupportPolicy from "../pages/termsAndConditions/SupportPolicy";
// PRIVATE ROUTE
import PrivateRoute from "./PrivateRoute";

export default function AppRouter() {
	return (
		<>
			<Routes>
				{/* *************** PRIVATE ROUTES *************** */}
				<Route path='/' element={<PrivateRoute Component={Layout} />} />
				<Route
					path='/monitoring/*'
					element={<PrivateRoute Component={Layout} />}
				/>
				<Route
					path='/map-view/*'
					element={<PrivateRoute Component={Layout} />}
				/>
				<Route
					path='/organization-management/*'
					element={<PrivateRoute Component={Layout} />}
				/>
				<Route
					path='/users-management/*'
					element={<PrivateRoute Component={Layout} />}
				/>
				<Route
					path='/reports/*'
					element={<PrivateRoute Component={Layout} />}
				/>
				<Route path='/tools/*' element={<PrivateRoute Component={Layout} />} />
				<Route
					path='/edge-management/*'
					element={<PrivateRoute Component={Layout} />}
				/>
				{/* *************** PUBLIC ROUTES *************** */}
				<Route path='/login' element={<LoginPage />} />
				<Route path='/login-offline' element={<LoginOfflinePage />} />
				<Route path='/terms/mssa' element={<MssaPage />} />
				<Route path='/terms/eula' element={<EulaPage />} />
				<Route path='/terms/sla' element={<SlaPage />} />
				<Route path='/terms/supportpolicy' element={<SupportPolicy />} />
				<Route path='*' element={<NotFoundPage />} />
			</Routes>
		</>
	);
}
