import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Button, InputNumber, Tooltip, Table, Drawer, Divider } from "antd";
// I18N TRANSLATION
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faCircleExclamation,
	faTable,
	faWarning,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateShowSpecialSwitches } from "../../../redux/configurationFiles/configurationFilesSlice";
import { useLazyGetAllSpecialSwitchesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

const { Column } = Table;

function SpecialSwitchesTable({
	showLoading,
	byteCount,
	setSpecialSwitches,
	definitionBytes,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const dispatch = useDispatch();
	const [specialSwitches, setSpecialWitches] = useState<any>([]);
	const [maxBytes, setMaxBytes] = useState(0);
	const [maxBits, setMaxBits] = useState(0);
	const [dataIsValid, setDataIsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { showSpecialSwitches } = useSelector(
		(state: any) => state.configurationFile
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	const [
		triggerGetSpecialSwitches,
		{ data: dataSpecialSwitches, isLoading: isLoadingGetSpecialSwitchesData },
	] = useLazyGetAllSpecialSwitchesQuery();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onClickClose = () => {
		dispatch(updateShowSpecialSwitches(false));
	};

	const buildSpecialSwitchesObj = (data_: any, totalBits: number) => {
		// Build initial object
		const newObj: any = [];
		if (data_ && data_.length > 0) {
			data_.map((item: any, index: number) => {
				newObj.push({
					index,
					id: item.id,
					specialSwitchName: item.message,
					specialSwitchShortName: item.short_message,
					byte: 0,
					bit: 0,
					status: "Not Used",
					highlight: false,
				});
				return true;
			});
		}
		// Populate previous data
		for (let index = 0; index < byteCount; index += 1) {
			for (let index2 = 0; index2 < totalBits; index2 += 1) {
				if (
					definitionBytes[index].bits[index2].specialSwitchId &&
					definitionBytes[index].bits[index2].specialSwitchName !== ""
				) {
					const index3 = newObj.findIndex(
						(x: any) =>
							x.id === definitionBytes[index].bits[index2].specialSwitchId
					);
					newObj[index3].status = "Valid";
					newObj[index3].byte = definitionBytes[index].bits[index2].byte;
					newObj[index3].bit = definitionBytes[index].bits[index2].bit;
				}
			}
		}
		setSpecialWitches(newObj);
	};

	const validateSpecialSwitch = (newArr_: any) => {
		let isValid = true;
		const newArr = [...newArr_];
		for (let index = 0; index < newArr.length; index += 1) {
			// Check if special switch is NOT USED OR VALID
			if (newArr[index].byte === 0 && newArr[index].bit === 0) {
				newArr[index].status = "Not Used";
			} else {
				newArr[index].status = "Valid";
			}
			// Check if special switch is not valid
			for (let index2 = 0; index2 < newArr.length; index2 += 1) {
				if (
					newArr[index].byte === newArr[index2].byte &&
					newArr[index].bit === newArr[index2].bit &&
					index !== index2 &&
					newArr[index].byte > 0 &&
					newArr[index].byte > 0 &&
					newArr[index2].byte > 0 &&
					newArr[index2].byte > 0
				) {
					newArr[index].status = "Not Valid";
					isValid = false;
				}
			}
		}
		setDataIsValid(isValid);
		setSpecialWitches(newArr);
	};

	const onChangeByte = (newValue: number, index: number) => {
		showLoading();
		const newArr = [...specialSwitches];
		newArr[index].byte = newValue;
		if (newValue === 0) {
			newArr[index].bit = 0;
		}
		validateSpecialSwitch(newArr);
	};

	const onChangeBit = (newValue: number, index: number) => {
		showLoading();
		const newArr = [...specialSwitches];
		newArr[index].bit = newValue;
		validateSpecialSwitch(newArr);
	};

	const onClickSave = () => {
		setSpecialSwitches(specialSwitches);
		showLoading();
		dispatch(updateShowSpecialSwitches(false));
	};

	const getInitialData = async () => {
		let totalBits = 7;
		if (byteCount === 1) {
			totalBits = 255;
		}
		showIsLoading();
		setMaxBits(totalBits);
		setMaxBytes(byteCount);

		const token = await GETJwtToken();
		triggerGetSpecialSwitches({
			token,
		});
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (showSpecialSwitches) {
			getInitialData();
		}
	}, [showSpecialSwitches]);

	useEffect(() => {
		if (dataSpecialSwitches && dataSpecialSwitches.data) {
			let totalBits = 7;
			if (byteCount === 1) {
				totalBits = 255;
			}
			buildSpecialSwitchesObj(dataSpecialSwitches.data, totalBits);
		}
	}, [dataSpecialSwitches]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={showSpecialSwitches}
		>
			{/** ---------------------------------------------------- */}
			{/** SPECIAL SWITCHES */}
			<div>
				<Divider orientation='left' className='generalStyles__drawerDivider'>
					<h5>
						<FontAwesomeIcon
							icon={faTable}
							className='generalStyles__info generalStyles__mrFix'
						/>
						{t("configuration.configurationFiles.specialSwitches")}
					</h5>
				</Divider>
			</div>
			{/** ---------------------------------------------------- */}
			{/** CONFIGURATION DATA */}
			<div className={`drawer__box__${theme} row`}>
				<div>
					{/** ---------------------------------------------------- */}
					{/** STATUS BITS TABLE */}
					<Table
						rowClassName={(record, index) => getTableRowClass(index, theme)}
						loading={isLoading || isLoadingGetSpecialSwitchesData}
						dataSource={specialSwitches}
						size='small'
						pagination={{
							defaultPageSize: 15,
						}}
						rowKey={(record) => record.id}
					>
						<Column
							title='Status'
							dataIndex='status'
							key='status'
							className='generalStyles__tableHeaderLink'
							render={(text) => (
								<div className='generalStyles__mlFix25'>
									{text === "Valid" && (
										<Tooltip title={text}>
											<FontAwesomeIcon
												icon={faCheckCircle}
												className='generalStyles__success'
											/>
										</Tooltip>
									)}
									{text === "Not Used" && (
										<Tooltip title={`Special Swtich ${text}`}>
											<FontAwesomeIcon
												icon={faCircleExclamation}
												className='generalStyles__icon'
											/>
										</Tooltip>
									)}
									{text === "Not Valid" && (
										<Tooltip title={`Special Swtich ${text}`}>
											<FontAwesomeIcon
												icon={faWarning}
												className='generalStyles__warning'
											/>
										</Tooltip>
									)}
								</div>
							)}
						/>
						<Column
							title='Short Name'
							dataIndex='specialSwitchShortName'
							key='specialSwitchShortName'
							className='generalStyles__tableHeaderLink'
							render={(text) => (
								<>
									<div>{text}</div>
								</>
							)}
						/>
						<Column
							title='Default Name'
							dataIndex='specialSwitchName'
							key='specialSwitchName'
							className='generalStyles__tableHeaderLink'
							render={(text) => (
								<>
									<div>{text}</div>
								</>
							)}
						/>
						<Column
							title='Byte'
							dataIndex='byte'
							key='byte'
							className='generalStyles__tableHeaderLink'
							width='100px'
							render={(text, record: any) => (
								<InputNumber
									bordered={false}
									style={{ width: "100%" }}
									value={text}
									onChange={(e) => {
										onChangeByte(e, record.index);
									}}
									min={0}
									max={maxBytes}
								/>
							)}
						/>
						<Column
							title='Bit'
							dataIndex='bit'
							key='bit'
							className='generalStyles__tableHeaderLink'
							width='100px'
							render={(text, record: any) => (
								<InputNumber
									bordered={false}
									style={{ width: "100%" }}
									value={text}
									onChange={(e) => {
										onChangeBit(e, record.index);
									}}
									min={0}
									max={maxBits}
									disabled={record.byte === 0}
								/>
							)}
						/>
					</Table>
				</div>
			</div>
			{/** ---------------------------------------------------- */}
			{/** CLOSE AND SAVE BUTTONS */}
			<div className='mt-4'>
				<div>
					<Button
						type='default'
						className='buttonStyle__3'
						onClick={onClickClose}
					>
						{t("general.close")}
					</Button>
					<Button
						className='buttonStyle__3'
						type='primary'
						disabled={!dataIsValid}
						onClick={onClickSave}
					>
						{t("general.save")}{" "}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default SpecialSwitchesTable;
