import React from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterLevelType() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeFilterType = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.levelTypeName = newValue;
		// Reset previous data selected
		copy.organizationName = "";
		copy.organizationId = "";
		copy.serverName = "";
		copy.serverId = "";
		copy.bankName = "";
		copy.bankId = "";
		copy.liftName = "";
		copy.liftId = "";
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Select
				showSearch
				optionFilterProp='children'
				className='generalStyles__width100'
				allowClear={false}
				value={filters.levelTypeName}
				onChange={onChangeFilterType}
				size='small'
				disabled={!filters.displaySelectTypeLevel}
			>
				{[
					{ id: "ORGANIZATIONS", name: "Organizations" },
					{ id: "SERVERS", name: "Servers" },
					{ id: "BANKS", name: "Banks" },
					{ id: "LIFTS", name: "Lifts" },
				].map((item: any) => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))}
			</Select>
		</div>
	);
}

export default ReportsFilterLevelType;
