import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import OffsetTypeConfigurationTable from "./OffsetTypeTable";
import OffsetTypeForm from "./OffsetTypeForm";
import OffsetTypeDrawer from "./OffsetTypeDrawer";
import OffsetTypeDeleteModal from "./OffsetTypeDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

// eslint-disable-next-line arrow-body-style
const OffsetTypePage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("offset-types-catalog", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<OffsetTypeConfigurationTable />
					<OffsetTypeForm />
					<OffsetTypeDrawer />
					<OffsetTypeDeleteModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default OffsetTypePage;
