import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Cascader, Dropdown, Menu, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faList, faStar } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	useLazyGetOrganizationsCascaderQuery,
	useLazyGetFavoriteGridsQuery,
} from "../../../redux/monitoring/monitoringAPI";
import {
	updateCascaderSelectMultipleSelectionOptions,
	updateTopViewLayout,
	updateDetailViewLayout,
	updateStatusViewLayout,
	updateIdGridSelected,
	updateMonitorViewIsLoading,
} from "../../../redux/monitoring/monitoringSlice";
import { updateOrganizationSelectMultipleData } from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// import "antd/dist/antd.min.css";

function OrganizationCascaderSelectMultiple() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { SHOW_CHILD } = Cascader;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [cascaderData, setCascaderData] = useState([]);
	const [cascaderValue, setCascaderValue] = useState<string[]>([]);
	const [listFavoriteGrids, setListFavoriteGrids] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { cascaderSelectMultipleSelectionOptions, refreshPage } = useSelector(
		(state: any) => state.monitoring
	);
	const { theme, userLogged } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetOrganizationsCascader,
		{
			data: dataGetOrganizationsCascader,
			isLoading: isLoadingOrganizationCascader,
			isFetching: isFetchingOrganizationCascader,
		},
	] = useLazyGetOrganizationsCascaderQuery();
	const [
		triggerGetFavoriteGrids,
		{
			data: dataGetFavoriteGrids,
			isLoading: isLoadingFavoriteGrids,
			isFetching: isFetchingFavoriteGrids,
		},
	] = useLazyGetFavoriteGridsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetOrganizationsCascader({ token });
		triggerGetFavoriteGrids({ token });
		if (
			cascaderSelectMultipleSelectionOptions &&
			cascaderSelectMultipleSelectionOptions.length > 0
		) {
			setCascaderValue(cascaderSelectMultipleSelectionOptions);
		}
	};

	const buildCascaderArray = () => {
		if (
			dataGetOrganizationsCascader &&
			dataGetOrganizationsCascader.organizations &&
			dataGetOrganizationsCascader.organizations.length > 0
		) {
			const newArr: any = [];
			// ***************************************
			// BUILD ORGANIZATIONS CASCADER
			dataGetOrganizationsCascader.organizations.map((org: any) => {
				const orgCascader: any = {
					value: org.id,
					label: org.name,
					children: [],
					type: "organization",
				};
				// ***************************************
				// BUILD SERVER CASCADER
				if (org && org.servers && org.servers.length > 0) {
					org.servers.map((serv: any) => {
						const servCascader: any = {
							value: serv.id,
							label: serv.name,
							children: [],
							type: "server",
						};
						// ***************************************
						// BUILD CHANNELS CASCADER
						if (serv && serv.channels && serv.channels.length > 0) {
							serv.channels.map((channel: any) => {
								const channelCascader: any = {
									value: channel.id,
									label: channel.name,
									children: [],
									type: "channel",
								};
								// ***************************************
								// BUILD BANKS CASCADER
								if (channel && channel.banks && channel.banks.length > 0) {
									channel.banks.map((bank: any) => {
										const bankCascader: any = {
											value: bank.id,
											label: bank.name,
											children: [],
											type: "bank",
											idOrganization: org.id,
											idServer: serv.id,
											idChannel: channel.id,
											idBank: bank.id,
											nameOrganization: org.name,
											nameServer: serv.name,
											nameChannel: channel.name,
											typeChannel: channel.type,
											socketUrl: serv.socket_url,
											...bank,
										};
										return channelCascader.children.push(bankCascader);
									});
								}
								return servCascader.children.push(channelCascader);
							});
						}
						return orgCascader.children.push(servCascader);
					});
				}
				return newArr.push(orgCascader);
			});
			setCascaderData(newArr);
		}
	};

	const onChangeCascader = (_: any, selectedOptions: any) => {
		const newArrSelectedOptions: any = [];
		const newArrSelectedBanks: any = [];
		if (selectedOptions) {
			selectedOptions.map((item: any) => {
				// Build cascader value array
				const subArr: any = [];
				item.map((item2: any) => subArr.push(item2.value));
				newArrSelectedOptions.push(subArr);
				// Get all banks selected
				if (item && item.length === 4) {
					newArrSelectedBanks.push(item[3]);
				}
				return true;
			});
		}
		dispatch(updateOrganizationSelectMultipleData(newArrSelectedBanks));
		dispatch(
			updateCascaderSelectMultipleSelectionOptions(newArrSelectedOptions)
		);
		setCascaderValue(newArrSelectedOptions);
	};

	const onClickFavoriteGrids = (grid: any) => {
		const jsonObject = JSON.parse(grid.grid);
		dispatch(updateIdGridSelected(grid.id));
		dispatch(updateTopViewLayout(jsonObject.topViewLayout));
		dispatch(updateDetailViewLayout(jsonObject.detailViewLayout));
		dispatch(updateStatusViewLayout(jsonObject.statusViewLayout));
		dispatch(
			updateOrganizationSelectMultipleData(
				jsonObject.organizationSelectMultipleData
			)
		);
		dispatch(
			updateCascaderSelectMultipleSelectionOptions(
				jsonObject.cascaderSelectMultipleSelectionOptions
			)
		);
		setCascaderValue(jsonObject.cascaderSelectMultipleSelectionOptions);
	};

	const filterFirstGridByOrganization = (gridList: any) => {
		const newArr: any = [];
		gridList.map((grid: any) => {
			let gridIsValid = true;
			grid.organizationSelectMultipleData.map((org: any) => {
				if (org.idOrganization !== userLogged.org.id) {
					gridIsValid = false;
				}
				return true;
			});
			if (gridIsValid) {
				newArr.push(grid);
			}
			return true;
		});
		setListFavoriteGrids(newArr);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (refreshPage) {
			initData();
		}
	}, [refreshPage]);

	useEffect(() => {
		if (dataGetOrganizationsCascader) {
			buildCascaderArray();
		}
	}, [dataGetOrganizationsCascader]);

	useEffect(() => {
		if (dataGetFavoriteGrids && dataGetFavoriteGrids.data) {
			const newData: any = [];
			dataGetFavoriteGrids.data.map((grid: any) => {
				let organizationSelectMultipleData = [];
				if (grid && grid.grid) {
					const parseString = JSON.parse(grid.grid);
					organizationSelectMultipleData =
						parseString.organizationSelectMultipleData;
				}
				newData.push({ ...grid, organizationSelectMultipleData });
				return true;
			});
			if (
				userLogged &&
				userLogged.role &&
				userLogged.role.name !== process.env.REACT_APP_ROLE_MASTER_NAME
			) {
				filterFirstGridByOrganization(newData);
			} else {
				setListFavoriteGrids(newData);
			}
		}
	}, [dataGetFavoriteGrids]);

	useEffect(() => {
		if (userLogged && userLogged.pref_grid_id) {
			if (listFavoriteGrids && listFavoriteGrids) {
				listFavoriteGrids.map((grid: any) => {
					if (grid.id === userLogged.pref_grid_id) {
						onClickFavoriteGrids(grid);
					}
					return true;
				});
			}
		}
	}, [userLogged, listFavoriteGrids]);

	useEffect(() => {
		if (
			isLoadingFavoriteGrids ||
			isFetchingFavoriteGrids ||
			isLoadingOrganizationCascader ||
			isFetchingOrganizationCascader
		) {
			dispatch(updateMonitorViewIsLoading(true));
		} else {
			dispatch(updateMonitorViewIsLoading(false));
		}
	}, [
		isLoadingFavoriteGrids,
		isFetchingFavoriteGrids,
		isLoadingOrganizationCascader,
		isFetchingOrganizationCascader,
	]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__flex'>
			{/** ******************************************* */}
			{/** CASCADER */}
			<div className='generalStyles__mlFix'>
				<Cascader
					maxTagCount='responsive'
					options={cascaderData}
					onChange={onChangeCascader}
					placeholder='Please select'
					multiple
					value={cascaderValue}
					showCheckedStrategy={SHOW_CHILD}
					style={{ width: "350px" }}
				/>
			</div>
			<div className={`monitoringView__headerTagContainer__${theme}`}>
				<div>
					<div>
						<Dropdown
							overlay={
								<Menu>
									{listFavoriteGrids && (
										<>
											{listFavoriteGrids.map((grid: any) => (
												<Menu.Item
													onClick={() => {
														onClickFavoriteGrids(grid);
													}}
													key={grid.id}
												>
													<div>
														{userLogged &&
															userLogged.pref_grid_id &&
															userLogged.pref_grid_id === grid.id && (
																<FontAwesomeIcon
																	className='generalStyles__warning generalStyles__mrFix'
																	icon={faStar}
																/>
															)}
														<span
															className={`${
																userLogged &&
																userLogged.pref_grid_id &&
																userLogged.pref_grid_id === grid.id
																	? ""
																	: "generalStyles__mlFix25"
															}`}
														>
															{grid.name}
														</span>
													</div>
												</Menu.Item>
											))}
										</>
									)}
								</Menu>
							}
							placement='bottomLeft'
						>
							<Tooltip
								title={
									listFavoriteGrids && listFavoriteGrids.length > 0
										? ""
										: t("monitoring.noGridsFound")
								}
							>
								<FontAwesomeIcon
									style={{
										fontSize: "20px",
										textAlign: "center",
										paddingLeft: "10px",
										paddingTop: "4px",
									}}
									className={`generalStyles__themeIcon1__${theme} ${
										listFavoriteGrids && listFavoriteGrids.length > 0 ? "" : ""
									}`}
									icon={
										listFavoriteGrids && listFavoriteGrids.length > 0
											? faList
											: faBan
									}
								/>
							</Tooltip>
						</Dropdown>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrganizationCascaderSelectMultiple;
