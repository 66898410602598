import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer } from "antd";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateGroup,
	updateOpenDrawer,
} from "../../../redux/groups/groupsSlice";
// COMPONENTS
import DrawerGroupInformation from "../../components/drawer/DrawerGroupInformation";
import DrawerMessageTypeInformation from "../../components/drawer/DrawerMessageTypeInformation";
import DrawerOrganizationInformation from "../../components/drawer/DrawerOrganizationInformation";
// INTERFACES
import { GroupInterface } from "../../../interfaces/Groups.interface";
import { RootState } from "../../../app/store";

function GroupsDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_GROUP = {
		id: "",
		name: "",
		message_type_id: 0,
		organization_id: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<GroupInterface>(BLANK_GROUP);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// LOCAL STORAGE AND VARIABLES ******************** */
	const { group, openDrawer } = useSelector((state: RootState) => state.group);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawer(false));
		dispatch(updateGroup(BLANK_GROUP));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (group) {
			setData(group);
		}
	}, [group]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='600'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawer}
		>
			{data && (
				<div>
					{/** ************************************************* */}
					{/** GROUP INFORMATION */}
					<DrawerGroupInformation data={group} />
					<br />
					{/** ************************************************* */}
					{/** MESSAGE TYPE INFORMATION */}
					<DrawerMessageTypeInformation data={group.message_type} />
					<br />
					{/** ************************************************* */}
					{/** ORGANIZATION INFORMATION */}
					<DrawerOrganizationInformation data={group.organization} />
				</div>
			)}
		</Drawer>
	);
}

export default GroupsDrawer;
