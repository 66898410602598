import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteLiftMutation } from "../../../redux/lifts/liftAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import { updateOpenModalDeleteLift } from "../../../redux/lifts/liftSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

library.add(faTrash, faTriangleExclamation);

function LiftsDeleteModal() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] =
		useState(true);
	const [confirmLiftId, setConfirmLiftId] = useState("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { lift, openModalDeleteLift } = useSelector((state: any) => state.lift);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerDeleteLift,
		{ isLoading, isSuccess, error, isError, reset: resetDeleteLift },
	] = useDeleteLiftMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOk = () => {
		dispatch(updateOpenModalDeleteLift(false));
	};

	const handleCancel = () => {
		dispatch(updateOpenModalDeleteLift(false));
	};

	const handleCloseModal = () => {
		dispatch(updateOpenModalDeleteLift(false));
	};

	const handleDeleteItem = async () => {
		const token = await GETJwtToken();
		triggerDeleteLift({ id: lift.id, token });
	};

	const handleSubmitDeleteItem = async (event: any) => {
		if (event.key === "Enter" && !disableButtonConfirmDelete) {
			const token = await GETJwtToken();
			triggerDeleteLift({ id: lift.id, token });
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalDeleteLift) {
			setDisableButtonConfirmDelete(true);
			setConfirmLiftId("");
			resetDeleteLift();
		}
	}, [openModalDeleteLift]);

	useEffect(() => {
		setDisableButtonConfirmDelete(true);
		if (
			confirmLiftId &&
			lift &&
			lift.id &&
			confirmLiftId.toLowerCase() === lift.id.toLowerCase()
		) {
			setDisableButtonConfirmDelete(false);
		} else {
			setDisableButtonConfirmDelete(true);
		}
	}, [confirmLiftId]);

	useEffect(() => {
		dispatch(updateOpenModalDeleteLift(false));
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={handleOk}
			closable={false}
			onCancel={handleCancel}
			open={openModalDeleteLift}
			footer={null}
		>
			<Spin spinning={isLoading}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<p className='mt-3 drawer__text'>
							{t("organizationManagement.deleteDescription.text1")}
							<b className='generalStyles__warning'>
								{t("organizationManagement.deleteDescription.text2")}{" "}
							</b>
							{t("edgeManagement.lifts.theLift")}{" "}
							<b className='generalStyles__warning'>
								{lift && lift.id ? lift.id : ""}{" "}
							</b>
							{t("edgeManagement.lifts.confirmLiftId")}
						</p>
						<Input
							placeholder='ID'
							onChange={(e) => {
								setConfirmLiftId(e.target.value);
							}}
							value={confirmLiftId}
							onKeyDown={handleSubmitDeleteItem}
							status='warning'
						/>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button
								htmlType='submit'
								className={styleDeleteButton(disableButtonConfirmDelete, theme)}
								onClick={handleDeleteItem}
								disabled={disableButtonConfirmDelete}
							>
								{t("organizationManagement.delete")}
							</Button>
							<Button onClick={handleCloseModal} className='buttonStyle__4'>
								{t("organizationManagement.close")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='DELETE'
				error={error}
				name='Lift'
			/>
		</Modal>
	);
}

export default LiftsDeleteModal;
