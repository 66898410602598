import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerServer } from "../../../redux/servers/serverSlice";
// COMPONENTS
import DrawerOrganizationInformation from "../../components/drawer/DrawerOrganizationInformation";
import DrawerServerInformation from "../../components/drawer/DrawerServerInformation";
import DrawerChannelsAssigned from "../../components/drawer/DrawerChannelsAssigned";

function ServersDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [serverData, setServerData] = useState({});
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	const { server, openDrawerServer } = useSelector(
		(state: any) => state.server
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerServer(false));
	};

	const buildServerData = () => {
		const newObj = {
			id: server && server.id ? server.id : "",
			name: server && server.name ? server.name : "",
			ip: server && server.ip ? server.ip : "",
		};
		setServerData(newObj);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		buildServerData();
	}, [openDrawerServer]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerServer}
		>
			{server && (
				<div>
					{/** ************************************************* */}
					{/** SERVER INFORMATION */}
					<DrawerServerInformation data={serverData} />
					{/** ************************************************* */}
					{/** ORGANIZATION INFORMATION */}
					<DrawerOrganizationInformation
						data={server && server.org ? server.org : []}
					/>
					{/** ************************************************* */}
					{/** SERVER CHANNELS */}
					<DrawerChannelsAssigned
						data={server && server.channels ? server.channels : []}
					/>
				</div>
			)}
			{/** ************************************************* */}
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button
						type='default'
						onClick={onClickClose}
						className='buttonStyle__5'
					>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default ServersDrawer;
