import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// INTERFACES
import { DrawerCloseSaveButtonInterface } from "../../../interfaces/Global.interface";

function DrawerCloseSaveButton({
	onClickClose,
	onClickSave,
	disableSaveButton,
}: DrawerCloseSaveButtonInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-4'>
			<div>
				<Button
					type='default'
					onClick={onClickClose}
					className='buttonStyle__3'
				>
					{t("general.close")}
				</Button>
				<Button
					onClick={onClickSave}
					disabled={disableSaveButton}
					type='primary'
					className='buttonStyle__3'
				>
					{t("general.save")}
				</Button>
				<br />
			</div>
		</div>
	);
}

export default DrawerCloseSaveButton;
