import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenModalCreateNewOrganization,
	updateOrganizationRefreshTable,
} from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function OrganizationsHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewOrganization = () => {
		dispatch(updateOpenModalCreateNewOrganization(true));
	};

	const handleReloadPage = () => {
		dispatch(updateOrganizationRefreshTable(true));
		setTimeout(() => {
			dispatch(updateOrganizationRefreshTable(false));
		}, 1000);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__pageHeader'>
			{validatePermission("orgs-create", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						type='primary'
						icon={
							<FontAwesomeIcon icon={faPlus} className='generalStyles__mrFix' />
						}
						onClick={handleOpenModalCreateNewOrganization}
					>
						{t("organizationManagement.createNewOrganization")}
					</Button>
				</div>
			)}
			{validatePermission("orgs-view", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						type='primary'
						icon={
							<FontAwesomeIcon
								icon={faArrowsRotate}
								className='generalStyles__mrFix'
							/>
						}
						onClick={() => {
							handleReloadPage();
						}}
					>
						{t("organizationManagement.refreshTable")}
					</Button>
				</div>
			)}
		</div>
	);
}

export default OrganizationsHeader;
