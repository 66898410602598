import React from "react";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { faBuildingCircleCheck } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";

function DrawerOrganizationInformation({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider
				icon={faBuildingCircleCheck}
				title={t("organizationManagement.organization")}
			/>
			{/** ************************************************* */}
			{/** ORGANIZATION INFORMATION */}
			{data && (
				<div className={`drawer__box__${theme}`}>
					{Object.keys(data).map((keyName: string, index: number) => (
						<div className='generalStyles__flex' key={index}>
							<div>
								<p
									className={isMobile ? "drawer__titleMobile" : "drawer__title"}
								>
									{keyName
										? keyName.charAt(0).toUpperCase() +
										  keyName.slice(1).replaceAll("_", " ")
										: "--"}
								</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>{data[keyName] || "--"}</p>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default DrawerOrganizationInformation;
