import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { notification } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// INTERFACES
import { GlobalAlert } from "../../interfaces/Global.interface";

function GlobalAlert2({
	isError,
	isSuccess,
	requestType,
	error,
	name,
}: GlobalAlert) {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const [t] = useTranslation("global");

	type NotificationType = "success" | "info" | "warning" | "error";

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const launchNotification = (title: string, status: NotificationType) => {
		let customClass = "";
		if (status === "success") {
			customClass = "generalStyles__notificationSuccess";
		} else if (status === "info") {
			customClass = "generalStyles__notificationInfo";
		} else if (status === "warning") {
			customClass = "generalStyles__notificationWarning";
		} else if (status === "error") {
			customClass = "generalStyles__notificationError";
		}
		//
		let description = "";
		if (error) {
			if (error && error.data && error.data.message) {
				description = error.data.message;
			} else {
				description = t("sideBar.users");
			}
		} else if (requestType === "POST") {
			description = `${name} ${t("general.alertPost")}`;
		} else if (requestType === "PUT") {
			description = `${name} ${t("general.alertPut")}`;
		} else if (requestType === "DELETE") {
			description = `${name} ${t("general.alertDelete")}`;
		}
		//
		notification[status]({
			message: title,
			description,
			placement: "top",
			className: customClass,
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (isSuccess) {
			launchNotification(t("general.success"), "success");
		}
		if (isError) {
			launchNotification(t("general.error"), "error");
		}
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div id='global-alert' />
		</div>
	);
}

export default GlobalAlert2;
