import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useResetUserPasswordMutation } from "../../../redux/users/userAPI";
import { updateOpenModalResetUserPassword } from "../../../redux/users/userSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

function UsersResetPasswordModal() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { user, openModalResetUserPassword } = useSelector(
		(state: any) => state.user
	);
	const [
		disableButtonConfirmResetUserPassowrd,
		setDisableButtonConfirmResetUserPassword,
	] = useState(true);
	const [confirmUserName, setConfirmUserName] = useState("");
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerResetUserPassword,
		{ isLoading, isSuccess, isError, error, reset: resetReserUserPassword },
	] = useResetUserPasswordMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOk = () => {
		dispatch(updateOpenModalResetUserPassword(false));
	};

	const handleCancel = () => {
		dispatch(updateOpenModalResetUserPassword(false));
	};

	const handleCloseModal = () => {
		dispatch(updateOpenModalResetUserPassword(false));
	};

	const handleResetPassword = async () => {
		const token = await GETJwtToken();
		triggerResetUserPassword({ id: user.id, token });
	};

	const handleSubmitResetUserPassword = async (event: any) => {
		if (event.key === "Enter") {
			handleResetPassword();
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalResetUserPassword) {
			setDisableButtonConfirmResetUserPassword(true);
			setConfirmUserName("");
			resetReserUserPassword();
		}
	}, [openModalResetUserPassword]);

	useEffect(() => {
		setDisableButtonConfirmResetUserPassword(true);
		let firstName = "";
		let middleName = "";
		let lastName = "";
		if (user.first_name) {
			firstName = user.first_name;
		}
		if (user.middle_name) {
			middleName = ` ${user.middle_name}`;
		}
		if (user.last_name) {
			lastName = ` ${user.last_name}`;
		}
		const userName = firstName + middleName + lastName;
		//
		if (
			confirmUserName &&
			confirmUserName.toLowerCase() === userName.toLowerCase()
		) {
			setDisableButtonConfirmResetUserPassword(false);
		} else {
			setDisableButtonConfirmResetUserPassword(true);
		}
	}, [confirmUserName]);

	useEffect(() => {
		dispatch(updateOpenModalResetUserPassword(false));
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={handleOk}
			closable={false}
			onCancel={handleCancel}
			open={openModalResetUserPassword}
			footer={null}
		>
			<Spin spinning={isLoading}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<p className='mt-3 drawer__text'>
							{t("userManagement.resetPasswordDescription.text1")}
							<b className='generalStyles__warning'>
								{t("userManagement.resetPasswordDescription.text2")}{" "}
							</b>
							{t("userManagement.resetPasswordDescription.text3")}
							<b className='generalStyles__warning'>
								{user.first_name} {user.middle_name} {user.last_name}{" "}
							</b>
							{t("userManagement.resetPasswordDescription.text4")}
						</p>
						<Input
							placeholder={t("userManagement.userName")}
							onChange={(e) => {
								setConfirmUserName(e.target.value);
							}}
							value={confirmUserName}
							onKeyDown={handleSubmitResetUserPassword}
							status='warning'
						/>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button
								htmlType='submit'
								className={styleDeleteButton(
									disableButtonConfirmResetUserPassowrd,
									theme
								)}
								onClick={handleResetPassword}
								disabled={disableButtonConfirmResetUserPassowrd}
							>
								{t("userManagement.resetPasswordDescription.text2")}
							</Button>
							<Button onClick={handleCloseModal} className='buttonStyle__4'>
								{t("userManagement.close")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='PUT'
				error={error}
				name='User'
			/>
		</Modal>
	);
}

export default UsersResetPasswordModal;
