import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select, Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// TIMEZONE
import timezones from "timezones-list";
import moment from "moment-timezone";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import {
	TimeZoneInterface,
	ReportSliceFiltersInterface,
} from "../../../interfaces/Reports.interface";

function ReportsDrawerTimestampFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listTimezones, setListTimezones] = useState<TimeZoneInterface[]>([]);
	const [localTimeZone, setLocalTimeZone] = useState(true);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeTimezone = (newValue: string) => {
		const index = listTimezones.findIndex(
			(object: TimeZoneInterface) => object.label === newValue
		);
		if (index >= 0) {
			const copy: ReportSliceFiltersInterface = structuredClone(filters);
			copy.timezoneName = listTimezones[index].name;
			copy.timezoneLabel = listTimezones[index].label;
			copy.timezoneCode = listTimezones[index].tzCode;
			copy.timezoneUTC = listTimezones[index].utc;
			dispatch(updateFilters(copy));
		}
	};

	const onChangeLocalTimeZone = (newValue: boolean) => {
		setLocalTimeZone(newValue);
		// Reset default local time zone
		const index = listTimezones.findIndex(
			(object: TimeZoneInterface) => object.tzCode === moment.tz.guess()
		);
		if (index >= 0) {
			const copy: ReportSliceFiltersInterface = structuredClone(filters);
			copy.timezoneName = listTimezones[index].name;
			copy.timezoneLabel = listTimezones[index].label;
			copy.timezoneCode = listTimezones[index].tzCode;
			copy.timezoneUTC = listTimezones[index].utc;
			dispatch(updateFilters(copy));
		}
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (timezones && timezones.length > 0) {
			setListTimezones(timezones);
		}
	}, [timezones]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`drawer__box__${theme}`}>
			{/* SWITCH LOCAL ZONE */}
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>{t("general.localTimeZone")}:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					<Switch onChange={onChangeLocalTimeZone} checked={localTimeZone} />
				</div>
			</div>
			{/** TIMEZONE */}
			<div className='generalStyles__flex mt-3'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>{t("general.timezone")}:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					<Select
						className='generalStyles__width100'
						onChange={onChangeTimezone}
						showSearch
						size='small'
						value={filters.timezoneLabel}
						optionFilterProp='children'
						disabled={localTimeZone}
						filterOption={(input: string, option: any) =>
							option.children.toLowerCase().includes(input.toLowerCase())
						}
					>
						{listTimezones.map((item) => (
							<Option key={item.label} value={item.label}>
								{item.label}
							</Option>
						))}
					</Select>
				</div>
			</div>
			{/* UTC */}
			<div className='generalStyles__flex mt-3'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>UTC:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					{`UTC ${filters.timezoneUTC}`}
				</div>
			</div>
			{/* LABEL */}
			<div className='generalStyles__flex mt-3'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>TZ-Code:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					{filters.timezoneCode}
				</div>
			</div>
			{/* ZONE NAME */}
			<div className='generalStyles__flex mt-3'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>Zone Name:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					{filters.timezoneName}
				</div>
			</div>
		</div>
	);
}

export default ReportsDrawerTimestampFilters;
