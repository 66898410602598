import React, { useState, useEffect } from 'react'
// AMPLIFY
import { Auth } from 'aws-amplify'
// REACT BOOTSTRAP
import Collapse from 'react-bootstrap/Collapse'
// ANT DESIGN COMPONENTS
import { Input, Button } from 'antd'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faKey,
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAmplifyActiveComponent,
  updateIsLoading,
} from '../../redux/login/loginSlice'
// COMPONENTS
import CustomAmplifyBackToLogin from './CustomAmplifyBackToLogin'

library.add(faKey, faCircleCheck, faCircleExclamation)

function CustomAplifyForceChangePassword() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const [loginErrorMessage, setLoginErrorMessage] = useState<any>('')
  const [newPasswordValid, setNewPasswordValid] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [backToLoginView, setBackToLoginView] = useState(false)
  const loginState = useSelector((state: any) => state.login)
  const [
    newPasswordContains8Characters,
    setNewPasswordContains8Characters,
  ] = useState(false)
  const [newPasswordContainsNumber, setNewPasswordContainsNumber] = useState(
    false,
  )
  const [
    newPasswordContainsSpecialCharacter,
    setNewPasswordContainsSpecialCharacter,
  ] = useState(false)
  const [
    newPasswordContainsUpperCase,
    setNewPasswordContainsUpperCase,
  ] = useState(false)
  const [
    newPasswordContainsLowerCase,
    setNewPasswordContainsLowerCase,
  ] = useState(false)
  const [
    openCollapseValidateNewPassword,
    setOpenCollapseValidateNewPassword,
  ] = useState(false)

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleSubmitForceChangePassword = async () => {
    try {
      dispatch(updateIsLoading(true))
      await Auth.completeNewPassword(loginState.user, newPassword)
      dispatch(updateIsLoading(false))
      setBackToLoginView(true)
    } catch (error) {
      setBackToLoginView(false)
      dispatch(updateIsLoading(false))
      setLoginErrorMessage(error)
    }
  }

  const handleSubmitForceChangePasswordEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmitForceChangePassword()
    }
  }

  const validateStringContains8Characters = (e: string) => e.length >= 8

  const validateStringContainsNumber = (e: string) =>
    String(e).toLowerCase().match(/\d/)

  const validateStringContainsSpecialCharacter = (e: string) =>
    String(e)
      .toLowerCase()
      // eslint-disable-next-line no-useless-escape
      .match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)

  const validateStringContainsUpperCase = (e: string) =>
    String(e).match(/.*[A-Z].*/)

  const validateStringContainsLowerCase = (e: string) =>
    String(e).match(/.*[a-z]/)

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    // OPEN COLLAPSE VALIDATE PASSWORD
    if (newPassword.length > 0) {
      setOpenCollapseValidateNewPassword(true)
    } else {
      setOpenCollapseValidateNewPassword(false)
    }
    // validate 8 characters
    if (validateStringContains8Characters(newPassword)) {
      setNewPasswordContains8Characters(true)
    } else {
      setNewPasswordContains8Characters(false)
    }
    // validate a number in string
    if (validateStringContainsNumber(newPassword)) {
      setNewPasswordContainsNumber(true)
    } else {
      setNewPasswordContainsNumber(false)
    }
    // validate a special character in string
    if (validateStringContainsSpecialCharacter(newPassword)) {
      setNewPasswordContainsSpecialCharacter(true)
    } else {
      setNewPasswordContainsSpecialCharacter(false)
    }
    // validate an upercase in string
    if (validateStringContainsUpperCase(newPassword)) {
      setNewPasswordContainsUpperCase(true)
    } else {
      setNewPasswordContainsUpperCase(false)
    }
    // validate a lowerCase in string
    if (validateStringContainsLowerCase(newPassword)) {
      setNewPasswordContainsLowerCase(true)
    } else {
      setNewPasswordContainsLowerCase(false)
    }
  }, [newPassword])

  useEffect(() => {
    // validate valid password
    if (
      newPasswordContains8Characters &&
      newPasswordContainsSpecialCharacter &&
      newPasswordContainsNumber &&
      newPasswordContainsUpperCase &&
      newPasswordContainsLowerCase
    ) {
      setNewPasswordValid(true)
    } else {
      setNewPasswordValid(false)
    }
  }, [
    newPasswordValid,
    newPasswordContains8Characters,
    newPasswordContainsSpecialCharacter,
    newPasswordContainsNumber,
    newPasswordContainsUpperCase,
    newPasswordContainsLowerCase,
  ])

  return (
    <div>
      {!backToLoginView ? (
        <>
          <div>
            <p className="loginStyle__inputTitle">Update Your Password</p>
            <p className="loginStyles__forceChangePasswordDescription ">
              You need to update your password because this is the first time
              you are singing in, or because your password has expired.
            </p>
          </div>
          <div>
            {/* INPUT - FORCE CHANGE PASSWORD */}
            <p className="loginStyle__inputTitle mt-3">New Password Required</p>
            <Input.Password
              className="loginStyles__mobileInput"
              placeholder="New Password"
              prefix={
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faKey}
                />
              }
              onChange={(e) => {
                setNewPassword(e.target.value)
              }}
              onKeyDown={handleSubmitForceChangePasswordEnter}
              value={newPassword}
            />
          </div>
          {/* PASSWORD VALIDATOR */}
          <Collapse in={openCollapseValidateNewPassword}>
            <div className="loginStyles__validateNewPasswordContainer">
              <span>
                {newPasswordContains8Characters ? (
                  <FontAwesomeIcon
                    className="generalStyles__success generalStyles__mrFix"
                    icon={faCircleCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="generalStyles__error generalStyles__mrFix"
                    icon={faCircleExclamation}
                  />
                )}
                Contains at least 8 characters
              </span>
              <br />
              <span>
                {newPasswordContainsNumber ? (
                  <FontAwesomeIcon
                    className="generalStyles__success generalStyles__mrFix"
                    icon={faCircleCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="generalStyles__error generalStyles__mrFix"
                    icon={faCircleExclamation}
                  />
                )}
                Contains at least 1 number
              </span>
              <br />
              <span>
                {newPasswordContainsSpecialCharacter ? (
                  <FontAwesomeIcon
                    className="generalStyles__success generalStyles__mrFix"
                    icon={faCircleCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="generalStyles__error generalStyles__mrFix"
                    icon={faCircleExclamation}
                  />
                )}
                Contains at least 1 special character
              </span>
              <br />
              <span>
                {newPasswordContainsUpperCase ? (
                  <FontAwesomeIcon
                    className="generalStyles__success generalStyles__mrFix"
                    icon={faCircleCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="generalStyles__error generalStyles__mrFix"
                    icon={faCircleExclamation}
                  />
                )}
                Contains at least 1 uppercase letter
              </span>
              <br />
              <span>
                {newPasswordContainsLowerCase ? (
                  <FontAwesomeIcon
                    className="generalStyles__success generalStyles__mrFix"
                    icon={faCircleCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="generalStyles__error generalStyles__mrFix"
                    icon={faCircleExclamation}
                  />
                )}
                Contains at least 1 lowercase letter
              </span>
              <br />
            </div>
          </Collapse>
          {/** BUTTON - LOGIN */}
          <div className="generalStyles__flex mt-4">
            <div>
              <Button
                disabled={!newPasswordValid}
                onClick={handleSubmitForceChangePassword}
              >
                <span>Save New Password</span>
              </Button>
            </div>
            <div className="generalStyles__mlFix">
              <Button
                className="buttonStyle__2 loginStyles__buttonActive"
                onClick={() => {
                  dispatch(updateAmplifyActiveComponent('sign-in'))
                }}
              >
                <span>Sign in With Another Account</span>
              </Button>
            </div>
          </div>

          <p className="loginStyle__errorMessage">
            {loginErrorMessage.message}
          </p>
        </>
      ) : (
        <>
          <CustomAmplifyBackToLogin />
        </>
      )}
    </div>
  )
}

export default CustomAplifyForceChangePassword
