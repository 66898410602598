import React, { useState, useEffect } from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// ANT DESIGN COMPONENTS
import { Select, Button } from "antd";
// REDUX
import { useLazyGetOrganizationsQuery } from "../../../redux/organizations/organizationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function FilterSelectOrganization({
	placeHolder,
	setFilterValue,
	filterValue,
	refreshTable,
	refreshParameters,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [filterOrganizationsList, setFilterOrganizationsList] = useState<any>(
		[]
	);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetOrganizations, { data: dataOrganizations }] =
		useLazyGetOrganizationsQuery();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetOrganizations({
			token,
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (dataOrganizations && dataOrganizations.data) {
			setFilterOrganizationsList(dataOrganizations.data);
		}
	}, [dataOrganizations]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__searchDropdown'>
			<Select
				showSearch
				optionFilterProp='children'
				size='small'
				allowClear
				style={{ width: 250 }}
				filterOption={(input: any, option: any) =>
					option.children.toLowerCase().includes(input.toLowerCase())
				}
				onChange={(value) => {
					setFilterValue(value);
				}}
				placeholder={placeHolder}
				value={filterValue}
			>
				{filterOrganizationsList.map((item: any) => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))}
			</Select>
			<div className='generalStyles__flexEnd mt-3'>
				<div>
					<Button
						className='buttonStyle__1'
						size='small'
						style={{ width: 90 }}
						onClick={() => {
							refreshTable(
								refreshParameters.length > 0 ??
									refreshParameters.map((item: any) => item)
							);
						}}
					>
						{t("general.search")}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default FilterSelectOrganization;
