import React from "react";
// ANT DESIGN COMPONENTS
import { Dropdown, Menu, Space } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faPenToSquare,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateGroup,
	updateOpenForm,
	updateFormAction,
	updateOpenModalDelete,
} from "../../../redux/groups/groupsSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";
// INTERFACES

function GroupsTableActions({ selectedItem }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalDelete = () => {
		dispatch(updateGroup(selectedItem));
		dispatch(updateOpenModalDelete(true));
		dispatch(updateFormAction("EDIT"));
	};
	const handleOpenModalEdit = () => {
		dispatch(updateGroup(selectedItem));
		dispatch(updateOpenForm(true));
		dispatch(updateFormAction("EDIT"));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// ROW ACTIONS ************************************ */
	const manageActions = () => {
		const EDIT = validatePermission("permission-not-defined", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faPenToSquare}
							text={t("general.edit")}
							onClick={handleOpenModalEdit}
						/>
					),
					key: "1",
			  }
			: null;
		const DELETE = validatePermission("permission-not-defined", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faTrash}
							text={t("general.delete")}
							onClick={handleOpenModalDelete}
						/>
					),
					key: "2",
			  }
			: null;

		return [EDIT, DELETE];
	};
	const menu = <Menu items={manageActions()} />;
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Dropdown overlay={menu} trigger={["click"]}>
				<Space className='generalStyles__hoverCursor'>
					<FontAwesomeIcon icon={faEllipsis} />
				</Space>
			</Dropdown>
		</div>
	);
}

export default GroupsTableActions;
