import React, { useState, useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
import { usePostCarUseComparisonsMutation } from "../../../redux/reports/chartsAPI";
// COMPONENTS
import ReportsHeader from "../ReportsHeader";
import ReportsDrawerFilters from "../drawer/ReportsDrawerFilters";
import ChartBar from "../charts/ChartBar";
import ChartPie from "../charts/ChartPie";
import ChartLine from "../charts/ChartLine";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTotalCarsInBank, GenerateCarUseComparisons } from "../charts/utils";

const ReportCarUseChartComparisons = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [carListData, setCarListData] = useState<any>([]);
	const [chartData, setChartData] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);
	const { filters, reportRefreshTable } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetChart, { data, isLoading }] =
		usePostCarUseComparisonsMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getChartData = async () => {
		const token = await GETJwtToken();
		triggerGetChart({
			token,
			body: {
				bank_id: filters.bankId,
				start_date: filters.startDate,
				end_date: filters.endDate,
				timezone: filters.timezoneCode,
			},
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (data && data.results) {
			const response = GenerateCarUseComparisons(data.results);
			setChartData(response);
			setCarListData(response);
		} else {
			setChartData([{ count: 0, day: "", lift_name: "" }]);
			setCarListData([{ count: 0, day: "", lift_name: "" }]);
		}
	}, [data]);

	useEffect(() => {
		if (reportRefreshTable) {
			getChartData();
		}
	}, [reportRefreshTable]);

	useEffect(() => {
		if (carListData && carListData.length > 0) {
			const response = getTotalCarsInBank(carListData);
			const copy: ReportSliceFiltersInterface = structuredClone(filters);
			copy.selectedCarsList = response;
			copy.carsList = response;
			dispatch(updateFilters(copy));
		}
	}, [carListData]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("fault-report", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "CHART",
							levelTypeName: "BANKS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: [],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText2",
							displayDownloadCSVReport: false,
							displayDownloadPDFReport: false,
							reportName: "carUseComparisons",
							reportServiceName: "",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: true,
							displayDateByRange: false,
							displayAdvanceFilterCharts: true,
							displayAdvanceFiltersTable: false,
							chartFaultType: "FLOOR",
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
						}}
					/>
					{filters.chartType === "pie" && <ChartPie charData={[]} />}
					{(filters.chartType === "line" ||
						filters.chartType === "line_natural" ||
						filters.chartType === "step_before" ||
						filters.chartType === "step_after") && (
						<ChartLine
							data={chartData}
							isLoading={isLoading}
							bottomLabel='CATEGORY'
							leftLabel='COUNT'
						/>
					)}
					{(filters.chartType === "bar_vertical" ||
						filters.chartType === "bar_stack_vertical" ||
						filters.chartType === "bar_horizontal" ||
						filters.chartType === "bar_stack_horizontal") && (
						<>
							<ChartBar
								data={chartData}
								isLoading={isLoading}
								bottomLabel='CATEGORY'
								leftLabel='COUNT'
							/>
						</>
					)}
					<ReportsDrawerFilters />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default ReportCarUseChartComparisons;
