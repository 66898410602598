import React, { useEffect } from 'react'
// ANT DESIGN COMPONENTS
import { Button, notification } from 'antd'
// REDUX
import { useDispatch } from 'react-redux'
import { updateAmplifyActiveComponent } from '../../redux/login/loginSlice'

function CustomAmplifyBackToLogin() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()

  const launchAlert = () => {
    notification.success({
      message: 'Success',
      description: 'Password updated successfully!',
      placement: 'top',
      className: 'generalStyles__notificationSuccess',
    })
  }

  useEffect(() => {
    launchAlert()
  }, [])

  return (
    <div className="mt-4">
      {/** BUTTON - LOGIN */}
      <div id="custom-target" />
      <div className="generalStyles__flex loginStyles__backToLoginTopFix">
        <div>
          <Button
            onClick={() => {
              dispatch(updateAmplifyActiveComponent('sign-in'))
            }}
          >
            Back To Login
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CustomAmplifyBackToLogin
