import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Switch, InputNumber, Button, Spin } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSave } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePutOffsetsBulkMutation,
	useLazyGetOffsetsByDataBlockQuery,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// UTILS
import {
	getTableRowClass,
	getOffsetsCountByConfiguration,
	generateExcelAndDownload,
	buildOffsetsTypeDataObject,
	buildOffsetsFullTableData,
	buildOffsetsRangeTableData,
} from "../../../utils/utils";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

const { Column } = Table;

function OffsetsTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [rangeTableColumns, setRangeTableColumns] = useState<any>([]);
	const [rangeTableData, setRangeTableData] = useState<any>([]);
	const [fullTableData, setFullTableData] = useState<any>([]);
	const [fullTableColumns, setFullTableColumns] = useState<any>([]);
	const [fullTable, setFullTable] = useState(false);
	const [editMode, setEditMode] = useState(false);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { configurationFile } = useSelector(
		(state: any) => state.configurationFile
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		putOffsetsBulk,
		{ isSuccess, isError, error, isLoading: isLoadingPutOffsetsBulk, reset },
	] = usePutOffsetsBulkMutation();
	const [
		getOffsetsByDataBlock,
		{ data: dataOffsetsByDataBlock, isLoading: isLoadingOffsetsByDataBlock },
	] = useLazyGetOffsetsByDataBlockQuery();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onClickAutoFillOffsets = (rangeTableData_: any) => {
		const clone = structuredClone(rangeTableData_);
		for (let x = 0; x < clone.length; x += 1) {
			let counter = 0;
			for (let y = 0; y < clone[x].statusArr.length; y += 1) {
				if (clone[x].statusArr[y].keyIndex === 0) {
					counter = clone[x].statusArr[y].offset;
				} else if (clone[x].statusArr[y].keyIndex !== 0) {
					if (counter > 0) counter += 1;
				}
				const newOffset = counter === 0 ? 0 : counter;
				clone[x].statusArr[y].offset = newOffset;
			}
		}
		const offsetsFullTableData = buildOffsetsFullTableData(clone, []);
		setFullTableColumns(offsetsFullTableData[0]);
		setFullTableData(offsetsFullTableData[1]);
	};

	const onChangeOffsetFirstValue = (
		newValue: number,
		offset: string,
		liftName: string
	) => {
		const clone = structuredClone(rangeTableData);
		if (clone && clone.length > 0) {
			for (let x = 0; x < clone.length; x += 1) {
				// Change specific name
				for (let y = 0; y < clone[x].statusArr.length; y += 1) {
					// Save new offset value
					if (
						clone[x].liftName === liftName &&
						clone[x].statusArr[y].key === offset
					) {
						clone[x].statusArr[y].offset = newValue;
					}
					// Check if offset is valid
					if (clone[x].statusArr[y].offset === null) {
						clone[x].statusArr[y].isValid = false;
					} else {
						clone[x].statusArr[y].isValid = true;
					}
				}
			}
			setRangeTableData(clone);
			onClickAutoFillOffsets(clone);
		}
	};

	const onChangeSwitchFullTable = (newValue: boolean) => {
		setFullTable(newValue);
		showLoading();
	};

	const onChangeSwitchEditMode = (newValue: boolean) => {
		setEditMode(newValue);
		setFullTable(false);
		showLoading();
	};

	const onClickSave = async () => {
		if (data) {
			const copy: any = structuredClone(data.offsets);
			rangeTableData.map((item: any) => {
				if (item && item.statusArr) {
					item.statusArr.map((item2: any) => {
						if (item2 && item2.keyIndex === 0) {
							// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
							// update offset value
							copy.map((offset: any, copyIndex: number) => {
								delete copy[copyIndex].definitions;
								// const myArray = item2.name.split("-");
								const extractNumber = item2.name.match(/(\d+)/);
								const parcialName = item2.name.replace(
									extractNumber[0].toString(),
									""
								);
								const name = parcialName.slice(0, -1);
								if (
									item2.keyIndex === 0 &&
									item.liftnumber === offset.position &&
									name === offset.offset_type.name
								) {
									copy[copyIndex].initial_value = item2.offset;
									copy[copyIndex].last_value =
										item2.offset + item2.byteCount - 1;
								}
								return true;
							});
						}
						return true;
					});
				}
				return true;
			});
			// Save bulk offsets
			const token = await GETJwtToken();
			const BODY = {
				token,
				body: { offsets: copy },
			};
			putOffsetsBulk(BODY);
		}
	};

	const initData = async () => {
		const token = await GETJwtToken();
		getOffsetsByDataBlock({
			token,
			dataBlockID: configurationFile.data_block.id,
		});
	};

	const onClickDownloadExcel = () => {
		const newArr: any = [];
		if (fullTable) {
			const copy = structuredClone(fullTableData);
			copy.map((item: any) => {
				const newItem: any = {
					Offset: item.name,
					Byte: `${item.byte}`,
					TotalByes: getOffsetsCountByConfiguration(item.name, data.offsets),
				};
				fullTableColumns.map((item2: any) => {
					newItem[item2.liftName] = item[`${item2.liftName}${item.id}`] || "--";
					return true;
				});
				newArr.push(newItem);
				return true;
			});
			generateExcelAndDownload(newArr, "fullTableOffsets");
		} else {
			const copy = structuredClone(rangeTableData);
			copy.map((item: any) => {
				const newItem: any = {
					Lift: item.liftName,
				};
				rangeTableColumns.map((item2: any) => {
					item.statusArr.map((item3: any) => {
						if (`${item2.name}-0` === item3.key) {
							newItem[item2.name] = item3.offset;
						}
						return true;
					});
					return true;
				});
				newArr.push(newItem);
				return true;
			});
			generateExcelAndDownload(newArr, "rangeTableOffsets");
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, [configurationFile]);

	useEffect(() => {
		if (dataOffsetsByDataBlock && dataOffsetsByDataBlock.data && data) {
			showLoading();
			const offsetsData = buildOffsetsTypeDataObject(
				dataOffsetsByDataBlock.data
			);
			setData(offsetsData);
			// generate and build full table
			const offsetsFullTableData = buildOffsetsFullTableData(
				[],
				offsetsData.offsets
			);
			setFullTableColumns(offsetsFullTableData[0]);
			setFullTableData(offsetsFullTableData[1]);
			// Generate and build range table
			const offsetsRangeTableData = buildOffsetsRangeTableData(offsetsData);
			setRangeTableColumns(offsetsRangeTableData[0]);
			setRangeTableData(offsetsRangeTableData[1]);
			// Reset
			reset();
		}
	}, [dataOffsetsByDataBlock]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoadingOffsetsByDataBlock}>
			{/** SWITCH TABLE */}
			<div
				className='generalStyles__pageHeader'
				style={{ display: "flex", justifyContent: "space-between" }}
			>
				<div>
					<div className='generalStyles__flex'>
						<div className='generalStyles__mrFix25'>
							<span className='configuration__configurationNameTitle'>
								{configurationFile && configurationFile.name
									? configurationFile.name
									: "--"}
							</span>
						</div>
					</div>
				</div>
				<div className='generalStyles__flex'>
					<div>
						<Switch
							checked={fullTable}
							onChange={(e) => {
								onChangeSwitchFullTable(e);
							}}
							checkedChildren='Range Table'
							unCheckedChildren='Full Table'
							style={{ marginRight: "20px", width: "110px" }}
						/>
					</div>
					<div>
						<Switch
							checked={editMode}
							onChange={(e) => {
								onChangeSwitchEditMode(e);
							}}
							style={{ width: "110px" }}
							checkedChildren='Edit Offsets'
							unCheckedChildren='Offsets'
						/>
					</div>
					<div>
						<Button
							className='buttonStyle__4  generalStyles__mlFix'
							style={{ marginTop: "-5px" }}
							onClick={onClickDownloadExcel}
							icon={
								<FontAwesomeIcon
									className='generalStyles__mrFix'
									icon={faDownload}
								/>
							}
						>
							Download Excel File
						</Button>
					</div>
					<div>
						<Button
							icon={
								<FontAwesomeIcon
									className='generalStyles__mrFix'
									icon={faSave}
								/>
							}
							className='buttonStyle__1 generalStyles__mlFix'
							style={{ marginTop: "-5px" }}
							onClick={onClickSave}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
			<div className='mt-3'>
				{fullTable ? (
					<>
						<div>
							{/** ---------------------------------------------------- */}
							{/** FULL TABLE */}
							{fullTableData && fullTableData.length > 0 && (
								<Table
									rowClassName={(record, index) =>
										getTableRowClass(index, theme)
									}
									dataSource={fullTableData}
									pagination={{
										defaultPageSize: 10,
										showSizeChanger: true,
										pageSizeOptions: ["10", "20", "50", "100"],
									}}
									loading={isLoading || isLoadingPutOffsetsBulk}
									size='small'
									rowKey={(record) => record.id}
									scroll={{ x: "100%" }}
								>
									{/** NAME COLUMN */}
									<Column
										title='Offset'
										dataIndex='name'
										key='name'
										className='generalStyles__tableHeaderLink'
										fixed
										width='150px'
										render={(text) => (
											<div>
												<div className='configuration__columnEllipsis'>
													<span>{text}</span>
												</div>
											</div>
										)}
									/>
									{/** BYTE NUMBER */}
									<Column
										title='Byte'
										dataIndex='byte'
										key='byte'
										width='100px'
										render={(text, record: any) => (
											<div>
												<div className=''>
													<span>
														{text} /{" "}
														{record.byteCount ||
															getOffsetsCountByConfiguration(
																record.name,
																data.offsets
															)}
													</span>
												</div>
											</div>
										)}
									/>
									{/** DINAMIC COLUMN */}
									{fullTableColumns.length > 0 && (
										<>
											{fullTableColumns.map((item: any) => (
												<Column
													title={item.liftName}
													dataIndex={item.liftName}
													key={item.key}
													width='125px'
													className='configuration__columnEllipsis'
													render={(text, record: any) => (
														<div>
															<div className='generalStyles__mlFix'>
																{record[`${item.liftName}${record.id}`]}
															</div>
														</div>
													)}
												/>
											))}
										</>
									)}
								</Table>
							)}
						</div>
					</>
				) : (
					<>
						<div>
							{/** ---------------------------------------------------- */}
							{/** RANGE TABLE */}
							{rangeTableData && rangeTableData.length > 0 && (
								<Table
									rowClassName={(record, index) =>
										getTableRowClass(index, theme)
									}
									dataSource={rangeTableData}
									pagination={{
										defaultPageSize: 10,
										showSizeChanger: true,
										pageSizeOptions: ["10", "20", "50", "100"],
									}}
									scroll={{ x: "100%" }}
									loading={isLoading || isLoadingPutOffsetsBulk}
									size='small'
									rowKey={(record) => record.key}
								>
									{/** NAME COLUMN */}
									<Column
										title='Lift'
										dataIndex='liftName'
										key='liftName'
										fixed
										className='generalStyles__tableHeaderLink '
										width='150px'
										render={(text) => (
											<div className=''>
												<span>{text}</span>
											</div>
										)}
									/>
									{/** DINAMIC COLUMN */}
									{rangeTableColumns.length > 0 && (
										<>
											{rangeTableColumns.map((item: any) => (
												<Column
													title={item.name}
													dataIndex={item.name}
													key={item.key}
													className='configuration__columnEllipsis'
													render={(text, record: any) => (
														<>
															{record.statusArr.map(
																(item2: any, index2: number) => (
																	<div key={index2}>
																		{`${item.name}-0` === item2.key && (
																			<>
																				<InputNumber
																					status={
																						item2.isValid ? "" : "warning"
																					}
																					bordered={!item2.isValid}
																					style={{ width: "100%" }}
																					disabled={!editMode}
																					precision={0}
																					value={item2.offset}
																					onChange={(e) => {
																						onChangeOffsetFirstValue(
																							e,
																							item2.key,
																							record.liftName
																						);
																					}}
																				/>
																			</>
																		)}
																	</div>
																)
															)}
														</>
													)}
												/>
											))}
										</>
									)}
								</Table>
							)}
						</div>
					</>
				)}
			</div>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='PUT'
				error={error}
				name='Configuration'
			/>
		</Spin>
	);
}

export default OffsetsTable;
