import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(fetchBaseQuery(), { maxRetries });

export const messageAPI = createApi({
	reducerPath: "messageAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetMessages"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET MESSAGE TYPES */
		getMessages: builder.query({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/messages/`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetMessages"],
		}),
		//* *********************************************************** */
		//* POST MESSAGE TYPE */
		postMessage: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/messages/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetMessages"],
		}),
		//* *********************************************************** */
		//* PUT MESSGE TYPE */
		putMessage: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/messages/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetMessages"],
		}),
		//* *********************************************************** */
		//* DELETE MESSAGE TYPE */
		deleteMessage: builder.mutation({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/messages/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetMessages"],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetMessagesQuery,
	usePostMessageMutation,
	usePutMessageMutation,
	useDeleteMessageMutation,
} = messageAPI;
