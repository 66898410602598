import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import MessagesTable from "./MessagesTable";
import MessagesDeleteModal from "./MessagesDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const MessagesPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("permission-not-defined", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<MessagesTable />
					<MessagesDeleteModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default MessagesPage;
