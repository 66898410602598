import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const maxCacheInSeconds = 0;
const maxRetries = 2;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
  }),
  { maxRetries }
);

export const countriesStatesCitiesAPI = createApi({
  reducerPath: 'countriesStatesCitiesAPI',
  baseQuery,
  keepUnusedDataFor: maxCacheInSeconds,
  endpoints: (builder) => ({
    //* ************************************** */
    //* GET ALL COUNTRIES */
    getCountries: builder.mutation({
      query: () => ({
        url: 'https://countriesnow.space/api/v0.1/countries/codes',
        method: 'get',
      }),
    }),
    //* ************************************** */
    //* GET ALL STATES */
    getStates: builder.mutation({
      query: (country: string) => ({
        url: 'https://countriesnow.space/api/v0.1/countries/states',
        method: 'post',
        body: { country },
      }),
    }),
    //* ************************************** */
    //* GET ALL CITIES */
    getCities: builder.mutation({
      query: (body: Object) => ({
        url: 'https://countriesnow.space/api/v0.1/countries/state/cities',
        method: 'post',
        body,
      }),
    }),
  }),
});

export const {
  useGetCountriesMutation,
  useGetCitiesMutation,
  useGetStatesMutation,
} = countriesStatesCitiesAPI;
