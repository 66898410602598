import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { notification } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateGlobalAlert } from "../../redux/home/homeSlice";

function GlobalAlert() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { globalAlert } = useSelector((state: any) => state.home);

	type NotificationType = "success" | "info" | "warning" | "error";

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const launchNotification = (
		title: string,
		text: any,
		status: NotificationType
	) => {
		let customClass = "";
		if (status === "success") {
			customClass = "generalStyles__notificationSuccess";
		} else if (status === "info") {
			customClass = "generalStyles__notificationInfo";
		} else if (status === "warning") {
			customClass = "generalStyles__notificationWarning";
		} else if (status === "error") {
			customClass = "generalStyles__notificationError";
		}
		let description = text;
		if (title === "Error") {
			if (text && text.data && text.data.message) {
				description = text.data.message;
			} else {
				description = t("sideBar.users");
			}
		}
		notification[status]({
			message: title,
			description,
			placement: "top",
			className: customClass,
		});
		// clean alert
		dispatch(
			updateGlobalAlert({
				title: "",
				description: "",
				status: "",
			})
		);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	// GLOBAL ALERT
	useEffect(() => {
		if (
			globalAlert &&
			globalAlert.title &&
			globalAlert.description &&
			globalAlert.status
		) {
			launchNotification(
				globalAlert.title,
				globalAlert.description,
				globalAlert.status
			);
		}
	}, [globalAlert]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div id='global-alert' />
		</div>
	);
}

export default GlobalAlert;
