import React from "react";
// ANT DESIGN COMPONENTS
import { Tag, Alert } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlug,
	faCheckCircle,
	faBan,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";

function DrawerChannelsAssigned({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider
				icon={faPlug}
				title={t("edgeManagement.channels.channelsAssigned")}
			/>
			{/** ************************************************* */}
			{data && data.length > 0 ? (
				<>
					{data.map((item: any) => (
						<div className={`drawer__box__${theme} mt-3`}>
							{/* ID */}
							<div className='generalStyles__flex'>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										ID
									</p>
								</div>
								<div className='drawer__textContainer'>
									<p className='drawer__text'>
										{item && item.id ? item.id : "--"}
									</p>
								</div>
							</div>
							{/* STATUS */}
							<div className='generalStyles__flex'>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										{t("general.status")}
									</p>
								</div>
								<div className='drawer__textContainer'>
									<p className='drawer__text'>
										{item.active ? (
											<Tag
												color='success'
												icon={
													<FontAwesomeIcon
														icon={faCheckCircle}
														className='generalStyles__mrFix'
													/>
												}
												className='drawer__tag'
											>
												{t("userManagement.active")}
											</Tag>
										) : (
											<Tag
												color='error'
												icon={
													<FontAwesomeIcon
														icon={faBan}
														className='generalStyles__mrFix'
													/>
												}
												className='drawer__tag'
											>
												{t("general.inactive")}
											</Tag>
										)}
									</p>
								</div>
							</div>
							{/* NAME */}
							<div className='generalStyles__flex'>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										{t("general.name")}
									</p>
								</div>
								<div className='drawer__textContainer'>
									<p className='drawer__text'>
										{item && item.name ? item.name : "--"}
									</p>
								</div>
							</div>
							{/* INDEX */}
							<div className='generalStyles__flex'>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										{t("edgeManagement.servers.index")}
									</p>
								</div>
								<div className='drawer__textContainer'>
									<p className='drawer__text'>
										{item && item.index ? item.index : "--"}
									</p>
								</div>
							</div>
							{/* TYPE */}
							<div className='generalStyles__flex'>
								<div>
									<p
										className={
											isMobile ? "drawer__titleMobile" : "drawer__title"
										}
									>
										{t("edgeManagement.servers.type")}
									</p>
								</div>
								<div className='drawer__textContainer'>
									<p className='drawer__text'>
										{item && item.type ? item.type : "--"}
									</p>
								</div>
							</div>
						</div>
					))}
				</>
			) : (
				<>
					<div className={`drawer__box__${theme}`}>
						<Alert
							message={t("edgeManagement.channels.noChannelsFound")}
							type='warning'
							showIcon
						/>
					</div>
				</>
			)}
		</div>
	);
}

export default DrawerChannelsAssigned;
