import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";

function TableColumnLiftType({ liftType }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className=''>
			{liftType === "ELEVATOR" && (
				<Tag color='blue' className='drawer__typeTag'>
					{liftType}
				</Tag>
			)}
			{liftType === "ESCALATOR" && (
				<Tag color='orange' className='drawer__typeTag'>
					{liftType}
				</Tag>
			)}
			{liftType === "MOVINGWALK" && (
				<Tag color='green' className='drawer__typeTag'>
					{liftType}
				</Tag>
			)}{" "}
			{liftType === "" && (
				<Tag color='red' className='drawer__typeTag'>
					N/A
				</Tag>
			)}{" "}
		</div>
	);
}

export default TableColumnLiftType;
