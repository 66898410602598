import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Spin, Button, Table, Divider } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableCells, faTrash } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateOpenModalManageGrids } from "../../../redux/monitoring/monitoringSlice";
import {
	useLazyGetFavoriteGridsQuery,
	useDeleteFavoriteGridsMutation,
} from "../../../redux/monitoring/monitoringAPI";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACECES
import { RootState } from "../../../app/store";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

function MonitoringModalManageGrids() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listFavoriteGrids, setListFavoriteGrids] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalManageGrids } = useSelector(
		(state: RootState) => state.monitoring
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetFavoriteGrids,
		{ data: dataGetFavoriteGrids, isLoading, isFetching },
	] = useLazyGetFavoriteGridsQuery();
	const [
		deleteMonitorGrid,
		{
			isSuccess: isSuccessDeleteMonitorGrid,
			isError: isErrorDeleteMonitorGrid,
			error: errorPutMonitorGrid,
			isLoading: isLoadingDeleteMonitorGrid,
			reset: resetDeleteMonitorGrid,
		},
	] = useDeleteFavoriteGridsMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetFavoriteGrids({ token });
	};

	const onClickDeleteGrid = async (grid: any) => {
		const token = await GETJwtToken();
		deleteMonitorGrid({ id: grid.id, token });
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (openModalManageGrids) {
			initData();
		}
	}, [openModalManageGrids]);

	useEffect(() => {
		if (dataGetFavoriteGrids) {
			setListFavoriteGrids(dataGetFavoriteGrids);
		}
	}, [dataGetFavoriteGrids]);

	useEffect(() => {
		resetDeleteMonitorGrid();
	}, [isSuccessDeleteMonitorGrid, isErrorDeleteMonitorGrid]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			closable={false}
			onCancel={() => {
				dispatch(updateOpenModalManageGrids(false));
			}}
			open={openModalManageGrids}
			footer={null}
		>
			<Spin spinning={isLoadingDeleteMonitorGrid}>
				<div className=' mt-4'>
					{/** ************************************************************* */}
					{/** HEADER */}

					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faTableCells}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{t("monitoring.manageGrids")}
							</h5>
						</Divider>
					</div>
					{/** ************************************************************* */}
					{/** LIST GRIDS SAVED */}
					<div>
						<Table
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							className='customTable'
							dataSource={listFavoriteGrids.data}
							loading={isLoading || isFetching}
							rowKey={(record) => record.id}
							size='small'
						>
							{/** COLUMN - NAME */}
							<Column
								render={(text) => (
									<>
										<div className=''>{text}</div>
									</>
								)}
								title={t("organizationManagement.organizationName")}
								dataIndex='name'
								key='name'
								className='generalStyles__tableHeaderLink'
							/>
							{/** COLUMN - ACTIONS */}
							<Column
								title={t("organizationManagement.actions")}
								key='action'
								align='center'
								render={(_: any, record: any) => (
									<FontAwesomeIcon
										onClick={() => {
											onClickDeleteGrid(record);
										}}
										className='generalStyles__warning generalStyles__hoverCursor'
										icon={faTrash}
									/>
								)}
							/>
						</Table>
					</div>
					{/** ************************************************************* */}
					{/** CLOSE BUTTON */}
					<div className='generalStyles__flexEnd'>
						<div />
						<div>
							<Button
								onClick={() => {
									dispatch(updateOpenModalManageGrids(false));
								}}
								className='buttonStyle__8'
							>
								{t("general.close")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorDeleteMonitorGrid}
				isSuccess={isSuccessDeleteMonitorGrid}
				requestType='DELETE'
				error={errorPutMonitorGrid}
				name='Grid'
			/>
		</Modal>
	);
}

export default MonitoringModalManageGrids;
