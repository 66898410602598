import React from "react";
// ANT DESIGN COMPONENTS
import { InputNumber } from "antd";
// INTERFACES
import { FormInputNumberInterface } from "../../../interfaces/Global.interface";

function FormInputNumber({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
}: FormInputNumberInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__text'>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<InputNumber
						className='generalStyles__input'
						size='small'
						style={{ width: "100%" }}
						min={1}
						value={inputValue}
						onChange={(e: any) => {
							inputOnChange(e, inputVariableName);
						}}
					/>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormInputNumber;
