import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingShield } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function DrawerConfigurationInformation({ data }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <br />
      {/** ************************************************* */}
      {/** CONFIGURATION FILE INFORMATION */}
      <div>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faBuildingShield}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t("configuration.configurationFiles.configurationFiles")}
          </h5>
        </Divider>
      </div>
      <div className={`drawer__box__${theme}`}>
        {/* ID */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              ID:
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data.id ? data.id : "--"}</p>
          </div>
        </div>
        {/* NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("configuration.configurationFiles.name")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data.name ? data.name : "--"}</p>
          </div>
        </div>
        {/* TYPE */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("configuration.configurationFiles.type")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <div className="">
              {data && data.lift_type === "ELEVATOR" && (
                <Tag color="blue" className="drawer__typeTag">
                  {data.lift_type}
                </Tag>
              )}
              {data && data.lift_type === "ESCALATOR" && (
                <Tag color="volcano" className="drawer__typeTag">
                  {data.lift_type}
                </Tag>
              )}
              {data && data.lift_type === "MOVINGWALK" && (
                <Tag color="green" className="drawer__typeTag">
                  {data.lift_type}
                </Tag>
              )}
            </div>
          </div>
        </div>
        {/* REAR DOORS */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("configuration.configurationFiles.rearDoors")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.rear_door ? (
                <Tag color="green" className="drawer__typeTag">
                  {t("general.active")}
                </Tag>
              ) : (
                <Tag color="red" className="drawer__typeTag">
                  {t("general.inactive")}
                </Tag>
              )}
            </p>
          </div>
        </div>
        {/* LIFTS */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("configuration.configurationFiles.lifts")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data.lifts ? data.lifts : "--"}</p>
          </div>
        </div>
        {/* STOPS */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("configuration.configurationFiles.stops")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data.stops ? data.stops : "--"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrawerConfigurationInformation;
