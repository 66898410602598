// INTERFACES
import { ReportChartData } from "../../../interfaces/Reports.interface";

export const getChartTextColor = (theme: string) => {
	if (theme === "dark") {
		return "white";
	}
	if (theme === "light") {
		return "#2968c0";
	}
	if (theme === "blue") {
		return "white";
	}
	return "black";
};

export const getChartBackgroundColor = (theme: string) => {
	if (theme === "dark") {
		return "black";
	}
	if (theme === "light") {
		return "white";
	}
	if (theme === "blue") {
		return "#0c1a28";
	}
	return "";
};

export const getChartTicklineColor = (theme: string) => {
	if (theme === "dark") {
		return "#4f4f4f";
	}
	if (theme === "light") {
		return "#206feb";
	}
	if (theme === "blue") {
		return "#4f4f4f";
	}
	return "";
};

export const getChartAxisHighlight = (theme: string) => {
	if (theme === "dark") {
		return "white";
	}
	if (theme === "light") {
		return "#2968c0";
	}
	if (theme === "blue") {
		return "white";
	}
	return "";
};

export const getChartDataByMonth = (data: ReportChartData[]) => {
	const groupedByMonth = data.reduce((acc: any, item: any) => {
		const month = item.day.split("/")[1];
		if (!acc[month]) {
			acc[month] = [];
		}
		acc[month].push(item);
		return acc;
	}, {});
	const result = Object.values(groupedByMonth);
	return result;
};

export const getTotalCarsInBank = (data: ReportChartData[]) => {
	const copy: ReportChartData[] = structuredClone(data);
	const cars: string[] = [];
	copy.map((item: ReportChartData) => cars.push(item.lift_name));
	const uniqueCars = cars.filter(
		(item: string, index: number) => cars.indexOf(item) === index
	);
	const removeBlankCars = uniqueCars.filter((car) => car !== "");
	return removeBlankCars;
};

const sortDataByDay = (data: ReportChartData[]) => {
	data.sort((a: any, b: any) => {
		// Convert day strings to format "DD/MM" to compare properly
		const dateA = a.day.split("/").reverse().join("/");
		const dateB = b.day.split("/").reverse().join("/");
		// Compare dates
		if (dateA < dateB) {
			return -1;
		}
		if (dateA > dateB) {
			return 1;
		}
		return 0;
	});
	return data;
};

const generateMirrorData = (data: ReportChartData[]) => {
	const copy: ReportChartData[] = structuredClone(data);
	const newData: any = [];
	copy.map((item: any) => {
		const itemCopy = structuredClone(item);
		const num = item.count;
		itemCopy.count = item.lift_name.toLowerCase() === "down" ? -num : num;
		newData.push(itemCopy);
		return true;
	});
	return newData;
};

export const getAxisLabels = (data: ReportChartData[], type: string) => {
	let copy: ReportChartData[] = structuredClone(data);
	const lowerType = type.toLowerCase();
	if (lowerType === "day") {
		copy = sortDataByDay(copy);
	}
	const xAxisData: string[] = [];
	//
	copy.map((item: any) => xAxisData.push(item[lowerType]));
	const uniqueArrayXAxisData = xAxisData.filter(
		(item: string, index: number) => xAxisData.indexOf(item) === index
	);
	return uniqueArrayXAxisData;
};

export const generatePieDataByMonth = (
	data: ReportChartData[],
	carsSelected: string[]
) => {
	const copy: ReportChartData[] = structuredClone(data);
	// Remove all unselected cars
	const newData: ReportChartData[] = [];
	copy.map((item: ReportChartData) => {
		if (item && item.lift_name && carsSelected.includes(item.lift_name)) {
			newData.push(item);
		}
		return true;
	});
	// Generate unique cars
	const cars: string[] = [];
	newData.map((item: ReportChartData) => cars.push(item.lift_name));
	return "";
};

export const generatePieData = (
	data: ReportChartData[],
	carsSelected: string[]
) => {
	// Sort data by date
	let copy = structuredClone(data);
	copy = sortDataByDay(copy);
	// Remove all unselected cars
	const newData: ReportChartData[] = [];
	copy.map((item: ReportChartData) => {
		if (item && item.lift_name && carsSelected.includes(item.lift_name)) {
			newData.push(item);
		}
		return true;
	});
	// Generate xAxisData
	const uniqueArrayAxisData = getAxisLabels(newData, "day");
	// Generate pie chart data
	const pieData: any = [];
	uniqueArrayAxisData.map((item: string) => {
		const newArr: any = [];
		newData.map((item2: any) => {
			if (item2.day === item) {
				newArr.push({ label: item2.lift_name, value: item2.count });
			}
			return true;
		});
		pieData.push({ label: item, data: newArr });
		return true;
	});
	return pieData;
};

export const generateBarsDataFault = (
	data: any,
	generateStack: boolean,
	carsSelected: any,
	type: string,
	mirror: boolean
) => {
	let copy: ReportChartData[];
	copy = structuredClone(data);
	// Generate Mirror Data
	if (mirror) {
		const responseMirror = generateMirrorData(data);
		copy = structuredClone(responseMirror);
	}
	// Sort data by date
	if (type === "day") {
		copy = sortDataByDay(copy);
	}
	// Remove all unselected cars
	const newData: any = [];
	copy.map((item: any) => {
		if (item && item.lift_name && carsSelected.includes(item.lift_name)) {
			newData.push(item);
		} else {
			// eslint-disable-next-line no-lonely-if
			if (type === "day") {
				newData.push({ day: item.day, lift_name: item.lift_name, count: 0 });
			} else if (type === "fault") {
				newData.push({
					fault: item.fault,
					lift_name: item.lift_name,
					count: 0,
				});
			} else if (type === "position") {
				newData.push({
					position: item.fault,
					lift_name: item.lift_name,
					count: 0,
				});
			} else if (type === "floor") {
				newData.push({
					floor: item.floor,
					lift_name: item.lift_name,
					count: 0,
				});
			}
		}
		return true;
	});
	// Generate Series Data
	const cars: string[] = [];
	newData.map((item: ReportChartData) => cars.push(item.lift_name));
	const uniqueCars = cars.filter(
		(item: string, index: number) => cars.indexOf(item) === index
	);
	// Generate xAxisData
	const uniqueArrayAxisData = getAxisLabels(newData, type);
	const seriesData: any = [];
	// Extract unique cars to generate series array
	uniqueCars.map((label: string) => {
		if (label !== "" && carsSelected.includes(label)) {
			const seriesBody: any = {
				data: uniqueArrayAxisData,
				label,
				highlightScope: {
					highlighted: "item",
					faded: "global",
				},
			};
			if (generateStack) {
				seriesBody.stack = "A";
			}
			if (label === "UP") {
				seriesBody.color = "#00e300";
			}
			if (label === "DOWN") {
				seriesBody.color = "#e70000";
			}
			seriesData.push(seriesBody);
		}
		return true;
	});
	// Fill series data with count fron API
	const copySeriesData = structuredClone(seriesData);
	for (let index1 = 0; index1 < seriesData.length; index1 += 1) {
		const newCopy = structuredClone(seriesData[index1].data);
		newCopy.map((item: any, itemIndex1: number) => {
			newData.map((item2: any) => {
				if (type === "day") {
					if (
						item === item2.day &&
						copySeriesData[index1].label === item2.lift_name
					) {
						newCopy[itemIndex1] = item2.count;
					}
				} else if (type === "fault") {
					if (
						item === item2.fault &&
						copySeriesData[index1].label === item2.lift_name
					) {
						newCopy[itemIndex1] = item2.count;
					}
				} else if (type === "position") {
					if (
						item === item2.position &&
						copySeriesData[index1].label === item2.lift_name
					) {
						newCopy[itemIndex1] = item2.count;
					}
				} else if (type === "floor") {
					if (
						item === item2.floor &&
						copySeriesData[index1].label === item2.lift_name
					) {
						newCopy[itemIndex1] = item2.count;
					}
				}
				return true;
			});
			return true;
		});
		copySeriesData[index1].data = newCopy;
	}
	// Replace all string by 0
	copySeriesData.map((serie: any, indexSerie: number) => {
		const newArray = serie.data.map((item: any) =>
			typeof item === "string" ? 0 : item
		);
		copySeriesData[indexSerie].data = newArray;
		return true;
	});
	return copySeriesData;
};

export const generateLinesData = (
	data: ReportChartData[],
	curveType: string,
	carsSelected: string[],
	type: string,
	mirror: boolean
) => {
	const lowerType = type.toLowerCase();
	let copy: ReportChartData[];
	copy = structuredClone(data);
	// Generate Mirror Data
	if (mirror) {
		const responseMirror = generateMirrorData(data);
		copy = structuredClone(responseMirror);
	}
	// Sort data by date
	if (lowerType === "day") {
		copy = sortDataByDay(copy);
	} // Remove all unselected cars
	const newData: ReportChartData[] = [];
	copy.map((item: any) => {
		if (item && item.lift_name && carsSelected.includes(item.lift_name)) {
			newData.push(item);
		}
		return true;
	});
	// Generate xAxisData
	const uniqueArrayAxisData = getAxisLabels(newData, lowerType);
	// Generate Series Data
	const cars: string[] = [];
	newData.map((item: any) => cars.push(item.lift_name));
	const uniqueCars = cars.filter(
		(item: string, index: number) => cars.indexOf(item) === index
	);
	const seriesData: any = [];
	// Extract unique cars to generate series array
	uniqueCars.map((label: string) => {
		const seriesBody: any = {
			data: uniqueArrayAxisData,
			label,
			curve: curveType,
			highlightScope: {
				highlighted: "item",
				faded: "global",
			},
		};
		if (label === "UP") {
			seriesBody.color = "#00e300";
		}
		if (label === "DOWN") {
			seriesBody.color = "#e70000";
		}
		seriesData.push(seriesBody);
		return true;
	});
	// Fill series data with count fron API
	const copySeriesData = structuredClone(seriesData);
	for (let index1 = 0; index1 < seriesData.length; index1 += 1) {
		const newCopy = structuredClone(seriesData[index1].data);
		newCopy.map((item: any, itemIndex1: number) => {
			newData.map((item2: any) => {
				if (lowerType === "day") {
					if (
						item === item2.day &&
						copySeriesData[index1].label === item2.lift_name
					) {
						newCopy[itemIndex1] = item2.count;
					}
				} else if (lowerType === "fault") {
					if (
						item === item2.fault &&
						copySeriesData[index1].label === item2.lift_name
					) {
						newCopy[itemIndex1] = item2.count;
					}
				} else if (lowerType === "position") {
					if (
						item === item2.position &&
						copySeriesData[index1].label === item2.lift_name
					) {
						newCopy[itemIndex1] = item2.count;
					}
				} else if (lowerType === "floor") {
					if (
						item === item2.floor &&
						copySeriesData[index1].label === item2.lift_name
					) {
						newCopy[itemIndex1] = item2.count;
					}
				}
				return true;
			});
			return true;
		});
		copySeriesData[index1].data = newCopy;
	}
	// Replace all string by 0
	copySeriesData.map((serie: any, indexSerie: number) => {
		const newArray = serie.data.map((item: any) =>
			typeof item === "string" ? 0 : item
		);
		copySeriesData[indexSerie].data = newArray;
		return true;
	});
	return copySeriesData;
};

export const GenerateTripsToAndFromAFloorData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.count,
				lift_name: item.category,
				floor: item.floor_name,
			});
			return true;
		});
	}
	return newData;
};

export const GenerateHallButtonCountFloorData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.count,
				lift_name: item.direction,
				floor: item.floor_name,
			});
			return true;
		});
	}
	return newData;
};

export const GenerateHallButtonCountDayData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.count,
				lift_name: item.direction,
				floor: item.hours,
			});
			return true;
		});
	}
	return newData;
};

export const GenerateWaitTimeByFloorData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.seconds,
				lift_name: item.direction,
				floor: item.floor_name,
			});
			return true;
		});
	}
	return newData;
};

export const GenerateWaitTimeByDayData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.seconds,
				lift_name: item.direction,
				floor: item.hours,
			});
			return true;
		});
	}
	return newData;
};

export const GenerateDistributionDayData = (data: any) => {
	const newUpData: any = [];
	const newDownData: any = [];

	if (data) {
		data.map((item: any) => {
			if (item.direction === "UP") {
				newUpData.push({
					count: item.percentage,
					lift_name: item.category,
					floor: item.hours.toString(),
				});
			} else if (item.direction === "DOWN") {
				newDownData.push({
					count: item.percentage,
					lift_name: item.category,
					floor: item.hours.toString(),
				});
			}
			return true;
		});
	}
	return [newUpData, newDownData];
};

export const GenerateDistributionDirectionData = (data: any) => {
	const newUpData: any = [];
	const newDownData: any = [];

	if (data) {
		data.map((item: any) => {
			if (item.direction === "UP") {
				newUpData.push({
					count: item.counts,
					lift_name: "Counts",
					floor: item.category,
				});
				newUpData.push({
					count: item.percentage,
					lift_name: "Percentage",
					floor: item.category,
				});
				newUpData.push({
					count: item.total,
					lift_name: "Total",
					floor: item.category,
				});
			} else if (item.direction === "DOWN") {
				newDownData.push({
					count: item.counts,
					lift_name: "Counts",
					floor: item.category,
				});
				newDownData.push({
					count: item.percentage,
					lift_name: "Percentage",
					floor: item.category,
				});
				newDownData.push({
					count: item.total,
					lift_name: "Total",
					floor: item.category,
				});
			}
			return true;
		});
	}
	return [newUpData, newDownData];
};

export const GenerateDistributionTotalData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.counts,
				lift_name: "Counts",
				floor: item.category,
			});
			newData.push({
				count: item.percentage,
				lift_name: "Percentage",
				floor: item.category,
			});
			newData.push({
				count: item.total,
				lift_name: "Total",
				floor: item.category,
			});
			return true;
		});
	}
	return newData;
};

export const GenerateCarUseDayCycle = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.count,
				lift_name: item.category,
				floor: item.hours,
			});
			return true;
		});
	}
	return newData;
};

export const GenerateCarUseComparisons = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.carcalls,
				lift_name: item.lift_name,
				floor: "carcalls",
			});
			newData.push({
				count: item.cycles,
				lift_name: item.lift_name,
				floor: "cycles",
			});
			newData.push({
				count: item.hallcalls,
				lift_name: item.lift_name,
				floor: "hallcalls",
			});
			newData.push({
				count: item.reopens,
				lift_name: item.lift_name,
				floor: "reopens",
			});
			newData.push({
				count: item.starts,
				lift_name: item.lift_name,
				floor: "starts",
			});
			return true;
		});
	}
	return newData;
};

export const GenerateCarUseTimes = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: parseFloat(item.close),
				lift_name: item.lift_name,
				floor: "close",
			});
			newData.push({
				count: parseFloat(item.closecc),
				lift_name: item.lift_name,
				floor: "closecc",
			});
			newData.push({
				count: parseFloat(item.dwell),
				lift_name: item.lift_name,
				floor: "dwell",
			});
			newData.push({
				count: parseFloat(item.dwellhc),
				lift_name: item.lift_name,
				floor: "dwellhc",
			});
			newData.push({
				count: parseFloat(item.open),
				lift_name: item.lift_name,
				floor: "open",
			});

			newData.push({
				count: parseFloat(item.openhc),
				lift_name: item.lift_name,
				floor: "openhc",
			});
			return true;
		});
	}
	return newData;
};
