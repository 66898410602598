import React from "react";
// ANT DESIGN COMPONENTS
import { Dropdown, Menu, Space } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faTrash,
	faPenToSquare,
	faBan,
	faElevator,
	faArrowUp19,
	faGear,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenModalDeleteBank,
	updateOpenModalCreateEditBank,
	updateBank,
	updateOpenDrawerBankFloorNames,
	updateOpenDrawerLiftsPosition,
	updateOpenDrawerAssignActions,
	updateFormAction,
} from "../../../redux/banks/bankSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";
// INTERFACES
import { BanksTableActionInterface } from "../../../interfaces/Bank.interface";

function BanksTableAction({ selectedItem }: BanksTableActionInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalEdit = () => {
		dispatch(updateBank(selectedItem));
		dispatch(updateOpenModalCreateEditBank(true));
		dispatch(updateFormAction("EDIT"));
	};

	const handleOpenModalDelete = () => {
		dispatch(updateBank(selectedItem));
		dispatch(updateOpenModalDeleteBank(true));
	};

	const handleOpenDrawerFloorNames = () => {
		dispatch(updateBank(selectedItem));
		dispatch(updateOpenDrawerBankFloorNames(true));
	};

	const handleOpenDrawerLiftsPosition = () => {
		dispatch(updateBank(selectedItem));
		dispatch(updateOpenDrawerLiftsPosition(true));
	};

	const handleOpenDrawerAssignActions = () => {
		dispatch(updateBank(selectedItem));
		dispatch(updateOpenDrawerAssignActions(true));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// ROW ACTIONS ************************************ */
	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon
							className='generalStyles__error generalStyles__mrFix'
							icon={faBan}
						/>
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const FLOOR_NAMES = validatePermission("banks-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faElevator}
							text={t("edgeManagement.banks.floorNames")}
							onClick={handleOpenDrawerFloorNames}
						/>
					),
					key: "1",
			  }
			: null;

		const EDIT = validatePermission("banks-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faPenToSquare}
							text={t("organizationManagement.edit")}
							onClick={handleOpenModalEdit}
						/>
					),
					key: "2",
			  }
			: null;

		const DELETE = validatePermission("banks-delete", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faTrash}
							text={t("organizationManagement.delete")}
							onClick={handleOpenModalDelete}
						/>
					),
					key: "3",
			  }
			: null;

		const LIFT_POSITION = validatePermission("banks-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faArrowUp19}
							text={t("edgeManagement.banks.liftPosition")}
							onClick={handleOpenDrawerLiftsPosition}
						/>
					),
					key: "4",
			  }
			: null;

		const ASSIGN_ACTIONS = validatePermission("adapters-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faGear}
							text={t("adapter.assignActions")}
							onClick={handleOpenDrawerAssignActions}
						/>
					),
					key: "5",
			  }
			: null;

		// RETURN OBJECT WITH VALID ACTIONS
		if (EDIT || DELETE || FLOOR_NAMES || LIFT_POSITION || ASSIGN_ACTIONS) {
			return [EDIT, ASSIGN_ACTIONS, FLOOR_NAMES, LIFT_POSITION, DELETE];
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Dropdown overlay={menu} trigger={["click"]}>
				<Space className='generalStyles__hoverCursor'>
					<FontAwesomeIcon icon={faEllipsis} />
				</Space>
			</Dropdown>
		</div>
	);
}

export default BanksTableAction;
