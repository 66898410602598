import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import {
	Drawer,
	Spin,
	Divider,
	InputNumber,
	Tag,
	Tooltip,
	Button,
	Alert,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowUp19,
	faBuildingShield,
	faCircleCheck,
	faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerLiftsPosition } from "../../../redux/banks/bankSlice";
// INTERFACE
import {
	LiftPositionInterface,
	BankInterface,
} from "../../../interfaces/EdgeManagement.interface";

function BanksDrawerLiftsPosition() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listLiftPosition, setListLiftPosition] = useState<
		LiftPositionInterface[]
	>([]);
	const [maxLifts, setMaxLifts] = useState(0);
	const [formIsValid, setFormIsValid] = useState(true);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerLiftsPosition, bank } = useSelector(
		(state: any) => state.bank
	);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClose = () => {
		dispatch(updateOpenDrawerLiftsPosition(false));
	};

	const buildArrayLiftsPosition = (bank_: BankInterface) => {
		const newArr: LiftPositionInterface[] = [];
		let numberOfLifts: number = 0;
		let name = "";
		let liftId = "";
		if (bank_.number_of_lifts) {
			numberOfLifts = bank_.number_of_lifts;
		} else {
			numberOfLifts = 8;
		}
		// Create new array off lifts position
		for (let x = 0; x < numberOfLifts; x += 1) {
			let reserved = false;
			// Validate if position is reserved
			if (bank_ && bank_.lifts && bank_.lifts.length > 0) {
				name = (x + 1).toString();
				liftId = "";
				for (let y = 0; y < bank_.lifts.length; y += 1) {
					if (bank_.lifts[y].position === x + 1) {
						reserved = true;
						name = bank_.lifts[y].name || "--";
						liftId = bank_.lifts[y].id;
					}
				}
			}
			// Push new item in array
			newArr.push({
				name,
				id: x + 1,
				liftId,
				reserved,
				isValid: true,
			});
		}
		setListLiftPosition(newArr);
	};

	const validateLiftsPositions = (
		listLiftPosition_: LiftPositionInterface[]
	) => {
		const newArr: LiftPositionInterface[] = [];
		setFormIsValid(true);
		for (let x = 0; x < listLiftPosition_.length; x += 1) {
			const obj = {
				name: listLiftPosition_[x].name,
				id: listLiftPosition_[x].id,
				liftId: listLiftPosition_[x].liftId,
				reserved: listLiftPosition_[x].reserved,
				isValid: true,
			};
			for (let y = 0; y < listLiftPosition_.length; y += 1) {
				if (listLiftPosition_[x].id === listLiftPosition_[y].id && x !== y) {
					obj.isValid = false;
					setFormIsValid(false);
				}
			}
			newArr.push(obj);
		}
		setListLiftPosition(newArr);
	};

	const onChangeLiftPosition = (newValue: number, index: number) => {
		const newArr = [...listLiftPosition];
		newArr[index].id = newValue;
		validateLiftsPositions(newArr);
	};

	const onClickSave = () => {
		const newArr: any = [];
		listLiftPosition.map((item: any) => {
			if (item && item.liftId) {
				newArr.push({
					id: item.liftId,
					car_number: item.id,
				});
			}
			return true;
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openDrawerLiftsPosition) {
			setMaxLifts(bank.number_of_lifts || 0);
			buildArrayLiftsPosition(bank);
		}
	}, [openDrawerLiftsPosition]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClose}
			closable={false}
			open={openDrawerLiftsPosition}
		>
			<Spin spinning={false} tip={t("general.loading")}>
				{/** ************************************************* */}
				{/** BANK INFORMATION */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faBuildingShield}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("edgeManagement.banks.bankInformation")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{/* ID BANK */}
					<div className='generalStyles__flex'>
						<div>
							<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
								ID:
							</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>{bank.id ? bank.id : "--"}</p>
						</div>
					</div>
					{/* BANK NAME */}
					<div className='generalStyles__flex'>
						<div>
							<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
								{t("edgeManagement.banks.bankName")}
							</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>{bank.name ? bank.name : "--"}</p>
						</div>
					</div>
					{/* TOTAL LIFTS */}
					<div className='generalStyles__flex'>
						<div>
							<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
								{t("edgeManagement.lifts.lifts")}
							</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>
								{bank.number_of_lifts ? bank.number_of_lifts : "--"}
							</p>
						</div>
					</div>
					{/* FLOORS COUNT */}
					<div className='generalStyles__flex'>
						<div>
							<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
								{t("edgeManagement.banks.floorsBelowLobby")}
							</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>
								{bank.floor_count ? bank.floor_count : "--"}
							</p>
						</div>
					</div>
					{/* FLOORS BELOW LOBBY */}
					<div className='generalStyles__flex'>
						<div>
							<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
								{t("edgeManagement.banks.floorsBelowLobby")}
							</p>
						</div>
						<div className='drawer__textContainer'>
							<p className='drawer__text'>
								{bank.floors_below_lobby ? bank.floors_below_lobby : "--"}
							</p>
						</div>
					</div>
				</div>
				<br />
				{/** ************************************************* */}
				{/** LIFTS POSITION */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faArrowUp19}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("edgeManagement.banks.liftPosition")}
						</h5>
					</Divider>
				</div>
				<div>
					{listLiftPosition.map(
						(item: LiftPositionInterface, index: number) => (
							<div className={`drawer__box__${theme} mt-3`} key={index}>
								{/** ID */}
								{item.liftId !== "" && (
									<div className='col-md-12'>
										<div className='generalStyles__flex'>
											<div className='generalStyles__inputFlexName'>
												<span className='drawer__text'>ID</span>
											</div>
											<div className='generalStyles__width100'>
												<span>{item.liftId || "--"}</span>
											</div>
											<div className='generalStyles__infoTooltipIconBlank' />
										</div>
									</div>
								)}

								{/** NAME */}
								<div className='col-md-12 mt-3'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span className='drawer__text'>
												{t("configuration.configurationFiles.name")}
											</span>
										</div>
										<div className='generalStyles__width100'>
											{item.liftId !== "" ? (
												<span>{item.name}</span>
											) : (
												<Alert
													message={t("edgeManagement.banks.positionNotUsed")}
													type='info'
													showIcon
												/>
											)}
										</div>
										<div className='generalStyles__infoTooltipIconBlank' />
									</div>
								</div>
								{/** POSITION */}
								<div className='col-md-12 mt-3'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span> {t("edgeManagement.banks.liftPosition")}</span>
										</div>
										<div className='generalStyles__width100'>
											<InputNumber
												className='generalStyles__inputNumberFull'
												value={item.id}
												min={1}
												max={maxLifts}
												precision={0}
												status={!item.isValid ? "error" : ""}
												onChange={(e: any) => {
													onChangeLiftPosition(e, index);
												}}
											/>
										</div>
										<div className='mt-1'>
											{item.isValid ? (
												<Tooltip
													title={`${t("general.valid")}`}
													placement='top'
												>
													<FontAwesomeIcon
														icon={faCircleCheck}
														className='generalStyles__success generalStyles__mlFix'
													/>
												</Tooltip>
											) : (
												<Tooltip
													title={`${t("general.notValid")}`}
													placement='top'
												>
													<FontAwesomeIcon
														icon={faCircleExclamation}
														className='generalStyles__error generalStyles__mlFix'
													/>
												</Tooltip>
											)}
										</div>{" "}
									</div>
								</div>
								{/** STATUS */}
								<div className='col-md-12 mt-3'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span className='drawer__text'>
												{t("organizationManagement.status")}
											</span>
										</div>
										<div className='generalStyles__width100'>
											{item.reserved ? (
												<Tag color='red' style={{ textAlign: "center" }}>
													Reserved
												</Tag>
											) : (
												<Tag color='green'>Not used</Tag>
											)}
										</div>
										<div className='generalStyles__infoTooltipIconBlank' />
									</div>
								</div>
							</div>
						)
					)}
					<br />
					{/** ---------------------------------------------------- */}
					<div className='mt-4'>
						<div>
							<Button onClick={onClose} className='buttonStyle__3'>
								{t("organizationManagement.close")}
							</Button>
							<Button
								onClick={onClickSave}
								disabled={!formIsValid}
								className='buttonStyle__3'
								type='primary'
							>
								{t("general.save")}
							</Button>
						</div>
					</div>
					<br />
				</div>
			</Spin>
		</Drawer>
	);
}

export default BanksDrawerLiftsPosition;
