import React from "react";
// ANT DESIGN COMPONENTS
import { Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// COMPONENTS
import ReportsFilterChartType from "../filters/ReportsFilterChartType";
import ReportsFiltersCheckboxCars from "../filters/ReportsFiltersCheckboxCars";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsDrawerChartsFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeChartMirror = (newValue: boolean) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.chartMirror = newValue;
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`drawer__box__${theme}`}>
			{/* CHAR TYPE */}
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>{t("general.chartType")}:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					<ReportsFilterChartType />
				</div>
			</div>
			{/* CHAR MIRROR */}
			{filters.displayChartMirror && (
				<div className='generalStyles__flex mt-3'>
					<div className='generalStyles__inputFlexNameLarge'>
						<span className='generalStyles__inputFlexRequired'>*</span>
						<span className='drawer__title'>{t("general.mirror")}:</span>
					</div>
					<div className='drawer__textContainer generalStyles__width100'>
						<Switch
							onChange={onChangeChartMirror}
							checked={filters.chartMirror}
						/>
					</div>
				</div>
			)}
			{/* CARS */}
			<div className='generalStyles__flex mt-3'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>{t("general.cars")}:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					<ReportsFiltersCheckboxCars />
				</div>
			</div>
		</div>
	);
}

export default ReportsDrawerChartsFilters;
