import React from "react";

function EulaText() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__textJustify'>
			{/** ************************************************** */}
			{/** LIFNET EULA TEMPLATE PAGE 1 */}
			<h2>LIFTNET END USER LICENSE TERMS</h2>
			<p>Last Updated Date: [6 September 2023]</p>
			<p>
				These LiftNet End User License Terms (“EULA Terms”) govern your use of
				Integrated Display Systems LLC’s (“LiftNet”), web-based software as a
				services provided on a subscription basis and enabling the use of the
				proprietary software and any associated hardware provided by LiftNet
				(“Subscribed Services”), including all user manuals, technical manuals,
				and any other materials provided by in printed, electronic, or other
				form, that describe the Subscribed Services or their use or
				specifications (the “Documentation”) provided to you (“you” or “your”)
				for use pursuant to and subject to a Software as a Services (SaaS)
				Agreement (the “SaaS Terms”) between LiftNet and your employer or other
				person or entity who lawfully controls the computers, networks, and
				infrastructure for which the LiftNet Subscribed Services are provided
				(“Customer”).
			</p>
			<p>
				BY CHECKING THE “ACCEPT” BOX OR OTHERWISE INDICATING YOUR ACCEPTANCE OF
				THESE EULA TERMS IN THE LOGIN PROCESS, YOU: (i) REPRESENT THAT YOU ARE
				DULY AUTHORIZED BY CUSTOMER TO ACCESS AND USE THE SUBSCRIBED SERVICES;
				AND (ii) ACCEPT THESE EULA TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY
				THEM. IF YOU DO NOT AGREE TO THESE EULA TERMS, DO NOT CLICK TO ACCEPT OR
				OTHERWISE INDICATE YOUR ACCEPTANCE, AND YOU WILL HAVE NO LICENSE TO, AND
				MUST NOT ACCESS OR USE, THE SUBSCRIBED SERVICES.
			</p>
			<p>
				LIFTNET MAY, AT ANY TIME, AND AT LIFTNET’S SOLE DISCRETION, MODIFY THE
				EULA TERMS AND MAKE AN UPDATED VERSION AVAILABLE TO YOU FOR ACCEPTANCE
				AT A SUBSEQUENT LOGIN EVENT. IF YOU DO NOT AGREE TO THE UPDATED EULA
				TERMS, YOU SHOULD NOT CLICK TO ACCEPT OR OTHERWISE INDICATE YOUR
				ACCEPTANCE, AND YOU WILL HAVE NO RIGHT TO, AND MUST NOT ACCESS OR USE
				THE SUBSCRIBED SERVICES FOLLOWING SUCH AN UPDATE.
			</p>
			<p>
				<b>
					1.
					<span className='loginStyles__underLiying'>
						Access to Subscribed Services.
					</span>
				</b>{" "}
				Subject to your compliance with these EULA Terms, LiftNet hereby grants
				you a non-exclusive, non-transferable, non-sublicensable, license to use
				the Subscribed Services solely in accordance with the Documentation, as
				installed on the equipment provided or made available to you by Customer
				and for Customer’s internal business purposes only. Your rights
				hereunder will terminate immediately on the earlier to occur of: (a) the
				expiration or earlier termination of the SaaS Terms between LiftNet and
				Customer; or (b) your ceasing to be authorized by Customer to use the
				Subscribed Services for any or no reason.
			</p>
			<p>
				<b>
					2.
					<span className='loginStyles__underLiying'>Restrictions.</span>
				</b>{" "}
				You shall not, directly or indirectly: (a) access or use any portion of
				the Subscribed Services except as expressly authorized pursuant to the
				Quote or these SaaS Terms; (b) cause or permit decompilation, reverse
				assembly or reverse engineering of all or any portion of the Subscribed
				Services; (c) copy any ideas, features, functions or graphics of the
				Subscribed Services or modify or make derivative works based upon the
				Subscribed Services; (d) delete, fail to reproduce or modify any patent,
				copyright, trademark or other proprietary rights notices which appear on
				or in the Subscribed Services or Documentation; or (e) directly or
				indirectly, sublicense, relicense, distribute, disclose, use, rent or
				lease the Subscribed Services, or any portion thereof, for third party
				use, third party training, facilities management or time-sharing, or use
				as an application service provider or service bureau. Without limiting
				the foregoing, you may not use the Subscribed Services to: (i) send or
				store material containing viruses, worms, Trojan horses or other harmful
				computer code, files, scripts, agents or programs; (ii) interfere with
				or disrupt the integrity or performance of the Subscribed Services or
				the data contained therein; (iii) attempt to gain unauthorized access to
				the Subscribed Services; (iv) use the Subscribed Services or
				Documentation in violation of any law, regulation, or rule; or (v) use
				the Subscribed Services or Documentation for purposes of competitive
				analysis of the Subscribed Services, the development of a competing
				software product or service, or any other purpose that is to LiftNet’s
				commercial disadvantage.
			</p>
			<p>
				<b>
					3.
					<span className='loginStyles__underLiying'>Compliance Measures.</span>
				</b>{" "}
				The Subscribed Services may contain technological copy protection or
				other security features designed to prevent unauthorized use of the
				Subscribed Services, including features to protect against the use: (a)
				beyond the scope of the rights granted to you pursuant to Section 1; or
				(b) prohibited under Section 2. You shall not, and shall not attempt to,
				remove, disable, circumvent, or otherwise create or implement any
				workaround to, any such copy protection or security features.
			</p>
			{/** ************************************************** */}
			{/** LIFNET EULA TEMPLATE PAGE 2 */}
			<p>
				<b>
					4.
					<span className='loginStyles__underLiying'>Use of Information.</span>
				</b>{" "}
				LiftNet may, directly or indirectly through the services of others,
				collect and store information regarding use of the Subscribed Services
				and about equipment through which the Subscribed Services are accessed
				and used. You agree that LiftNet may use such information for any
				purpose related to any use of the Subscribed Services by you, including
				but not limited to: (a) improving the performance of the Subscribed
				Services or developing updates; and (b) verifying compliance with the
				terms of the SaaS Agreement and these EULA Terms and enforcing LiftNet’s
				rights, including all intellectual property rights in and to the
				Subscribed Services. You acknowledge and agree that the Subscribed
				Services are not intended to process, and you will not input an of the
				following information for yourself or any other person: account number
				(bank account, credit card, etc.); address; biometric identifier;
				license or identification number; date of birth; government identifiers
				(such as social security numbers); name; personnel number; photograph or
				video identifiable to an individual; and may also include other
				information related to an individual that may directly or indirectly
				identify the individual (e.g. salary, performance rating, purchase
				history, call history, etc.)
			</p>
			<p>
				<b>
					5.
					<span className='loginStyles__underLiying'>
						Intellectual Property Rights.
					</span>
				</b>{" "}
				You acknowledge that the Subscribed Services are not sold to you. You do
				not acquire any ownership interest in the Subscribed Services under
				these EULA Terms, or any other rights to the Subscribed Services other
				than to use them in accordance with the access right granted under these
				EULA Terms, subject to all terms, conditions, and restrictions. LiftNet
				reserves and shall retain its entire right, title, and interest in and
				to the Subscribed Services and all intellectual property rights arising
				out of or relating to the Subscribed Services, subject to the rights
				expressly granted to you under these EULA Terms. You shall use
				commercially reasonable efforts to safeguard all Subscribed Services
				from infringement, misappropriation, theft, misuse, or unauthorized
				access. You shall notify LiftNet or Customer immediately of any
				unauthorized use of any password or account credential issued to You or
				any other known or suspected breach of security of the Subscribed
				Services.
			</p>
			<p>
				<b>
					6.
					<span className='loginStyles__underLiying'>Disclaimers.</span>
				</b>{" "}
				IN NO EVENT WILL LIFTNET BE LIABLE TO YOU FOR ANY USE, INTERRUPTION,
				DELAY, OR INABILITY TO USE THE SUBSCRIBED SERVICES. YOU ARE PROVIDED
				ACCESS TO THE SUBSCRIBED SERVICES PURSUANT TO THE SAAS TERMS BETWEEN
				LIFTNET AND CUSTOMER, SOLELY FOR THE BENEFIT OF CUSTOMER AND AT
				CUSTOMER’S DISCRETION. YOU ACKNOWLEDGE THAT YOU HAVE NO RIGHTS UNDER THE
				SAAS TERMS INCLUDING ANY RIGHTS TO ENFORCE ANY OF ITS TERMS. ANY
				OBLIGATION OR LIABILITY LIFTNET MAY HAVE WITH RESPECT TO YOUR USE OR
				INABILITY TO USE THE SUBSCRIBED SERVICES SHALL BE SOLELY TO CUSTOMER
				PURSUANT TO THE SAAS TERMS AND SUBJECT TO ALL LIMITATIONS OF LIABILITY
				SET FORTH THEREIN.
			</p>
			<p>
				<b>
					7.
					<span className='loginStyles__underLiying'>Governing Law.</span>
				</b>{" "}
				These EULA Terms are governed by and construed in accordance with the
				internal laws of the State of Illinois without giving effect to any
				choice or conflict of law provision or rule (whether of the State of
				Illinois or any other jurisdiction) that would cause the application of
				laws of any jurisdiction other than those of the State of Illinois.
			</p>
		</div>
	);
}

export default EulaText;
