/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RoleState {
	role: Object;
	openModalCreateNewRole: boolean;
	openModalEditRole: boolean;
	openModalDeleteRole: boolean;
	openModalBlockUnlockRole: boolean;
	openDrawerRole: boolean;
	roleRefreshTable: boolean;
}

const initialState: RoleState = {
	role: {},
	openModalCreateNewRole: false,
	openModalEditRole: false,
	openModalDeleteRole: false,
	openModalBlockUnlockRole: false,
	openDrawerRole: false,
	roleRefreshTable: false,
};

export const roleSlice = createSlice({
	name: "role",
	initialState,
	reducers: {
		updateRole: (state, action: PayloadAction<Object>) => {
			state.role = action.payload;
		},
		updateOpenModalCreateNewRole: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateNewRole = action.payload;
		},
		updateOpenModalEditRole: (state, action: PayloadAction<boolean>) => {
			state.openModalEditRole = action.payload;
		},
		updateOpenModalDeleteRole: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteRole = action.payload;
		},
		updateOpenModalBlockUnlockRole: (state, action: PayloadAction<boolean>) => {
			state.openModalBlockUnlockRole = action.payload;
		},
		updateOpenDrawerRole: (state, action: PayloadAction<boolean>) => {
			state.openDrawerRole = action.payload;
		},
		updateRoleRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.roleRefreshTable = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateRole,
	updateOpenModalCreateNewRole,
	updateOpenModalEditRole,
	updateOpenModalDeleteRole,
	updateOpenModalBlockUnlockRole,
	updateOpenDrawerRole,
	updateRoleRefreshTable,
} = roleSlice.actions;

export default roleSlice.reducer;
