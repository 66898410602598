import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const actionAPI = createApi({
	reducerPath: "actionAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetActions"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ALL ACTIONS */
		getActions: builder.query({
			query: (body: any) => ({
				url: "/action-catalogs/?page=1&limit=9999",
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetActions"],
		}),
		//* *********************************************************** */
		//* CREATE ACTION */
		postAction: builder.mutation({
			query: (body: any) => ({
				url: "/action-catalogs/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetActions"],
		}),
		//* *********************************************************** */
		//* DELETE ACTION */
		deleteAction: builder.mutation({
			query: (body: any) => ({
				url: `/action-catalogs/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetActions"],
		}),
		//* *********************************************************** */
		//* EDIT ACTION */
		putAction: builder.mutation({
			query: (body: any) => ({
				url: `/action-catalogs/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetActions"],
		}),
	}),
});

export const {
	useLazyGetActionsQuery,
	usePostActionMutation,
	usePutActionMutation,
	useDeleteActionMutation,
} = actionAPI;
