import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Input, Select, InputNumber, Tooltip, Alert, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// FONT AWESOME LIBRYARY AND ICONS
import {
	faBuilding,
	faCircleInfo,
	faEthernet,
	faGears,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenModalCreateEditBank,
	updateBank,
} from "../../../redux/banks/bankSlice";
import ConfigurationFilesAssignConfiguration from "../../configurationManagement/configurationFiles/ConfigurationFilesAssignConfiguration";
import {
	usePostBankMutation,
	usePutBankMutation,
} from "../../../redux/banks/bankAPI";
// COMPONETNS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import SelectOrganization from "../../components/select/SelectOrganization";
import SelectServerByOrganization from "../../components/select/SelectServerByOrganization";
import AdaptersAssignAdapter from "../adapters/AdaptersAssignAdapter";
import DrawerCloseSaveButton from "../../components/drawer/DrawerCloseSaveButton";
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import { RootState } from "../../../app/store";
import {
	BanksCreateFormInterface,
	BankInterface,
} from "../../../interfaces/Bank.interface";
import { ChannelInterface } from "../../../interfaces/Channel.interface";
import { FloorsAndDirectionDataInterface } from "../../../interfaces/Configurations.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function BanksCreateForm({ renderComponent }: BanksCreateFormInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Option } = Select;
	const BLANK_FLOORS_DIRECTION: FloorsAndDirectionDataInterface = {
		numberOfLifts: 0,
		numberOfStops: 2,
		maxNumberOfStops: 0,
		numberOfFloorsBelowLobby: 0,
		configurationId: "",
		adapterId: "",
	};
	const BLANK_BANK: BankInterface = {
		id: "",
		name: "",
		type: "ELEVATOR",
		channel_id: "",
		server_id: "",
		configuration_id: "",
		adapter_id: "",
		columns: 0,
		stops: 0,
		number_of_lifts: 0,
		logical_port: 0,
		floor_count: 0,
		floor_below_lobby: 0,
		board: 1,
		rear_doors: false,
		active: true,
		control_panel: `{"carcall":{"code":"cc","map":[{"offset":"3c","target_bit":"01","new_bit":"10"}]},"rear_carcall":{"code":"rcc","map":[{"offset":"3c","new_byte":"1a"}]},"close_door":{"code":"cd","map":[{"offset":"3c","target_bit":"01","new_bit":"10"}]},"open_door":{"code":"od","map":[{"offset":"3c","new_byte":"1a"}]},"hall_call":{"code":"hc","map":[{"offset":"3c","target_bit":"01","new_bit":"10"}]},"secure":{"code":"sec","map":[{"offset":"3c","new_byte":"10"}]}}`,
		lifts: [],
		offsets: [],
		floor_names: [],
		direct_link: "",
		configurations: [],
		data_blocks: [],
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [bank, setBank] = useState(BLANK_BANK);
	const [idOrganization, setIdOrganization] = useState<string>("");
	const [channels, setChannels] = useState<ChannelInterface[]>([]);
	const [channelType, setChannelType] = useState<string>("");
	const [dataBlocks, setDataBlocks] = useState([]);
	const [floorsAndDirectionData, setFloorsAndDirectionData] =
		useState<FloorsAndDirectionDataInterface>(BLANK_FLOORS_DIRECTION);
	const [showLoading, setShowLoading] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		openModalCreateEditBank,
		formAction,
		bank: selectedBank,
	} = useSelector((state: RootState) => state.bank);
	const { theme } = useSelector((state: RootState) => state.home);
	const { server } = useSelector((state: RootState) => state.server);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPostBank,
		{
			isSuccess: isSuccessPostBank,
			isLoading: isLoadingPostBank,
			isError: isErrorPostBank,
			error: errorPostBank,
			reset: resetPostBank,
		},
	] = usePostBankMutation();

	const [
		triggerPutBank,
		{
			isSuccess: isSuccessPutBank,
			isError: isErrorPutBank,
			error: errorPutBank,
			isLoading: isLoadingPutBank,
			reset: resetPutBank,
		},
	] = usePutBankMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickSave = async () => {
		const token = await GETJwtToken();
		const BODY = { ...bank };
		if (
			channelType === "SERIAL" ||
			channelType === "TCP" ||
			channelType === "TCP_BOT"
		) {
			delete BODY.adapter_id;
		}
		if (channelType === "ADAPTER") {
			delete BODY.configuration_id;
		}
		if (formAction === "CREATE") {
			triggerPostBank({ token, body: BODY });
		} else {
			const copy = structuredClone(BODY);
			delete copy.data_blocks;
			delete copy.lifts;
			triggerPutBank({ token, body: copy, id: bank.id });
		}
	};

	const onChangeShowLoading = () => {
		setShowLoading(true);
		setTimeout(() => {
			setShowLoading(false);
		}, 300);
	};

	const resetData = () => {
		setBank(BLANK_BANK);
		setIdOrganization("");
		setChannels([]);
		setChannelType("");
		setDataBlocks([]);
		setFloorsAndDirectionData(BLANK_FLOORS_DIRECTION);
		resetPostBank();
		resetPutBank();
		dispatch(updateBank(BLANK_BANK));
	};

	const onClickClose = () => {
		resetData();
		dispatch(updateOpenModalCreateEditBank(false));
	};

	const onChangeFormValue = (newValue: string | number, inputName: string) => {
		const copy: BankInterface = structuredClone(bank);
		copy[inputName] = newValue;
		setBank(copy);
	};

	const onChangeIdOrganization = (newValue: string) => {
		setIdOrganization(newValue);
		const copy: BankInterface = structuredClone(bank);
		copy.server_id = "";
		copy.channel_id = "";
		setBank(copy);
	};

	const onChangeServer = (server_: any) => {
		const copy: any = structuredClone(bank);
		copy.server_id = server_.id;
		copy.channel_id = "";
		setBank(copy);
		if (server_.channels) {
			setChannels(server_.channels);
		}
	};

	const fillEditForm = () => {
		const copy = structuredClone(selectedBank);
		if (copy && copy.channel && copy.channel.server && copy.channel.server.id) {
			copy.server_id = copy.channel.server.id;
			setBank(copy);
		}
		if (copy && copy.channel && copy.channel.type) {
			setChannelType(copy.channel.type);
		}
		if (
			copy &&
			copy.channel &&
			copy.channel.server &&
			copy.channel.server.org &&
			copy.channel.server.org.id
		) {
			setIdOrganization(copy.channel.server.org.id);
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalCreateEditBank) {
			onChangeShowLoading();
			if (formAction === "EDIT") {
				fillEditForm();
			} else if (
				server &&
				server.id !== "" &&
				renderComponent === "serverPage"
			) {
				const copy: any = structuredClone(bank);
				copy.server_id = server.id;
				setBank(copy);
				if (server && server.channels) {
					setChannels(server.channels);
				}
				if (server && server.org && server.org.id) {
					setIdOrganization(server.org.id);
				}
			}
		}
	}, [openModalCreateEditBank]);

	useEffect(() => {
		if (bank.channel_id !== "") {
			const index = channels.findIndex((x: any) => x.id === bank.channel_id);
			if (index >= 0) {
				setChannelType(channels[index].type);
			}
		}
	}, [bank.channel_id]);

	useEffect(() => {
		onChangeShowLoading();
	}, [bank.type, idOrganization, bank.server_id]);

	useEffect(() => {
		if (dataBlocks) {
			const copy: BankInterface = structuredClone(bank);
			copy.data_blocks = dataBlocks;
			setBank(copy);
		}
	}, [dataBlocks]);

	useEffect(() => {
		if (floorsAndDirectionData) {
			const copy: BankInterface = structuredClone(bank);
			if (channelType !== "ADAPTER") {
				copy.floor_count = floorsAndDirectionData.numberOfStops;
				copy.floor_below_lobby =
					floorsAndDirectionData.numberOfFloorsBelowLobby;
				copy.stops = floorsAndDirectionData.numberOfStops;
				copy.number_of_lifts = floorsAndDirectionData.numberOfLifts;
				copy.configuration_id = floorsAndDirectionData.configurationId;
			} else {
				copy.floor_count = floorsAndDirectionData.numberOfStops;
				copy.floor_below_lobby =
					floorsAndDirectionData.numberOfFloorsBelowLobby;
				copy.stops = floorsAndDirectionData.numberOfStops;
				copy.number_of_lifts = floorsAndDirectionData.numberOfLifts;
				copy.adapter_id = floorsAndDirectionData.adapterId;
			}
			setBank(copy);
		}
	}, [floorsAndDirectionData]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPostBank, isErrorPostBank, isSuccessPutBank, isErrorPutBank]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='650'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openModalCreateEditBank}
		>
			<Spin spinning={showLoading || isLoadingPostBank || isLoadingPutBank}>
				{/** ************************************************************* */}
				{/** DIVIDER BANK */}
				<DrawerRowDivider
					icon={faBuilding}
					title={
						formAction === "CREATE"
							? t("edgeManagement.banks.createNewBank")
							: t("edgeManagement.banks.editBank")
					}
				/>
				<div className={`drawer__box__${theme}`}>
					{/** NAME */}
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p className='drawer__title'>
								{" "}
								<span className='generalStyles__inputFlexRequired'>*</span>
								{t("general.name")}
							</p>
						</div>
						<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
							<Input
								size='small'
								onChange={(value) => {
									onChangeFormValue(value.target.value, "name");
								}}
								value={bank.name}
							/>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** TYPE */}
					<div className='generalStyles__flex drawer__container mt-1'>
						<div>
							<p className='drawer__title'>
								{" "}
								<span className='generalStyles__inputFlexRequired'>*</span>
								{t("general.type")}
							</p>
						</div>
						<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
							<Select
								value={bank.type}
								onChange={(value) => {
									onChangeFormValue(value, "type");
								}}
								className='generalStyles__width100'
								size='small'
								disabled={formAction === "EDIT"}
							>
								{["ELEVATOR", "ESCALATOR", "MOVINGWALK", "BOT", "GPIO"].map(
									(item: string) => (
										<Option key={item} value={item}>
											{item}
										</Option>
									)
								)}
							</Select>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** BOARD */}
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p className='drawer__title'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								{t("configuration.configurationFiles.board")}
							</p>
						</div>
						<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
							<InputNumber
								className='generalStyles__inputNumberFull'
								precision={0}
								value={bank.board}
								onChange={(e: any) => {
									onChangeFormValue(e, "board");
								}}
								max={99999}
								size='small'
							/>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
				</div>
				<br />
				{/** ************************************************************* */}
				{/** DIVIDER BANK CONFIGURATION */}
				<DrawerRowDivider icon={faEthernet} title={t("general.channel")} />
				<div className={`drawer__box__${theme}`}>
					{/** ORGANIZATION */}
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p className='drawer__title'>
								{" "}
								<span className='generalStyles__inputFlexRequired'>*</span>
								{t("userManagement.organization")}
							</p>
						</div>
						<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
							<SelectOrganization
								onChangeIdOrganization={onChangeIdOrganization}
								value={idOrganization}
								disabledInput={
									renderComponent === "serverPage" || formAction === "EDIT"
								}
							/>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** SERVER */}
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p className='drawer__title'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								{t("edgeManagement.servers.server")}
							</p>
						</div>
						<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
							<SelectServerByOrganization
								onChangeServer={onChangeServer}
								value={bank.server_id}
								idOrganization={idOrganization}
								disabledInput={
									renderComponent === "serverPage" || formAction === "EDIT"
								}
							/>
							<div className='generalStyles__infoTooltipIconBlank'>
								{bank.server_id === "" && idOrganization === "" && (
									<Tooltip
										title={t("tooltip.organizationRequired")}
										placement='left'
									>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
					{/** CHANNEL */}
					<div className='generalStyles__flex drawer__container'>
						<div>
							<p className='drawer__title'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								{t("edgeManagement.channels.channel")}
							</p>
						</div>
						<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
							{formAction === "CREATE" ? (
								<Select
									value={bank.channel_id}
									onChange={(value) => {
										onChangeFormValue(value, "channel_id");
									}}
									className='generalStyles__width100'
									size='small'
									disabled={bank.server_id === ""}
								>
									{channels.map((item: ChannelInterface) => (
										<Option key={item.id} value={item.id}>
											<span className='generalStyles__info'>{item.type}</span>{" "}
											<span>{item.name}</span>{" "}
										</Option>
									))}
								</Select>
							) : (
								<Input
									size='small'
									disabled
									value={
										selectedBank &&
										selectedBank.channel &&
										selectedBank.channel.name
											? selectedBank.channel.name
											: "--"
									}
								/>
							)}
							<div className='generalStyles__infoTooltipIconBlank'>
								{bank.channel_id === "" && (
									<Tooltip title={t("tooltip.serverRequired")} placement='left'>
										<FontAwesomeIcon
											icon={faCircleInfo}
											className='generalStyles__infoTooltipIcon'
										/>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
				</div>
				{/** ************************************************************* */}
				{/** DIVIDER ASSIGN CONFIGURATION */}
				{formAction === "CREATE" && (
					<>
						{(channelType === "SERIAL" || channelType === "TCP") &&
							bank.type !== "GPIO" && (
								<>
									<br />
									<ConfigurationFilesAssignConfiguration
										setDataBlocks={setDataBlocks}
										liftType={bank.type}
										setFloorsAndDirectionData={setFloorsAndDirectionData}
									/>
								</>
							)}
						{(channelType === "SERIAL" || channelType === "TCP") &&
							bank.type === "GPIO" && (
								<>
									<br />
									<DrawerRowDivider
										icon={faGears}
										title={`${t("general.configuration")}`}
									/>
									<div className={`drawer__box__${theme}`}>
										<Alert
											message={t("edgeManagement.banks.gpioNotAssignable")}
											type='warning'
											showIcon
											closable
										/>
									</div>
								</>
							)}
						{/** ************************************************************* */}
						{/** DIVIDER ASSIGN ADAPTER */}
						{channelType === "ADAPTER" && (
							<>
								<br />
								<AdaptersAssignAdapter
									liftType={bank.type}
									setFloorsAndDirectionData={setFloorsAndDirectionData}
								/>
							</>
						)}
						{/** ************************************************************* */}
						{/** DIVIDER ASSIGN ADAPTER OR CONFIGURATION */}
						{channelType === "" && (
							<>
								<br />
								<DrawerRowDivider
									icon={faGears}
									title={`${t(
										"edgeManagement.banks.configurationAndAdapters"
									)}`}
								/>
								<div className={`drawer__box__${theme}`}>
									<Alert
										message={t(
											"edgeManagement.banks.configurationAndAdaptersDescription"
										)}
										type='warning'
										showIcon
										closable={false}
									/>
								</div>
							</>
						)}
					</>
				)}
				{/** ************************************************************* */}
				{/** COMPONENT CLOSE SAVE BUTTON */}
				<DrawerCloseSaveButton
					onClickClose={onClickClose}
					onClickSave={onClickSave}
					disableSaveButton={false}
				/>
				{/** ************************************************************* */}
				{/** GLOBAL ALERT */}
				<GlobalAlert2
					isError={isErrorPostBank}
					isSuccess={isSuccessPostBank}
					requestType='POST'
					error={errorPostBank}
					name='Bank'
				/>
				<GlobalAlert2
					isError={isErrorPutBank}
					isSuccess={isSuccessPutBank}
					requestType='PUT'
					error={errorPutBank}
					name='Bank'
				/>
			</Spin>
		</Drawer>
	);
}

export default BanksCreateForm;
