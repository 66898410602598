import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBan,
	faCircleCheck,
	faEthernet,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";

function DrawerChannelInformation({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider
				icon={faEthernet}
				title={t("edgeManagement.channels.channelInformation")}
			/>
			{/** ************************************************* */}
			<div className={`drawer__box__${theme} mt-3`}>
				{/* ID */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							ID
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data && data.id ? data.id : "--"}</p>
					</div>
				</div>
				{/* STATUS */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("general.status")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.active ? (
								<Tag
									color='success'
									icon={
										<FontAwesomeIcon
											icon={faCircleCheck}
											className='generalStyles__mrFix'
										/>
									}
									className='drawer__tag'
									style={{ width: "108px", textAlign: "center" }}
								>
									{t("userManagement.active").toUpperCase()}
								</Tag>
							) : (
								<Tag
									color='error'
									icon={
										<FontAwesomeIcon
											icon={faBan}
											className='generalStyles__mrFix'
										/>
									}
									className='drawer__tag'
									style={{ width: "108px", textAlign: "center" }}
								>
									{t("userManagement.lock").toUpperCase()}
								</Tag>
							)}
						</p>
					</div>
				</div>
				{/* TYPE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("general.type")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<div className=''>
							{data && data.type && (
								<>
									{data.type === "TCP" && (
										<Tag color='blue' className='drawer__typeTag'>
											{data.type}
										</Tag>
									)}
									{data.type === "ADAPTER" && (
										<Tag color='orange' className='drawer__typeTag'>
											{data.type}
										</Tag>
									)}
									{data.type === "SERIAL" && (
										<Tag color='green' className='drawer__typeTag'>
											{data.type}
										</Tag>
									)}{" "}
								</>
							)}
						</div>
					</div>
				</div>
				{/* NAME */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("general.name")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.name ? data.name : "--"}
						</p>
					</div>
				</div>
				{/* INDEX */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("edgeManagement.channels.index")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.index ? data.index : "--"}
						</p>
					</div>
				</div>
				{/* BAUD RATE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("edgeManagement.servers.baudRate")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.baud_rate ? data.baud_rate : "--"}
						</p>
					</div>
				</div>
				{/* MESSAGE TYPE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("edgeManagement.channels.messageType")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.message_type ? data.message_type : "--"}
						</p>
					</div>
				</div>
				{/* SOCKET ADDRESS */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("edgeManagement.channels.socketAddress")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.socket_address ? data.socket_address : "--"}
						</p>
					</div>
				</div>
				{/* VENDOR SOCKET ADDRESS */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("edgeManagement.channels.vendorSocketAddress")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.vendor_socket_address
								? data.vendor_socket_address
								: "--"}
						</p>
					</div>
				</div>
				{/* REPORTING SOCKET ADDRESS */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("edgeManagement.channels.reportingSocketAddress")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.reporting_socket_address
								? data.reporting_socket_address
								: "--"}
						</p>
					</div>
				</div>
				{/* HEART BEAT FREQUENCY */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("edgeManagement.channels.heartbeatFrequency")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.heartbeat_frequency
								? data.heartbeat_frequency
								: "--"}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DrawerChannelInformation;
