import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tooltip } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenModalControlPanel,
	updateLiftSelected,
	updateLayoutSelected,
	updateExistsFaultsInMonitoringView,
	updateModalControlPanelTab,
	updateExistsFaultsInLiftSelected,
	updatePannelFirstState,
} from "../../../redux/monitoring/monitoringSlice";
import { udpateOpenModalTotpToken } from "../../../redux/authentication/authenticationSlice";
// COMPONENTS
import MonitoringIconsStatus from "../monitoringIcons/MonitoringIconsStatus";
// INTERFACES
import {
	MonitoringLiftStatusViewInterface,
	SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function MonitoringLiftStatusView({
	lift,
	layout,
	socketMessage,
}: MonitoringLiftStatusViewInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [liftStatus, setLiftStatus] = useState<
		"" | "SUCCESS" | "ERROR" | "SERVICE"
	>("");
	const [hasFaults, setHasFaults] = useState<boolean>(false);
	const [faultMessage, setFaultMessage] = useState<boolean>(false);
	const [lastSocketMessage, setLastSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { totpTokenIsValid } = useSelector(
		(state: RootState) => state.authentication
	);
	const { faults } = useSelector((state: RootState) => state.monitoring);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const onClickOpenModalElevator = () => {
		dispatch(updateLiftSelected(lift));
		dispatch(updateLayoutSelected(layout));
		dispatch(
			updateModalControlPanelTab({
				liftType: "ELEVATOR",
				tab: hasFaults || faultMessage ? "FAULTS" : "PANEL",
			})
		);
		dispatch(updateExistsFaultsInLiftSelected(hasFaults));
		if (
			// eslint-disable-next-line no-constant-condition
			totpTokenIsValid ||
			process.env.REACT_APP_ENVIRONMENT === "OFFLINE" ||
			true
		) {
			dispatch(updateOpenModalControlPanel(true));
			dispatch(updatePannelFirstState(lastSocketMessage));
		} else {
			dispatch(udpateOpenModalTotpToken(true));
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (lift && lift.id === socketMessage.lift_id) {
			setLastSocketMessage(socketMessage);
		}
	}, [socketMessage]);

	useEffect(() => {
		if (
			lastSocketMessage.icon.type.toUpperCase() !== "FAULT" &&
			lastSocketMessage.icon.type.toUpperCase() !== "ERROR"
		) {
			setLiftStatus("SUCCESS");
		}
		// FAULT MESSAGE
		if (
			lastSocketMessage.icon.type.toUpperCase() === "FAULT" ||
			lastSocketMessage.icon.type.toUpperCase() === "ERROR"
		) {
			setLiftStatus("ERROR");
			setFaultMessage(true);
			setHasFaults(true);
			dispatch(updateExistsFaultsInMonitoringView(true));
			setTimeout(() => {
				setFaultMessage(false);
			}, 1000 * 60 * 60);
		}
	}, [lastSocketMessage]);

	useEffect(() => {
		if (
			faults &&
			faults.liftId &&
			faults.liftId !== "" &&
			lift &&
			faults.type === "LIFT" &&
			faults.liftId === lift.id
		) {
			setHasFaults(false);
			setFaultMessage(false);
			setLiftStatus("SUCCESS");
		}
		if (faults && faults.type === "ALL") {
			setHasFaults(false);
			setFaultMessage(false);
			setLiftStatus("SUCCESS");
		}
	}, [faults]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div>
				<div
					className={`monitoringView__topViewContainer__${theme}  ${
						faultMessage ? `monitoringView__dangerElevator   ` : ""
					}${faultMessage ? `monitoringView__dangerElevator__top ` : ""}${
						faultMessage ? `monitoringView__dangerElevator__bottom` : ""
					}  `}
					style={{
						height: "30px",
						width: "30px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						marginLeft: "6px",
						marginRight: "6px",
						marginTop: "-10px",
					}}
				>
					<div>
						<MonitoringIconsStatus
							liftStatus={liftStatus}
							hasFaults={hasFaults}
							carService={lastSocketMessage.service_mode.name}
						/>
					</div>
				</div>
				{/** ELEVATOR NAME */}
				<div
					role='button'
					tabIndex={0}
					onKeyDown={() => {}}
					onClick={() => {
						onClickOpenModalElevator();
					}}
					style={{
						marginTop: "5px",
						fontSize: "9px",
						fontWeight: "bolder",
						textAlign: "center",
					}}
				>
					{faultMessage || hasFaults ? (
						<div>
							<div>
								<Tooltip placement='top' title={lift.name}>
									<span className='generalStyles__error'>
										{lift && lift.name
											? lift.name.toUpperCase().substring(0, 6)
											: ""}
									</span>
								</Tooltip>
							</div>
						</div>
					) : (
						<div>
							<div>
								<Tooltip placement='top' title={lift.name}>
									<span className=''>
										{lift && lift.name
											? lift.name.toUpperCase().substring(0, 6)
											: ""}
									</span>
								</Tooltip>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default MonitoringLiftStatusView;
