import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Radio, Empty, Spin, Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePostMonitoringCommandMutation,
	usePostEmulatorCommandMutation,
} from "../../../redux/monitoring/monitoringAPI";
// INTERFACES
import {
	BankFloorInterface,
	ControlPanelHallCallInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
// AUTHENTICATION
import GETJwtToken from "../../../redux/authentication/authentication";

function ControlPanelHallCall({ currentFloor }: ControlPanelHallCallInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [hallCallSelected, setHallCallSelected] = useState<"U" | "D">("U");
	const [doorSelected, setDoorSelected] = useState<"F" | "R">("F");
	const [secureCalls, setSecureCalls] = useState<boolean>(false);
	const [displayFrontDoors, setDisplayFrontDoors] = useState<boolean>(true);
	const [displayRearDoors, setDisplayRearDoors] = useState<boolean>(true);
	const [showLoading, setShowLoading] = useState<boolean>(true);
	const [spinningFloorNo, setSpinningFloorNo] = useState<number>(0);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { liftSelected, layoutSelected, socketConnection } = useSelector(
		(state: RootState) => state.monitoring
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [postCommand, { isLoading: isLoadingPostCommand }] =
		usePostMonitoringCommandMutation();

	const [postCommandEmulator, { isLoading: isLoadingPostCommandEmulator }] =
		usePostEmulatorCommandMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickHallCall = async (floor: number) => {
		const token = await GETJwtToken();
		if (socketConnection !== "EMULATOR") {
			const BODY = {
				token,
				body: {
					cmd_adapter:
						layoutSelected &&
						layoutSelected.typeChannel &&
						layoutSelected.typeChannel === "ADAPTER",
					cmd_off: false,
					lift_id: layoutSelected.lifts[0].id,
					short_msg: `${secureCalls ? "S" : ""}E${hallCallSelected}${
						doorSelected === "R" ? "R" : ""
					}HC${floor}`,
				},
				serverId: layoutSelected.idServer,
			};
			postCommand(BODY);
		} else {
			const BODY = {
				token,
				body: {
					type: "HALLCALL",
					lift_id: liftSelected.id,
					floor,
					bank_id: layoutSelected.idBank,
					front_rear: doorSelected === "F" ? "front" : "rear",
					upDown: hallCallSelected === "U" ? "up" : "down",
					upDownAssigned:
						hallCallSelected === "U" ? "upAssigned" : "downAssigned",
				},
				serverId: layoutSelected.idServer,
			};
			postCommandEmulator(BODY);
		}
	};

	const onChangeSecureCalls = (checked: boolean) => {
		setSecureCalls(checked);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		setShowLoading(true);
		setTimeout(() => {
			setShowLoading(false);
		}, 500);
	}, []);

	useEffect(() => {
		let bankHasFrontDoors = false;
		let bankHasRearDoors = false;
		if (layoutSelected && layoutSelected.bank_floors) {
			layoutSelected.bank_floors.map((floor: any) => {
				if (floor.front_door) {
					bankHasFrontDoors = true;
				}
				if (floor.rear_door) {
					bankHasRearDoors = true;
				}
				return true;
			});
		}
		setDisplayFrontDoors(bankHasFrontDoors);
		setDisplayRearDoors(bankHasRearDoors);
	}, [layoutSelected]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className='monitoringView__detailsBox'
			style={{ minHeight: "400px", height: "auto" }}
		>
			<div
				className={`montoringView__controlPanel montoringView__controlPanel__${theme}`}
				style={{
					minHeight: "400px",
					height: "auto",
					paddingBottom: "25px",
				}}
			>
				<Spin spinning={showLoading} style={{ paddingTop: "250px" }}>
					{!showLoading && (
						<>
							{(displayFrontDoors || displayRearDoors) && (
								<>
									{/** ACTIONS */}
									<div className={` drawer__box__${theme} mt-3`}>
										<div className='generalStyles__spaceBetween'>
											<div className='generalStyles__flex'>
												<Radio.Group
													value={doorSelected}
													onChange={(e) => {
														setDoorSelected(e.target.value);
													}}
												>
													{displayFrontDoors && (
														<Radio value='F'>
															{t("configuration.configurationFiles.frontDoors")}
														</Radio>
													)}
													{displayRearDoors && (
														<Radio value='R'>
															{t("configuration.configurationFiles.rearDoors")}
														</Radio>
													)}
												</Radio.Group>
											</div>
											{layoutSelected &&
												layoutSelected.idOrganization &&
												layoutSelected.idOrganization ===
													"88bed3b9-28b1-452f-a2bb-f4a29646bbf8" && (
													<div>
														{secureCalls && (
															<FontAwesomeIcon
																icon={faLock}
																fontSize={18}
																className='monitoring__modalSettings__icon generalStyles__mrFix'
															/>
														)}
														<span
															className='generalStyles__mrFix'
															style={{ marginTop: "5px" }}
														>
															{t("general.secureCalls")}
														</span>
														<Switch
															checked={secureCalls}
															onChange={onChangeSecureCalls}
														/>
													</div>
												)}
										</div>
									</div>
									{/** ACTIONS */}
									<div className={` drawer__box__${theme} mt-3`}>
										<div className='generalStyles__flex'>
											<div className='generalStyles__width100'>
												<div className='generalStyles__flex'>
													<Radio.Group
														value={hallCallSelected}
														onChange={(e) => {
															setHallCallSelected(e.target.value);
														}}
													>
														<Radio value='U'>UP</Radio>
														<Radio value='D'>DOWN</Radio>
													</Radio.Group>
												</div>
											</div>
										</div>
									</div>
								</>
							)}
							{/** PANEL */}
							<div className={`drawer__box__${theme} mt-3`}>
								<div className='monitoringView__controlPanelPanel'>
									{displayFrontDoors || displayRearDoors ? (
										<>
											{layoutSelected.bank_floors.map(
												(floor: BankFloorInterface) => (
													<div key={floor.id}>
														<Button
															className={`monitoring__modalSettings__button__1 ${
																liftSelected && currentFloor === floor.floor_no
																	? `monitoringView__controlPanelNumber__active__${theme}`
																	: `monitoringView__controlPanelNumber__${theme}`
															}`}
															shape='circle'
															onClick={() => {
																onClickHallCall(floor.floor_no);
																setSpinningFloorNo(floor.floor_no);
															}}
															disabled={
																(doorSelected === "F" && !floor.front_door) ||
																(doorSelected === "R" && !floor.rear_door)
															}
														>
															{(isLoadingPostCommand ||
																isLoadingPostCommandEmulator) &&
															spinningFloorNo === floor.floor_no ? (
																<Spin
																	spinning={
																		(isLoadingPostCommand ||
																			isLoadingPostCommandEmulator) &&
																		spinningFloorNo === floor.floor_no
																	}
																/>
															) : (
																<span style={{ marginTop: "3px" }}>
																	{floor && floor.name && floor.name !== ""
																		? floor.name.substring(0, 3).toUpperCase()
																		: floor.floor_index}
																</span>
															)}
														</Button>{" "}
													</div>
												)
											)}
										</>
									) : (
										<Empty
											description={
												<span>{t("general.bankHasNoFrontRearDoors")}</span>
											}
										/>
									)}
								</div>
							</div>
						</>
					)}
				</Spin>
			</div>
		</div>
	);
}

export default ControlPanelHallCall;
