import React from "react";
// ANT DESIGN COMPONENTS
import { Button, Checkbox } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTable } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateTerminalResetTable,
	updateTerminalShowJsonObject,
	updateTerminalAutoScroll,
	updateTerminalLastShortMessage,
	updateTerminalShowAllMessages,
	updateTerminalDownloadReport,
} from "../../../redux/monitoring/monitoringSlice";
// INTERFACES
import { RootState } from "../../../app/store";

function TerminalFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		terminalShowJsonObject,
		terminalAutoScroll,
		terminalShowAllMessages,
	} = useSelector((state: RootState) => state.monitoring);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickResetTable = () => {
		dispatch(updateTerminalResetTable(true));
		dispatch(updateTerminalLastShortMessage(BLANK_SOCKET_MESSAGE));
		setTimeout(() => {
			dispatch(updateTerminalResetTable(false));
		}, 100);
	};

	const onChangeShowJsonObject = (newValue: boolean) => {
		dispatch(updateTerminalShowJsonObject(newValue));
	};

	const onChangeAutoScroll = (newValue: boolean) => {
		dispatch(updateTerminalAutoScroll(newValue));
	};

	const onChangeShowAllMessages = (newValue: boolean) => {
		dispatch(updateTerminalShowAllMessages(newValue));
		dispatch(updateTerminalAutoScroll(false));
	};

	const onClickDownloadExcel = () => {
		dispatch(updateTerminalDownloadReport(true));
		setTimeout(() => {
			dispatch(updateTerminalDownloadReport(false));
		}, 100);
	};
	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__flex'>
			{/** LIFT */}
			<div className='col-md-12'>
				<div className='generalStyles__spaceBetween'>
					<div />

					<div className='generalStyles__flex'>
						<div className='generalStyles__mlFix mt-1'>
							<Checkbox
								onChange={(e) => {
									onChangeShowAllMessages(e.target.checked);
								}}
								checked={terminalShowAllMessages}
							>
								All Messages
							</Checkbox>
						</div>
						<div className='generalStyles__mlFix mt-1'>
							<Checkbox
								onChange={(e) => {
									onChangeAutoScroll(e.target.checked);
								}}
								checked={terminalAutoScroll}
							>
								Auto Scroll
							</Checkbox>
						</div>
						<div className='generalStyles__mlFix generalStyles__mrFix mt-1'>
							<Checkbox
								onChange={(e) => {
									onChangeShowJsonObject(e.target.checked);
								}}
								checked={terminalShowJsonObject}
							>
								JSON Object
							</Checkbox>
						</div>
						<div>
							<Button
								className='buttonStyle__1  generalStyles__mrFix '
								onClick={onClickResetTable}
								icon={
									<FontAwesomeIcon
										className='generalStyles__mrFix '
										icon={faTable}
									/>
								}
							>
								Reset Table
							</Button>
						</div>
						<div>
							<Button
								className='buttonStyle__8  generalStyles__mrFix '
								onClick={onClickDownloadExcel}
								icon={
									<FontAwesomeIcon
										className='generalStyles__mrFix'
										icon={faDownload}
									/>
								}
							>
								Download Excel File
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TerminalFilters;
