import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteIconDefinitionMutation } from "../../../redux/configurationFiles/configurationFilesAPI";
import { updateOpenModalDeleteIconDefinition } from "../../../redux/tools/toolsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

library.add(faTrash, faTriangleExclamation);

function IconDefinitionDeleteModal() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] =
		useState(true);
	const [confirmName, setConfirmName] = useState("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { iconDefinition, openModalDeleteIconDefinition } = useSelector(
		(state: any) => state.tool
	);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerDeleteIconDefinition,
		{ isLoading, isSuccess, error, isError, reset: resetDeleteIconDefinition },
	] = useDeleteIconDefinitionMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenModalDeleteIconDefinition(false));
	};

	const handleDeleteItem = async () => {
		const token = await GETJwtToken();
		triggerDeleteIconDefinition({ id: iconDefinition.id, token });
	};

	const handleSubmitDeleteItem = async (event: any) => {
		if (event.key === "Enter" && !disableButtonConfirmDelete) {
			const token = await GETJwtToken();
			triggerDeleteIconDefinition({ id: iconDefinition.id, token });
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalDeleteIconDefinition) {
			setDisableButtonConfirmDelete(true);
			setConfirmName("");
			resetDeleteIconDefinition();
		}
	}, [openModalDeleteIconDefinition]);

	useEffect(() => {
		setDisableButtonConfirmDelete(true);
		if (
			confirmName &&
			iconDefinition &&
			iconDefinition.message &&
			confirmName.toLowerCase() === iconDefinition.message.toLowerCase()
		) {
			setDisableButtonConfirmDelete(false);
		} else {
			setDisableButtonConfirmDelete(true);
		}
	}, [confirmName]);

	useEffect(() => {
		onClickClose();
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={onClickClose}
			closable={false}
			onCancel={onClickClose}
			open={openModalDeleteIconDefinition}
			footer={null}
		>
			<Spin spinning={isLoading}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<p className='mt-3 drawer__text'>
							{t("organizationManagement.deleteDescription.text1")}
							<b className='generalStyles__warning'>
								{t("organizationManagement.deleteDescription.text2")}{" "}
							</b>
							{t("configuration.iconDefinition.theIconDefinition")}{" "}
							<b className='generalStyles__warning'>
								{iconDefinition && iconDefinition.message
									? iconDefinition.message
									: ""}{" "}
							</b>
							{t("configuration.iconDefinition.confirmIconDefinitionName")}{" "}
						</p>
						<Input
							placeholder={t("general.name")}
							onChange={(e) => {
								setConfirmName(e.target.value);
							}}
							value={confirmName}
							onKeyDown={handleSubmitDeleteItem}
							status='warning'
						/>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button
								htmlType='submit'
								className={styleDeleteButton(disableButtonConfirmDelete, theme)}
								onClick={handleDeleteItem}
								disabled={disableButtonConfirmDelete}
							>
								{t("organizationManagement.delete")}
							</Button>
							<Button onClick={onClickClose} className='buttonStyle__4'>
								{t("organizationManagement.close")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='DELETE'
				error={error}
				name='Icon Definition'
			/>
		</Modal>
	);
}

export default IconDefinitionDeleteModal;
