import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import SpecialSwitchesCatalogTable from "./SpecialSwitchesCatalogTable";
import SpecialSwitchesCatalogDrawer from "./SpecialSwitchesCatalogDrawer";
import SpecialSwitchesCatalogForm from "./SpecialSwitchesCatalogForm";
import SpecialSwitchesCatalogDeleteModal from "./SpecialSwitchesCatalogDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

// eslint-disable-next-line arrow-body-style
const SpecialSwitchesCatalogPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("special-switches-catalog", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<SpecialSwitchesCatalogTable />
					<SpecialSwitchesCatalogDrawer />
					<SpecialSwitchesCatalogForm />
					<SpecialSwitchesCatalogDeleteModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default SpecialSwitchesCatalogPage;
