import React from "react";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// INTERFACES
import { DrawerRowItemInterface } from "../../../interfaces/Global.interface";

function DrawerRowItem({ title, value }: DrawerRowItemInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__flex drawer__container'>
			<div>
				<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
					{title}
				</p>
			</div>
			<div className='drawer__textContainer'>
				<p className='drawer__text'>{value}</p>
			</div>
		</div>
	);
}

export default DrawerRowItem;
