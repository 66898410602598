import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const toolAPI = createApi({
	reducerPath: "toolAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetPermissions"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ALL PERMISSIONS */
		getPermissions: builder.query({
			query: (body: any) => ({
				url: `/permissions/?&page=${body.page}&limit=${body.limit}${
					body.searchAndFilterString || ""
				}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetPermissions"],
		}),
		//* *********************************************************** */
		//* CREATE PERMISSION */
		postPermission: builder.mutation({
			query: (body: any) => ({
				url: "/permissions/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetPermissions"],
		}),
		//* *********************************************************** */
		//* EDIT PERMISSION */
		putPermission: builder.mutation({
			query: (body: any) => ({
				url: `/permissions/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetPermissions"],
		}),
		//* *********************************************************** */
		//* DELETE PERMISSION */
		deletePermission: builder.mutation({
			query: (body: any) => ({
				url: `/permissions/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetPermissions"],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetPermissionsQuery,
	usePostPermissionMutation,
	usePutPermissionMutation,
	useDeletePermissionMutation,
} = toolAPI;
