import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";
import { OrganizationInterface } from "../../../interfaces/Organizations.interface";
import { SelectOrganizationInterface } from "../../../interfaces/Global.interface";

function SelectOrganization({
	value,
	onChangeIdOrganization,
	disabledInput,
}: SelectOrganizationInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [organizations, setOrganizations] = useState<OrganizationInterface[]>(
		[]
	);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { userLogged } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetOrganizations, { data, isLoading }] =
		useLazyGetOrganizationAndChildsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetOrganizations({
			page: 1,
			limit: 9999,
			token,
			orgId: userLogged.org.id,
		});
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (data && data.data && data.data.children) {
			const myOrganization = {
				id: data.data.id,
				address: data.data.address,
				city: data.data.city,
				country: data.data.country,
				name: data.data.name,
				phone_code: data.data.phone_code,
				phone: data.data.phone,
				state: data.data.state,
				type: data.data.type,
			};
			setOrganizations([myOrganization, ...data.data.children]);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Select
			showSearch
			filterOption={(input: any, option: any) =>
				option.children.toLowerCase().includes(input.toLowerCase())
			}
			className='generalStyles__width100'
			size='small'
			loading={isLoading}
			value={value}
			onChange={onChangeIdOrganization}
			disabled={disabledInput}
		>
			{organizations.map((item: OrganizationInterface) => (
				<Option key={item.id} value={item.id}>
					{item.name}
				</Option>
			))}
		</Select>
	);
}

export default SelectOrganization;
