import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Spin } from "antd";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteMessageMutation } from "../../../redux/messages/messagesAPI";
import {
    updateOpenModalDelete,
    updateMessage,
    updateFormAction,
} from "../../../redux/messages/messagesSlice";
// COMPONENTS
import ModalDeleteItemContainer from "../../components/modal/ModalDeleteItemContainer";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";

function MessageDeleteModal() {
    // LOCAL STORAGE AND VARIABLES ******************** */
    const dispatch = useDispatch();

    // ************************************************ */
    // GLOBAL VARIABLES ******************************* */

    // ************************************************ */
    // USE STATE VARIABLES **************************** */

    // ************************************************ */
    // REDUX SLICE VARIABLES ************************** */
    const { message, openModalDelete } = useSelector(
        (state: RootState) => state.message
    );

    // ************************************************ */
    // SERVICES AND API CALLS ************************* */
    const [
        triggerDeleteMessage,
        { isLoading, isSuccess, isError, error, reset: resetDeleteMessage },
    ] = useDeleteMessageMutation();

    // ************************************************ */
    // FUNCTIONS ************************************** */
    const onClickClose = () => {
        dispatch(updateOpenModalDelete(false));
        dispatch(updateFormAction("CREATE"));
        dispatch(updateMessage({ id: "", position: false, short_message: "", message_type_id: "" }));
    };

    const onClickDelete = async () => {
        const token = await GETJwtToken();
        triggerDeleteMessage({ id: message.id, token });
        dispatch(
            updateMessage({
                id: "", position: false, short_message: "", message_type_id: ""
            })
        );
    };

    // ************************************************* */
    // USE EFFECT ************************************** */
    useEffect(() => {
        if (openModalDelete) {
            resetDeleteMessage();
        }
    }, [openModalDelete]);

    useEffect(() => {
        if (isSuccess || isError) {
            dispatch(updateOpenModalDelete(false));
        }
    }, [isSuccess, isError]);
    // ************************************************ */
    // COMPONENT ************************************** */
    return (
        <Modal
            onOk={onClickClose}
            closable={false}
            onCancel={onClickClose}
            open={openModalDelete}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <ModalDeleteItemContainer
                    name={message.short_message}
                    onClickDelete={onClickDelete}
                    onClickClose={onClickClose}
                />
            </Spin>
            {/** ---------------------------------------------------- */}
            {/** GLOBAL ALERT */}
            <GlobalAlert2
                isError={isError}
                isSuccess={isSuccess}
                requestType='DELETE'
                error={error}
                name='Message'
            />
        </Modal>
    );
}

export default MessageDeleteModal;
