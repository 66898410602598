import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteRoleMutation } from "../../../redux/roles/roleAPI";
import { updateOpenModalDeleteRole } from "../../../redux/roles/roleSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

function RolesDeleteRoleModal() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	library.add(faTrash, faTriangleExclamation);
	const dispatch = useDispatch();
	const { role, openModalDeleteRole } = useSelector((state: any) => state.role);
	const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] =
		useState(true);
	const [confirmRoleName, setConfirmRoleName] = useState("");
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerDeleteRole,
		{ isLoading, isSuccess, error, isError, reset: resetDeleteRole },
	] = useDeleteRoleMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOk = () => {
		dispatch(updateOpenModalDeleteRole(false));
	};

	const handleCancel = () => {
		dispatch(updateOpenModalDeleteRole(false));
	};

	const handleCloseModal = () => {
		dispatch(updateOpenModalDeleteRole(false));
	};

	const handleDeleteItem = async () => {
		const token = await GETJwtToken();
		triggerDeleteRole({ id: role.id, token });
	};

	const handleSubmitDeleteItem = async (event: any) => {
		if (event.key === "Enter" && !disableButtonConfirmDelete) {
			const token = await GETJwtToken();
			triggerDeleteRole({ id: role.id, token });
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalDeleteRole) {
			setDisableButtonConfirmDelete(true);
			setConfirmRoleName("");
			resetDeleteRole();
		}
	}, [openModalDeleteRole]);

	useEffect(() => {
		setDisableButtonConfirmDelete(true);
		if (
			confirmRoleName &&
			role &&
			role.name &&
			confirmRoleName.toLowerCase() === role.name.toLowerCase()
		) {
			setDisableButtonConfirmDelete(false);
		} else {
			setDisableButtonConfirmDelete(true);
		}
	}, [confirmRoleName]);

	useEffect(() => {
		dispatch(updateOpenModalDeleteRole(false));
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={handleOk}
			closable={false}
			onCancel={handleCancel}
			open={openModalDeleteRole}
			footer={null}
		>
			<Spin spinning={isLoading}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<p className='mt-3 drawer__text'>
							{t("organizationManagement.deleteDescription.text1")}
							<b className='generalStyles__warning'>
								{t("organizationManagement.deleteDescription.text2")}{" "}
							</b>
							{t("organizationManagement.deleteDescription.text7")}{" "}
							<b className='generalStyles__warning'>
								{role && role.name ? role.name : ""}{" "}
							</b>
							{t("organizationManagement.deleteDescription.text8")}
						</p>
						<Input
							placeholder={t("organizationManagement.roleName")}
							onChange={(e) => {
								setConfirmRoleName(e.target.value);
							}}
							value={confirmRoleName}
							onKeyDown={handleSubmitDeleteItem}
							status='warning'
						/>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button
								htmlType='submit'
								className={styleDeleteButton(disableButtonConfirmDelete, theme)}
								onClick={handleDeleteItem}
								disabled={disableButtonConfirmDelete}
							>
								{t("organizationManagement.delete")}
							</Button>
							<Button onClick={handleCloseModal} className='buttonStyle__4'>
								{t("organizationManagement.close")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='DELETE'
				error={error}
				name='Role'
			/>
		</Modal>
	);
}

export default RolesDeleteRoleModal;
