/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Alert, Dropdown, Space, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faTrash,
	faPenToSquare,
	faBan,
	faBuildingCircleArrowRight,
	faCalendarDay,
	faStar,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import {
	updateOpenModalEditOrganization,
	updateOpenModalCreateSubOrganization,
	updateOpenDrawerOrganization,
	updateOrganization,
	updateOrganizationTableParams,
	updateOrganizationBreadcrumb,
	updateOpenDrawerHolidays,
} from "../../../redux/organizations/organizationSlice";
import { updateGlobalAlert } from "../../../redux/home/homeSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";
import ModalDeleteItemOrganization from "../../components/modal/ModalDeleteItemOrganization";
// INTERFACES
import {
	OrganizationInterface,
	OrganizationNavigateBreadcrumbInterface,
} from "../../../interfaces/Organizations.interface";
import { TablePaginationInterface } from "../../../interfaces/Global.interface";
import { RootState } from "../../../app/store";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

const OrganizationTable = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [organizations, setOrganizations] = useState<OrganizationInterface[]>(
		[]
	);
	const [organization, setOrganization] = useState<OrganizationInterface>({
		id: "",
		address: "",
		city: "",
		country: "",
		name: "",
		parent_id: "",
		phone_code: "",
		phone: "",
		state: "",
		type: "",
		servers: [],
	});
	const [idOrganizationSelected, setIdOrganizationSelected] = useState("");
	const [openModalDelete, setOpenModalDelete] = useState(false);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: RootState) => state.user);
	const { theme, userLogged } = useSelector((state: RootState) => state.home);
	const {
		organizationTableParams,
		organizationRefreshTable,
		organizationBreadcrumb,
		organizationUpdateBreadcrumb,
	} = useSelector((state: RootState) => state.organization);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetOrganizationsTable,
		{ data, isLoading, isError, isFetching },
	] = useLazyGetOrganizationAndChildsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenDrawerHolidays = () => {
		dispatch(updateOrganization(organization));
		dispatch(updateOpenDrawerHolidays(true));
	};

	const handleOpenModalCreateSubOrganization = () => {
		dispatch(updateOrganization(organization));
		dispatch(updateOpenModalCreateSubOrganization(true));
	};

	const handleOpenModalEdit = () => {
		dispatch(updateOrganization(organization));
		dispatch(updateOpenModalEditOrganization(true));
	};

	const handleOpenModalDelete = () => {
		setOpenModalDelete(true);
	};

	const onCellClick = (record: OrganizationInterface) => {
		if (record) {
			dispatch(updateOrganization(record));
			dispatch(updateOpenDrawerOrganization(true));
		}
	};

	const navigateThrowOrganization = (
		record: OrganizationNavigateBreadcrumbInterface,
		updateBreadCrumb: boolean
	) => {
		refreshTable(record.id, updateBreadCrumb, record.name);
		setIdOrganizationSelected(record.id);
	};

	const handleTableChange = async (pagination: TablePaginationInterface) => {
		dispatch(
			updateOrganizationTableParams({
				pagination,
			})
		);
		//
		const token = await GETJwtToken();
		triggerGetOrganizationsTable({
			page: pagination.current,
			limit: pagination.pageSize,
			token,
			orgId: idOrganizationSelected,
			searchAndFilterString: "",
		});
	};

	const refreshTable = async (
		orgId: string,
		updateBreadcrumb: boolean,
		orgName: string
	) => {
		dispatch(
			updateOrganizationTableParams({
				pagination: {
					current: 1,
					pageSize: 10,
					total: organizationTableParams.pagination.total,
				},
				filters: {},
				sorter: {},
			})
		);
		//
		const token = await GETJwtToken();
		const response = await triggerGetOrganizationsTable({
			page: 1,
			limit: 10,
			token,
			orgId,
			searchAndFilterString: "",
		}).unwrap();
		if (
			updateBreadcrumb &&
			response &&
			response.data &&
			response.data.children
		) {
			dispatch(
				updateOrganizationBreadcrumb([
					...organizationBreadcrumb,
					{ orgId, name: orgName },
				])
			);
		}
		if (response && response.data && !response.data.children) {
			dispatch(
				updateGlobalAlert({
					title: orgName,
					description:
						"This organization does not have sub organizations asigned",
					status: "info",
				})
			);
		}
	};

	const getInitialData = async (orgId: string) => {
		const token = await GETJwtToken();
		triggerGetOrganizationsTable({
			page: organizationTableParams.pagination.current,
			limit: organizationTableParams.pagination.pageSize,
			token,
			orgId,
			searchAndFilterString: "",
		});
	};

	// ************************************************ */
	// TABLE ACTIONS ********************************** */
	const manageActions = (record: OrganizationInterface) => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon
							className='generalStyles__error generalStyles__mrFix'
							icon={faBan}
						/>
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const CREATE_SUB_ORGANIZATION = validatePermission(
			"orgs-create",
			permissions
		)
			? {
					label: (
						<TableActionsMenuItem
							icon={faBuildingCircleArrowRight}
							text={t("organizationManagement.createSubOrganization")}
							onClick={handleOpenModalCreateSubOrganization}
						/>
					),
					key: "1",
			  }
			: null;

		const HOLIDAYS = validatePermission("holidays-view", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faCalendarDay}
							text={t("general.holidays")}
							onClick={handleOpenDrawerHolidays}
						/>
					),
					key: "2",
			  }
			: null;

		const EDIT = validatePermission("orgs-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faPenToSquare}
							text={t("organizationManagement.edit")}
							onClick={handleOpenModalEdit}
						/>
					),
					key: "3",
			  }
			: null;

		const DELETE = validatePermission("orgs-delete", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faTrash}
							text={t("organizationManagement.delete")}
							onClick={handleOpenModalDelete}
						/>
					),
					key: "4",
			  }
			: null;

		// return object with valid actions
		if (organization) {
			if (EDIT || DELETE || CREATE_SUB_ORGANIZATION || HOLIDAYS) {
				if (record.id === userLogged.org_id) {
					return [CREATE_SUB_ORGANIZATION, HOLIDAYS];
				}
				return [CREATE_SUB_ORGANIZATION, HOLIDAYS, EDIT, DELETE];
			}
		}
		return [BLANK];
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		// clean breadcrumb
		dispatch(updateOrganizationBreadcrumb([]));
		if (userLogged && userLogged.org) {
			setIdOrganizationSelected(userLogged.org.id);
			getInitialData(userLogged.org.id);
			dispatch(
				updateOrganizationBreadcrumb([
					{ orgId: userLogged.org.id, name: userLogged.org.name },
				])
			);
		}
	}, []);

	useEffect(() => {
		if (data && data.data) {
			dispatch(
				updateOrganizationTableParams({
					...organizationTableParams,
					pagination: {
						...organizationTableParams.pagination,
						total: data.total,
					},
				})
			);

			if (data.data.children) {
				setOrganizations([
					{
						id: data.data.id,
						address: data.data.address,
						city: data.data.city,
						country: data.data.country,
						name: data.data.name,
						phone_code: data.data.phone_code,
						phone: data.data.phone,
						state: data.data.state,
						type: data.data.type,
					},
					...data.data.children,
				]);
			} else {
				setOrganizations([
					{
						id: data.data.id,
						address: data.data.address,
						city: data.data.city,
						country: data.data.country,
						name: data.data.name,
						phone_code: data.data.phone_code,
						phone: data.data.phone,
						state: data.data.state,
						type: data.data.type,
					},
				]);
			}
		}
	}, [data]);

	useEffect(() => {
		if (organizationRefreshTable) {
			refreshTable(idOrganizationSelected, false, "");
		}
	}, [organizationRefreshTable]);

	useEffect(() => {
		// update breadcrumb from organization breadcrumb
		// remove items from breadcrumb and load again
		if (organizationUpdateBreadcrumb && organizationUpdateBreadcrumb.update) {
			navigateThrowOrganization(organizationUpdateBreadcrumb, false);
		}
	}, [organizationUpdateBreadcrumb]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("orgs-view", permissions) ? (
				<>
					{!isError ? (
						<Table
							rowClassName={(record, index) => {
								if (record.id === userLogged.org_id) {
									return "table-row-active";
								}
								return getTableRowClass(index, theme);
							}}
							className='customTable'
							dataSource={organizations}
							loading={isLoading || isFetching}
							rowKey={(record) => record.id}
							size='small'
							pagination={organizationTableParams.pagination}
							onChange={(pagination) => {
								handleTableChange(pagination);
							}}
							scroll={{ x: 900 }}
						>
							{/** COLUMN - NAME */}
							<Column
								onCell={(record: OrganizationNavigateBreadcrumbInterface) => ({
									onClick: () => {
										navigateThrowOrganization(record, true);
									},
								})}
								render={(text, record: any) => (
									<div className=''>
										{record.id === userLogged.org_id ? (
											<FontAwesomeIcon
												className='generalStyles__warning generalStyles__mrFix'
												icon={faStar}
											/>
										) : (
											<span className='generalStyles__mrFix25' />
										)}
										<span>{text}</span>
									</div>
								)}
								title={t("organizationManagement.organizationName")}
								dataIndex='name'
								key='name'
								className='generalStyles__tableHeaderLink'
							/>
							{/** COLUMN - TYPE */}
							<Column
								onCell={(record: OrganizationInterface) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("organizationManagement.type")}
								dataIndex='type'
								key='type'
								render={(text) => {
									if (text === "SUBORG") {
										return (
											<Tag color='cyan' className='drawer__typeTag'>
												{t("organizationManagement.subOrganization")}
											</Tag>
										);
									}
									if (text === "MASTER") {
										return (
											<Tag color='blue' className='drawer__typeTag'>
												{t("organizationManagement.master")}
											</Tag>
										);
									}
									if (text === "ORG") {
										return (
											<Tag color='green' className='drawer__typeTag'>
												{t("organizationManagement.organization")}
											</Tag>
										);
									}
									return true;
								}}
							/>
							{/** COLUMN - ADDRESS */}
							<Column
								onCell={(record: OrganizationInterface) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("organizationManagement.address")}
								dataIndex='address'
								key='address'
								render={(text) => <div className=''>{text}</div>}
							/>
							{/** COLUMN - COUNTRY */}
							<Column
								onCell={(record: OrganizationInterface) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("organizationManagement.country")}
								dataIndex='country'
								key='country'
								render={(text) => <div className=''>{text}</div>}
							/>
							{/** COLUMN - ACTIONS */}
							<Column
								title={t("organizationManagement.actions")}
								key='action'
								render={(_: any, record: OrganizationInterface) => (
									<Dropdown
										menu={{
											items: manageActions(record),
										}}
										trigger={["click"]}
										className=''
									>
										<Space
											className='generalStyles__hoverCursor'
											onClick={() => {
												setOrganization(record);
											}}
										>
											<FontAwesomeIcon icon={faEllipsis} />
										</Space>
									</Dropdown>
								)}
							/>
						</Table>
					) : (
						<Alert
							message={t("organizationManagement.errorDescription.error")}
							description={t("organizationManagement.errorDescription.text1")}
							type='error'
							showIcon
							className='mt-3'
						/>
					)}
				</>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty
							description={t(
								"organizationManagement.listNotAvailableOrganizations"
							)}
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					</div>
				</>
			)}
			{/** DELETE ITEM COMPONENT */}
			<ModalDeleteItemOrganization
				nameComponent='organization'
				nameItem={organization.name}
				onClickClose={() => {
					setOpenModalDelete(false);
				}}
				openModalDelete={openModalDelete}
			/>
		</div>
	);
};

export default OrganizationTable;
