import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
import CustomAmplifyCarousel from "../login/CustomAmplifyCarousel";

function MssaPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='loginStyles__body'>
			{/** ************************************************** */}
			<div className='loginStyles__loginSubBox1'>
				<CustomAmplifyCarousel />
			</div>
			<div className='loginStyles__loginSubBox2'>
				<div
					style={{
						textAlign: "justify",
						overflowY: "scroll",
						height: "100%",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent: "flex-end",
							padding: "30px",
						}}
					>
						<div>
							<Button
								style={{
									backgroundColor: "#969696",
									color: "white",
								}}
								shape='circle'
								icon={<FontAwesomeIcon icon={faX} />}
								onClick={() => {
									window.location.href = "/login";
								}}
							/>
						</div>
					</div>
					<div style={{ padding: "0px 120px 50px 120px" }}>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 1 */}
						<h2>SOFTWARE AS A SERVICE (SaaS) LICENSE TERMS</h2>
						<p>Last Updated Date: [1 October 2023]</p>
						<p>
							These Software as a Service (SaaS) License Terms (the{" "}
							<span className='loginStyles__underLiying'>“SaaS Terms”</span> ),
							is a binding agreement by and between Integrated Display Systems
							LLC, an Illinois limited liability company (“LiftNet”) and the
							person or entity identified in a quote or other acknowledgement of
							a customer order issued by LiftNet as the customer (“Customer”).
						</p>
						<p>
							IMPORTANT – READ CAREFULLY: THESE SaaS TERMS ARE PART OF A BINDING
							CONTRACT BETWEEN CUSTOMER AND LIFTNET AND CONTAINS WAIVERS OF
							RIGHTS CUSTOMER MAY OTHERWISE HAVE. THESE SaaS TERMS GOVERN
							CUSTOMER’S ACCESS TO AND USE OF THE SUBSCRIBED SERVICES (AS THAT
							TERM IS DEFINED BELOW) AND POSSESSION AND USE OF HARDWARE (AS THAT
							TERM IS DEFINED BELOW). LIFTNET PROVIDES THE CUSTOMER ACCESS TO
							AND USE OF THE SUBSCRIBED SERVICES AND POSSESSION AND USE OF THE
							HARDWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THESE
							SaaS TERMS AND ON THE CONDITION THAT CUSTOMER ACCEPTS AND COMPLIES
							WITH THEM.
						</p>
						<p>
							BY SUBMITTING A PURCHASE ORDER (“PO”) OR OTHER ACCEPTANCE OF
							LIFTNET’S QUOTE THAT INCORPORATES THESE SaaS TERMS, CUSTOMER
							ACCEPTS THESE SaaS TERMS AND AGREES THAT CUSTOMER IS LEGALLY BOUND
							BY THE TERMS AND CONDITIONS HEREIN. IF CUSTOMER DOES NOT AGREE TO
							THE TERMS AND CONDITIONS OF THE QUOTE AND THESE SaaS TERMS,
							LIFTNET WILL NOT AND DOES NOT PROVIDE ACCESS TO THE SUBSCRIBED
							SERVICES TO CUSTOMER AND CUSTOMER AND ANY EMPLOYEE OR AGENT OF
							CUSTOMER MUST NOT ACCESS OR USE THE SUBSCRIBED SERVICES.
						</p>
						<p>
							LIFTNET MAY, AT ANY TIME, AND AT LIFTNET’S SOLE DISCRETION, MODIFY
							THE TERMS AND CONDITION OF THESE SaaS TERMS BY POSTING AN UPDATED
							VERSION AT HTTPS://APP.LIFTNET.COM/TERMS/MSSA OR A
							LIFTNET-DESIGNATED SUCCESSOR SITE. ANY SUCH MODIFICATION WILL BE
							EFFECTIVE IMMEDIATELY UPON PUBLIC POSTING, PROVIDED THAT FOR
							CUSTOMERS WITH A THEN-CURRENT SUBSCRIPTION, SUCH CHANGES WILL NOT
							TAKE EFFECT UNTIL CUSTOMER’S NEXT RENEWAL TERM. CUSTOMER’S
							CONTINUED USE OF THE SUBSCRIBED SERVICES AND HARDWARE FOLLOWING
							SUCH MODIFICATIONS CONSTITUTES CUSTOMER’S ACCEPTANCE OF THE
							MODIFICATIONS.
						</p>
						<br />
						<h4>1.Definitions .</h4>
						<p>
							Capitalized terms used and not otherwise defined in these SaaS
							Terms shall have the meanings given in the Terms and Conditions
							attached to the applicable Quote
						</p>
						<p>
							<b>1.1</b>{" "}
							<span className='loginStyles__underLiying'>“Affiliate”</span>{" "}
							means any entity that is controlled by Customer, where “control”
							means the ownership of, or the power to vote, more than fifty
							percent (50%) of the voting stock, shares, or interests in an
							entity.
						</p>
						<p>
							<b>1.2</b>{" "}
							<span className='loginStyles__underLiying'>
								“Authorized User”
							</span>{" "}
							means Customer’s employees, consultants, contractors, and agents
							(a) who are authorized by Customer to access and use the
							Subscribed Services under the rights granted to Customer pursuant
							to these SaaS Terms; and (b) that have accepted the EULA upon
							login to the Subscribed Services.
						</p>
						<p>
							<b>1.3</b>{" "}
							<span className='loginStyles__underLiying'>
								“Customer Content”
							</span>{" "}
							means all data, information, and other content (a) transmitted by
							or on behalf of Customer through the System; (b) provided by
							Customer or on Customer’s behalf for use in connection with the
							Subscribed Services; or (c) otherwise processed or stored by
							LiftNet or its contractors on Customer’s behalf pursuant to these
							SaaS Terms.
						</p>
						<p>
							<b>1.4</b>{" "}
							<span className='loginStyles__underLiying'>“Documentation”</span>{" "}
							means the then-current, commercially available user manuals,
							training materials and technical manuals relating to the
							Subscribed Services provided to Customer by LiftNet pursuant to
							these SaaS Terms.
						</p>
						<p>
							<b>1.5</b>{" "}
							<span className='loginStyles__underLiying'>“EULA”</span> means the
							end user license terms displayed to individual users at login to
							the Subscription Services, which may be updated by LiftNet from
							time to time by providing the terms at an individual user’s next
							login.
						</p>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 2 */}
						<p>
							<b>1.6</b>{" "}
							<span className='loginStyles__underLiying'>“Hardware”</span> means
							the hardware and associated equipment and materials provided by
							LiftNet to Customer to facilitate the use of the Subscribed
							Services.
						</p>
						<p>
							<b>1.7</b>{" "}
							<span className='loginStyles__underLiying'>
								“Intellectual Property Rights”
							</span>{" "}
							means, on a world-wide basis, any and all (a) rights associated
							with works of authorship, including without limitation,
							copyrights, copyrightable rights, moral rights and mask work
							rights; (b) trademark, service mark and trade name rights and any
							similar rights recognized under applicable law and all goodwill
							associated therewith; (c) rights in confidential information and
							trade secret; (d) patents and patentable rights; (e) all rights
							with respect to inventions, discoveries, improvements, know-how,
							formulas, algorithms, processes, technical information and other
							technology; (f) applications and registrations for any of the
							foregoing; and (f) all other intellectual and industrial property
							rights of every kind or nature, whether arising by operation of
							law, contract, license or otherwise.
						</p>
						<p>
							<b>1.8</b>{" "}
							<span className='loginStyles__underLiying'>
								“LiftNet Software”
							</span>{" "}
							means LiftNet’s proprietary software programs used by LiftNet to
							provide the Subscribed Services (including, without limitation,
							all source code, object code, designs, copyrightable works, ideas,
							inventions, technology and other Intellectual Property Rights
							therein), as modified, enhanced or replaced by LiftNet from time
							to time. For the avoidance of doubt, LiftNet Software does not
							include Customer Content.
						</p>
						<p>
							<b>1.9</b>{" "}
							<span className='loginStyles__underLiying'>“Marks”</span> means
							the trademarks, service marks or trade names of Customer.
						</p>
						<p>
							<b>1.10</b>{" "}
							<span className='loginStyles__underLiying'>
								“Personal Information”
							</span>{" "}
							means any information relating to an identified or identifiable
							natural person, and as otherwise defined by applicable privacy
							laws and regulations. Examples of Personal Information include the
							following: account number (bank account, credit card, etc.);
							address; biometric identifier; license or identification number;
							date of birth; government identifiers (such as social security
							numbers); name; personnel number; photograph or video identifiable
							to an individual; and may also include other information related
							to an individual that may directly or indirectly identify the
							individual (e.g. salary, performance rating, purchase history,
							call history, etc.).
						</p>
						<p>
							<b>1.11</b>{" "}
							<span className='loginStyles__underLiying'>“Quote”</span> means
							the quote issued and Acknowledged by LiftNet that sets forth: (a)
							the Subscribed Services and Hardware to be procured, (b) the
							applicable Fees and payment schedule, and (c) the Initial Term.
						</p>
						<p>
							<b>1.12</b>{" "}
							<span className='loginStyles__underLiying'>“SLA”</span> means
							LiftNet’s then-current Service Level Agreement, which is available
							at https://app.liftnet.com/terms/mssa or any LiftNet-designated
							successor site, as updated by LiftNet from time to time.
						</p>
						<p>
							<b>1.13</b>{" "}
							<span className='loginStyles__underLiying'>
								“Subscribed Services”
							</span>{" "}
							means LiftNet’s proprietary, web-based software as a services set
							forth in the Quote which are provided to Customer on a
							subscription basis and enable use of the LiftNet Software through
							the System, including any associated Hardware.
						</p>
						<p>
							<b>1.14</b>{" "}
							<span className='loginStyles__underLiying'>“System”</span> means
							the LiftNet Software and the server grade computers and related
							networks maintained by or on behalf of LiftNet and its third-party
							providers to host the LiftNet Software and provide the Subscribed
							Services to Customer, all as hereafter modified, enhanced or
							replaced by LiftNet.
						</p>
						<p>
							<b>1.15</b>{" "}
							<span className='loginStyles__underLiying'>“Term”</span> means the
							term as set forth on the Quote, including any Renewal Terms.
						</p>
						<br />
						<br />
						<h4>2.Services .</h4>
						<p>
							<b>2.1</b>{" "}
							<span className='loginStyles__underLiying'>
								Subscribed Services
							</span>{" "}
							. Subject to the terms and conditions set forth herein, including
							without limitation, Customer’s payment of all applicable Fees,
							LiftNet hereby agrees to provide the Subscribed Services, and in
							connection therewith, LiftNet hereby grants to Customer during the
							Term a non-exclusive, non-transferable, non- sublicensable,
							limited right and license to (a) access and use the Subscribed
							Services subject to the terms and conditions specified in these
							SaaS Terms and the Quote, solely for Customer’s internal use by
							its Authorized Users; (b) to transmit and receive Customer Content
							to and from the System; and (c) use the Documentation in
							connection with such rights. The rights granted to Customer herein
							shall terminate upon the termination or expiration of the Quote or
							Contract. All rights not expressly granted to Customer are
							reserved by LiftNet and its licensors.
						</p>
						<p>
							<b>2.2</b>{" "}
							<span className='loginStyles__underLiying'>Limitations</span> .
							Customer and its Authorized Users shall not and shall not permit
							anyone to: (a) access or use any portion of the Subscribed
							Services or System except as expressly authorized pursuant to the
							Quote or tthese SaaS Terms; (b) cause or permit decompilation,
							reverse assembly or reverse engineering of all or any portion of
							the Subscribed Services or System; (c) copy any ideas, features,
							functions or graphics of the Subscribed Services or System or
							modify or make derivative works based upon the Subscribed Services
							or System; (d) delete, fail to reproduce or modify any patent,
							copyright, trademark or other proprietary rights notices which
							appear on or in the Subscribed Services, System or Documentation;
							or (e) directly or indirectly, sublicense, relicense,
							distribute,disclose, use, rent or lease the Subscribed Services or
							System, or any portion thereof, for third party use, third party
							training, facilities management or time-sharing, or use as an
							application service provider or service bureau. Without limiting
							the foregoing, Customer may not use the Subscribed Services or
							System to: (i) send or store material containing viruses, worms,
							Trojan horses or other harmful computer code, files, scripts,
							agents or programs; (ii) interfere with or disrupt the integrity
							or performance of the Subscribed Services, System or the data
							contained therein; or (iii) attempt to gain unauthorized access to
							the Subscribed Services or System.
						</p>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 3 */}
						<p>
							<b>2.3</b>{" "}
							<span className='loginStyles__underLiying'>Support</span> .
							Customer will be responsible for providing first line maintenance
							and support to its Authorized Users in connection with the
							Subscribed Services. Qualified employees of Customer who have been
							trained on use of the Subscribed Services (the{" "}
							<span className='loginStyles__underLiying'>
								“Designated Employees”
							</span>{" "}
							) may contact LiftNet with technical questions or issues with
							respect to the Subscribed Services and to report System outages or
							failures. LiftNet shall respond to the technical support questions
							from the Designated Employees and commence the process of
							responding to System or Subscribed Services outages or failures in
							accordance with LiftNet’s standard SLAs. The Designated Employees
							shall assist LiftNet in resolving issues with the Subscribed
							Services and System. LiftNet is under no obligation to provide
							functional updates, enhancements, or upgrades to the System or
							Subscribed Services by any specific time.
						</p>
						<p>
							<b>2.4</b>{" "}
							<span className='loginStyles__underLiying'>
								System Availability
							</span>{" "}
							. LiftNet will use commercially reasonable efforts to schedule
							updates to the System for evenings and/or weekends to minimize
							disruption. Customer acknowledges and agrees that certain portions
							of the Subscribed Services, including without limitation, data
							storage, hosting, and System hardware management, may be provided
							by third party service providers. LiftNet will provide ongoing
							management of the System, located at the third-party provider’s
							location, in accordance with LiftNet’s agreement with the
							third-party provider(s), in order to maintain the best practical
							availability of the Subscribed Services. LiftNet may change its
							third-party data hosting provider to another hosting provider, in
							LiftNet’s sole discretion, from time to time.
						</p>
						<p>
							<b>2.5</b>{" "}
							<span className='loginStyles__underLiying'>
								Provisioning of the Subscribed Services
							</span>{" "}
							. LiftNet may update the functionality and user interface of the
							Subscribed Services from time to time in its sole discretion as
							part of its ongoing improvement of the Subscribed Services.
							Customer agrees that its subscription to the Subscribed Services
							is neither contingent on the delivery of any future functionality
							or features nor dependent on any oral or written public comments
							made by LiftNet regarding future functionality or features.
						</p>
						<br />
						<br />
						<h4>3.Customer Obligations </h4>
						<p>
							<b>3.1</b>{" "}
							<span className='loginStyles__underLiying'>Resources</span> .
							Except as expressly set forth herein, Customer and its Authorized
							Users shall be solely responsible for providing all resources,
							equipment and software at its or their respective facilities which
							are necessary for them to access the System and/or receive the
							Subscribed Services. Customer and its Authorized Users must
							provide all equipment and licenses necessary to access and use the
							Internet, and pay all fees associated with such access and use. To
							the extent LiftNet’s provision of the Subscribed Services requires
							data, documents, information or materials of any nature to be
							furnished, in whole or in part, by Customer or its employees,
							agents, contractors, representatives or Authorized Users, Customer
							will cause such employees, agents, contractors, representatives
							and Authorized Users to furnish such data, documents and
							information in a manner which permits LiftNet to perform the
							Subscribed Services as contemplated herein and the Quote. LiftNet
							will not be liable for delays or failures resulting from
							Customer’s failure to perform its obligations under these SaaS
							Terms or the Quote.
						</p>
						<p>
							<b>3.2</b>{" "}
							<span className='loginStyles__underLiying'>
								Third Party Coordination; Required Consents
							</span>{" "}
							. To the extent the Subscribed Services require access to a third
							party service provider who is under contract with Customer, or
							access or use of such provider’s information or interconnection
							with such provider’s services, facilities, technology or systems
							in order to receive or transmit Customer Content, Customer shall
							be responsible for obtaining any required third-party licenses or
							consents necessary for LiftNet to access and use such information,
							services, facilities, technology or systems. LiftNet shall not be
							liable for any acts or omissions of such third party provider.
						</p>
						<p>
							<b>3.3</b>{" "}
							<span className='loginStyles__underLiying'>
								Integrated Third-Party Software
							</span>{" "}
							. LiftNet may integrate third-party computer software into the
							Subscribed Services. In such an event, LiftNet will obtain, at no
							additional charge to Customer, all rights necessary for Customer
							to use such third-party computer software with the Subscribed
							Services. All free software is distributed to Customer WITHOUT ANY
							WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY WARRANTY
							OF TITLE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. A
							copy of the free software is included with the Subscribed
							Services. LiftNet disclaims on behalf of all individuals or
							entities that distributed such free software to LiftNet (the
							“Contributors”) all warranties and conditions, express and
							implied, including warranties or conditions of title and
							non-infringement, and implied warranties or conditions of
							merchantability and fitness for a particular purpose; and LiftNet
							excludes on behalf of all such Contributors (i) all liability for
							damages, including direct, indirect, special, incidental and
							consequential damages, such as lost profits; and (ii) any
							provisions which differ from these SaaS Terms which are offered by
							any particular Contributor alone and not by any other party.
						</p>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 4 */}
						<p>
							<b>3.4</b>{" "}
							<span className='loginStyles__underLiying'>
								Compliance with Laws
							</span>{" "}
							. Customer will comply with all applicable laws, rules and
							regulations relating to Customer’s or its Authorized User’s
							receipt or use of the Subscribed Services. Without limiting the
							foregoing, (a) Customer will not provide or submit Personal
							Information to LiftNet or the Subscribed Services without
							Liftnet’s advanced and express written consent and (b) to the
							extent Customer provides or submits such Personal Information to
							LiftNet, Customer will be solely responsible for: (i) determining
							the extent to which the design or provision of the Subscribed
							Services is subject to any privacy laws or regulations (“Privacy
							Laws”) or the oversight of any regulatory agency charged with the
							enforcement thereof (“Regulatory Oversight”); and (ii) determining
							if the design and operation of the Subscribed Services complies
							with Privacy Laws and Regulatory Oversight applicable to the
							Customer’s use of the Subscribed Services. If Customer reasonably
							determines that the design and operation of the Subscribed
							Services does not comply with Privacy Laws and Regulatory
							Oversight applicable to the Customer’s use of the Subscribed
							Services, Customer shall not use the Subscribed Services or
							System. Customer shall not export the Subscribed Services, System
							or Documentation in violation of U.S. Department of Commerce
							export administration regulations.
						</p>
						<p>
							<b>3.5</b>{" "}
							<span className='loginStyles__underLiying'>Activity</span> .
							LiftNet will provide Customer access to the Subscribed Services by
							issuance of a confidential site address and passwords to Customer.
							Such site address and passwords can be used by Customer’s
							Authorized Users to access the Subscribed Services. Each
							Authorized User is required to agree to the EULA prior to
							accessing the Subscribed Services. Customer is responsible for
							maintaining the confidentiality of such address and passwords and
							any activity that transpires through the use of such address and
							passwords. Customer shall: (a) notify LiftNet immediately of any
							unauthorized use of any password or account or any other known or
							suspected breach of security; (b) report to LiftNet immediately
							and use reasonable efforts to stop immediately any unauthorized
							copying or distribution of Customer Content that is known or
							suspected by Customer; and (c) not impersonate another LiftNet
							Customer or user or provide false identity information to gain
							access to or use of the Subscribed Services.
						</p>
						<br />
						<br />
						<h4>4.Prices; Payment</h4>
						<p>
							<b>4.1</b>{" "}
							<span className='loginStyles__underLiying'>
								Invoicing and Payment
							</span>{" "}
							. Except as otherwise specified in a Quote all payment obligations
							are non- cancellable, non-refundable and non-contingent. Customer
							may not set-off any amounts owing to Customer against any payments
							owing to LiftNet hereunder. Payments which are not received when
							due shall bear interest at the lesser of the maximum amount
							chargeable by law or one and a half percent (<b>1.5</b>%) per
							month commencing with the date payment was due. In addition, in
							the event Customer fails to timely pay any fees or charges when
							due, LiftNet may, in its discretion, suspend or terminate any
							Subscribed Services or other services hereunder in accordance with{" "}
							<span className='loginStyles__underLiying'>Section 5</span> .
							Customer will continue to be charged for all Subscribed Services
							and other services during any period of suspension.
						</p>
						<p>
							<b>4.2</b>{" "}
							<span className='loginStyles__underLiying'>Taxes and Duties</span>{" "}
							. Excluding taxes based on LiftNet’s net income, Customer is
							liable and responsible for paying all federal, state and local
							sales, foreign withholding, value added, use, property, excise,
							service and other taxes, and all duties and customs fees relating
							to Customer’s receipt or use of the Subscribed Services, whether
							or not LiftNet invoices Customer for such taxes, duties or customs
							fees, unless Customer timely provides LiftNet with a valid tax
							exemption or direct pay certificate showing Customer is exempt
							from such payments. If LiftNet is required to pay any such taxes,
							duties or customs fees, Customer shall reimburse LiftNet for such
							amounts in accordance with{" "}
							<span className='loginStyles__underLiying'>Section 4.1</span> ,
							and Customer further agrees to indemnify, defend and hold harmless
							LiftNet for any such taxes, duties and customs fees and any
							related costs, interest and penalties paid or payable by LiftNet
							with respect thereto.
						</p>
						<p>
							<b>4.3</b>{" "}
							<span className='loginStyles__underLiying'>Audits</span> . During
							the Term, upon thirty (30) days prior written notice to Customer,
							LiftNet may audit Customer’s facilities, records and use of the
							Subscribed Services to determine Customer’s compliance with the
							terms and conditions of these SaaS Terms. Such audits shall occur
							during regular business hours and shall be conducted in a manner
							designed to limit disruption to Customer’s business.
						</p>
						<br />
						<br />
						<h4>5.Term and Termination .</h4>
						<p>
							<b>5.1</b> <span className='loginStyles__underLiying'>Term</span>{" "}
							. The Initial Subscription Term shall commence on the date upon
							which the parties schedule LiftNet to perform the commissioning
							event as stated on the Quote and shall continue until the end of
							the Initial Subscription Term set forth on the Quote or until
							terminated as provided herein. Upon expiration of the Initial
							Subscription Term, these SaaS Terms shall automatically renew for
							successive periods equal to the Initial Subscription Term (each, a
							“Renewal Term” and together with the Initial Subscription Term,
							the “Term”) unless either party gives at least thirty (30) prior
							days written notice of its intention not to renew.
						</p>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 5 */}
						<p>
							<b>5.2</b>{" "}
							<span className='loginStyles__underLiying'>
								Termination of Agreement for Breach
							</span>{" "}
							. Either party may terminate these SaaS Terms and the applicable
							Quote by delivery of written notice of termination, for material
							breach of these SaaS Terms or the Quote by the other party, if the
							other party does not cure such breach within thirty (30) days
							following receipt of such notice. Such notice shall set forth in
							reasonable detail the nature of the breach and the date of
							termination.
						</p>
						<p>
							<b>5.3</b>{" "}
							<span className='loginStyles__underLiying'>
								Termination for Bankruptcy
							</span>{" "}
							. LiftNet may terminate these SaaS Terms immediately if Customer
							ceases business operations, makes a general assignment for the
							benefit of creditors, becomes insolvent or the subject of
							voluntary bankruptcy or receivership proceedings, or if bankruptcy
							or receivership proceedings are initiated against it and not
							lifted within 10 days
						</p>
						<p>
							<b>5.4</b>{" "}
							<span className='loginStyles__underLiying'>
								Suspension of Services
							</span>{" "}
							. In the event (a) Customer fails to timely pay any fees when due;
							or (b) LiftNet believes that any element of the Subscribed
							Services, or Customer’s receipt or use thereof, violates any
							applicable law, rule or regulation, or (c) LiftNet believes
							Customer’s acts or omissions are or are likely to compromise the
							security of the System, LiftNet may it its sole discretion suspend
							any Subscribed Services and other services immediately without
							notice.
						</p>
						<p>
							<b>5.5</b>{" "}
							<span className='loginStyles__underLiying'>
								Effect of Termination
							</span>{" "}
							. Termination of these SaaS Terms shall automatically terminate
							the Quote. Termination of the Quote shall terminate these SaaS
							Terms. Upon termination of these SaaS Terms or Quote for any
							reason Customer shall (a) immediately cease using the applicable
							Subscribed Services, (b) return all Documentation to LiftNet, (c)
							return to LiftNet or destroy all copies of LiftNet’s Trade Secrets
							and Confidential Information in every form, (d) provide LiftNet
							prompt access to any and all Hardware for retrieval and removal of
							the Hardware; (e) pay LiftNet a reasonable replacement fee, if
							applicable, for any damaged Hardware, and (f) certify in writing
							to LiftNet that it and each of its Affiliates have performed the
							foregoing obligations. If these SaaS Terms or the Quote is
							terminated early for any reason other than due to LiftNet’s
							breach, all payment obligations for the then-current Term shall
							become immediately due and owing to LiftNet.
						</p>
						<p>
							<b>5.6</b>{" "}
							<span className='loginStyles__underLiying'>
								Survival . Sections 1, 4, 5.6, 6.2, and 7, 8, 9, 10 and 11
							</span>{" "}
							shall survive any termination of these SaaS Terms in accordance
							with their respective terms. In the event of any termination
							hereunder, Customer shall not be entitled to any refund of any
							payments made by Customer.
						</p>
						<br />
						<br />
						<h4>6.Warranties</h4>
						<p>
							<b>6.1</b>{" "}
							<span className='loginStyles__underLiying'>
								Limited Warranty{" "}
							</span>{" "}
							. Except for personal computers and servers for which the
							manufacturer’s warranty shall be the sole and exclusive warranty,
							LiftNet warrants that the Hardware it provides to Customer will
							(i) be free from defects in materials and workmanship under normal
							and proper use for a period of one (1) year from date of delivery,
							provided that Customer provides LiftNet with written notice of a
							defect during this limited warranty period. LiftNet’s liability,
							and Customer’s sole and exclusive remedy, for breach of this
							limited warranty will be limited to replacement, repair, or
							refund, in LiftNet’s sole discretion, of any affected Hardware
							that has been properly stored, installed, used, and maintained,
							provided such Hardware is returned to LiftNet without further
							damage within fourteen (14) days of discovery of the alleged
							defect and subject to confirmation of the defect by LiftNet’s
							inspection. This limited warranty is void if, in LiftNet’s
							reasonable opinion, the failure to perform was caused in whole or
							part by: (a) improper handling, use, operation, or testing by
							anyone other than LiftNet; (b) failure to properly install or
							maintain the Hardware by anyone other than LiftNet; (c) failure to
							install, maintain, or service Hardware in accordance with the
							current edition of any applicable safety code or LiftNet’s written
							instructions; (d) modification, alteration, or unauthorized repair
							by anyone other than LiftNet; (e) use with products or components
							that are incompatible with the Hardware; (f) Force Majeure events
							or acts of vandalism, sabotage, or hacking; (g) radio frequency
							interference; (h) removal from the country where the Hardware was
							purchased, use in a country in which they are not registered for
							use, and/or use in a country for which they were not designed; (i)
							use in environments in which failure of the Hardware could lead to
							personal injury or significant property damage; (j) Hardware
							having been subjected to operating conditions outside of specified
							parameters; or (k) any other cause beyond normal usage in
							accordance with LiftNet’s written instructions.
						</p>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 6 */}
						<p>
							<b>6.2</b>{" "}
							<span className='loginStyles__underLiying'>
								Customer Warranty
							</span>{" "}
							. Customer represents and warrants that (a) Customer has all
							rights (including third party licenses and consents) necessary for
							LiftNet to access and use the Customer Content in the performance
							of the Services and (b) Customer will use the Services in
							accordance with the Quote and Documentation
						</p>
						<p>
							<b>6.3</b>{" "}
							<span className='loginStyles__underLiying'>Disclaimer</span> .
							EXCEPT AS EXPRESSLY STATED IN{" "}
							<span className='loginStyles__underLiying'>SECTION 6.1</span> ,
							LIFTNET PROVIDES THE SUBSCRIBED SERVICES AS-IS WITH NO
							REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
							IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), WITH RESPECT TO
							THE SUBSCRIBED SERVICES, THE SYSTEM OR ANY OTHER SERVICES PROVIDED
							PURSUANT TO THESE SAAS TERMS, INCLUDING WITHOUT LIMITATION,
							PROFESSIONAL SERVICES. LIFTNET EXPRESSLY DISCLAIMS ALL OTHER
							WARRANTIES OR CONDITIONS, INCLUDING WITHOUT LIMITATION, WARRANTIES
							OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, ALL
							WARRANTIES ARISING FROM CONDUCT, COURSE OF DEALING OR CUSTOM OF
							TRADE, AND ALL WARRANTIES OF TITLE AND NON-INFRINGEMENT. LIFTNET
							WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES OR
							OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND ELECTRONIC
							COMMUNICATIONS OR OTHER SYSTEMS. TO THE MAXIMUM EXTENT PERMITTED
							BY LAW, NEITHER LIFTNET NOR ANY OF ITS THIRD PARTY SUPPLIERS MAKES
							ANY REPRESENTATION, WARRANTY OR GUARANTEE AS TO THE RELIABILITY,
							TIMELINESS, QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR
							COMPLETENESS OF ANY SOFTWARE OR ANY CONTENT THEREIN OR GENERATED
							THEREWITH, OR THAT: (A) THE USE OF ANY SOFTWARE WILL BE SECURE,
							TIMELY, UNINTERRUPTED OR ERROR-FREE; (B) THE SOFTWARE WILL OPERATE
							IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM, OR DATA;
							(C) THE SOFTWARE (OR ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
							MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SOFTWARE) WILL
							MEET CUSTOMER’S REQUIREMENTS OR EXPECTATIONS); (D) ERRORS OR
							DEFECTS CAN OR WILL BE CORRECTED; OR (E) THE SOFTWARE IS FREE OF
							VIRUSES OR OTHER HARMFUL COMPONENTS. CUSTOMER MAY HAVE OTHER
							STATUTORY RIGHTS, BUT THE DURATION OF STATUTORILY REQUIRED
							WARRANTIES, IF ANY, WILL BE LIMITED TO THE SHORTEST PERIOD
							PERMITTED BY LAW.
						</p>
						<br />
						<br />
						<h4>7.Confidentiality .</h4>
						<p>
							<b>7.1</b>{" "}
							<span className='loginStyles__underLiying'>Confidentiality</span>{" "}
							. Each party (the{" "}
							<span className='loginStyles__underLiying'>
								{" "}
								“Receiving Party”
							</span>
							) acknowledges that it will have access to Confidential
							Information and Trade Secrets of the other party (the{" "}
							<span className='loginStyles__underLiying'>
								“Disclosing Party”
							</span>{" "}
							). For purposes of these SaaS Terms, “Trade Secrets” means
							information, without regard to form, which: (a) derives economic
							value, actual or potential, from not being generally known to, and
							not being readily ascertainable by proper means by, other persons
							who can obtain economic value from its disclosure or use; and (b)
							is the subject of efforts that are reasonable under the
							circumstances to maintain its secrecy; and{" "}
							<span className='loginStyles__underLiying'>
								“Confidential Information”
							</span>{" "}
							means information, other than Trade Secrets, that is of value to
							Disclosing Party and is treated as confidential. LiftNet’s Trade
							Secrets and Confidential Information include, without limitation,
							the Subscribed Services, the System, the Documentation and object
							and source code for the LiftNet Software. The Receiving Party
							agrees to use the Trade Secrets and Confidential Information of
							the Disclosing Party solely for purposes of performing its
							obligations or exercising its rights under these SaaS Terms. The
							Receiving Party agrees to discuss the Trade Secrets and
							Confidential information of the Disclosing Party only with, and to
							transmit the Trade Secrets and Confidential Information only to,
							those officers, employees and consultants of the Receiving Party
							who have a need to know the Trade Secrets or Confidential
							Information for the purposes set forth herein and who have agreed
							in writing to treat such information as confidential on terms no
							less restrictive than as set forth in these SaaS Terms. The
							parties acknowledge and agree that the terms of any previously
							executed confidentiality or nondisclosure agreements shall remain
							in effect with respect to the information exchanged thereunder.
						</p>
						<p>
							<b>7.2</b>{" "}
							<span className='loginStyles__underLiying'>
								Security Precautions
							</span>{" "}
							. The Receiving Party shall take commercially reasonable security
							precautions to prevent unauthorized use and disclosure of the
							Trade Secrets and Confidential Information of the Disclosing Party
							and shall use at least the same degree of care the Receiving Party
							employs with respect to its own Trade Secrets and Confidential
							Information, but in no event less than a reasonable standard of
							care. The Receiving Party shall not permit unauthorized access to
							the Trade Secrets or Confidential Information of the Disclosing
							Party.
						</p>
						<p>
							<b>7.3</b>{" "}
							<span className='loginStyles__underLiying'>
								{" "}
								Duration and Exceptions
							</span>{" "}
							. With regard to Confidential Information, the obligations in this{" "}
							<span className='loginStyles__underLiying'>Section 7</span> shall
							continue for the Term and for a period of five (5) years
							thereafter. With regard to Trade Secrets, the obligations in this{" "}
							<span className='loginStyles__underLiying'>Section 7</span> shall
							continue for so long as such information constitutes a trade
							secret under applicable law, but in no event less than the Term
							and for a period of five (5) years thereafter. The Receiving
							Party’s obligations with respect to Trade Secrets and Confidential
							Information of the Disclosing Party shall not apply to the extent
							such Trade Secrets or Confidential Information: (a) are previously
							known to the Receiving Party without restriction on disclosure;
							(b) cease to be secret or confidential except by reason of a
							breach of these SaaS Terms by the Receiving Party; (c) are
							independently developed by the Receiving Party without reference
							to the Trade Secrets or Confidential Information of the Disclosing
							Party; or (d) were received from a third party without obligations
							of confidence and without breach of these SaaS Terms. In addition,
							the Receiving Party may disclose Trade Secrets and Confidential
							Information of the Disclosing Party to the extent such disclosure
							is required by applicable law or by any governmental authority,
							provided the Receiving Party notifies the Disclosing Party, if
							permitted by law, of the applicable legal requirements before such
							disclosure occurs so as to enable the Disclosing Party to obtain
							such protection as may be available to preserve the
							confidentiality of such information.
						</p>
						<br />
						<br />
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 7 */}
						<h4>8.Intellectual Property Rights </h4>
						<p>
							<b>8.1</b>{" "}
							<span className='loginStyles__underLiying'>
								LiftNet’s Intellectual Property
							</span>{" "}
							. LiftNet (or its licensors) shall own and retains title to the
							Subscribed Services, System, and Documentation, and all
							modifications, alterations, derivative works, and enhancements
							thereto, and all copies thereof and Intellectual Property Rights
							therein. Except as specified herein, Customer does not acquire any
							rights, express or implied, in the Subscribed Services, System or
							Documentation, and has no right to commercialize or transfer the
							Subscribed Services, System or Documentation, in whole or in part.
							No license, right or Intellectual Property Right in any LiftNet
							trademark, trade name or service mark is granted pursuant to these
							SaaS Terms.
						</p>
						<p>
							<b>8.2</b>{" "}
							<span className='loginStyles__underLiying'>Customer Content</span>{" "}
							. Customer shall own all Customer Content. Customer shall have
							sole responsibility for the accuracy, completeness, quality,
							integrity, legality, reliability, timeliness, appropriateness, and
							intellectual property ownership and right to use all Customer
							Content, and LiftNet shall not be responsible or liable for the
							deletion, correction, destruction, damage, loss or failure to
							store Customer Content for any reason. LiftNet does not warrant
							the correctness, completeness, merchantability or fitness for a
							particular purpose of any Customer Content, and Customer shall
							hold LiftNet harmless from any and all third-party claims arising
							out of Customer’s use or dissemination of any such Customer
							Content. In the event these SaaS Terms are terminated (other than
							by reason of Customer’s breach), LiftNet will make available to
							Customer a file of the Customer Content in its possession, if any,
							within thirty (30) days following Customer’s request; provided
							such request is made within thirty (30) days following termination
							of the Agreement. LiftNet reserves the right to (a) withhold,
							remove and/or discard Customer Content in its possession, if any,
							in the event Customer breaches these SaaS Terms, including,
							without limitation, non-payment of fees and charges; and (b) purge
							and delete Customer Content, if any, in its possession if Customer
							fails to request such Customer Data within thirty (30) days
							following termination of these SaaS Terms.
						</p>
						<p>
							<b>8.3</b>{" "}
							<span className='loginStyles__underLiying'>
								License to Customer Content
							</span>{" "}
							. Customer hereby grants to LiftNet a non-exclusive right and
							license to (a) receive, retrieve, process, use and transmit any
							Customer Content necessary or reasonably desirable to perform the
							Subscribed Services or other services; (b) use, copy, manipulate
							and store any Customer Content that will be archived, stored or
							otherwise transmitted in connection with the Subscribed Services
							or other services; and (c) to aggregate Customer Content and data
							with content and data from other Customers (
							<span className='loginStyles__underLiying'>
								“Data Aggregations”
							</span>{" "}
							) for purposes including, without limitation, product and service
							development and commercialization and quality improvement
							initiatives. LiftNet will redact Customer Content in such a way as
							to not divulge Customer’s Confidential Information or Trade
							Secrets. All Data Aggregations will be the sole and exclusive
							property of LiftNet.
						</p>
						<p>
							<b>8.4</b>{" "}
							<span className='loginStyles__underLiying'>
								License to the Marks
							</span>{" "}
							. Customer hereby grants to LiftNet the worldwide, non-exclusive
							limited right and license during the Term to use the Marks in
							connection with performance of the Subscribed Services and its
							other obligations under these SaaS Terms.
						</p>
						<br />
						<br />
						<h4>9.Defense and Indemnification .</h4>
						<p>
							<b>9.1</b>{" "}
							<span className='loginStyles__underLiying'>
								Limited Covenant to Defend{" "}
							</span>{" "}
							. LiftNet will defend any third party claim brought against
							Customer to the extent that the claim, if true, would constitute
							an infringement or misappropriation by the Subscribed Services of
							any valid and subsisting patent or copyright of which LiftNet had
							actual knowledge; provided, however, that: (i) Customer
							immediately advises LiftNet of the claim upon learning of the
							assertion of the claim; and (ii) LiftNet is given the sole right
							to control the defense and/or settlement of the claim, in
							litigation or otherwise.
						</p>
						<p>
							<b>9.2</b>{" "}
							<span className='loginStyles__underLiying'>
								Injunctions Obtained by Third Parties
							</span>{" "}
							. If a third-party infringement claim, of which LiftNet is
							notified in accordance with{" "}
							<span className='loginStyles__underLiying'> Section 9.1</span> (or
							of which LiftNet is otherwise aware or believe is likely) results,
							or in LiftNet’s opinion is likely to result, in an injunction
							prohibiting Customer from continued use of the Subscribed Services
							that is the subject matter of the claim, then LiftNet may, in its
							sole discretion and at its expense: (a) procure for Customer the
							right to continue to use the Subscribed Services that are the
							subject matter of the claim; (b) replace or modify the Subscribed
							Services that are the subject matter of the claim to make them
							non-infringing, but, where reasonably possible, preserving the
							functionality of such Subscribed Services; or (c) if the foregoing
							remedies are not commercially practical, suspend or terminate
							access to the infringing Subscribed Services.
						</p>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 8 */}
						<p>
							<b>9.3</b>{" "}
							<span className='loginStyles__underLiying'>
								Exceptions to Duties to Defend and Indemnify
							</span>{" "}
							. Notwithstanding any other provisions hereof, LiftNet shall have
							no obligation to indemnify or defend Customer for any third party
							claim pursuant to this{" "}
							<span className='loginStyles__underLiying'>Section 9</span> , nor
							be required to pay losses, damages or expenses under this{" "}
							<span className='loginStyles__underLiying'>Section 9</span> , if
							Customer agrees to settle any such claim without the prior written
							consent of LiftNet, or if the claim arises out of, in whole or in
							part: (a) a modification of the Subscribed Services by anyone
							other than LiftNet; (b) use of the Subscribed Services other than
							in accordance with the Documentation or the terms of these SaaS
							Terms; (c) use of a release of the Subscribed Services without
							having implemented updates, the use of which would have cured the
							alleged infringement; (d) any third party software or service; (e)
							use of the Subscribed Services in combination with Third Party
							Offering or any other third party hardware, software, database or
							materials where, absent such combination, the Subscribed Services
							would not be infringing; or (f) Customer’s negligence or willful
							misconduct.
						</p>
						<p>
							<b>9.4</b>{" "}
							<span className='loginStyles__underLiying'>Sole Obligation</span>{" "}
							. This <span className='loginStyles__underLiying'>Section 9</span>{" "}
							states LiftNet’s sole obligation, and Customer’s sole and
							exclusive remedy, with respect to infringement of proprietary and
							Intellectual Property Rights.
						</p>
						<br />
						<br />
						<h4>10.Limitation on Liability.</h4>
						<p>
							<b>10.1</b>{" "}
							<span className='loginStyles__underLiying'>
								EXCLUSION OF DAMAGES
							</span>{" "}
							. IN NO EVENT SHALL LIFTNET OR ANY OF ITS AFFILIATES OR THEIR
							RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS OR
							REPRESENTATIVES BE LIABLE TO CUSTOMER OR ANY OTHER PERSON OR
							ENTITY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR
							CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION, LOSS OF
							GOODWILL OR BUSINESS PROFITS, WORK STOPPAGE, DATA LOSS, OR
							COMPUTER FAILURE, DELAY OR MALFUNCTION), EVEN IF LIFTNET HAS BEEN
							ADVISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES.{" "}
						</p>
						<p>
							<b>10.2</b>{" "}
							<span className='loginStyles__underLiying'>
								LIMITATION OF LIABILITY
							</span>{" "}
							. LIFTNET’S TOTAL AGGREGATE LIABILITY TO CUSTOMER OR ANY OTHER
							PERSON OR ENTITY FOR ANY AND ALL CLAIMS AND DAMAGES ARISING FROM
							OR OUT OF THESE SAAS TERMS (WHETHER ARISING UNDER CONTRACT, TORT,
							NEGLIGENCE, STRICT LIABILITY OR OTHERWISE) SHALL IN NO EVENT
							EXCEED THE FEES PAID BY CUSTOMER TO LIFTNET DURING THE SIX (6)
							MONTHS IMMEDIATELY PRECEDING THE DAY THE ACT OR OMISSION OCCURRED
							THAT GAVE RISE TO CUSTOMER’S FIRST CLAIM.
						</p>
						<p>
							<b>10.3</b>{" "}
							<span className='loginStyles__underLiying'>EXCEPTIONS</span> . THE
							FOREGOING LIMITATIONS APPLY TO THE EXTENT PERMITTED BY APPLICABLE
							LAW.
						</p>
						<br />
						<br />
						<h4>11.Miscellaneous .</h4>
						<h5>11.1 Governing Law; Dispute Resolution.</h5>
						<p style={{ marginLeft: "25px" }}>
							<b>11.1.1</b>{" "}
							<span className='loginStyles__underLiying'>Governing Law</span> .
							The laws of the State of Illinois shall govern these SaaS Terms,
							without reference to conflicts of law rules or principles. The
							parties specifically disclaim the application of the UN Convention
							on Contracts for the International Sale of Goods to the
							interpretation or enforcement of these SaaS Terms.
						</p>
						<h5>11.1.2 Dispute Resolution . </h5>
						<p style={{ marginLeft: "50px" }}>
							<b>11.1.2.1</b>{" "}
							<span className='loginStyles__underLiying'>
								Informal Dispute Resolution
							</span>{" "}
							. The parties will attempt to resolve all disputes, claims, or
							controversies arising under or related to these SaaS Terms or its
							subject matter or any right or obligation created by these SaaS
							Terms (“Dispute”) through good faith negotiations conducted by the
							representatives of the parties. The party asserting the Dispute
							will give notice to the other party describing the Dispute in
							reasonable detail. If the parties are unable to resolve the
							Dispute within 30 days following the initiation of such
							negotiations, then the Dispute will be resolved in accordance with{" "}
							<span className='loginStyles__underLiying'>
								Section 11.1.2.2.
							</span>{" "}
						</p>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 9 */}
						<p style={{ marginLeft: "50px" }}>
							<b>11.1.2.2</b>{" "}
							<span className='loginStyles__underLiying'>Arbitration</span> .
							Notwithstanding anything contained in these SaaS Terms to the
							contrary, all disputes arising under these SaaS Terms which cannot
							be resolved under{" "}
							<span className='loginStyles__underLiying'>Section 11.1.2.1</span>{" "}
							, will, before the institution of legal proceedings (except legal
							proceedings which seek equitable relief), will be arbitrated in
							Illinois, U.S.A., before a single arbitrator who is jointly
							selected and mutually approved by the parties. If the parties are
							unable to or fail to agree on the selection of the arbitrator
							within fifteen (15) days of the demand for arbitration being
							served, the arbitrator will be appointed by the American
							Arbitration Association in accordance with its rules and this
							subsection. The arbitrator will serve as a neutral, independent,
							and impartial arbitrator. The arbitration will be administered by
							the American Arbitration Association pursuant to its Commercial
							Arbitration Rules (and in accordance with the expedited procedures
							in those rules). For any procedural aspects of the arbitration not
							specifically addressed in these SaaS Terms or the American
							Arbitration Association rules, the laws of the state of Illinois,
							U.S.A., will apply. The arbitrator will require the non-prevailing
							party to pay for the costs of arbitration, including reasonable
							in-house and outside attorneys’ fees incurred by the prevailing
							party in connection with the arbitration, and may allocate those
							costs between the parties to reflect the extent each prevailed on
							the claims presented. Any arbitration decision rendered will be
							final and binding. The results of the arbitration procedure will
							be considered the Confidential Information of each party, provided
							that judgment thereon may be entered in any court of competent
							jurisdiction, and each party may disclose the same in connection
							with enforcing its rights hereunder.
						</p>
						<p>
							<b>11.2</b>{" "}
							<span className='loginStyles__underLiying'>Force Majeure</span> .
							Neither party will be liable for any loss, damage or delay
							resulting from any event beyond such party’s reasonable control (a
							“Force Majeure Event”), and delivery and performance dates will be
							extended to the extent of any delays resulting from any such Force
							Majeure Event. Each party will promptly notify the other upon
							becoming aware that a Force Majeure Event has occurred or is
							likely to occur and will use commercially reasonable efforts to
							minimize any resulting delay in or interference with the
							performance of its obligations under these SaaS Terms.
							Notwithstanding any other provision of this{" "}
							<span className='loginStyles__underLiying'>Section 11.2</span> , a
							Force Majeure Event shall not relieve Customer of its obligations
							to pay monies due and owing to LiftNet hereunder.
						</p>
						<p>
							<b>11.3</b>{" "}
							<span className='loginStyles__underLiying'>Assignment</span> .
							Neither party shall assign, transfer, or otherwise delegate any of
							its rights, duties, or obligations under these SaaS Terms in whole
							or in part to any individual, firm or corporation without the
							prior written consent of the other party, which consent shall not
							be unreasonably withheld, and any attempted assignment (whether by
							operation of law or otherwise) shall be void; except that LiftNet
							may delegate any of its rights, duties, or obligations under these
							SaaS Terms to one or more of its affiliates. Notwithstanding the
							foregoing, either party may assign its rights, duties, and
							obligations hereunder, without approval of the other party, to a
							party that succeeds to all or substantially all of its assets or
							business (whether by sale, merger, operation of law or otherwise),
							so long as the assignee agrees in writing to be bound by the terms
							and conditions of these SaaS Terms; provided, however, that any
							such assignment by Customer shall be subject to any fee
							adjustments specified in an Quote, or that may be necessary
							because of Customer’s use of the subscribed Services beyond the
							licensing parameters specified in the applicable Quote; and
							further provided that no such assignment may be to a competitor of
							LiftNet. These SaaS Terms shall be binding upon and shall inure to
							the benefit of the parties hereto and their successors and
							permitted assigns.
						</p>
						<p>
							<b>11.4</b>{" "}
							<span className='loginStyles__underLiying'>
								Independent Contractors
							</span>{" "}
							. Nothing in these SaaS Terms shall be construed to create an
							agency, joint venture, partnership or other form of business
							association between the parties. Neither party has the right or
							authority to make any contract, representation or binding promise
							of any nature on behalf of the other party, and neither party
							shall hold itself out as having such right or authority.{" "}
						</p>
						<p>
							<b>11.5</b>{" "}
							<span className='loginStyles__underLiying'> No Waiver</span> . The
							failure on the part of either party to exercise any right or
							remedy hereunder will not operate as further waiver of such right
							or remedy in the future or any other right or remedy.
						</p>
						<p>
							<b>11.6</b>{" "}
							<span className='loginStyles__underLiying'>Severability</span>. In
							the event that any provision of these SaaS Terms is held invalid
							or unenforceable in any circumstances by a court of competent
							jurisdiction, the remainder of these SaaS Terms, and the
							application of such provision in any other circumstances, will not
							be affected thereby.
						</p>
						<p>
							<b>11.7</b>{" "}
							<span className='loginStyles__underLiying'>Notices</span> . All
							official notices (including any notices regarding breach,
							termination, renewal, etc.) required or permitted hereunder shall
							be in writing and shall be delivered personally or sent by
							certified, registered mail or next day express mail or courier,
							postage prepaid. Any such notice shall be deemed given (a) when so
							delivered personally; (b) three (3) days after, when sent by
							certified or registered mail; or (c) the day after, when sent by
							next day express mail or courier, as follows: (i) if to Customer,
							at the address set forth on Customer’s PO; and (ii) if to LiftNet,
							to it at: Attn: LiftNet Operations, 250 Hamilton Rd., Arlington
							Heights, IL 60005. In addition, routine, non-contractual notices,
							consents and approvals (including support) given under these SaaS
							Terms may be delivered in writing as provided above or through
							electronic mail or other electronic record addressed to the
							parties identified herein.
						</p>
						{/** ************************************************** */}
						{/** LIFNET SAS TEMPLATE PAGE 10 */}
						<p>
							<b>11.8 </b>
							<span className='loginStyles__underLiying'>Marketing</span> .
							Customer agrees that LiftNet may reference Customer’s status as a
							user of the Subscribed Services in marketing materials and sales
							presentations and use Customer’s Marks for this purpose.
						</p>
						<p>
							<b>11.9</b>{" "}
							<span className='loginStyles__underLiying'>
								Waiver of Jury Trial
							</span>{" "}
							. LIFTNET AND CUSTOMER, HAVING BEEN REPRESENTED BY COUNSEL, EACH
							KNOWINGLY AND VOLUNTARILY WAIVES ANY RIGHT TO TRIAL BY JURY IN ANY
							ACTION OR PROCEEDING TO ENFORCE OR DEFEND ANY RIGHTS, WHETHER IN
							CONTRACT, TORT OR OTHERWISE, THESE SAAS TERMS OR ANY RELATED
							AGREEMENT OR UNDER ANY AMENDMENT, INSTRUMENT, DOCUMENT OR
							AGREEMENT DELIVERED OR WHICH MAY IN THE FUTURE BE DELIVERED IN
							CONNECTION WITH THESE SAAS TERMS, AND AGREES THAT ANY SUCH ACTION
							OR PROCEEDING WILL BE TRIED BEFORE A COURT AND NOT BEFORE A JURY.
						</p>
						<br />
						<div>
							<Button
								onClick={() => {
									window.location.href = "/login";
								}}
								className='buttonStyle__6'
							>
								Back To Login
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MssaPage;
