import { Auth } from "aws-amplify";

const GETJwtToken = (): Promise<string> =>
	new Promise((resolve) => {
		if (process.env.REACT_APP_ENVIRONMENT !== "OFFLINE") {
			Auth.currentSession()
				.then((data) => {
					const jwt = data.getIdToken();
					resolve(`Bearer ${jwt.getJwtToken()}`);
				})
				.catch(() => {
					Auth.signOut();
					window.location.href = `${process.env.REACT_APP_LOGIN_ROUTE}`;
				});
		} else if (process.env.REACT_APP_ENVIRONMENT === "OFFLINE") {
			fetch(
				`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/offline/refresh`,
				{
					method: "POST",
					headers: new Headers({
						Authorization: `Bearer ${localStorage.getItem("offlineToken")}`,
					}),
				}
			)
				.then((response) => response.json())
				.then((json) => {
					if (json && json.token) {
						localStorage.setItem("offlineToken", json.token);
						resolve(`Bearer ${json.token}`);
					}
				})
				.catch((err) => resolve(`Bearer - ${err}`));
		}
	});

export default GETJwtToken;
