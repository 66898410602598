import React, { useEffect, useState } from "react";
import axios from "axios";
// ANT DESIGN COMPONENTS
import { Table, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// TIMEZONE
import moment from "moment-timezone";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenDrawerRows,
	updateReportRow,
	updateFilters,
} from "../../redux/reports/reportsSlice";
// AUTHORIZATION
import validatePermission from "../../utils/validatePermissions";
import GETJwtToken from "../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../components/table/TableNoDataBox";
import ReportsDrawer from "./drawer/ReportsDrawer";
import ReportsAlertDownloadModal from "./ReportsAlertDownloadModal";
// INTERFACES
import { TablePaginationInterface } from "../../interfaces/Global.interface";
import {
	ReportsTableInterface,
	ReportsListOfColumnsInterface,
	ReportSliceFiltersInterface,
} from "../../interfaces/Reports.interface";
import { RootState } from "../../app/store";

function ReportsTable({
	response,
	triggerGetReport,
	isLoading,
	isError,
	error,
}: ReportsTableInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const CUSTOM_COLUM_NAMES: any = {
		up_time_target: "AVAILABILITY (%)",
		unavailability: "UNAVAILABILITY (%)",
		start_timestamp: "START TIME",
		end_timestamp: "END TIME",
	};
	const { Column } = Table;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>([]);
	const [pagination, setPagination] = useState<TablePaginationInterface>({
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ["10", "20", "50", "100"],
		total: -1,
	});
	const [downloadReport, setDownloadReport] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: RootState) => state.user);
	const { reportRefreshTable, filters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	/** Take all the values that comes from report API call and create
  a list with all the available columns */
	const buildListOfColumnsArray = () => {
		const newArr: ReportsListOfColumnsInterface[] = [];
		Object.keys(data[0]).forEach((key: any) => {
			const isArrayValidation = Array.isArray(data[0][key]);
			if (!(typeof data[0][key] === "object" || isArrayValidation)) {
				newArr.push({
					label: key,
					value: key,
				});
			}
			return true;
		});
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.tableListColumns = newArr;
		copy.tableSelectedColumns = filters.defaultColumns;
		dispatch(updateFilters(copy));
	};

	const generalReportBody = (page: number, limit: number, total: number) => {
		const filtersTargetValue = [];
		// Generate level target value
		if (filters.levelTypeName === "ORGANIZATIONS") {
			filtersTargetValue.push({
				target: "org_id",
				value: filters.organizationId,
			});
		} else if (filters.levelTypeName === "SERVERS") {
			filtersTargetValue.push({
				target: "server_id",
				value: filters.serverId,
			});
		} else if (filters.levelTypeName === "BANKS") {
			filtersTargetValue.push({ target: "bank_id", value: filters.bankId });
		} else if (filters.levelTypeName === "LIFTS") {
			filtersTargetValue.push({ target: "lift_id", value: filters.liftId });
		}
		// Generate offsets target value
		if (filters.offsetsList.length >= 1) {
			let offsetsString = "";
			filters.offsetsList.map((offset: string, index: number) => {
				offsetsString = offsetsString.concat(
					offset,
					index + 1 < filters.offsetsList.length ? "," : ""
				);
				return true;
			});
			filtersTargetValue.push({ target: "msg.type", value: offsetsString });
		}
		// BODY
		const body = {
			page,
			limit,
			total,
			sort_direction: filters.sortAsc ? "asc" : "desc",
			filters: filtersTargetValue,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			download: downloadReport ? "true" : "false",
		};
		return body;
	};

	const dinamicColumnTitle = (title: string) => {
		if (CUSTOM_COLUM_NAMES[title]) {
			return CUSTOM_COLUM_NAMES[title].toUpperCase();
		}
		return title.toUpperCase().replaceAll("_", " ");
	};

	const generateDownloadHeaders = () => {
		let downloadHeaders = "";
		if (filters.tableSelectedColumns.length >= 1) {
			filters.tableSelectedColumns.map((column: string, index: number) => {
				downloadHeaders = downloadHeaders.concat(
					column,
					":",
					dinamicColumnTitle(column),
					index + 1 < filters.tableSelectedColumns.length ? "," : ""
				);
				return true;
			});
		}
		return downloadHeaders;
	};

	const beneficialAndPerformanceReportBody = (reportType: string) => {
		// Generate time ranges
		let timeListString = "";
		if (filters.exclusionTimeList.length >= 1) {
			filters.exclusionTimeList.map((timeItem: string, index: number) => {
				timeListString = timeListString.concat(
					timeItem[0],
					",",
					timeItem[1],
					index + 1 < filters.exclusionTimeList.length ? ";" : ""
				);
				return true;
			});
		}
		// Generate date ranges
		let dateListString = "";
		if (filters.exclusionDateList.length >= 1) {
			filters.exclusionDateList.map((dateItem: string, index: number) => {
				dateListString = dateListString.concat(
					dateItem[0],
					",",
					dateItem[1],
					index + 1 < filters.exclusionDateList.length ? ";" : ""
				);
				return true;
			});
		}
		// Generate download headers
		const downloadHeaders = generateDownloadHeaders();
		// BODY
		const body: any = {
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			download: downloadReport ? "true" : "false",
			download_headers: downloadHeaders,
			exclusions: {
				saturdays: filters.exclusionSaturdays ? "true" : "false",
				sundays: filters.exclusionSundays ? "true" : "false",
				holidays: filters.exclusionHolidays ? "true" : "false",
				time_ranges: timeListString,
				date_ranges: dateListString,
			},
			sort_direction: filters.sortAsc ? "asc" : "desc",
		};
		if (reportType === "BANK") {
			body.bank_id = filters.bankId;
		} else if (reportType === "SERVER") {
			body.server_id = filters.serverId;
		}
		return body;
	};

	const reportFaultsReportLogBody = () => {
		const body = {
			bank_id: filters.bankId,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			download: downloadReport ? "true" : "false",
		};
		return body;
	};

	const reportFaultsReportRecentBody = () => {
		const body = {
			bank_id: filters.bankId,
			weeks: filters.weeksNumber,
			timezone: "America/Guatemala",
			download: downloadReport ? "true" : "false",
			limit: filters.limitNumber,
		};
		return body;
	};

	const reportTrafficDetailedSummary = () => {
		const body = {
			bank_id: filters.bankId,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			download: downloadReport ? "true" : "false",
		};
		return body;
	};

	const reportTrafficHallCallSummary = () => {
		const body = {
			bank_id: filters.bankId,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			chart_by: "FLOOR",
			download: downloadReport ? "true" : "false",
		};
		return body;
	};

	const outOfServiceReportBody = () => {
		// Generate download headers
		const downloadHeaders = generateDownloadHeaders();
		// BODY
		const body = {
			server_id: filters.serverId,
			timezone: filters.timezoneCode,
			download: downloadReport ? "true" : "false",
			lift_type: filters.liftType === "ALL" ? "" : filters.liftType,
			sort_direction: filters.sortAsc ? "asc" : "desc",
			download_headers: downloadHeaders,
		};
		return body;
	};

	const reportTrafficLongestWaitTimeListReportBody = () => {
		// BODY
		const body = {
			bank_id: filters.bankId,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			limit: filters.limitNumber,
			download: downloadReport ? "true" : "false",
		};
		return body;
	};

	const getData = async (page: number, limit: number, total: number) => {
		const token = await GETJwtToken();
		let body = {};
		if (
			filters.reportName === "generalReport" ||
			filters.reportName === "faultReport" ||
			filters.reportName === "trafficReport" ||
			filters.reportName === "carUsageReport" ||
			filters.reportName === "serviceReport"
		) {
			body = generalReportBody(page, limit, total);
		} else if (filters.reportName === "beneficialReport") {
			body = beneficialAndPerformanceReportBody("BANK");
		} else if (filters.reportName === "performanceReport") {
			body = beneficialAndPerformanceReportBody("SERVER");
		} else if (filters.reportName === "outOfServiceReport") {
			body = outOfServiceReportBody();
		} else if (filters.reportName === "reportFaultsReportLog") {
			body = reportFaultsReportLogBody();
		} else if (filters.reportName === "reportFaultsReportRecent") {
			body = reportFaultsReportRecentBody();
		} else if (filters.reportName === "reportTrafficDetailedSummary") {
			body = reportTrafficDetailedSummary();
		} else if (filters.reportName === "reportTrafficLongestWaitTimeList") {
			body = reportTrafficLongestWaitTimeListReportBody();
		} else if (filters.reportName === "reportTrafficHallCallSummary") {
			body = reportTrafficHallCallSummary();
		}
		triggerGetReport({
			token,
			body,
		});
	};

	const buildAndDownloadReport = async () => {
		const token: string = await GETJwtToken();
		let url = "";
		setTimeout(() => {
			setDownloadReport(false);
		}, 1000);
		// Generate body
		let body: any = {};
		if (filters.reportName === "beneficialReport") {
			body = beneficialAndPerformanceReportBody("BANK");
			url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/lift-records/${filters.reportServiceName}`;
		} else if (filters.reportName === "performanceReport") {
			body = beneficialAndPerformanceReportBody("SERVER");
			url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/lift-records/${filters.reportServiceName}`;
		} else if (filters.reportName === "outOfServiceReport") {
			body = outOfServiceReportBody();
			url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/lift-records/${filters.reportServiceName}`;
		} else if (filters.reportName === "reportFaultsReportLog") {
			body = reportFaultsReportLogBody();
			url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/faults/log`;
		} else if (filters.reportName === "reportFaultsReportRecent") {
			body = reportFaultsReportRecentBody();
			url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/faults/recent`;
		} else if (filters.reportName === "reportTrafficDetailedSummary") {
			body = reportTrafficDetailedSummary();
			url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/traffic/summary/day`;
		} else if (filters.reportName === "reportTrafficLongestWaitTimeList") {
			body = reportTrafficLongestWaitTimeListReportBody();
			url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/traffic/wait-time`;
		} else if (filters.reportName === "reportTrafficHallCallSummary") {
			body = reportTrafficHallCallSummary();
			url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/traffic/summary/floor`;
		}
		// Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
		// eslint-disable-next-line no-return-await
		return await axios
			.post(url, body, {
				responseType: "blob",
				headers: {
					Authorization: token,
					"Content-type": "application/json",
				},
			})
			.then((res: any) => {
				const data1 = new Blob([res?.data], {
					type: "application / vnd. MS Excel",
				});
				const csvURL = window.URL.createObjectURL(data1);
				const tempLink = document.createElement("a");
				tempLink.href = csvURL;
				tempLink.setAttribute(
					"download",
					`${filters.reportName} ${moment()
						.tz(filters.timezoneCode || moment.tz.guess())
						.format("MMMM Do YYYY, h:mm:ss a")}.csv`
				);
				tempLink.click();
			});
	};

	const handleTableChange = async (pagination_: any) => {
		setPagination({
			...pagination,
			current: pagination_.current,
			pageSize: pagination_.pageSize,
			total: pagination_.total,
		});
		if (filters.useTablePagination) {
			getData(pagination_.current, pagination_.pageSize, pagination_.total);
		}
	};

	const refreshTable = async () => {
		setPagination({
			...pagination,
			current: 1,
			pageSize: 10,
		});
		getData(1, 10, pagination.total ? pagination.total : -1);
	};

	const formatDateColumn = (value: string) =>
		moment(value)
			.tz(
				filters.timezoneCode !== "" ? filters.timezoneCode : moment.tz.guess()
			)
			.format("MMMM Do YYYY, h:mm:ss a");

	const buildDataObject = (newData: any) => {
		const newArr: any = [];
		const formatDates = [
			"created_at",
			"synced_at",
			"start_timestamp",
			"end_timestamp",
			"timestamp",
			"out_of_service_timestamp",
		];
		newData.map((record: any) => {
			const newObject = { ...record };
			// Format dates
			formatDates.map((d: string) => {
				if (record[d]) {
					if (record[d] !== "0001-01-01T00:00:00Z" && record[d] !== "-") {
						newObject[d] = formatDateColumn(record[d]);
					} else {
						newObject[d] = "--";
					}
				}
				return true;
			});
			if (record.msg) {
				Object.keys(record.msg).forEach((key: any) => {
					newObject[key] = record.msg[key];
					return true;
				});
			}
			newArr.push(newObject);
			return true;
		});
		setData(newArr);
	};

	const onCellNameClick = (record: any) => {
		if (record) {
			dispatch(updateOpenDrawerRows(true));
			dispatch(updateReportRow(record));
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (data && data.length > 0) {
			buildListOfColumnsArray();
		}
	}, [data]);

	useEffect(() => {
		if (response) {
			if (response.data) {
				setPagination({
					...pagination,
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: response.total || response.results,
				});
				buildDataObject(response.data);
			} else if (response.results) {
				setPagination({
					...pagination,
					current: pagination.current,
					pageSize: pagination.pageSize,
					total: response.total || response.results,
				});
				buildDataObject(response.results);
			} else {
				setPagination({
					...pagination,
					current: 1,
					pageSize: 10,
					total: -1,
				});
				buildDataObject([]);
			}
		}
	}, [response]);

	useEffect(() => {
		if (isError) {
			setData([]);
			setPagination({
				current: 1,
				pageSize: 10,
				showSizeChanger: true,
				pageSizeOptions: ["10", "20", "50", "100"],
				total: 0,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (reportRefreshTable) {
			refreshTable();
		}
	}, [reportRefreshTable]);

	useEffect(() => {
		if (downloadReport) {
			buildAndDownloadReport();
		}
	}, [downloadReport]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("permission-not-defined", permissions) ? (
				<>
					{/** ******************************************* */}
					{/** TABLE */}
					<Table
						locale={{
							emptyText: (
								<TableNoDataBox
									isError={isError}
									errorMessage={
										error && error.data && error.data.message
											? error.data.message
											: "Error"
									}
									noItemsFound='No data found'
								/>
							),
						}}
						loading={isLoading}
						rowClassName={(record, index) => getTableRowClass(index, theme)}
						dataSource={data}
						size='small'
						className='mt-3'
						pagination={pagination}
						onChange={handleTableChange}
						scroll={{ x: 900 }}
						footer={() => (
							<div className='generalStyles__flexEnd'>
								<Tag>
									{pagination && pagination.total && pagination.total >= 0
										? pagination.total
										: "0"}{" "}
									Records
								</Tag>
							</div>
						)}
					>
						{/** DINAMIC COLUMNS */}
						{filters.tableSelectedColumns.map(
							(column: string, indexColumn: number) => (
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellNameClick(record);
										},
									})}
									title={dinamicColumnTitle(column)}
									dataIndex={column}
									key={indexColumn}
									render={(text: string) => <div className=''>{text}</div>}
								/>
							)
						)}
					</Table>
				</>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty
							description={t(
								"organizationManagement.listNotAvailableOrganizations"
							)}
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					</div>
				</>
			)}
			{/** ******************************************* */}
			{/** REPORT DRAWER BY ROW */}
			<ReportsDrawer />
			{/** ******************************************* */}
			{/** DOWNLOAD REPORT */}
			<ReportsAlertDownloadModal setDownloadReport={setDownloadReport} />
		</div>
	);
}

export default ReportsTable;
