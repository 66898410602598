/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// IMPORT INTERFACE
import { ActionInterface } from "../../interfaces/Actions.interface";

export interface ActionState {
	action: ActionInterface;
	actions: Array<ActionInterface>;
	openDrawer: boolean;
	openForm: boolean;
	showTable: boolean;
	refreshTable: boolean;
	formAction: string;
	openModalDelete: boolean;
}

const initialState: ActionState = {
	action: {
		id: "",
		cmd: "",
		label: "",
		tooltip: "",
		valid_states: [],
	},
	actions: [],
	openDrawer: false,
	openForm: false,
	showTable: false,
	refreshTable: false,
	formAction: "create",
	openModalDelete: false,
};

export const actionSlice = createSlice({
	name: "action",
	initialState,
	reducers: {
		updateAction: (state, action: PayloadAction<ActionInterface>) => {
			state.action = action.payload;
		},
		updateActions: (state, action: PayloadAction<ActionInterface[]>) => {
			state.actions = action.payload;
		},
		updateOpenDrawer: (state, action: PayloadAction<boolean>) => {
			state.openDrawer = action.payload;
		},
		updateOpenForm: (state, action: PayloadAction<boolean>) => {
			state.openForm = action.payload;
		},
		updateShowTable: (state, action: PayloadAction<boolean>) => {
			state.showTable = action.payload;
		},
		updateRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.refreshTable = action.payload;
		},
		updateFormAction: (state, action: PayloadAction<string>) => {
			state.formAction = action.payload;
		},
		updateOpenModalDelete: (state, action: PayloadAction<boolean>) => {
			state.openModalDelete = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateAction,
	updateActions,
	updateOpenDrawer,
	updateOpenForm,
	updateShowTable,
	updateRefreshTable,
	updateFormAction,
	updateOpenModalDelete,
} = actionSlice.actions;

export default actionSlice.reducer;
