import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button, Select, Spin, Divider, Input, Tooltip } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp19, faElevator } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutLiftMutation } from "../../../redux/lifts/liftAPI";
import { updateOpenModalEditLift } from "../../../redux/lifts/liftSlice";
// INTERFACE
import { LiftPositionInterface } from "../../../interfaces/EdgeManagement.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

function LiftsEditForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const [name, setName] = useState("");
	const [type, setType] = useState("ELEVATOR");
	const [formIsValid, setFormIsValid] = useState(false);
	const [liftPosition, setLiftPosition] = useState(0);
	const escalatorDirection = ["UP", "DOWN"];
	const walkingBankdDirection = ["FORDWARD", "BACKWARD"];
	const [liftDirection, setLiftDirection] = useState("");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listLiftPosition, setListLiftPosition] = useState<
		LiftPositionInterface[]
	>([]);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { lift, openModalEditLift } = useSelector((state: any) => state.lift);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		putLift,
		{ isSuccess, isError, error, isLoading, reset: resetPutLift },
	] = usePutLiftMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const onChangeName = (newValue: string) => {
		setName(newValue);
	};

	const onClickClose = () => {
		dispatch(updateOpenModalEditLift(false));
	};

	const onChangeLiftPosition = (newValue: number) => {
		setLiftPosition(newValue);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		//
		const BODY = {
			id: lift.id,
			body: {
				bank_id: lift && lift.bank && lift.bank.id ? lift.bank.id : "",
				type,
				position: lift.position,
				name,
				offsets: [],
				direction: liftDirection,
				control_panel: JSON.stringify({
					carcall: {
						code: "cc",
						map: [
							{
								offset: "3c",
								target_bit: "01",
								new_bit: "10",
							},
						],
					},
					rear_carcall: {
						code: "rcc",
						map: [
							{
								offset: "3c",
								new_byte: "1a",
							},
						],
					},
					close_door: {
						code: "cd",
						map: [
							{
								offset: "3c",
								target_bit: "01",
								new_bit: "10",
							},
						],
					},
					open_door: {
						code: "od",
						map: [
							{
								offset: "3c",
								new_byte: "1a",
							},
						],
					},
					hall_call: {
						code: "hc",
						map: [
							{
								offset: "3c",
								target_bit: "01",
								new_bit: "10",
							},
						],
					},
					secure: {
						code: "sec",
						map: [
							{
								offset: "3c",
								new_byte: "10",
							},
						],
					},
				}),
			},
			token,
		};
		putLift(BODY);
	};

	const onClose = () => {
		dispatch(updateOpenModalEditLift(false));
	};

	const onFill = () => {
		if (lift) {
			if (lift && lift.type && lift.type === "ELEVATOR") {
				setLiftDirection("N/D");
			} else {
				setLiftDirection(lift.direction);
			}
			setName(lift.name);
			setType(lift.type);
			setLiftPosition(lift.position);
		}
	};

	const buildArrayLiftsPosition = (numberOfLifts: number, lifts_: any) => {
		const newArr: LiftPositionInterface[] = [];
		// Create new array off lifts position
		for (let x = 0; x < numberOfLifts; x += 1) {
			let reserved = false;
			// Validate if position is reserved
			if (lifts_ && lifts_.length > 0) {
				for (let y = 0; y < lifts_.length; y += 1) {
					// If car number in list of lifts, set as reserved
					if (lifts_[y].car_number === x + 1) {
						reserved = true;
					}
					// Set as valid to select current car number
					if (x + 1 === lift.car_number) {
						reserved = false;
					}
				}
			}
			// Push new item in array
			newArr.push({
				name: `Position No ${x + 1}`,
				id: x + 1,
				reserved,
				liftId: "",
				isValid: true,
			});
		}
		setListLiftPosition(newArr);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (openModalEditLift) {
			onFill();
			resetPutLift();
			buildArrayLiftsPosition(lift.bank.number_of_lifts, lift.bank.lifts);
		}
	}, [openModalEditLift]);

	useEffect(() => {
		dispatch(updateOpenModalEditLift(false));
	}, [isSuccess, isError]);

	useEffect(() => {
		if (name !== "" && type !== "") {
			setFormIsValid(true);
		} else {
			setFormIsValid(false);
		}
	}, [name, type]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClose}
			closable={false}
			open={openModalEditLift}
		>
			<Spin spinning={isLoading} tip={t("general.loading")}>
				{/** *************************************************** */}
				{/** LIFT INFORMATION */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faElevator}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("edgeManagement.lifts.editLift")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme} row`}>
					{/** NAME */}
					<div className='col-md-12 '>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>
									{t("configuration.configurationFiles.name")}
								</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									className='generalStyles__inputNumberFull'
									size='small'
									value={name}
									onChange={(e) => {
										onChangeName(e.target.value);
									}}
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** TYPE */}
					<div className='col-md-12 mt-3'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>
									{t("edgeManagement.lifts.type")}
								</span>
							</div>
							<div className='generalStyles__width100'>
								<Select
									showSearch
									optionFilterProp='children'
									className='generalStyles__width100'
									allowClear
									size='small'
									value={type}
									disabled
								>
									{["ELEVATOR", "ESCALATOR", "MOVINGWALK"].map((item: any) => (
										<Option key={item} value={item}>
											{item}
										</Option>
									))}
								</Select>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** ESCALATOR / MOVINGWALK DIRECTION */}
					{(type === "ESCALATOR" || type === "MOVINGWALK") && (
						<div className='col-md-12 mt-3'>
							<div className='generalStyles__flex'>
								<div className='generalStyles__inputFlexName'>
									<span className='generalStyles__inputFlexRequired'>*</span>
									<span>{t("general.direction")}</span>
								</div>
								<div className='generalStyles__width100'>
									<Select
										value={liftDirection}
										onChange={(e: any) => {
											setLiftDirection(e);
										}}
										className='generalStyles__width100'
										size='small'
									>
										{type === "ESCALATOR" && (
											<>
												{escalatorDirection.map((item: any) => (
													<Option key={item} value={item}>
														{item}
													</Option>
												))}
											</>
										)}
										{type === "MOVINGWALK" && (
											<>
												{walkingBankdDirection.map((item: any) => (
													<Option key={item} value={item}>
														{item}
													</Option>
												))}
											</>
										)}
									</Select>
								</div>
								<div className='generalStyles__infoTooltipIconBlank' />
							</div>
						</div>
					)}
				</div>
				<br />
				{/** *************************************************** */}
				{/** LIFT POSITION */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faArrowUp19}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("edgeManagement.banks.liftPosition")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme} row`}>
					{/** LIFT POSITION -- */}
					<div className='col-md-12 mt-3'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>
									{t("edgeManagement.banks.liftPosition")}
								</span>
							</div>
							<div className='generalStyles__width100'>
								<Select
									className='generalStyles__width100'
									showSearch
									size='small'
									optionFilterProp='children'
									onChange={onChangeLiftPosition}
									value={liftPosition === 0 ? null : liftPosition}
								>
									{listLiftPosition.map((item: any) => (
										<Option
											key={item.id}
											value={item.id}
											disabled={item.reserved}
										>
											{item.reserved ? (
												<Tooltip title='Position Reserved' placement='left'>
													{item.name}
												</Tooltip>
											) : (
												<>{item.name}</>
											)}
										</Option>
									))}
								</Select>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
				</div>
				<br />
				{/** *************************************************** */}
				{/** CLOSE AND SAVE BUTTONS */}
				<div className='mt-4'>
					<div>
						<Button onClick={onClickClose} className='buttonStyle__3'>
							{t("organizationManagement.close")}
						</Button>
						<Button
							htmlType='submit'
							disabled={isLoading || !formIsValid}
							className='buttonStyle__3'
							type='primary'
							onClick={onClickSave}
						>
							{t("general.save")}
						</Button>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='PUT'
				error={error}
				name='Lift'
			/>
		</Drawer>
	);
}

export default LiftsEditForm;
