import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Spin, Input, Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleInfo,
	faEnvelope,
	faLock,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch } from "react-redux";
import { usePostLoginOfflineMutation } from "../../redux/login/loginAPI";
import { updateUser } from "../../redux/login/loginSlice";
// CUSTOM AMPLIFY COMPONENTS
import CustomAmplifyCarousel from "../login/CustomAmplifyCarousel";
// STYLES
import logo from "../../assets/logo/LiftNetLogo.png";
import "../../styles/generalStyles.scss";

const LoginOfflinePage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [launchToDashboard, setLaunchToDashboard] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const [triggerLoggin, { data: dataLoginOffline, isError, error }] =
		usePostLoginOfflineMutation();

	const onClickLogin = () => {
		// Call offline login service
		triggerLoggin({ body: { email, password } });
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (dataLoginOffline) {
			localStorage.setItem("offlineToken", dataLoginOffline.token);
			const response = {
				username: "5c099235-165e-43d6-b0ba-8a8581a1ab17",
				pool: {
					userPoolId: "us-east-2_G7uEE7P0C",
					clientId: "s9en4iv123b6qlrugg0cfgioh",
					client: {
						endpoint: "https://cognito-idp.us-east-2.amazonaws.com/",
						fetchOptions: {},
					},
					advancedSecurityDataCollectionFlag: true,
					storage: {
						headerTitle: "monitoring",
						useLoggedAmplify: "false",
						theme: "blue",
					},
				},
				Session:
					"AYABeCmDmgDN-EVJ01yFyAsIp-EAHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMAS2Fybjphd3M6a21zOnVzLWVhc3QtMjo0MTc1Njc5MDM0Njk6a2V5LzVjZDI0ZDRjLWVjNWItNGU4Ny05MGI2LTVkODdkOTZmY2RkMgC4AQIBAHjif3k0w30uAyP92ifoZ0jN6g50UW_KR0w9Vv2c_wlQAgH-QZ-NNGghuF7hjt1FrqQ1AAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMfbxqDNdgRVj2ZWcOAgEQgDvTZFVcFmETCaO2hHNl1P1ZeF1M8ItTtf04bTQYaVoIEanOb-byKKK1cD1YpfJzlLFKh-oaA3G7OQ04wQIAAAAADAAAEAAAAAAAAAAAAAAAAADKqQVsVLTOhTHklfBxkobW_____wAAAAEAAAAAAAAAAAAAAAEAAAEASfR7LCnePUtBuptfuzwMxPPqrMcftN4aZy3X0u_etosge8p-qKs4BGJ3-dgn9HvHKLSQYID6xDTPzaQKzwDFmyJcMyL6o6Lm8MVal36tRGx3unrguUjGpaSmWhPYyuWpT7e9tzdqq3Ny_p_DRwxKV15yl3WtygelcJqpg5ORCR1aenDrpvXFpYTDy_tSTK6g6Hb_AigqZShZo7Et25WM2UsWvUvKDHb0pgFSTbBV7cqrpKWbHkXOTW6gfTEI1WP423MPlZl5t-daU0S0JSP44Lm0A0WhCBuT4H4XJtNDyTVbsBmtM9j5HRmva7lDWKIZwWy2HKChyVFL6gVPZj4SC9WmLEc4JlzzLIgnK83OIoU",
				client: {
					endpoint: "https://cognito-idp.us-east-2.amazonaws.com/",
					fetchOptions: {},
				},
				signInUserSession: null,
				authenticationFlowType: "USER_SRP_AUTH",
				storage: {
					headerTitle: "monitoring",
					useLoggedAmplify: "false",
					theme: "blue",
				},
				keyPrefix: "CognitoIdentityServiceProvider.s9en4iv123b6qlrugg0cfgioh",
				userDataKey:
					"CognitoIdentityServiceProvider.s9en4iv123b6qlrugg0cfgioh.5c099235-165e-43d6-b0ba-8a8581a1ab17.userData",
				challengeName: "SOFTWARE_TOKEN_MFA",
				challengeParam: { FRIENDLY_DEVICE_NAME: "My TOTP device" },
			};
			localStorage.setItem("useLoggedAmplify", "true");
			dispatch(updateUser(response));
			setLaunchToDashboard(true);
		}
	}, [dataLoginOffline]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<>
			{launchToDashboard ? (
				<>
					<Navigate to='/monitoring' />
				</>
			) : (
				<div className='loginStyles__body'>
					<div className='loginStyles__loginSubBox1 d-none d-sm-block'>
						<CustomAmplifyCarousel />
					</div>
					<div className='loginStyles__loginSubBox2'>
						<Spin spinning={false} wrapperClassName='loginStyle__spinContainer'>
							<div>
								<div className='loginStyles__100Container'>
									<div className='loginStyles__33Container' />
									<div className='loginStyles__33Container'>
										<div className='loginStyles__loginMainBox'>
											{/** LOGO */}
											<div className='generalStyles__flex d-block d-sm-none'>
												<div>
													<img
														src={logo}
														className='loginStyles__liftNetLogo'
														alt=''
														width='150'
														height='32'
													/>
												</div>
											</div>
											{/** DESCRIPTION */}
											<div className=' d-none d-sm-block'>
												<p className='loginStyles__homeLogin'>
													Login to Lift-Net
												</p>
												<p className='loginStyles__homeLogin loginStyles__monitoringApplication'>
													Monitoring Offline Application
												</p>
											</div>
											{/* ********** LOGIN ********** */}
											{/* INPUT - EMAIL */}
											<Input
												placeholder='Email'
												prefix={
													<FontAwesomeIcon
														icon={faEnvelope}
														className='generalStyles__info generalStyles__mrFix'
													/>
												}
												onChange={(e) => {
													setEmail(e.target.value);
												}}
												value={email}
											/>
											<br />
											<br />
											{/* INPUT - PASSWORD */}
											<Input.Password
												placeholder='Password'
												prefix={
													<FontAwesomeIcon
														className='generalStyles__info generalStyles__mrFix'
														icon={faLock}
													/>
												}
												onChange={(e) => {
													setPassword(e.target.value);
												}}
												value={password}
											/>
											{isError && (
												<p
													className='generalStyles__error'
													style={{
														marginTop: "5px",
														marginBottom: "-10px",
														marginLeft: "2px",
													}}
												>
													{error}
												</p>
											)}
											<Button
												className='buttonStyle__1 mt-3'
												onClick={onClickLogin}
											>
												Login
											</Button>
										</div>
									</div>
									<div className='loginStyles__33Container'>
										<div
											style={{
												position: "absolute",
												bottom: "10px",
												right: "0px",
											}}
										>
											<div
												role='button'
												tabIndex={0}
												onKeyDown={() => {}}
												onClick={() => {
													window.location.href = "/terms/mssa";
												}}
											>
												<FontAwesomeIcon
													className='generalStyles__info'
													icon={faCircleInfo}
												/>
												<span className='generalStyles__mlFix generalStyles__mrFix25 loginStyles__termsAndConditionsLink'>
													Terms and conditions
												</span>
											</div>
											<div />
										</div>
									</div>
								</div>
							</div>
						</Spin>
					</div>
				</div>
			)}
		</>
	);
};

export default LoginOfflinePage;
