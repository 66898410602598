/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// AMPLIFY
import { Auth, Hub } from "aws-amplify";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateLoginAmplify,
	updateJwtToken,
} from "../../redux/login/loginSlice";
// CUSTOM AMPLIFY COMPONENTS
import CustomAmplifySignIn from "./CustomAmplifySignIn";
import CustomAmplifyForgotPassword from "./CustomAmplifyForgotPassword";
import CustomAmplifyMFASetUp from "./CustomAmplifyMFASetUp";
import CustomAmplifyCarousel from "./CustomAmplifyCarousel";
import CustomAmplifyForceChangePassword from "./CustomAmplifyForceChangePassword";
// STYLES
import logo from "../../assets/logo/LiftNetLogo.png";
import "../../styles/generalStyles.scss";

const LoginPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { amplifyActiveComponent, isLoading } = useSelector(
		(state: any) => state.login
	);
	const [launchToDashboard, setLaunchToDashboard] = useState(false);

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const successLogin = () => {
		localStorage.setItem("headerTitle", "home");
		Auth.currentSession().then((data: any) => {
			const jwt = data.getIdToken();
			dispatch(updateJwtToken(`Bearer ${jwt.getJwtToken()}`));
		});
	};
	const checkUserLogged = async () => {
		try {
			await Auth.currentAuthenticatedUser();
			dispatch(updateLoginAmplify(true));
			localStorage.setItem("useLoggedAmplify", "true");
			setLaunchToDashboard(true);
			return true;
		} catch {
			dispatch(updateLoginAmplify(false));
			localStorage.setItem("useLoggedAmplify", "false");
			setLaunchToDashboard(false);
			return false;
		}
	};
	const launchLogin = async () => {
		await checkUserLogged();
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	useEffect(() => {
		launchLogin();
	}, []);

	useEffect(() => {
		Hub.listen("auth", (authData: any) => {
			if (authData && authData.payload && authData.payload.event) {
				if (authData.payload.event === "signIn_failure") {
					dispatch(updateLoginAmplify(false));
					localStorage.setItem("useLoggedAmplify", "false");
					setLaunchToDashboard(false);
				} else if (authData.payload.event === "signIn") {
					dispatch(updateLoginAmplify(true));
					localStorage.setItem("useLoggedAmplify", "true");
					setLaunchToDashboard(true);
				}
			}
		});
	});

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<>
			{launchToDashboard ? (
				<>
					{successLogin()}
					{<Navigate to='/monitoring' />}
				</>
			) : (
				<div className='loginStyles__body'>
					<div className='loginStyles__loginSubBox1 d-none d-sm-block'>
						<CustomAmplifyCarousel />
					</div>
					<div className='loginStyles__loginSubBox2'>
						<Spin
							spinning={isLoading}
							wrapperClassName='loginStyle__spinContainer'
						>
							<div>
								<div className='loginStyles__100Container'>
									<div className='loginStyles__33Container' />
									<div className='loginStyles__33Container'>
										<div className='loginStyles__loginMainBox'>
											{/** LOGO */}
											<div className='generalStyles__flex d-block d-sm-none'>
												<div>
													<img
														src={logo}
														className='loginStyles__liftNetLogo'
														alt=''
														width='150'
														height='32'
													/>
												</div>
											</div>
											{/** DESCRIPTION */}
											<div className=' d-none d-sm-block'>
												<p className='loginStyles__homeLogin'>
													Login to Lift-Net
												</p>
												<p className='loginStyles__homeLogin loginStyles__monitoringApplication'>
													Monitoring Application
												</p>
											</div>
											{/* ********** LOGIN ********** */}
											{amplifyActiveComponent === "sign-in" && (
												<CustomAmplifySignIn />
											)}
											{/* ********** FORGOT PASSWORD ********** */}
											{amplifyActiveComponent === "forgot-password" && (
												<CustomAmplifyForgotPassword />
											)}
											{/* ********** MFA SET-UP ********** */}
											{amplifyActiveComponent === "mfa-setup" && (
												<CustomAmplifyMFASetUp />
											)}
											{/* ********** FORCE CHANGE PASSWORD ********** */}
											{amplifyActiveComponent === "force-change-password" && (
												<CustomAmplifyForceChangePassword />
											)}
										</div>
									</div>
									<div className='loginStyles__33Container'>
										<div
											style={{
												position: "absolute",
												bottom: "10px",
												right: "0px",
												display: "flex",
												fontSize: "12px",
											}}
										>
											{/** ********************************* */}
											{/** MSSA PAGE */}
											<div
												role='button'
												tabIndex={0}
												onKeyDown={() => {}}
												onClick={() => {
													window.location.href = "/terms/mssa";
												}}
											>
												<FontAwesomeIcon
													className='generalStyles__info'
													icon={faCircleInfo}
												/>
												<span className='generalStyles__mlFix generalStyles__mrFix25 loginStyles__termsAndConditionsLink'>
													Terms and conditions
												</span>
											</div>
											{/** ********************************* */}
											{/** EULA PAGE */}
											<div
												role='button'
												tabIndex={0}
												onKeyDown={() => {}}
												onClick={() => {
													window.location.href = "/terms/eula";
												}}
											>
												<FontAwesomeIcon
													className='generalStyles__info'
													icon={faCircleInfo}
												/>
												<span className='generalStyles__mlFix generalStyles__mrFix25 loginStyles__termsAndConditionsLink'>
													End User License Agreement
												</span>
											</div>
											{/** ********************************* */}
											{/** SLA PAGE */}
											<div
												role='button'
												tabIndex={0}
												onKeyDown={() => {}}
												onClick={() => {
													window.location.href = "/terms/sla";
												}}
											>
												<FontAwesomeIcon
													className='generalStyles__info'
													icon={faCircleInfo}
												/>
												<span className='generalStyles__mlFix generalStyles__mrFix25 loginStyles__termsAndConditionsLink'>
													Service, Support and Training Policy
												</span>
											</div>
											{/** ********************************* */}
											{/** SUPPORT POLICY PAGE */}
											<div
												role='button'
												tabIndex={0}
												onKeyDown={() => {}}
												onClick={() => {
													window.location.href = "/terms/supportpolicy";
												}}
											>
												<FontAwesomeIcon
													className='generalStyles__info'
													icon={faCircleInfo}
												/>
												<span className='generalStyles__mlFix generalStyles__mrFix25 loginStyles__termsAndConditionsLink'>
													Support Policy
												</span>
											</div>
											<div />
										</div>
									</div>
								</div>
							</div>
						</Spin>
					</div>
				</div>
			)}
		</>
	);
};

export default LoginPage;
