import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// TIMEZONE
import timezones from "timezones-list";
import moment from "moment-timezone";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSearch,
	faFilter,
	faInfoCircle,
	faDownload,
	faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateReportRefreshTable,
	updateOpenDrawerFilters,
	updateFilters,
	updateOpenAlertDownloadReport,
} from "../../redux/reports/reportsSlice";
// COMPONENTS
import ReportsFilterDateRange from "./filters/ReportsFilterDateRange";
import ReportsFilterDateType from "./filters/ReportsFilterDateType";
import ReportsFilterDateTypeCharts from "./filters/ReportsFilterDateTypeCharts";
import ReportsFilterLevelType from "./filters/ReportsFilterLevelType";
import ReportsFilterGetBanks from "./filters/ReportsFilterGetBanks";
import ReportsFilterGetServers from "./filters/ReportsFilterGetServers";
import ReportsFilterGetOrganizations from "./filters/ReportsFilterGetOrganizations";
import ReportsFilterGetLifts from "./filters/ReportsFilterGetLifts";
import ReportsFilterDateDay from "./filters/ReportsFilterDateDay";
import ReportsFilterDateWeek from "./filters/ReportsFilterDateWeek";
import ReportsFilterDateMonth from "./filters/ReportsFilterDateMonth";
import ReportsFilterDateQuarter from "./filters/ReportsFilterDateQuarter";
import ReportsFilterDateYear from "./filters/ReportsFilterDateYear";
import ReportsFilterWeeksNumber from "./filters/ReportsFilterWeeksNumber";
import ReportsFilterLimitNumber from "./filters/ReportsFilterLimitNumber";
// INTERFACES
import { RootState } from "../../app/store";
import {
	TimeZoneInterface,
	ReportsHeaderInterface,
} from "../../interfaces/Reports.interface";

function ReportsHeader({ reportDefaultData }: ReportsHeaderInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getDefaultTimezone = () => {
		// Find default timezone
		const index = timezones.findIndex(
			(object: TimeZoneInterface) => object.tzCode === moment.tz.guess()
		);
		if (index >= 0) {
			return {
				timezoneName: timezones[index].name,
				timezoneLabel: timezones[index].label,
				timezoneCode: timezones[index].tzCode,
				timezoneUTC: timezones[index].utc,
			};
		}
		return {
			timezoneName: "",
			timezoneLabel: "",
			timezoneCode: "",
			timezoneUTC: "",
		};
	};

	const onClickResetFilters = () => {
		const defaultTime = getDefaultTimezone();
		// Format the current date to the specified format
		dispatch(
			updateFilters({
				reportType: reportDefaultData.reportType,
				levelTypeName: reportDefaultData.levelTypeName,
				displaySelectTypeLevel: reportDefaultData.displaySelectTypeLevel,
				liftType: reportDefaultData.liftType,
				startDate: moment().startOf("month").toISOString(),
				endDate: moment().endOf("month").toISOString(),
				dateType: "MONTH",
				organizationId: "",
				organizationName: "",
				serverName: "",
				serverId: "",
				liftName: "",
				liftId: "",
				bankName: "",
				bankId: "",
				banks: [],
				displayAdvanceFiltersExclusions:
					reportDefaultData.displayAdvanceFiltersExclusions,
				exclusionTimeList: [],
				exclusionDateList: [],
				exclusionTime: false,
				exclusionDate: false,
				exclusionSaturdays: false,
				exclusionSundays: false,
				exclusionHolidays: false,
				timezoneCode: defaultTime?.timezoneCode,
				timezoneLabel: defaultTime?.timezoneLabel,
				timezoneUTC: defaultTime?.timezoneUTC,
				timezoneName: defaultTime?.timezoneName,
				timezoneLocal: false,
				tableListColumns: [],
				tableSelectedColumns: [],
				useTablePagination: reportDefaultData.useTablePagination,
				defaultColumns: reportDefaultData.defaultColumns,
				displayAdvanceFiltersOffsets:
					reportDefaultData.displayAdvanceFiltersOffsets,
				disabledOffsets: reportDefaultData.disabledOffsets,
				defaultOffsets: reportDefaultData.defaultOffsets,
				offsetsList: reportDefaultData.defaultOffsets,
				infoDescriptionText: reportDefaultData.infoDescriptionText,
				reportName: reportDefaultData.reportName,
				reportServiceName: reportDefaultData.reportServiceName,
				displayDownloadCSVReport: reportDefaultData.displayDownloadCSVReport,
				displayDownloadPDFReport: reportDefaultData.displayDownloadPDFReport,
				displayRecentWeeksPicket: reportDefaultData.displayRecentWeeksPicket,
				displayInputLimitItems: reportDefaultData.displayInputLimitItems,
				weeksNumber: 5,
				limitNumber: 10,
				sortAsc: true,
				getFirstDefaultValue: true,
				carsList: [],
				selectedCarsList: [],
				chartType: reportDefaultData.chartType,
				chartFaultType: reportDefaultData.chartFaultType,
				displayAdvanceFilterCharts:
					reportDefaultData.displayAdvanceFilterCharts,
				displayAdvanceFiltersTable:
					reportDefaultData.displayAdvanceFiltersTable,
				displayDateByRange: reportDefaultData.displayDateByRange,
				displayChartMirror: reportDefaultData.displayChartMirror,
				chartMirror: reportDefaultData.chartMirror,
			})
		);
	};

	const onClickSearch = () => {
		dispatch(updateReportRefreshTable(true));
		setTimeout(() => {
			dispatch(updateReportRefreshTable(false));
		}, 1000);
	};

	const onClickDownloadCSVReport = () => {
		dispatch(updateOpenAlertDownloadReport(true));
	};

	const onClickDownloadPDFReport = () => {
		dispatch(updateOpenAlertDownloadReport(true));
	};

	const disabledSearchRerport = () => {
		let disabled = false;
		if (
			(filters.levelTypeName === "ORGANIZATIONS" &&
				filters.organizationId === "") ||
			(filters.levelTypeName === "SERVERS" && filters.serverId === "") ||
			(filters.levelTypeName === "BANKS" && filters.bankId === "") ||
			(filters.levelTypeName === "LIFTS" && filters.liftId === "")
		) {
			disabled = true;
		}
		return disabled;
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		onClickResetFilters();
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__spaceBetween'>
			<div className='generalStyles__flex mt-1'>
				{/**  DATE */}
				{!filters.displayRecentWeeksPicket && (
					<>
						{filters.displayDateByRange ? (
							<>
								<div className='generalStyles__mrFix generalStyles__width300px'>
									<ReportsFilterDateRange />
								</div>
							</>
						) : (
							<div className='generalStyles__mrFix generalStyles__width300px'>
								{filters.dateType === "TODAY" && <ReportsFilterDateDay />}
								{filters.dateType === "WEEK" && <ReportsFilterDateWeek />}
								{filters.dateType === "MONTH" && <ReportsFilterDateMonth />}
								{filters.dateType === "QUARTER" && <ReportsFilterDateQuarter />}
								{filters.dateType === "YEAR" && <ReportsFilterDateYear />}
							</div>
						)}
					</>
				)}
				{/**  DATE TYPE */}
				{!filters.displayRecentWeeksPicket && (
					<div className='generalStyles__mrFix generalStyles__width100px'>
						{filters.reportType === "CHART" ? (
							<ReportsFilterDateTypeCharts />
						) : (
							<ReportsFilterDateType />
						)}
					</div>
				)}
				{/** LIMIT ITEMS */}
				{filters.displayInputLimitItems && (
					<div className='generalStyles__width150px generalStyles__mrFix'>
						<ReportsFilterLimitNumber />
					</div>
				)}
				{/**  WEEKS PICKER */}
				{filters.displayRecentWeeksPicket && (
					<div className='generalStyles__width150px generalStyles__mrFix'>
						<ReportsFilterWeeksNumber />
					</div>
				)}

				{/** CASCADER LEVEL TYPE */}
				{filters.displaySelectTypeLevel && (
					<div className='generalStyles__mrFix generalStyles__width200px'>
						<ReportsFilterLevelType />
					</div>
				)}
				{/** FILTER BY CASCADER */}
				<div className='generalStyles__mrFix generalStyles__width200px'>
					<div>
						{filters.levelTypeName === "SERVERS" && <ReportsFilterGetServers />}
						{filters.levelTypeName === "BANKS" && <ReportsFilterGetBanks />}
						{filters.levelTypeName === "ORGANIZATIONS" && (
							<ReportsFilterGetOrganizations />
						)}
						{filters.levelTypeName === "LIFTS" && <ReportsFilterGetLifts />}
					</div>
				</div>
				{/** REPORT TYPE INFORMATION */}
				<div>
					<Tooltip title={t(`${filters.infoDescriptionText}`)}>
						<FontAwesomeIcon
							icon={faInfoCircle}
							fontSize={22}
							className='generalStyles__info'
						/>
					</Tooltip>
				</div>
			</div>
			<div className='generalStyles__flex'>
				{/** SEARCH */}
				<div className='generalStyles__mlFix'>
					<Tooltip
						placement='topLeft'
						title={
							disabledSearchRerport()
								? `${t(
										"general.reportsFilterLevelTypeRequired1"
								  )} ${filters.levelTypeName.slice(0, -1).toLowerCase()} ${t(
										"general.reportsFilterLevelTypeRequired2"
								  )}`
								: t("general.search")
						}
					>
						<Button
							className={
								disabledSearchRerport()
									? "buttonStyle__inactive__1__dark"
									: "buttonStyle__6"
							}
							icon={<FontAwesomeIcon icon={faSearch} />}
							onClick={() => {
								onClickSearch();
							}}
							disabled={disabledSearchRerport()}
						/>
					</Tooltip>
				</div>
				{/** DOWNLOAD CSV/EXCEL */}
				{filters.displayDownloadCSVReport && (
					<div className='generalStyles__mlFix'>
						<Tooltip
							placement='topLeft'
							title={`${t("general.download")} ${t("general.report")}`}
						>
							<Button
								className={
									disabledSearchRerport()
										? "buttonStyle__inactive__1__dark"
										: "buttonStyle__8"
								}
								icon={<FontAwesomeIcon icon={faDownload} />}
								onClick={onClickDownloadCSVReport}
								disabled={disabledSearchRerport()}
							/>
						</Tooltip>
					</div>
				)}
				{/** DOWNLOAD PDF */}
				{filters.displayDownloadPDFReport && (
					<div className='generalStyles__mlFix'>
						<Tooltip placement='topLeft' title={`${t("general.download")} PDF`}>
							<Button
								className={
									disabledSearchRerport()
										? "buttonStyle__inactive__1__dark"
										: "buttonStyle__8"
								}
								icon={<FontAwesomeIcon icon={faFilePdf} />}
								onClick={onClickDownloadPDFReport}
							/>
						</Tooltip>
					</div>
				)}
				{/** OPEN ADVANCE FILTERS DRAWER */}
				<div className='generalStyles__mlFix'>
					<Tooltip placement='topLeft' title={t("general.advanceFilters")}>
						<Button
							className='buttonStyle__6'
							icon={<FontAwesomeIcon icon={faFilter} />}
							onClick={() => {
								dispatch(updateOpenDrawerFilters(true));
							}}
						/>
					</Tooltip>
				</div>
			</div>
		</div>
	);
}

export default ReportsHeader;
