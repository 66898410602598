import React from "react";
// ANT DESIGN COMPONENTS
import { Menu, Dropdown, Space } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faTrash,
	faKey,
	faLock,
	faUnlock,
	faBan,
	faShield,
	faEdit,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";
import { udpateOpenModalTotpToken } from "../../../redux/authentication/authenticationSlice";
import {
	updateOpenModalDeleteUser,
	updateOpenModalCreateEditUser,
	updateOpenModalResetUserPassword,
	updateOpenModalBlockUnBlockUser,
	updateUser,
	updateFormCreateEdit,
} from "../../../redux/users/userSlice";
// UTILS
import validatePermission from "../../../utils/validatePermissions";

function UsersTableActions({ selectedItem, setPswType }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalEdit = () => {
		dispatch(updateUser(selectedItem));
		dispatch(updateOpenModalCreateEditUser(true));
		dispatch(updateFormCreateEdit("edit"));
	};

	const handleOpenModalDelete = () => {
		dispatch(updateUser(selectedItem));
		dispatch(updateOpenModalDeleteUser(true));
	};

	const handleOpenModalResetUserPassword = () => {
		dispatch(updateUser(selectedItem));
		dispatch(updateOpenModalResetUserPassword(true));
	};

	const handleOpenModalLockUnlock = () => {
		dispatch(updateUser(selectedItem));
		dispatch(updateOpenModalBlockUnBlockUser(true));
	};

	const handleGetOfflinePassword = () => {
		setPswType("get");
		dispatch(updateUser(selectedItem));
		dispatch(udpateOpenModalTotpToken(true));
	};

	const handleResetOfflinePassword = () => {
		setPswType("post");
		dispatch(updateUser(selectedItem));
		dispatch(udpateOpenModalTotpToken(true));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// ROW ACTIONS ************************************ */
	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon
							className='generalStyles__error generalStyles__mrFix'
							icon={faBan}
						/>
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const EDIT = validatePermission("users-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faEdit}
							text={t("organizationManagement.edit")}
							onClick={handleOpenModalEdit}
						/>
					),
					key: "1",
			  }
			: null;

		const GET_OFFLINE_PASSWORD =
			validatePermission("users-offline-psw-user-view", permissions) &&
			selectedItem &&
			selectedItem.created_offline_psw
				? {
						label: (
							<TableActionsMenuItem
								icon={faShield}
								text={t("userManagement.getOfflinePassword")}
								onClick={handleGetOfflinePassword}
							/>
						),
						key: "2",
				  }
				: null;

		const RESET_OFFLINE_PASSWORD = validatePermission(
			"users-offline-psw-user-create",
			permissions
		)
			? {
					label: (
						<TableActionsMenuItem
							icon={faShield}
							text={t("userManagement.generateNewPassword")}
							onClick={handleResetOfflinePassword}
						/>
					),
					key: "3",
			  }
			: null;

		const BLOCK_USER = validatePermission("users-block-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faLock}
							text={t("general.block")}
							onClick={handleOpenModalLockUnlock}
						/>
					),
					key: "4",
			  }
			: null;

		const UNLOCK_USER = validatePermission("users-unblock-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faUnlock}
							text={t("general.unlock")}
							onClick={handleOpenModalLockUnlock}
						/>
					),
					key: "5",
			  }
			: null;

		let BLOCK_UNLOCK_USER = null;
		if (selectedItem.status === "ACTIVE") {
			BLOCK_UNLOCK_USER = BLOCK_USER;
		} else {
			BLOCK_UNLOCK_USER = UNLOCK_USER;
		}

		const RESET_PASSWORD = validatePermission("users-reset-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faKey}
							text={t("userManagement.resetPassword")}
							onClick={handleOpenModalResetUserPassword}
						/>
					),
					key: "6",
			  }
			: null;

		const DELETE = validatePermission("users-delete", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faTrash}
							text={t("userManagement.delete")}
							onClick={handleOpenModalDelete}
						/>
					),
					key: "7",
			  }
			: null;
		// RETURN OBJECT WITH VALID ACTIONS
		if (selectedItem.status === "ACTIVE") {
			if (
				EDIT ||
				BLOCK_UNLOCK_USER ||
				RESET_PASSWORD ||
				DELETE ||
				RESET_OFFLINE_PASSWORD ||
				GET_OFFLINE_PASSWORD
			) {
				return [
					EDIT,
					GET_OFFLINE_PASSWORD,
					RESET_OFFLINE_PASSWORD,
					RESET_PASSWORD,
					DELETE,
					BLOCK_UNLOCK_USER,
				];
			}
		}
		if (selectedItem.status === "NEW") {
			if (EDIT || DELETE) {
				return [EDIT, DELETE];
			}
		}
		if (selectedItem.status === "BLOCKED") {
			if (BLOCK_UNLOCK_USER || DELETE) {
				return [DELETE, BLOCK_UNLOCK_USER];
			}
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Dropdown overlay={menu} trigger={["click"]}>
				<Space className='generalStyles__hoverCursor'>
					<FontAwesomeIcon icon={faEllipsis} />
				</Space>
			</Dropdown>
		</div>
	);
}

export default UsersTableActions;
