import React from "react";
// ANT DESIGN COMPONENTS
import { Input } from "antd";
// INTERFACES
import { FormInputJsonTextAreaInterface } from "../../../interfaces/Global.interface";

function FormInputJsonTextArea({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
  isRequired
}: FormInputJsonTextAreaInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { TextArea } = Input;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const isValidJSON = (str: string) => {
		try {
			JSON.parse(str);
			return true;
		} catch (e) {
			return false;
		}
	};
	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					{isRequired && <span className='generalStyles__inputFlexRequired'>*</span>}
					<span className='drawer__text'>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<TextArea
						className='generalStyles__input'
						size='small'
						rows={10}
						value={inputValue}
						onChange={(event: any) => {
							inputOnChange(event.currentTarget.value, inputVariableName);
						}}
						status={isValidJSON(inputValue) || inputValue === "" ? "" : "error"}
					/>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormInputJsonTextArea;
