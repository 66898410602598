/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Button, Spin, Table, Dropdown, Space, Menu, Empty } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faTrash,
	faPenToSquare,
	faBan,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOffsetType,
	updateOpenFormOffsetType,
	updateOpenDrawerOffsetType,
	updateOpenModalDeleteOffsetType,
	updateOpenFormOffsetTypeAction,
	updateShowOffsetTypeForm,
} from "../../../redux/tools/toolsSlice";
import { useLazyGetAllOffsetsTypesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// COMPONENTS
import OffsetTypeHeader from "./OffsetTypeHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

const { Column } = Table;

function OffsetTypeTable() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetOffsetsTypes,
		{ data: liftOffsetsType, isFetching: isFetchingGetOffsetsType },
	] = useLazyGetAllOffsetsTypesQuery();
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [selectedItem, setSelectedItem] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { offsetTypeRefreshTable, showOffsetTypeTable } = useSelector(
		(state: any) => state.tool
	);

	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon
							className='generalStyles__error generalStyles__mrFix'
							icon={faBan}
						/>
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const EDIT = validatePermission("offset-types-catalog", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalEdit();
							}}
							onKeyDown={() => {
								handleOpenModalEdit();
							}}
						>
							<span>
								<FontAwesomeIcon
									className='generalStyles__info generalStyles__mrFix'
									icon={faPenToSquare}
								/>
								{t("organizationManagement.edit")}
							</span>
						</div>
					),
					key: "2",
			  }
			: null;

		const DELETE = validatePermission("offset-types-catalog", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalDelete();
							}}
							onKeyDown={() => {
								handleOpenModalDelete();
							}}
						>
							<span>
								<FontAwesomeIcon
									className='generalStyles__info generalStyles__mrFix'
									icon={faTrash}
								/>
								{t("organizationManagement.delete")}
							</span>
						</div>
					),
					key: "3",
			  }
			: null;

		// RETURN OBJECT WITH VALID ACTIONS
		if (EDIT || DELETE) {
			return [EDIT, DELETE];
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickShowForm = () => {
		dispatch(updateShowOffsetTypeForm(true));
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const handleOpenModalEdit = () => {
		dispatch(updateOffsetType(selectedItem));
		dispatch(updateOpenFormOffsetType(true));
		dispatch(updateOpenFormOffsetTypeAction("update"));
	};

	const handleOpenModalDelete = () => {
		dispatch(updateOffsetType(selectedItem));
		dispatch(updateOpenModalDeleteOffsetType(true));
	};

	const onCellClick = (record: any) => {
		if (record) {
			dispatch(updateOffsetType(record));
			dispatch(updateOpenDrawerOffsetType(true));
		}
	};

	const getOffsetsList = async () => {
		const token = await GETJwtToken();
		triggerGetOffsetsTypes({ token });
	};

	const refreshTable = async () => {
		getOffsetsList();
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (showOffsetTypeTable) {
			getOffsetsList();
		}
	}, [showOffsetTypeTable]);

	useEffect(() => {
		if (offsetTypeRefreshTable) {
			refreshTable();
		}
	}, [offsetTypeRefreshTable]);
	// ************************************************ */
	// COMPONENT ************************************** */

	return (
		<div>
			{/** ----------------------------------------------------- */}
			{/** SHOW ALERT */}
			{!showOffsetTypeTable && (
				<div className='mb-4'>
					<Alert
						message={t("configuration.offsetTypeConfiguration.warning")}
						description={t(
							"configuration.offsetTypeConfiguration.warningDescription"
						)}
						type='warning'
						showIcon
						closable
					/>
					<Button
						className='buttonStyle__2 configuration__editRootPermisionsButton'
						onClick={onClickShowForm}
					>
						{t("configuration.offsetTypeConfiguration.showRootConfiguration")}
					</Button>
				</div>
			)}
			{/** ----------------------------------------------------- */}
			{/** OFFSET TYPE FORM */}
			{showOffsetTypeTable && (
				<Spin spinning={isLoading || isFetchingGetOffsetsType}>
					{validatePermission("offset-types-catalog", permissions) ? (
						<>
							<OffsetTypeHeader />
							{liftOffsetsType &&
								liftOffsetsType.data &&
								liftOffsetsType.data.length > 0 && (
									<Table
										rowClassName={(record, index) =>
											getTableRowClass(index, theme)
										}
										dataSource={liftOffsetsType.data}
										pagination={{
											defaultPageSize: 10,
											showSizeChanger: true,
											pageSizeOptions: ["10", "20", "50", "100"],
										}}
										size='small'
										className='mt-3'
										rowKey={(record) => record.id}
									>
										<Column
											title='Name'
											dataIndex='name'
											key='name'
											className=''
											onCell={(record) => ({
												onClick: () => {
													onCellClick(record);
												},
											})}
											render={(text) => (
												<div className='generalStyles__info'>{text}</div>
											)}
										/>
										<Column
											title={t("edgeManagement.banks.actions")}
											key='action'
											render={(_: any, record: any) => (
												<Dropdown
													overlay={menu}
													trigger={["click"]}
													className=''
												>
													<Space
														className='generalStyles__hoverCursor'
														onClick={() => {
															setSelectedItem(record);
														}}
													>
														<FontAwesomeIcon icon={faEllipsis} />
													</Space>
												</Dropdown>
											)}
										/>
									</Table>
								)}
						</>
					) : (
						<>
							<div className='generalStyles__noAccessToListTable'>
								<Empty
									description={t(
										"organizationManagement.listNotAvailableOrganizations"
									)}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							</div>
						</>
					)}
				</Spin>
			)}
		</div>
	);
}

export default OffsetTypeTable;
