import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Spin } from "antd";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteMessageTypeMutation } from "../../../redux/messageType/messagesTypeAPI";
import {
	updateOpenModalDelete,
	updateMessageType,
	updateFormAction,
} from "../../../redux/messageType/messagesTypeSlice";
// COMPONENTS
import ModalDeleteItemContainer from "../../components/modal/ModalDeleteItemContainer";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";

function MessageTypeDeleteModal() {
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();

	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { messageType, openModalDelete } = useSelector(
		(state: RootState) => state.messageType
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerDeleteMessageType,
		{ isLoading, isSuccess, isError, error, reset: resetDeleteMessageType },
	] = useDeleteMessageTypeMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenModalDelete(false));
		dispatch(updateFormAction("CREATE"));
		dispatch(updateMessageType({ id: "", name: "" }));
	};

	const onClickDelete = async () => {
		const token = await GETJwtToken();
		triggerDeleteMessageType({ id: messageType.id, token });
		dispatch(
			updateMessageType({
				id: "",
				name: "",
			})
		);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalDelete) {
			resetDeleteMessageType();
		}
	}, [openModalDelete]);

	useEffect(() => {
		if (isSuccess || isError) {
			dispatch(updateOpenModalDelete(false));
		}
	}, [isSuccess, isError]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={onClickClose}
			closable={false}
			onCancel={onClickClose}
			open={openModalDelete}
			footer={null}
		>
			<Spin spinning={isLoading}>
				<ModalDeleteItemContainer
					name={messageType.name}
					onClickDelete={onClickDelete}
					onClickClose={onClickClose}
				/>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='DELETE'
				error={error}
				name='Message Type'
			/>
		</Modal>
	);
}

export default MessageTypeDeleteModal;
