import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import ChannelsHeader from "./ChannelsHeader";
import ChannelsTable from "./ChannelsTable";
import ChannelsDeleteModal from "./ChannelsDeleteModal";
import ChannelsCreateEditForm from "./ChannelsCreateEditForm";
import ChannelsDrawer from "./ChannelsDrawer";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

function ChannelsPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const navigate = useNavigate();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);
	const { server } = useSelector((state: any) => state.server);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */0

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (!server) {
			navigate("/edge-management/servers");
		}
	}, []);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("channels", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ChannelsHeader />
					<ChannelsTable />
					<ChannelsDeleteModal />
					<ChannelsCreateEditForm />
					<ChannelsDrawer />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
}

export default ChannelsPage;
